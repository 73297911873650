import { compact, flatten } from 'lodash';
import { isEqual } from 'lodash';

// types
import { GroupedOptionsType, OptionType, OptionValueType } from 'components/Select/Select.types';

function findSelected<T extends OptionValueType>(
  value: OptionValueType | OptionValueType[],
  options: OptionType<T>[] | GroupedOptionsType<T> | undefined = []
): OptionType<T> | OptionType<T>[] | undefined {
  if (Array.isArray(value)) {
    return compact(flatten(value.map((selectedValue) => findSelected(selectedValue, options))));
  }

  if (Array.isArray(options)) {
    return options.find((option) => {
      return option.value === value || isEqual(option.value, value);
    });
  }

  return findSelected(value, flatten(Object.values(options)));
}

export default findSelected;
