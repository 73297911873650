import { getContactListContacts } from 'utils/api/contacts';
import { getContactListContactsQuery } from 'utils/constants/reactQueries';
import { useQuery } from 'react-query';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';

export const useContactsListsContactsQuery = (contactsListId?: string) => {
  const dispatch = useAppDispatch();

  return useQuery(
    [getContactListContactsQuery],
    async () => await getContactListContacts(contactsListId).then(({ data }) => data),
    {
      onError: (err) => {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error?.message ?? ''), error));
      },
      enabled: Boolean(contactsListId),
    }
  );
};
