import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import { TabVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';

export const tabPalette = (themeVariant: ThemeTokens): TabVariantsInterface => ({
  color: themeVariant.static.text.inverse.standard,
  active: {
    color: themeVariant.interactive.fill.primary.enabled,
    borderBottom: `2px solid ${themeVariant.interactive.fill.primary.enabled}`,
    hover: {
      backgroundColor: themeVariant.interactive.overlay.primary.hover,
    },
    focusVisible: {},
  },
  inactive: {
    hover: {
      backgroundColor: themeVariant.interactive.overlay.secondary.hover,
    },
    focusVisible: {
      backgroundColor: themeVariant.interactive.overlay.secondarySelected.enabled,
    },
  },
});
