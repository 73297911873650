import { LoginFlows } from 'pages/Login/UpdatePassword/UpdatePassword.interfaces';

export const getInitialScreenBasedOnFlow = (flow: LoginFlows) => {
  switch (flow) {
    case 'FORGOT_PASSWORD':
      return 'EMAIL_FOR_RESET_LINK_SCREEN';
    case 'NEW_SECURITY':
      return 'RECOMMEND_TO_UPDATE_PASSWORD_SCREEN';
    default:
      return 'EMAIL_FOR_RESET_LINK_SCREEN';
  }
};
