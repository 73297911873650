import { alpha } from '@mui/material/styles';
import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import { darkThemeColors } from './colors';

export const darkThemeTokens: ThemeTokens = {
  static: {
    background: {
      inverse: {
        weak: darkThemeColors.neutral[0],
        standard: darkThemeColors.neutral[10],
        strong: darkThemeColors.neutral[20],
      },
      default: {
        weak: darkThemeColors.neutral[80],
        standard: darkThemeColors.neutral[90],
        strong: darkThemeColors.neutral[100],
      },
    },
    text: {
      inverse: {
        weak: darkThemeColors.neutral[50],
        standard: darkThemeColors.neutral[60],
        strong: darkThemeColors.neutral[90],
      },
      default: {
        weak: darkThemeColors.neutral[50],
        standard: darkThemeColors.neutral[40],
        strong: darkThemeColors.neutral[10],
      },
      sentimentInverse: {
        negative: darkThemeColors.red[80],
        positive: darkThemeColors.green[80],
        notice: darkThemeColors.yellow[80],
        info: darkThemeColors.blue[80],
      },
      sentiment: {
        negative: darkThemeColors.red[40],
        positive: darkThemeColors.green[50],
        notice: darkThemeColors.yellow[40],
        info: darkThemeColors.blue[40],
      },
      divider: {
        default: {
          weak: alpha('#49657F', 0.1),
          standard: alpha('#49657F', 0.2),
          strong: alpha('#49657F', 0.32),
        },
        inverse: {
          weak: alpha('#899CAA', 0.1),
          standard: alpha('#899CAA', 0.2),
          strong: alpha('#899CAA', 0.32),
        },
      },
    },
    icon: {
      inverse: {
        weak: darkThemeColors.neutral[50],
        standard: darkThemeColors.neutral[60],
        strong: darkThemeColors.neutral[90],
      },
      default: {
        weak: darkThemeColors.neutral[50],
        standard: darkThemeColors.neutral[40],
        strong: darkThemeColors.neutral[10],
      },
    },
    support: {
      weak: {
        negative: alpha('#96040C', 0.24),
        positive: alpha('#004F13', 0.32),
        notice: alpha('#5D4008', 0.32),
        info: alpha('#0A547E', 0.32),
        neutral: alpha('#2F3942', 0.54),
      },
      standard: {
        negative: darkThemeColors.red[60],
        positive: darkThemeColors.green[60],
        notice: darkThemeColors.yellow[60],
        info: darkThemeColors.blue[60],
        neutral: darkThemeColors.neutral[60],
      },
      strong: {
        negative: darkThemeColors.red[40],
        positive: darkThemeColors.green[50],
        notice: darkThemeColors.yellow[40],
        info: darkThemeColors.blue[40],
        neutral: darkThemeColors.neutral[40],
      },
    },
  },
  interactive: {
    fill: {
      primary: {
        enabled: darkThemeColors.orange[70],
        hover: darkThemeColors.orange[80],
        pressed: darkThemeColors.orange[90],
        disabled: darkThemeColors.neutral[70],
        onPrimary: darkThemeColors.neutral[10],
        onPrimaryDisabled: darkThemeColors.neutral[60],
      },
      secondary: {
        enabled: darkThemeColors.neutral[60],
        hover: darkThemeColors.neutral[70],
        pressed: darkThemeColors.neutral[80],
        disabled: darkThemeColors.neutral[70],
        onSecondary: darkThemeColors.neutral[10],
        onSecondaryDisabled: darkThemeColors.neutral[60],
      },
      tertiary: {
        enabled: darkThemeColors.neutral[40],
        hover: darkThemeColors.neutral[30],
        pressed: darkThemeColors.neutral[60],
        disabled: darkThemeColors.neutral[70],
        onTertiary: darkThemeColors.neutral[100],
        onTertiaryDisabled: darkThemeColors.neutral[40],
      },
      support: {
        enabled: darkThemeColors.gold[50],
        hover: darkThemeColors.gold[60],
        pressed: darkThemeColors.gold[70],
        disabled: darkThemeColors.neutral[90],
        onSupport: darkThemeColors.neutral[90],
        onSupportDisabled: darkThemeColors.neutral[60],
      },
      inverse: {
        enabled: darkThemeColors.neutral[90],
        hover: darkThemeColors.neutral[80],
        pressed: darkThemeColors.neutral[70],
        disabled: darkThemeColors.neutral[20],
        onInverse: darkThemeColors.neutral[0],
        onInverseDisabled: darkThemeColors.neutral[40],
      },
      danger: {
        enabled: darkThemeColors.red[50],
        hover: darkThemeColors.red[60],
        pressed: darkThemeColors.red[80],
        disabled: darkThemeColors.red[100],
        onDanger: darkThemeColors.neutral[0],
        onDangerDisabled: darkThemeColors.red[90],
      },
    },

    outline: {
      primary: {
        default: darkThemeColors.orange[60],
        hover: darkThemeColors.orange[50],
        active: darkThemeColors.neutral[0],
        pressed: darkThemeColors.orange[40],
        disabled: darkThemeColors.neutral[70],
        error: darkThemeColors.red[80],
        focus: alpha(darkThemeColors.orange[60], 0.001),
      },
      secondary: {
        default: darkThemeColors.neutral[60],
        hover: darkThemeColors.neutral[50],
        active: darkThemeColors.neutral[0],
        pressed: darkThemeColors.neutral[40],
        disabled: darkThemeColors.neutral[70],
        error: darkThemeColors.red[80],
        focus: alpha('#93D2FF', 0.001),
      },
      inverse: {
        default: darkThemeColors.neutral[60],
        hover: darkThemeColors.neutral[80],
        active: darkThemeColors.neutral[90],
        pressed: darkThemeColors.neutral[100],
        disabled: darkThemeColors.neutral[30],
        error: darkThemeColors.red[80],
        focus: alpha('#7D8B95', 0.001),
      },
      danger: {
        default: darkThemeColors.red[70],
        enabled: darkThemeColors.red[60],
        hover: darkThemeColors.red[80],
        active: darkThemeColors.neutral[100],
        pressed: darkThemeColors.red[90],
        disabled: darkThemeColors.red[90],
      },
    },
    overlay: {
      primary: {
        enabled: alpha(darkThemeColors.orange[60], 0.001),
        hover: alpha(darkThemeColors.orange[60], 0.1),
        pressed: alpha(darkThemeColors.orange[60], 0.25),
      },
      primarySelected: {
        enabled: alpha(darkThemeColors.orange[60], 0.25),
        hover: alpha(darkThemeColors.orange[60], 0.3),
        pressed: alpha(darkThemeColors.orange[60], 0.4),
      },
      secondary: {
        enabled: alpha('#93D2FF', 0.001),
        hover: alpha('#93D2FF', 0.08),
        pressed: alpha('#93D2FF', 0.2),
      },
      secondarySelected: {
        enabled: alpha('#AFDDFF', 0.16),
        hover: alpha('#AFDDFF', 0.24),
        pressed: alpha('#AFDDFF', 0.32),
      },
      inverse: {
        enabled: alpha('#7D8B95', 0.001),
        hover: alpha('#7D8B95', 0.09),
        pressed: alpha('#7D8B95', 0.16),
      },
      inverseSelected: {
        enabled: alpha('#7D8B95', 0.15),
        hover: alpha('#7D8B95', 0.25),
        pressed: alpha('#7D8B95', 0.35),
      },
      danger: {
        enabled: alpha(darkThemeColors.red[70], 0.001),
        hover: alpha(darkThemeColors.red[70], 0.06),
        pressed: alpha(darkThemeColors.red[70], 0.1),
      },
      dangerSelected: {
        enabled: alpha(darkThemeColors.red[70], 0.05),
        hover: alpha(darkThemeColors.red[70], 0.1),
        pressed: alpha(darkThemeColors.red[70], 0.15),
      },
    },
  },
};
