import { Column, Row } from 'components/Grid';
import { StyledLabel } from 'components/TextArea/TextArea.styles';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledTagsWrapper = MuiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledWarning = MuiStyled(Box)`
  color: red;
`;

export const StyledStrong = MuiStyled('strong')`
  font-weight: 600;
`;

export const StyledNote = MuiStyled(StyledLabel)`
  margin-top: ${({ theme }) => `${theme.spacers['spacing-08']}`};
`;

export const RowModal = MuiStyled(Row)`
  padding-block: ${({ theme }) => `${theme.spacers['spacing-12']}`};
`;

export const ColumnModal = MuiStyled(Column)`
  padding-block: 0;
`;

export const StatusChangeWrapper = MuiStyled(Box)`
  padding: ${({ theme }) => `${theme.spacers['spacing-16']}`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacers['spacing-16']}`};
  background-color: ${({ theme }) => `${theme.palette.tertiary.lighter}`};
  margin-block: ${({ theme }) => `${theme.spacers['spacing-04']}`};
`;
