import Tag from 'components/Tag';
import TableCell, { MuiStyledTableCell } from './TableCell';
import { StyledShowMore, StyledShowMoreListElement } from './Table.styles';
import { ComponentPropsWithRef, useId, useState } from 'react';
import { Popover } from '@mui/material';

type TableShowMoreCellProps = ComponentPropsWithRef<typeof TableCell> & {
  items: string[];
  colSpan?: number;
};

export const TableShowMoreCell = ({ items, colSpan, ...props }: TableShowMoreCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = useId();

  const [firstItem, ...restItems] = items ?? [];

  return (
    <MuiStyledTableCell colSpan={colSpan} onClick={(event) => event.stopPropagation()}>
      {firstItem && <Tag label={firstItem} />}
      {Boolean(restItems.length) && (
        <>
          <StyledShowMore onClick={handleClick}>+ {restItems.length} more</StyledShowMore>
          <Popover
            id="popover-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ul onClick={handleClose}>
              {restItems.map((item, index) => (
                <StyledShowMoreListElement key={`${id}_${item}_${index}`}>
                  {item}
                </StyledShowMoreListElement>
              ))}
            </ul>
          </Popover>
        </>
      )}
    </MuiStyledTableCell>
  );
};
