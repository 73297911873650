import { StepsListType } from 'pages/Instruments/components/Form/types';

export const PARTNER_INFORMATION_STEP = 'PartnerInformation';
export const WALLETS_STEP = 'Wallets';
export const CALENDARS_STEP = 'Calendars';
export const USERS_STEP = 'Users';

export const PARTNER_INFORMATION_STEP_LABEL = 'Partner Details';

export type PartnerStepType =
  | typeof PARTNER_INFORMATION_STEP
  | typeof WALLETS_STEP
  | typeof CALENDARS_STEP
  | typeof USERS_STEP;

export const getFilteredSteps = (
  isNewPartner: boolean,
  calendarPermision: boolean,
  userPermission: boolean,
  walletPermision: boolean
): StepsListType<PartnerStepType>[] => {
  const filteredList: StepsListType<PartnerStepType>[] = [
    {
      id: PARTNER_INFORMATION_STEP,
      label: PARTNER_INFORMATION_STEP_LABEL,
    },
  ];
  if (!isNewPartner) {
    if (calendarPermision)
      filteredList.push({
        id: CALENDARS_STEP,
        label: CALENDARS_STEP,
      });
    if (userPermission)
      filteredList.push({
        id: USERS_STEP,
        label: USERS_STEP,
      });
    if (walletPermision)
      filteredList.push({
        id: WALLETS_STEP,
        label: WALLETS_STEP,
      });
  }

  return filteredList;
};
