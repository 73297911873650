import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { ProductBase, ProductStatus } from 'utils/types/product';
import { createEntityApiCall } from 'utils/api/crudActions';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { INSTRUMENTS_TAB_STATUS } from 'pages/Instruments/Instruments.constants';
import { getQueryString } from 'hooks/useUrlParams';
import { DEFAULT_PAGE, DEFAULT_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const ActivateProductModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const product = data.data as ProductBase;

  const productTypeLabel = data.custom?.isToken ? 'Token' : 'ETP';
  const productBaseRoute = data.custom?.isToken
    ? privateRoutesUrls.dashboardRoutes.tokens
    : privateRoutesUrls.dashboardRoutes.etps;

  const activateProductMutation = useMutation({
    mutationFn: (productData: ProductBase) =>
      createEntityApiCall(
        null,
        productData._actions?.activate?.uri ?? `products/id=${productData._id}/activate`
      ),
    onSuccess: () => {
      dispatch(
        createNotification(
          successNotification(
            `${product.name} ${productTypeLabel} has been activated`,
            productTypeLabel
          )
        )
      );
      navigate(
        productBaseRoute +
          `?${getQueryString({
            tab: INSTRUMENTS_TAB_STATUS.ACTIVE,
            page: DEFAULT_PAGE,
            pageSize: DEFAULT_ITEMS_PER_PAGE,
            status: ProductStatus.ACTIVE,
          })}`
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleActivate = () => {
    activateProductMutation.mutate(product);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={handleActivate}
        type="submit"
        data-qa-id="activateButton"
        isLoading={activateProductMutation.isLoading}
      >
        Activate
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        label={productTypeLabel}
        title={`Are you sure you want to activate ${product.name}?`}
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
