import { cloneDeep } from 'lodash';
import { sideNavItemsMap } from 'layouts/DashboardContainer/components/SideNav/sideNavigationMap';
import {
  NavItem,
  NavSubItem,
} from 'layouts/DashboardContainer/components/SideNav/SideNavigation.types';
import { AppRoute } from 'router/types';
import {
  customFilterBasedOnCompanyType,
  filterRoutesBasedOnPermissionsAndFeatureFlags,
} from 'router/utils';
import { User } from 'utils/types';

export const filteredSideNavItems = (routes: AppRoute[]) =>
  cloneDeep(sideNavItemsMap).filter((item: NavItem) => {
    if (item.subnav) {
      const filteredSubNav = item.subnav.filter((subItem: NavSubItem) =>
        routes.find((route) => route.path === subItem.path)
      );
      if (filteredSubNav.length === 0) {
        return false;
      }
      if (filteredSubNav.length !== item.subnav.length) {
        item.subnav = filteredSubNav;
      }
      return true;
    }
    return routes.find((route) => route.path === item.path);
  });

export const findNavItemBySubPath = (path: string) => {
  for (const navItem of sideNavItemsMap) {
    if (navItem.subnav) {
      const subNavItem = navItem.subnav.find((item) => item.path === path);
      if (subNavItem) {
        return navItem.title;
      }
    }
  }
};

export const getAllowedNavList = (user: User | null) =>
  (user &&
    filteredSideNavItems(
      customFilterBasedOnCompanyType(
        user,
        filterRoutesBasedOnPermissionsAndFeatureFlags(user.permissions ?? {})
      )
    )) ??
  [];
