import { Box, styled as MuiStyled } from '@mui/material';
import loginBg from 'assets/login-bg.svg';

export const StyledContainer = MuiStyled(Box)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const StyledContentWrapper = MuiStyled(Box)(({ theme }) => ({
  margin: 'auto 0',
  padding: '5px',
}));

export const StyledDetailContainer = MuiStyled(Box)`
  background-color: ${({ theme }) => theme.palette.tertiary.dark};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
`;

export const StyledLabel = MuiStyled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const StyledLogoWrapper = MuiStyled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  background-image: url(${loginBg});
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 175px;
  width: 100%;
`;
