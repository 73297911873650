export const ETPsWalletsTableColumnsConfig = [
  {
    label: 'Wallet Name',
    propName: 'walletName',
    propType: 'string',
    sort: false,
    width: '20%',
  },
  {
    label: 'Type',
    propName: 'type',
    propType: 'string',
    sort: false,
    width: '20%',
  },
  {
    label: 'Custodian',
    propName: 'custodian',
    propType: 'string',
    sort: false,
    width: '26%',
  },
  {
    label: 'Authorized Participant',
    propName: 'authorizedParticipant',
    propType: 'string',
    sort: false,
    width: '26%',
  },
];
