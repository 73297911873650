import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getRebalanceDetails } from 'utils/constants/reactQueries';
import { getRebalance } from 'utils/api/rebalance';

export const useCurrentRebalanceQuery = (productId?: string, date?: string) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getRebalanceDetails, productId, date],
    async () => {
      try {
        const dataResponse = await getRebalance(productId, date);
        return dataResponse;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(productId && date),
    }
  );
};
