import { ReactNode } from 'react';
import CreateExchangeModal from './CreateExchangeModal/CreateExchangeModal';
import DeleteExchangeModal from './DeleteExchangeModal/DeleteExchangeModal';
import UpdateExchangeModal from './UpdateExchangeModal/UpdateExchangeModal';
import { IModalWithData } from 'shared/Modals/types';

export const ExchangeModal = ({ ...props }: IModalWithData) => {
  const ExchangeModals: Record<string, ReactNode> = {
    CREATE: <CreateExchangeModal {...props} />,
    EDIT: <UpdateExchangeModal {...props} />,
    DELETE: <DeleteExchangeModal {...props} />,
  };

  return <>{ExchangeModals[props.data.type]}</>;
};
