import CssBaseline from '@mui/material/CssBaseline';
import spaceTokens from './utils/spacers';
import { customMuiOverrides } from './overrides/custom-mui';
import { createTheme, ThemeProvider as MuiThemeProvider, ThemeOptions } from '@mui/material/styles';
import { customShadows } from './utils/custom-shadows';
import { palette } from './palette';
import { shadows } from './utils/shadows';
import { typography } from './utils/typography';
import { useMemo } from 'react';
import { themeMode } from './themeTokens/themeMode';

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const themeVariant = 'light';
  const { customPalette, components } = themeMode(themeVariant);
  const memoizedValue = useMemo(
    () => ({
      palette: palette(themeVariant), // or palette('dark')
      shadows: shadows(themeVariant), // or shadows('dark')
      customShadows: customShadows(themeVariant), // or customShadows('dark')
      shape: { borderRadius: 8 },
      typography,
      spacers: spaceTokens,
      customPalette: customPalette,
      customComponents: components,
    }),
    []
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = customMuiOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
