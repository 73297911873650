import { styled as MuiStyled } from '@mui/material';

// components
import Button from 'components/Button';

export const StyledButton = MuiStyled(Button)`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
