// styles
import {
  StyledInformationContainer,
  StyledPrintContainer,
} from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { SecuritiesRegister } from 'pages/Orders/Register/types';
import { RegisterInformation } from 'pages/Orders/Register/RegisterDetails/sections/RegisterInformation';
import { ProductInformation } from 'pages/Orders/Register/RegisterDetails/sections/ProductInformation';
import { HistoricalStockSplits } from 'pages/Orders/Register/RegisterDetails/sections/HistoricalStockSplits/index';

interface RegisterPdfProps {
  singleRegister?: SecuritiesRegister;
}

export const RegisterPdf = ({ singleRegister }: RegisterPdfProps) => {
  return (
    <StyledPrintContainer>
      <StyledInformationContainer id={`pdf-${singleRegister?._id}`}>
        <ProductInformation register={singleRegister} />
        {singleRegister?.stockSplits && singleRegister?.stockSplits?.length > 0 && (
          <HistoricalStockSplits splits={singleRegister?.stockSplits} />
        )}
        <RegisterInformation register={singleRegister} />
      </StyledInformationContainer>
    </StyledPrintContainer>
  );
};
