import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const inverseButton = (theme: Theme) => {
  const inverseButton = theme.customComponents.buttonPalette.inverse;

  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'inverse' && (!props.emphasis || props.emphasis === 'high'),
      style: {
        color: inverseButton.highEmphasis.color,
        backgroundColor: inverseButton.highEmphasis.backgroundColor,
        boxShadow: '0px 3px 1px 0px rgba(0, 0, 0, 0.08)',
        '&:hover': {
          backgroundColor: inverseButton.highEmphasis.hover?.backgroundColor,
        },
        '&:active': {
          backgroundColor: inverseButton.highEmphasis.active?.backgroundColor,
        },
        '&:focus-visible': {
          boxShadow: inverseButton.highEmphasis.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: inverseButton.highEmphasis.disabled?.color,
          backgroundColor: inverseButton.highEmphasis.disabled?.backgroundColor,
          '& svg': {
            fill: inverseButton.highEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: inverseButton.highEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'inverse' && props.emphasis === 'medium',
      style: {
        color: inverseButton.mediumEmphasis.color,
        border: inverseButton.mediumEmphasis.border,
        '&:hover': {
          color: inverseButton.mediumEmphasis.hover?.color,
          border: inverseButton.mediumEmphasis.hover?.border,
          '& svg': {
            fill: inverseButton.mediumEmphasis.hover?.color,
          },
        },
        '&:active': {
          color: inverseButton.mediumEmphasis.active?.color,
          backgroundColor: inverseButton.mediumEmphasis.active?.backgroundColor,
          '& svg': {
            fill: inverseButton.mediumEmphasis.active?.color,
          },
        },
        '&:focus-visible': {
          border: inverseButton.mediumEmphasis.focusVisible?.border,
          boxShadow: inverseButton.mediumEmphasis.focusVisible?.boxShadow,
          backgroundColor: inverseButton.mediumEmphasis.focusVisible?.backgroundColor,
        },
        '&.Mui-disabled': {
          color: inverseButton.mediumEmphasis.disabled?.color,
          border: inverseButton.mediumEmphasis.disabled?.border,
          '& svg': {
            fill: inverseButton.mediumEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: inverseButton.mediumEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'inverse' && props.emphasis === 'low',
      style: {
        color: inverseButton.lowEmphasis.color,
        '&:hover': {
          color: inverseButton.lowEmphasis.hover?.color,
          backgroundColor: inverseButton.lowEmphasis.hover?.backgroundColor,
          '& svg': {
            fill: inverseButton.lowEmphasis.hover?.color,
          },
        },
        '&:active': {
          color: inverseButton.lowEmphasis.active?.color,
          backgroundColor: inverseButton.lowEmphasis.active?.backgroundColor,
          '& svg': {
            fill: inverseButton.lowEmphasis.active?.color,
          },
        },
        '&:focus-visible': {
          backgroundColor: inverseButton.lowEmphasis.focusVisible?.backgroundColor,
          boxShadow: inverseButton.lowEmphasis.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: inverseButton.lowEmphasis.disabled?.color,
          '& svg': {
            fill: inverseButton.lowEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: inverseButton.lowEmphasis.color,
        },
      },
    },
  ];
};
