import { Box, TextField } from '@mui/material';
import TableCell from 'components/Table/TableCell';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { styled as MuiStyled } from '@mui/material/styles';

export const StyledEditTableCell = MuiStyled(TableCell)`
  display: flex;
  height: inherit;
  gap: 8px;
  justify-content: space-between;
  button {
    visibility: hidden;
    align-self: center;
  }
  span {
    align-self: center;
    padding-right: 16px;
  }
  &:hover {
    button {
      visibility: visible;
    }
  }
`;

export const StyledTextField = MuiStyled(TextField)`
  background-color: ${({ theme }) => `${theme.palette.tertiary.lighter}`};
  border: 2px #9dc3fb solid;
  & input[type=number]::-webkit-outer-spin-button,
  & input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  & input[type=number] {
    -moz-appearance: textfield;
  }
  .MuiInputBase-input {
    padding: 11.5px 14px;
    widt
  }
`;

export const StyledActionsWrapper = MuiStyled(Box)`
  display: flex;
  svg {
    cursor: pointer;
    overflow: unset;
    height: 20px;
    width: 20px;
    padding-left: 2px;
    &:hover {
      background-color: ${({ theme }) => `${theme.palette.tertiary.dark}`};
    }
  }
`;

export const StyledCloseIcon = MuiStyled(CloseIcon)`
  padding: 4px;
`;

export const StyledInputTableCell = MuiStyled(TableCell)`
  padding: 0px;
`;

export const StyledCalculating = MuiStyled('span')`
  padding-inline: 16px;
`;
