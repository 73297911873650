import { Theme, alpha } from '@mui/material/styles';
import { tableRowClasses } from '@mui/material/TableRow';

// ----------------------------------------------------------------------

export function table(theme: Theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            borderRadius: theme.spacers['spacing-08'],
          },
          '& .MuiTableCell-root': {
            whiteSpace: 'nowrap',
            ...theme.typography.labelMediumStrong,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.customBackground.default.strong,
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightSemiBold,
          backgroundColor: theme.palette.customBackground.default.weak,
        },
        '&:last-child': {
          borderColor: 'transparent',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '.editIcons': {
            display: 'none',
          },
          '.actionsCell': {
            visibility: 'hidden',
          },
          '&:hover': {
            backgroundColor: theme.palette.overlay.secondary.hover,
            '.editIcons': {
              display: 'inline-grid',
            },
            '.actionsCell': {
              visibility: 'unset',
            },
          },
          [`&.${tableRowClasses.selected}`]: {
            backgroundColor: alpha(theme.palette.primary.dark, 0.04),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.dark, 0.08),
              cursor: 'pointer',
              border: '1px solid red',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomStyle: 'solid',
        },
        head: {
          fontSize: 14,
          color: theme.palette.text.secondary,
          fontWeight: theme.typography.fontWeightSemiBold,
          backgroundColor: theme.palette.grey[200],
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.grey[200]} 0%, ${theme.palette.grey[200]} 100%)`,
        },
        paddingCheckbox: {
          paddingLeft: theme.spacing(1),
        },
      },
    },
  };
}
