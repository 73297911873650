import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { ContactList } from 'utils/types';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { contactListDeletedNotifications } from 'shared/Notifications/contactList.notifications';
import { createNotification } from 'store/notifications/actions';
import { deleteEntityApiCall } from 'utils/api/crudActions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import Card from 'components/Card';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Typography } from '@mui/material';

export const DeleteContactListsModal = ({
  closeModal,
  onCloseModalAction,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const contactList = data.data as ContactList;
  const deleteUriPath = `/admin/contact-lists/id=${contactList._id}`;
  const contactListName = contactList.name;

  const deleteContactListMutation = useMutation({
    mutationFn: (uriPath: string) => {
      return deleteEntityApiCall(uriPath);
    },
    onSuccess: () => {
      dispatch(createNotification(contactListDeletedNotifications.success(contactListName)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const handleDelete = () => {
    deleteContactListMutation.mutate(deleteUriPath);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="interactive"
        fullWidth
        onClick={handleDelete}
        type="button"
        data-qa-id="deactivateButton"
        isLoading={deleteContactListMutation.isLoading}
      >
        Delete
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card footer={Footer} onClose={closeModal} title="Delete Distribution List">
        <Typography>{`Are you sure you want to delete ${contactListName} List?`}</Typography>
      </Card>
    </CustomModal>
  );
};
