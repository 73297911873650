import { ActivateWalletModal } from './ActivateWallet/ActivateWalletModal';
import { ProductConstituentAssetsProps, TokenConstituentAssetsProps } from 'utils/types/product';
import { CustodiansListProps } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import { DeleteWalletModal } from './DeleteWallet/DeleteWalletModal';
import { IModalWithData } from 'shared/Modals/types';
import { CompanySuggestionDto } from 'utils/types';
import { ReactNode } from 'react';
import { UpdateCustodianWalletModal } from './UpdateWallet/UpdateCustodianWalletModal';
import { UpdateProductsWalletModal } from './UpdateWallet/UpdateProductsWalletModal';
import { UpdateOwnWalletModal } from './UpdateWallet/UpdateOwnWalletModal';
import { WalletUnionType, WalletType } from 'utils/types/wallets';

export interface WalletModalData {
  wallet?: WalletUnionType;
  type: WalletType;
  productData?: {
    authorizedParticiants: string[];
    partners: CompanySuggestionDto[];
    custodians: CustodiansListProps;
    constituents: ProductConstituentAssetsProps | TokenConstituentAssetsProps;
  };
}

export const WalletsModal = ({ ...props }: IModalWithData) => {
  const { type } = props.data.data as WalletModalData;
  const { productData } = props.data.data as WalletModalData;

  const getEditOrCreate = (type: WalletType) => {
    if (type === WalletType.CUSTODIAN_WALLET) {
      if (productData) {
        return <UpdateProductsWalletModal {...props} />;
      }
      return <UpdateCustodianWalletModal {...props} />;
    }
    if (type === WalletType.OWN_WALLET) {
      return <UpdateOwnWalletModal {...props} />;
    }
  };

  const WalletsModals: Record<string, ReactNode> = {
    CREATE: getEditOrCreate(type),
    EDIT: getEditOrCreate(type),
    DELETE: <DeleteWalletModal {...props} />,
    CUSTOM: <ActivateWalletModal {...props} />,
  };

  return <>{WalletsModals[props.data.type]}</>;
};
