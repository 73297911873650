import useAppDispatch from 'hooks/useAppDispatch';
import { ExchangeForm } from 'shared/Modals/Exchange/ExchangeForm';
import { IModalWithData } from 'shared/Modals/types';
import { createExchange } from 'utils/api/exchanges';
import { createNotification } from 'store/notifications/actions';
import { exchangeCreatedNotifications } from 'shared/Notifications/exchange.notifications';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useCalendars } from 'pages/Admin/CalendarsPage/hooks/useCalendars';
import { useMutation } from 'react-query';
import { CustomModal } from 'shared/Modals/Modal';

const CreateExchangeModal = ({ closeModal, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const permissions = useUserPermissions();
  const loggedInUser = useAuthenticatedUser();
  const { calendars, loading } = useCalendars(
    loggedInUser.user?.organization.companyId,
    permissions?.canViewSystemCalendar
  );

  const createExchangeMutation = useMutation({
    mutationFn: createExchange,
    onSuccess: (data) => {
      dispatch(createNotification(exchangeCreatedNotifications.success(data?.name)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(exchangeCreatedNotifications.error(error.message), error));
    },
  });

  const isLoading = loading || createExchangeMutation.isLoading;

  return (
    <CustomModal open onCloseModal={closeModal}>
      <ExchangeForm
        calendars={calendars}
        loading={isLoading}
        onCancel={closeModal}
        onSubmit={createExchangeMutation.mutate}
      />
    </CustomModal>
  );
};

export default CreateExchangeModal;
