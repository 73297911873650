import useAppDispatch from 'hooks/useAppDispatch';
import { fetchCurrency } from 'store/currency/actions';
import { useCurrency } from 'store/currency/selectors';
import { isNil, upperCase } from 'lodash';
import { useEffect, useMemo } from 'react';

export default function useCurrencyOptions() {
  const dispatch = useAppDispatch();
  const currency = useCurrency();

  const currencyOptions = useMemo(
    () =>
      Array.isArray(currency.data)
        ? currency.data.map((currency) => ({
            label: upperCase(currency.ticker),
            value: currency.ticker,
          }))
        : [],
    [currency.data]
  );

  useEffect(() => {
    if (isNil(currency.data) && !currency.error && !currency.loading) {
      dispatch(fetchCurrency());
    }
  }, [currency.data, dispatch, currency.error, currency.loading]);

  return currencyOptions;
}
