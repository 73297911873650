import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const dangerButton = (theme: Theme) => {
  const dangerButton = theme.customComponents.buttonPalette.danger;
  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'danger' && (!props.emphasis || props.emphasis === 'high'),
      style: {
        color: dangerButton.highEmphasis.color,
        backgroundColor: dangerButton.highEmphasis.backgroundColor,
        '&:hover': {
          backgroundColor: dangerButton.highEmphasis.hover?.backgroundColor,
        },
        '&:active': {
          backgroundColor: dangerButton.highEmphasis.active?.backgroundColor,
        },
        '&:focus-visible': {
          backgroundColor: dangerButton.highEmphasis.focusVisible?.backgroundColor,
          boxShadow: dangerButton.highEmphasis.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: dangerButton.highEmphasis.disabled?.color,
          backgroundColor: dangerButton.highEmphasis.disabled?.backgroundColor,
          '& svg': {
            fill: dangerButton.highEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: dangerButton.highEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'danger' && props.emphasis === 'medium',
      style: {
        color: dangerButton.mediumEmphasis.color,
        border: dangerButton.mediumEmphasis.border,
        '&:hover': {
          color: dangerButton.mediumEmphasis.hover?.color,
          border: dangerButton.mediumEmphasis.hover?.border,
          '& svg': {
            fill: dangerButton.mediumEmphasis.hover?.color,
          },
        },
        '&:active': {
          color: dangerButton.mediumEmphasis.active?.color,
          border: dangerButton.mediumEmphasis.active?.border,
          '& svg': {
            fill: dangerButton.mediumEmphasis.active?.color,
          },
        },
        '&:focus-visible': {
          boxShadow: dangerButton.mediumEmphasis.focusVisible?.boxShadow,
          border: dangerButton.mediumEmphasis.focusVisible?.border,
          backgroundColor: dangerButton.mediumEmphasis.focusVisible?.backgroundColor,
        },
        '&.Mui-disabled': {
          color: dangerButton.mediumEmphasis.disabled?.color,
          border: dangerButton.mediumEmphasis.disabled?.border,
          '& svg': {
            fill: dangerButton.mediumEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: dangerButton.mediumEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'danger' && props.emphasis === 'low',
      style: {
        color: dangerButton.lowEmphasis.color,
        '&:hover': {
          color: dangerButton.lowEmphasis.hover?.color,
          backgroundColor: dangerButton.lowEmphasis.hover?.backgroundColor,
          '& svg': {
            fill: dangerButton.lowEmphasis.hover?.color,
          },
        },
        '&:active': {
          color: dangerButton.lowEmphasis.active?.color,
          backgroundColor: dangerButton.lowEmphasis.active?.backgroundColor,
          '& svg': {
            fill: dangerButton.lowEmphasis.active?.color,
          },
        },
        '&:focus-visible': {
          boxShadow: dangerButton.lowEmphasis.focusVisible?.boxShadow,
          backgroundColor: dangerButton.lowEmphasis.focusVisible?.backgroundColor,
        },
        '&.Mui-disabled': {
          color: dangerButton.lowEmphasis.disabled?.color,
          '& svg': {
            fill: dangerButton.lowEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: dangerButton.lowEmphasis.color,
        },
      },
    },
  ];
};
