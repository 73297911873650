import { IModalWithData } from 'shared/Modals/types';
import { EditConstituentModal } from './EditConstituentsModal/EditConstituentModal';
import { CreateConstituentModal } from './CreateConstituentModal/CreateConstituentModal';
import { ReactNode } from 'react';

export const ConstituentModal = ({ ...props }: IModalWithData) => {
  const ConstituentModals: Record<string, ReactNode> = {
    CREATE: <CreateConstituentModal {...props} />,
    EDIT: <EditConstituentModal {...props} />,
  };
  return <>{ConstituentModals[props.data.type]}</>;
};
