import { differenceInHours, format, parseISO } from 'date-fns';
import { dailyTableDateFormat } from 'shared/Tables/table.utils';

function getUTCHours(date: string | Date): number {
  const dateObject = new Date(date);
  const hours = String(dateObject.getUTCHours()).padStart(2, '0');
  return Number(hours);
}

function getUTCHoursWithAMPM(date: string | Date) {
  const dateObject = new Date(date);
  const hours = dateObject.getUTCHours();
  const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${minutes} ${amPm}`;
}

function getUTCOffsetInHours(timezone: string = 'Europe/Zurich') {
  const localTime = new Date();
  const utcTime = new Date(localTime.getTime() - localTime.getTimezoneOffset() * 60000);
  const localWithTimezone = new Date(localTime.toLocaleString('en-US', { timeZone: timezone }));
  const offsetInHours = differenceInHours(utcTime, localWithTimezone);
  return offsetInHours;
}

function getDate(dateString: string) {
  return format(parseISO(dateString), dailyTableDateFormat);
}

export default {
  hours: getUTCHours,
  AMPM: getUTCHoursWithAMPM,
  timezoneOffset: getUTCOffsetInHours,
  date: getDate,
};
