export const REBALANCE_TAB_STATUS = {
  TODAY: 'today',
  HISTORIC: 'past',
  UPCOMING: 'upcoming',
};

export enum REBALANCE_STATUS_LABEL {
  TODAY = 'Today',
  HISTORIC = 'Past',
  UPCOMING = 'Upcoming',
}

export type UsersTabType =
  | typeof REBALANCE_TAB_STATUS.TODAY
  | typeof REBALANCE_TAB_STATUS.HISTORIC
  | typeof REBALANCE_TAB_STATUS.UPCOMING
  | undefined;
