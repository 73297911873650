// documentation of onyx design token system: https://www.figma.com/file/G4QZm8Wj1LuOGDOEwzxHFk/%F0%9F%9A%A7--ONYX-Design-System
const colors = {
  white: '#FFFFFF',
  gray10: '#F7F7F7',
  gray15: '#F2F2F2',
  gray20: '#E6E6E6',
  gray25: '#D9D9D9',
  gray30: '#CCCCCC',
  gray40: '#B3B3B3',
  gray50: '#999999',
  gray60: '#808080',
  gray70: '#666666',
  gray75: '#595959',
  gray80: '#4D4D4D',
  gray90: '#333333',
  gray100: '#1A1A1A',
  red10: '#FFEEEE',
  red20: '#FFCCCC',
  red30: '#FF9999',
  red40: '#FF6666',
  red50: '#FF3333',
  red55: '#E60000',
  red60: '#FF0000',
  red90: '#660000',
  orange10: '#FFF8F3',
  orange50: '#FF8833',
  orange70: '#CC5500',
  yellow20: '#FCEDCF',
  yellow50: '#F3B73F',
  yellow55: '#F1AE27',
  yellow60: '#F0A50F',
  green10: '#F1FFF5',
  green20: '#D6F5DD',
  green30: '#ADEBBB',
  green50: '#5BD778',
  green70: '#28A445',
  green90: '#145222',
  blue10: '#F1F7FF',
  blue20: '#CEE1FD',
  blue30: '#9DC3FB',
  blue40: '#6CA4F9',
  blue50: '#3B86F7',
  blue60: '#0A68F5',
  blue65: '#095EDC',
  blue70: '#0853C4',
  blue80: '#063E93',
  blue90: '#042A62',
};

// opacity in hex values: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
const opacityInHex = {
  op05: '0D',
  op15: '26',
  op25: '40',
  op50: '80',
};

const colorWithOpacityInHex = (color: String, opacityInHex: String) =>
  [color, opacityInHex].join('');

const colorTokens = {
  // CORE TOKENS:
  // ui
  'color-ui-background': colors.gray10,
  'color-ui-01': colors.white,
  'color-ui-02': colors.gray10,
  'color-ui-03': colors.gray20,
  'color-ui-04': colors.gray50,
  'color-ui-05': colors.gray100,
  // interactive
  'color-interactive-01': colors.yellow50,
  'color-interactive-02': colors.gray80,
  'color-interactive-03': colors.gray80,
  'color-interactive-04': colors.blue60,
  // text
  'color-text-01': colors.gray100,
  'color-text-02': colors.gray80,
  'color-text-03': colors.gray40,
  'color-text-04': colors.gray100,
  'color-text-05': colors.white,
  'color-text-06': colors.gray60,
  'color-text-error': colors.red50,
  'color-link-01': colors.blue60,
  // icon
  'color-icon-01': colors.gray100,
  'color-icon-02': colors.gray80,
  'color-icon-03': colors.white,
  // field
  'color-field-01': colors.gray10,
  'color-field-02': colors.white,
  // support
  'color-info': colors.blue70,
  'color-success': colors.green70,
  'color-warning': colors.orange70,
  'color-error': colors.red50,
  'color-notification-info-background': colors.blue10,
  'color-notification-success-background': colors.green10,
  'color-notification-warning-background': colors.orange10,
  'color-notification-error-background': colors.red10,
  // inverse
  'color-inverse-01': colors.white,
  'color-inverse-02': colors.gray90,
  'color-inverse-hover': colors.gray80,
  'color-inverse-focus': colors.white,
  'color-inverse-info': colors.blue50,
  'color-inverse-success': colors.green50,
  'color-inverse-warning': colors.orange50,
  'color-inverse-error': colors.red40,
  'color-inverse-link': colors.blue40,
  // special
  'color-danger': colors.red50,
  'color-overlay-01': colorWithOpacityInHex(colors.gray100, opacityInHex.op50),
  'color-overlay-02': colorWithOpacityInHex(colors.gray90, opacityInHex.op25),
  // focus
  'color-focus': colors.blue30,
  // hover
  'color-hover-primary': colors.yellow55,
  'color-hover-link': colors.blue65,
  'color-hover-secondary': colors.gray75,
  'color-hover-tertiary': colors.yellow55,
  'color-hover-transparent': colorWithOpacityInHex(colors.gray100, opacityInHex.op05),
  'color-hover-ui': colors.gray15,
  'color-hover-row': colors.gray15,
  'color-hover-selected-ui': colors.gray25,
  'color-hover-danger': colors.red55,
  // active
  'color-active-primary': colors.yellow60,
  'color-active-secondary': colors.gray70,
  'color-active-tertiary': colors.yellow60,
  'color-active-transparent': colorWithOpacityInHex(colors.gray100, opacityInHex.op15),
  'color-active-ui': colors.gray20,
  'color-active-danger': colors.red60,
  // visited
  'color-visited-link': colors.blue80,
  // state
  'color-disabled-01': colors.gray10,
  'color-disabled-02': colors.gray30,
  'color-disabled-03': colors.gray50,
  'color-selected-ui': colors.gray20,
  // skeleton
  'color-skeleton-01': colors.gray15,
  'color-skeleton-02': colors.gray30,
  // highlight
  'color-highlight': colors.yellow20,
  // tags
  'color-tag-01': colors.gray90,
  'color-tag-background-01': colors.gray20,
  'color-tag-hover-01': colors.gray30,
  'color-tag-02': colors.white,
  'color-tag-background-02': colors.gray80,
  'color-tag-hover-02': colors.gray70,
  'color-tag-03': colors.green90,
  'color-tag-background-03': colors.green20,
  'color-tag-hover-03': colors.green30,
  'color-tag-04': colors.red90,
  'color-tag-background-04': colors.red20,
  'color-tag-hover-04': colors.red30,
  'color-tag-05': colors.blue90,
  'color-tag-background-05': colors.blue20,
  'color-tag-hover-05': colors.blue30,
  'color-tag-06': colors.white,
  'color-tag-background-06': colors.gray100,
  'color-tag-hover-06': colors.gray100,
  // util
  'color-transparent': 'transparent',
};

export default colorTokens;
