import { TableCell } from 'components/Table';
import { styled as MuiStyled, TableCell as MuiTableCell } from '@mui/material';

export const StyledTableCellWithChevron = MuiStyled('td')`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  min-width: 350px;
  display: flex;
  text-align: end;
  align-items: center;
  cursor: pointer;
  div {
    padding-right: ${({ theme }) => theme.spacers['spacing-16']};
  }
`;

export const StyledTableCellChevron = MuiStyled(TableCell)`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  width: 48px;
  cursor: pointer;
  padding: 0;
`;

export const MuiStyledTableCellChevron = MuiStyled(MuiTableCell)(({ theme }) => ({
  width: '68px',
  cursor: 'pointer',
  padding: '0 12px',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));
