import Card from 'components/Card';
import fontTypes from 'utils/theme/designTokens/fontTypes';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledConstituentModalLabel = MuiStyled('span')`
  ${() => fontTypes['body-01']};
  color: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledFieldsContainer = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacers['spacing-32']};
  margin-bottom: 32px;
`;

export const StyledCard = MuiStyled(Card)`
  width: 560px;
`;
