import { useEffect, useRef, useState } from 'react';

//types
import { FormPropsType, FormSchemaResponse } from 'components/Form/Form.types';
import { UseFormDataProps, UseFormDataReturnType } from 'components/Form/hooks/useFormData.types';

// helpers
import { retrieveFormProps } from 'components/Form/helpers/formData';
import { getFormSchema } from 'components/Form/formSchemas';
import { uniq } from 'lodash';

const useFormData = ({ schemaUrl }: UseFormDataProps): UseFormDataReturnType => {
  const schemaRef = useRef<string>();
  const [schema, setSchema] = useState<FormSchemaResponse | undefined>();
  const [formProps, setFormProps] = useState<FormPropsType>({
    defaultValues: {},
    types: {},
    enums: {},
    descriptions: {},
    placeholders: {},
    formatDescriptions: {},
    required: {},
    labels: {},
    errorMessage: {},
  });
  const [formLoading, setFormLoading] = useState<boolean>(true);

  useEffect(() => {
    if (schemaUrl && schemaUrl !== schemaRef.current) {
      schemaRef.current = schemaUrl;

      setFormLoading(true);
      getFormSchema(schemaUrl)
        ?.then((response) => {
          if (typeof response.data === 'object') {
            // ajv-error expects a key named `errorMessage` to properly set up the error
            // https://github.com/ajv-validator/ajv-errors/issues/141
            const jsonSchema = JSON.parse(
              JSON.stringify(response.data).replaceAll('x-errorMessage', 'errorMessage')
            );

            if (jsonSchema.properties?.marketOpen) {
              jsonSchema.properties.marketOpen = {
                ...jsonSchema.properties.marketOpen,
                isTimeBefore: 'marketClose',
              };
            }

            // TODO: reenable type validation when we work on fundIcon upload
            if (jsonSchema.properties?.fundIcon) {
              delete jsonSchema.properties.fundIcon.type;
            }

            setSchema({ ...jsonSchema, required: uniq(jsonSchema.required) });
          } else {
            setFormLoading(false);
          }
        })
        .catch(() => setFormLoading(false));
    }
  }, [schemaUrl]);

  useEffect(() => {
    if (schema) {
      const tmpFormProps = {
        defaultValues: {},
        types: {},
        enums: {},
        descriptions: {},
        placeholders: {},
        formatDescriptions: {},
        required: {},
        labels: {},
        errorMessage: {},
      };

      retrieveFormProps(schema, tmpFormProps);

      setFormProps(tmpFormProps);
      setFormLoading(false);
    }
  }, [schema]);

  return {
    formLoading,
    formProps,
    schema,
  };
};

export default useFormData;
