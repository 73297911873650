import { Chain } from 'utils/types/product';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg';

interface AddressLinkProps {
  destinationChain: Chain;
  address: string;
  align?: 'left' | 'right';
  gap?: boolean;
}

function AddressLink({
  destinationChain = Chain.ETHEREUM,
  address,
  align,
  gap = true,
}: AddressLinkProps) {
  let href = '';

  if (destinationChain === Chain.ETHEREUM) {
    href = `https://etherscan.io/address/${address}`;
  } else if (destinationChain === Chain.SOLANA) {
    href = `https://solscan.io/account/${address}`;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: `${align === 'left' ? 'flex-start' : 'flex-end'}`,
        gap: gap ? '0.5em' : '0em',
      }}
    >
      {address ? (
        <>
          <a
            style={{ textDecoration: href ? 'underline' : '' }}
            href={href}
            target={href ? '_blank' : ''}
            rel={href ? 'noreferrer' : ''}
          >
            {address}
          </a>
          {href && <ExternalLinkIcon style={{ minWidth: '25px' }} />}
        </>
      ) : (
        '-'
      )}
    </div>
  );
}

export default AddressLink;
