import ExchangeLocalTicker from 'pages/Instruments/components/Form/EtpDetails/components/ExchangeStep/components/ExchangeLocalTicker';
import {
  ExchangeLocalTickerType,
  ExchangeType,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import Table from 'components/Table';
import { TableHead as MuiTableHead, TableBody } from '@mui/material';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell, MuiStyledTableHeaderCell } from 'components/Table/TableCell';

interface ExchangeLocalTickerProps {
  productExchange: ExchangeType;
  localTickers?: ExchangeLocalTickerType[];
  onEdit: (localTicker: ExchangeLocalTickerType) => void;
  onDelete: (localTickerIndex: number) => void;
}

function ExchangeLocalTickerList({
  productExchange,
  localTickers = [],
  onEdit,
  onDelete,
}: ExchangeLocalTickerProps) {
  return (
    <MuiStyledTableRow hoverEffects={false}>
      <MuiStyledTableCell colSpan={5}>
        <Table noPadding={true}>
          <MuiTableHead>
            <MuiStyledTableRow hoverEffects={false}>
              <MuiStyledTableHeaderCell width="44%">Local ticker</MuiStyledTableHeaderCell>
              <MuiStyledTableHeaderCell width="42%">Currency</MuiStyledTableHeaderCell>
              <MuiStyledTableHeaderCell width="42%">Listing Date</MuiStyledTableHeaderCell>
              <MuiStyledTableHeaderCell width="8%"></MuiStyledTableHeaderCell>
            </MuiStyledTableRow>
          </MuiTableHead>
          <TableBody>
            {localTickers.map((localTicker, index) => (
              <ExchangeLocalTicker
                key={`${productExchange.name}-${localTicker.name}-${localTicker.currency}`}
                localTicker={localTicker}
                onEdit={onEdit}
                onDelete={() => onDelete(index)}
              />
            ))}
          </TableBody>
        </Table>
      </MuiStyledTableCell>
    </MuiStyledTableRow>
  );
}

export default ExchangeLocalTickerList;
