import useAppDispatch from 'hooks/useAppDispatch';
import { Deliverable, OrderFormProps } from 'utils/types/orders';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { SingleOrderView } from 'pages/Orders/Orders/Order/components/SingleOrderView';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCallback, useState, useEffect } from 'react';
import { useCreateOrder } from 'pages/Orders/Orders/Order/hooks/useCreateOrder';
import { useInstrumentsDelegatedOrder } from 'hooks/useInstrumentsQuery';
import { useLoadOrderDetails } from 'pages/Orders/Orders/Order/hooks/useLoadOrderDetails';
import { TitleArea } from 'components/TitleArea/TitleArea';

const DelegatedOrder = () => {
  const dispatch = useAppDispatch();
  const [updatedOrderData, setUpdatedOrderData] = useState<OrderFormProps>();
  const [seedDeliverables, setSeedDeliverables] = useState<Deliverable[]>([]);
  const { user } = useAuthenticatedUser();
  const { instruments, isLoading: loadingInstruments } = useInstrumentsDelegatedOrder();
  const instrument = instruments?.find((inst) => inst._id === updatedOrderData?.productId);
  const { data: order, isLoading: loading } = useLoadOrderDetails(updatedOrderData, instrument);
  const { createDelegatedOrder } = useCreateOrder();

  useEffect(() => {
    const isSeedOrder = Boolean(order) && !Boolean(order?._id) && !order?.hasPortfolioComposition;
    isSeedOrder &&
      !seedDeliverables.length &&
      setSeedDeliverables([...(order?.deliveries?.expected ?? [])]);
  }, [order, instrument?._id]);

  const handleOnSubmit = async (orderToSubmit: OrderFormProps) => {
    const isSeedOrder = Boolean(order) && !Boolean(order?._id) && !order?.hasPortfolioComposition;
    createDelegatedOrder.mutate({
      ...orderToSubmit,
      deliveries: isSeedOrder ? seedDeliverables : [],
      settlementType: orderToSubmit.settlementType || instrument?.standardSettlement!,
    });
  };

  const handleChangeOrder = useCallback(
    async (orderFormData: OrderFormProps | undefined) => {
      if (orderFormData?.productId) {
        try {
          setUpdatedOrderData(orderFormData);
        } catch (err) {
          const error = err as Error;
          dispatch(createNotification(errorNotification(error.message, 'Error'), error));
        }
      } else {
        setUpdatedOrderData(undefined);
        setSeedDeliverables([]);
      }
    },
    [dispatch]
  );

  const handleChangeSeedDeliverable = (deliverable: Deliverable) => {
    setSeedDeliverables([
      ...seedDeliverables.filter(
        (seedDeliverable) => seedDeliverable.ticker !== deliverable.ticker
      ),
      deliverable,
    ]);
  };

  return (
    <>
      <TitleArea
        title={<OrderPageHeader productType="ETP" isNewOrder isDelegatedOrder />}
        showBackButton={true}
      />
      <SingleOrderView
        productType="ETP"
        changeOrder={handleChangeOrder}
        onChangeSeedDeliverable={handleChangeSeedDeliverable}
        isSubmitting={createDelegatedOrder.isLoading}
        loading={loadingInstruments || loading}
        onSubmit={handleOnSubmit}
        order={order}
        orderFlow="delegate"
        seedDeliverables={seedDeliverables}
        user={user}
      />
    </>
  );
};

export default DelegatedOrder;
