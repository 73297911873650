import { ReactComponent as Ascending } from 'assets/ascending.svg';
import { ReactComponent as Descending } from 'assets/descending.svg';
import { ReactComponent as ExchangeIcon } from 'assets/exchange.svg';
import { ReactComponent as InfoSvg } from 'assets/info.svg';
import { TableCell as MuiTableCell } from '@mui/material';
import { styled as MuiStyled } from '@mui/material';

interface StyledIconProps {
  hovered?: string | undefined;
}

interface SizeProps {
  sizeprop?: 'small' | 'medium' | 'large';
}

export const MuiStyledTableCells = MuiStyled(MuiTableCell)<SizeProps>(
  ({ theme, sizeprop = 'medium' }) => ({
    whiteSpace: 'nowrap',
    alignItems: 'center',
    gap: '5px',
    '&.MuiTableCell-root': {
      padding: '0 24px',
      height: {
        small: '32px',
        medium: '48px',
        large: '68px',
      }[sizeprop],
    },
  })
);

export const MuiStyledHeaderTableCells = MuiStyled(MuiTableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    padding: '0 24px',
    lineHeight: 'unset',
    height: '40px',
    whiteSpace: 'nowrap',
  },
}));

export const MuiStyledExchangeIcon = MuiStyled(ExchangeIcon)<StyledIconProps>(
  ({ theme, hovered }) => ({
    visibility: hovered ? 'visible' : 'hidden',
    marginLeft: theme.spacers['spacing-04'],
    width: '12px',
    height: '12px',
    fill: hovered ? theme.palette.grey[700] : theme.palette.grey[400],
  })
);

export const MuiStyledAscendingIcon = MuiStyled(Ascending)<StyledIconProps>(
  ({ theme, hovered }) => ({
    width: '12px',
    height: '12px',
    fill: hovered ? theme.palette.grey[700] : theme.palette.grey[400],
  })
);

export const MuiStyledDescendingIcon = MuiStyled(Descending)<StyledIconProps>(
  ({ theme, hovered }) => ({
    width: '12px',
    height: '12px',
    fill: hovered ? theme.palette.grey[700] : theme.palette.grey[400],
  })
);

export const StyledInfoSvg = MuiStyled(InfoSvg)(({ theme }) => ({
  verticalAlign: 'top',
  marginLeft: theme.spacing(1),
  fill: theme.palette.secondary.main,
}));
