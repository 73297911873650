import { CompanySuggestionDto } from 'utils/types';
import { ProductCustodianWallet } from 'utils/types/wallets';
import Button from 'components/Button';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';

export type ETPWalletsTableRowProps = {
  wallet: ProductCustodianWallet;
  custodianName: string;
  partners: CompanySuggestionDto[];
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
};

export const ETPWalletsTableRow = ({
  wallet,
  partners,
  custodianName,
  editAction,
  deleteAction,
  activateAction,
}: ETPWalletsTableRowProps) => {
  return (
    <MuiStyledTableRow
      key={wallet._id}
      onClick={() => {
        wallet?._actions?.update && editAction();
      }}
    >
      <MuiStyledTableCell>{wallet.description}</MuiStyledTableCell>
      <MuiStyledTableCell>{wallet.chain}</MuiStyledTableCell>
      <MuiStyledTableCell>{custodianName}</MuiStyledTableCell>
      <MuiStyledTableCell>
        {partners.find((partner) => partner._id === wallet.transactingCompany)?.name}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell
        align="right"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {wallet?._actions && Object.keys(wallet?._actions) && (
          <TablePopoverMenu id={wallet._id ?? ''} className="actionsCell">
            {wallet?._actions?.activate && (
              <Button
                data-qa-id="activateWallet"
                fullWidth
                variant="ghost"
                onClick={activateAction}
              >
                Activate wallet
              </Button>
            )}
            {wallet?._actions?.delete && (
              <Button data-qa-id="deleteWallet" fullWidth variant="ghost" onClick={deleteAction}>
                Deactivate wallet
              </Button>
            )}
          </TablePopoverMenu>
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
