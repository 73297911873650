import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const secondaryButton = (theme: Theme) => {
  const secondaryButton = theme.customComponents.buttonPalette.secondary;
  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'secondary' && (!props.emphasis || props.emphasis === 'high'),
      style: {
        color: secondaryButton.highEmphasis.color,
        backgroundColor: secondaryButton.highEmphasis.backgroundColor,
        '&:hover': {
          backgroundColor: secondaryButton.highEmphasis.hover?.backgroundColor,
        },
        '&:active': {
          backgroundColor: secondaryButton.highEmphasis.active?.backgroundColor,
        },
        '&:focus-visible': {
          boxShadow: secondaryButton.highEmphasis.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: secondaryButton.highEmphasis.disabled?.color,
          backgroundColor: secondaryButton.highEmphasis.disabled?.backgroundColor,
          '& svg': {
            fill: secondaryButton.highEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: secondaryButton.highEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'secondary' && props.emphasis === 'medium',
      style: {
        color: secondaryButton.mediumEmphasis.color,
        border: secondaryButton.mediumEmphasis.border,
        '&:hover': {
          border: secondaryButton.mediumEmphasis.hover?.border,
        },
        '&:active': {
          border: secondaryButton.mediumEmphasis.active?.border,
        },
        '&:focus-visible': {
          color: secondaryButton.mediumEmphasis.focusVisible?.color,
          border: secondaryButton.mediumEmphasis.focusVisible?.border,
          boxShadow: secondaryButton.mediumEmphasis.focusVisible?.boxShadow,
          background: secondaryButton.mediumEmphasis.focusVisible?.backgroundColor,
          '& svg': {
            fill: secondaryButton.mediumEmphasis.focusVisible?.color,
          },
        },
        '&.Mui-disabled': {
          color: secondaryButton.mediumEmphasis.disabled?.color,
          border: secondaryButton.mediumEmphasis.disabled?.border,
          '& svg': {
            fill: secondaryButton.mediumEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: secondaryButton.mediumEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'secondary' && props.emphasis === 'low',
      style: {
        color: secondaryButton.lowEmphasis.color,
        '&:hover': {
          color: secondaryButton.lowEmphasis.hover?.color,
          backgroundColor: secondaryButton.lowEmphasis.hover?.backgroundColor,
          '& svg': {
            fill: secondaryButton.lowEmphasis.hover?.color,
          },
        },
        '&:active': {
          color: secondaryButton.lowEmphasis.active?.color,
          backgroundColor: secondaryButton.lowEmphasis.active?.backgroundColor,
          '& svg': {
            fill: secondaryButton.lowEmphasis.active?.color,
          },
        },
        '&:focus-visible': {
          backgroundColor: secondaryButton.lowEmphasis.focusVisible?.backgroundColor,
          boxShadow: secondaryButton.lowEmphasis.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: secondaryButton.lowEmphasis.disabled?.color,
          '& svg': {
            fill: secondaryButton.lowEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: secondaryButton.lowEmphasis.color,
        },
      },
    },
  ];
};
