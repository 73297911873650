import { FC } from 'react';
import Card from 'components/Card';
import {
  StyledPasscodeInput,
  StyledRightBottomButton,
} from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const Passcode: FC<{ handleVerifyPasscode: (otpCode: number) => void }> = ({
  handleVerifyPasscode,
}) => {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm<{ passcodeOTPInput: number }>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<{ passcodeOTPInput: number }> = ({ passcodeOTPInput }) => {
    handleVerifyPasscode(passcodeOTPInput);
  };

  return (
    <Card
      title="Security code"
      label="2FA Setup"
      footer={
        <MuiStyledModalFooterButtons>
          <StyledRightBottomButton
            variant="interactive"
            type="submit"
            form="passcodeForm"
            disabled={!isValid}
          >
            Verify
          </StyledRightBottomButton>
        </MuiStyledModalFooterButtons>
      }
    >
      Enter your authenticator 6-digit passcode
      <form id="passcodeForm" onSubmit={handleSubmit(onSubmit)}>
        <StyledPasscodeInput
          autoFocus
          {...register('passcodeOTPInput', {
            required: 'Enter otp code',
            minLength: {
              value: 6,
              message: 'OTP code length should be 6 digits',
            },
            maxLength: {
              value: 6,
              message: 'OTP code length should be 6 digits',
            },
          })}
          type="number"
        />
      </form>
    </Card>
  );
};
