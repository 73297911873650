import keyBy from 'lodash/keyBy';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { getETPExchanges } from 'utils/constants/reactQueries';
import { loadExchanges } from 'utils/api/exchanges';
import { useQuery } from 'react-query';

export const useExchangesQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery([getETPExchanges], () =>
    loadExchanges()
      .then((exchanges) => {
        return keyBy(exchanges, '_id');
      })
      .catch((error: Error) => {
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      })
  );
};
