import InstrumentForm from 'pages/Instruments/components/Form/ETPForm';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  INSTRUMENT_DETAILS_TAB,
  PARTNER_DETAILS_TAB,
  producTabs,
} from 'pages/Instruments/helpers/productTabs';
import { ETPPageHeader } from 'pages/Instruments/components/ETPPageHeader';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { getEtpDetailsUrl, getPartnerDetailsUrl } from 'pages/Instruments/components/Form/utils';
import { instrumentsActions } from 'store/instruments/slice';
import { privateRoutesUrls } from 'router/constants';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { useUserPermissions } from 'store/user/selectors';

function CreateInstrument() {
  const dispatch = useAppDispatch();
  const permissions = useUserPermissions();
  const navigate = useNavigate();
  const params = useParams();
  const { instrument } = useCurrentInstrument();
  const { removeCurrentInstrument } = instrumentsActions;

  const isCreateEtpDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.newEtpProductsEtpDetails)
  );
  const isEditEtpDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.editEtpProductsEtpDetails)
  );
  const isEtpDetailsRoute = isCreateEtpDetailsRoute || isEditEtpDetailsRoute;
  const activeTab = isEtpDetailsRoute ? INSTRUMENT_DETAILS_TAB : PARTNER_DETAILS_TAB;

  useEffect(() => {
    if (!permissions?.canCreateEtp) {
      navigate(privateRoutesUrls.dashboardRoutes.rootPath);
    }
  }, [navigate, permissions?.canCreateEtp]);

  useEffect(() => {
    return () => {
      dispatch(removeCurrentInstrument());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = (tab: string) => {
    if (tab === INSTRUMENT_DETAILS_TAB) {
      navigate(getEtpDetailsUrl(params.id));
    } else {
      navigate(getPartnerDetailsUrl(params.id));
    }
  };

  return (
    <>
      <TitleArea
        showBackButton={true}
        title={<ETPPageHeader isNewProduct={true} instrument={instrument} />}
        tabConfig={{
          tabs: producTabs,
          setUrlParamsWithDefaults: (data) => {
            handleChangeTab(data.tab as string);
          },
          active: activeTab,
        }}
      />
      <InstrumentForm activeTab={activeTab} />
    </>
  );
}

export default CreateInstrument;
