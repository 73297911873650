import { Theme } from '@mui/material/styles';

export function card(theme: Theme) {
  return {
    // Card
    MuiCard: {
      styleOverrides: {
        root: {
          // @TODO add theme colors
          border: '1.25px solid #98ABB833',
          borderRadius: theme.spacing(2),
          position: 'relative',
          padding: theme.spacing(2),
        },
      },
    },
    // Body
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
    // Footer
    MuiCardActions: {
      styleOverrides: {
        root: {
          // @TODO add option to add border top if the card doesn't contain a table?
          // @TODO add theme colors
          justifyContent: 'flex-end',
          padding: '0',
          paddingTop: theme.spacing(2),
        },
      },
    },
  };
}
