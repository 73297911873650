import Button from 'components/Button';
import {
  StyledDatePickerContainer,
  StyledPCFActionsContainer,
  StyledToolbar,
} from 'pages/Ledger/PCF/components/PCFStyles';

import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { PortfolioComposition, PortfolioCompositionStatus } from 'utils/types/pcfs';
import SingleDatePicker from 'components/DatepickerSingleDate/index';
import { useUserPermissions } from 'store/user/selectors';
import { DateValue } from 'components/DatepickerSingleDate';
import { NewCard } from 'components/NewCard/NewCard';

export interface PCFDetailsToolbarActionsHeaderProps {
  date: Date | null;
  handleDateChange: (value: DateValue) => void;
  isApprovingPCF: Boolean;
  isRefreshingSynthetic: Boolean;
  openDocument: (url?: string) => void;
  pcf?: PortfolioComposition;
  refreshOnyxData: () => void;
  setIsApproveModalOpen: (val: Boolean) => void;
  isTradingToday?: boolean;
}

export const PCFDetailsToolbarActionsHeader = ({
  date,
  handleDateChange,
  isApprovingPCF,
  isRefreshingSynthetic,
  openDocument,
  pcf,
  refreshOnyxData,
  setIsApproveModalOpen,
  isTradingToday,
}: PCFDetailsToolbarActionsHeaderProps) => {
  const permissions = useUserPermissions();

  return (
    <NewCard
      sx={{ padding: 0 }}
      body={
        <StyledToolbar>
          <StyledDatePickerContainer>
            <SingleDatePicker
              date={date}
              onChange={handleDateChange}
              inputDateFormat={'dd/MM/yyyy'}
              maxDate={new Date()}
            />
          </StyledDatePickerContainer>
          <StyledPCFActionsContainer>
            <Button
              onClick={() => openDocument(pcf?.documents?.officialPdf?.url)}
              size="medium"
              variant="tertiary"
              disabled={!(Boolean(pcf?.documents?.officialPdf?.url) && permissions?.canDownloadPcf)}
            >
              <span>PDF</span>
              <DownloadIcon />
            </Button>
            <Button
              onClick={() => openDocument(pcf?.documents?.officialExcel?.url)}
              size="medium"
              variant="tertiary"
              disabled={
                !(Boolean(pcf?.documents?.officialExcel?.url) && permissions?.canDownloadPcf)
              }
            >
              <span>XLS</span>
              <DownloadIcon />
            </Button>
            {pcf?.status !== PortfolioCompositionStatus.APPROVED && permissions?.canComparePcfs && (
              <Button
                isLoading={Boolean(isRefreshingSynthetic)}
                disabled={Boolean(isApprovingPCF) || !Boolean(isTradingToday)}
                onClick={() => refreshOnyxData()}
                size="medium"
                variant="secondary"
              >
                Refresh Onyx PCF Data
              </Button>
            )}
            {pcf?._actions?.approve && permissions?.canApprovePcfOverride && (
              <Button
                isLoading={Boolean(isApprovingPCF)}
                disabled={Boolean(isRefreshingSynthetic)}
                onClick={() => setIsApproveModalOpen(true)}
                size="medium"
                variant="interactive"
              >
                Approve PCF
              </Button>
            )}
          </StyledPCFActionsContainer>
        </StyledToolbar>
      }
    />
  );
};
