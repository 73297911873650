import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getTokenOrderDetails } from 'utils/api/tokens';
import { getTokenOrderDetailsQueryKey } from 'utils/constants/reactQueries';
import { OrderFormProps, Token } from 'utils/types';

export const useGetTokenOrderDetails = (orderProps?: OrderFormProps, token?: Token) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getTokenOrderDetailsQueryKey, { orderProps, token }],
    async () => {
      if (orderProps?.productId && orderProps) {
        try {
          const tokensResponse = await getTokenOrderDetails({
            ...orderProps,
          });
          return {
            ...tokensResponse,
            product: {
              ...tokensResponse.product,
            },
          };
        } catch (err) {
          const error = err as Error;
          dispatch(createNotification(errorNotification(error.message ?? ''), error));
        }
      }
    },
    {
      enabled: Boolean(orderProps?.productId && orderProps),
    }
  );
};
