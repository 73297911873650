import { ForwardedRef, MutableRefObject, RefCallback } from 'react';

function mergeRefs<T>(
  ...refs: Array<RefCallback<T> | ForwardedRef<T> | MutableRefObject<T> | null>
): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    });
  };
}

export default mergeRefs;
