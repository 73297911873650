import Button from 'components/Button';
import { IModalWithData } from 'shared/Modals/types';

import Card from 'components/Card';
import { CustomModal } from 'shared/Modals/Modal';
import { useApproveRebalanceDetails } from 'pages/Rebalance/hooks/useCalculateRebalanceDetails';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import { TradesTable } from './TradesTable/TradesTable';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Typography } from '@mui/material';

export const ApproveRebalanceModal = ({ data, onCloseModalAction, closeModal }: IModalWithData) => {
  const onSuccessCallback = () => {
    onCloseModalAction && onCloseModalAction();
    closeModal();
  };

  const approveRebalanceMutation = useApproveRebalanceDetails(onSuccessCallback);
  const rebalance = data.data as RebalanceWithDetails;

  const onSubmitHandler = () => {
    if (rebalance) {
      approveRebalanceMutation.mutate({
        id: rebalance?._id,
      });
    }
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={() => onSubmitHandler()}
        type="submit"
        data-qa-id="confirmButton"
        isLoading={approveRebalanceMutation.isLoading}
      >
        Approve Trades
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card title="Approval Confirmation" label={'Rebalance'} onClose={closeModal} footer={Footer}>
        <Typography variant="subheadingMedium">
          Would you like to approve the following trades:
        </Typography>
        <TradesTable rebalance={rebalance} />
      </Card>
    </CustomModal>
  );
};
