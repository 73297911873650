import Footer from 'pages/Instruments/components/Footer';
import useAppDispatch from 'hooks/useAppDispatch';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { ConstituentsTable } from 'shared/Tables/ConstituentsTable/ConstituentsTable';
import { ProductConstituentAssetType, ProductStatus } from 'utils/types/product';
import { createNotification } from 'store/notifications/actions';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ConstituentAssetType, ProductConstituentAssetsProps } from 'utils/types/product';

interface ConstituentProps {
  goBack: () => void;
  onSubmit: () => void;
}

function ConstituentStep({ goBack, onSubmit }: ConstituentProps) {
  const dispatch = useAppDispatch();
  const { saveInstrumentDraft } = useSaveETPHook();
  const { instrument, saving, loading } = useCurrentInstrument();
  const constituents =
    (useCurrentInstrumentFormData(CONSTITUENT_STEP) as ProductConstituentAssetsProps) ?? [];

  const handleRemoveConstituent = (constituentToUpdate: ConstituentAssetType) => {
    const updatedConstituentsList = constituents?.filter((el) => {
      return el._id !== String(constituentToUpdate._id);
    });
    if (updatedConstituentsList.length === 0) {
      if (instrument?.status === ProductStatus.ACTIVE) {
        dispatch(
          createNotification({
            message: 'Active Product should always have at least one constituent.',
            title: 'Constituents update',
            type: 'error',
          })
        );
        return;
      }
    }
    saveInstrumentDraft({
      [CONSTITUENT_STEP]: [...updatedConstituentsList] as ProductConstituentAssetType[],
    });
  };

  return (
    <>
      <ConstituentsTable
        deleteAction={handleRemoveConstituent}
        constituentsList={constituents}
        loading={loading || saving}
      />
      <Footer goBack={goBack} onSubmit={onSubmit} />
    </>
  );
}

export default ConstituentStep;
