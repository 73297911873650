// styles
import Input from 'components/Input';
import Grid from '@mui/material/Grid';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridItem from 'components/Form/components/FormGridItem';

function renderFees() {
  return (
    <>
      <Grid item marginTop={2} md={12}>
        <ProductConfigHeader title="Admin Fees" />
      </Grid>
      <FormGridItem>
        <Input name="adminCreationFee" />
      </FormGridItem>
      <FormGridItem>
        <Input name="adminRedemptionFee" />
      </FormGridItem>
      <Grid item marginTop={2} xs={12}>
        <ProductConfigHeader title="Execution Fees" />
      </Grid>
      <FormGridItem>
        <Input
          name="navPlusCreationExecutionFee"
          data-qa-id="navPlusCreationExecutionFee"
          data-qa-options-id="navPlusCreationExecutionFee"
        />
      </FormGridItem>
      <FormGridItem>
        <Input
          name="navPlusRedemptionExecutionFee"
          data-qa-id="navPlusRedemptionExecutionFee"
          data-qa-options-id="navPlusRedemptionExecutionFee"
        />
      </FormGridItem>
      <FormGridItem>
        <Input
          name="navGuaranteedCreationExecutionFee"
          data-qa-id="navGuaranteedCreationExecutionFee"
          data-qa-options-id="navGuaranteedCreationExecutionFee"
        />
      </FormGridItem>
      <FormGridItem>
        <Input
          name="navGuaranteedRedemptionExecutionFee"
          data-qa-id="navGuaranteedRedemptionExecutionFee"
          data-qa-options-id="navGuaranteedRedemptionExecutionFee"
        />
      </FormGridItem>
    </>
  );
}

export default renderFees;
