import { Routes, Route } from 'react-router-dom';
import PrivateDashboardRouteGuard from 'router/guards/PrivateDashboardRouteGuard';
import { publicRoutes, privateWeakRoutes, privateDashboardRoutes } from 'router/constants';
import PrivateWeakRouteGuard from 'router/guards/PrivateWeakRouteGuard';
import { useAuthenticatedUser } from 'store/user/selectors';
import NotFoundPage from 'pages/NotFound/NotFoundPage';
import { useEffect, useState } from 'react';
import {
  customFilterBasedOnCompanyType,
  filterRoutesBasedOnPermissionsAndFeatureFlags,
} from 'router/utils';

function AppRoutes() {
  const { user } = useAuthenticatedUser();
  const [privateRoutes, setPrivateRoutes] = useState(privateDashboardRoutes);

  useEffect(() => {
    if (user) {
      const allowedRoutes = customFilterBasedOnCompanyType(
        user,
        filterRoutesBasedOnPermissionsAndFeatureFlags(user.permissions ?? '')
      );
      setPrivateRoutes(allowedRoutes);
    }
  }, [user]);

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route path={route.path} key={route.path} element={<route.component />} />
      ))}
      {privateRoutes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateDashboardRouteGuard route={route}>
                <route.component />
              </PrivateDashboardRouteGuard>
            }
          />
        );
      })}
      {privateWeakRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PrivateWeakRouteGuard>
              <route.component />
            </PrivateWeakRouteGuard>
          }
        />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoutes;
