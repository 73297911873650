// TODO: move
import { ORDER_IN_KIND } from 'utils/constants';
import { Deliverable, Order, ProductType, User, WalletInfo } from 'utils/types';

function exportDeliveries(deliveries?: Deliverable[]) {
  return deliveries?.map((deliverable) => [
    deliverable.ticker,
    deliverable.amount,
    `"${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
      deliverable.totalInCurrency || 0
    )}"`,
  ]);
}

function getOrderCsvData(order: Order, user: User, productType: ProductType) {
  const expectedDeliveries = exportDeliveries(order.deliveries?.expected);
  const actualDeliveries = exportDeliveries(order.deliveries?.actual);
  const issuerWallets = order?.issuerWallets?.map((wallet: WalletInfo) => [
    `Issuer ${wallet.ticker} Wallet`,
    wallet.address,
  ]);

  let csvData: (string | number | undefined)[][] = [];

  if (productType === 'ETP') {
    const apWallets = order?.authorizedParticipantWallets?.map((wallet: WalletInfo) => [
      `Authorized Participant ${wallet.ticker} Wallet`,
      wallet.address,
    ]);

    csvData = [
      ['Order Number', order.externalId],
      ['Deal Date', order.dealDate],
      ['Settlement Date', order.settlement?.date],
      ['Creation/Redemption', order.type],
      ['Delivery Type', order?.deliveryType],
      ['Authorised Participant', order?.createdBy?.companyName],
      ['User Email', user?.contact.email],
      ['Number of Securities', order?.numberOfSecurities],
      ['Settlement Type', order?.settlement?.type],
      ['Standard Settlement Horizon', order?.settlement?.horizon],
      [],
      ...(expectedDeliveries || []),
      ...(actualDeliveries || []),
      [],
      ['Product Name', order?.product?.name],
      ['Series Name', order?.product?.seriesName],
      ['Series Letter', order?.product?.seriesLetter],
      ['Product ISIN', order?.product?.isin],
      [],
      ...(apWallets || []),
      ...(issuerWallets || []),
      [],
      ['Authorized Participant BP ID (SIX SIS AG)', process.env.REACT_APP_SETTLEMENT_BPID],
      ['Delivery/Receive', order?.deliveryType === ORDER_IN_KIND ? 'RECEIVE' : 'DELIVERY'],
      ['Issuer BP ID (SIX SIS AG)', process.env.REACT_APP_SETTLEMENT_BPID],
      ['Delivery/Receive', order?.deliveryType === ORDER_IN_KIND ? 'DELIVERY' : 'RECEIVE'],
    ];
  } else if (productType === 'Token') {
    const amWallets = order?.authorizedParticipantWallets?.map((wallet: WalletInfo) => [
      `Authorized Merchant ${wallet.ticker} Wallet`,
      wallet.address,
    ]);

    csvData = [
      ['Order Number', order.externalId],
      ['Deal Date', order.dealDate],
      ['Settlement Date', order.settlement?.date],
      ['Mint/Burn', order.type],
      ['Delivery Type', order?.deliveryType],
      ['Authorised Merchant', order?.createdBy?.companyName],
      ['User Email', user?.contact.email],
      [],
      ...(expectedDeliveries || []),
      ...(actualDeliveries || []),
      [],
      ['Product Name', order?.product?.name],
      ['Source Chain', order?.product?.sourceChain],
      ['Destination Chain', order?.product?.destinationChain],
      ['Mint/Burn Contract ID', order?.product?.contractAddress],
      [],
      ...(amWallets || []),
      ...(issuerWallets || []),
      [],
    ];
  }

  return csvData;
}

function downloadCsv(order: Order, user: User, productType: ProductType) {
  const csvData = getOrderCsvData(order, user, productType);
  const csvContent =
    'data:text/csv;charset=utf-8,' + csvData.map((row) => row.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${order.externalId}.csv`);
  document.body.appendChild(link);

  link.click();
}

export default downloadCsv;
