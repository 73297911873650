/* eslint-disable id-length */
export const greatestCommonDivisor = (x: number, y: number): number => {
  return y === 0 ? x : greatestCommonDivisor(y, x % y);
};

export const calculateRatio = (a?: number, b?: number): string => {
  if (!a || !b) return '-';

  const divisor = greatestCommonDivisor(a, b);
  const simplifiedA = a / divisor;
  const simplifiedB = b / divisor;

  return `${simplifiedA}:${simplifiedB}`;
};
