import useAppDispatch from 'hooks/useAppDispatch';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { approveRebalanceDetails, calculateRebalanceDetails } from 'utils/api/rebalance';
import { RebalanceConstituent, RebalanceWithDetails } from 'utils/types/rebalance';

export const useCalculateRebalanceDetails = () => {
  const dispatch = useAppDispatch();
  const [calculatedRebalance, setCalculatedRebalance] = useState<
    RebalanceWithDetails | null | undefined
  >(null);

  const calculateRebalanceMutation = useMutation({
    mutationFn: ({
      id,
      rebalanceConstituents,
    }: {
      id: string;
      rebalanceConstituents: RebalanceConstituent[];
    }) => {
      return calculateRebalanceDetails(id, rebalanceConstituents);
    },
    onSuccess: (data) => {
      if (data) {
        setCalculatedRebalance(data);
      }
    },
    onError: (err: Error) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  return { calculateRebalanceMutation, calculatedRebalance };
};

export const useApproveRebalanceDetails = (callbackOnSuccess?: () => void) => {
  const dispatch = useAppDispatch();
  const calculateRebalanceMutation = useMutation({
    mutationFn: ({
      id,
      rebalanceConstituents,
    }: {
      id: string;
      rebalanceConstituents?: RebalanceConstituent[];
    }) => {
      return approveRebalanceDetails(id, rebalanceConstituents);
    },
    onError: (err: Error) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
    onSuccess: (data) => {
      callbackOnSuccess && callbackOnSuccess();
      dispatch(
        createNotification({
          message: `Rebalance for ${data.product.ticker} has been approved and sent over to OMS.`,
          title: 'Rebalance Approved',
          type: 'success',
        })
      );
    },
  });

  return calculateRebalanceMutation;
};
