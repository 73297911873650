import { FC } from 'react';

// styles, assets
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import { StyledResetPasswordIcon } from 'pages/Login/UpdatePassword/UpdatePassword.styles';

// components
import Card from 'components/Card';
import Button from 'components/Button';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

interface Initialise2faSetupInterface {
  handleSkip: () => void;
  handleNext: () => void;
  firstLogin: boolean;
}

export const RecommendToUpdatePasswordScreen: FC<Initialise2faSetupInterface> = ({
  handleSkip,
  handleNext,
  firstLogin,
}) => {
  return (
    <Card
      title="Reset password"
      headerIcon={<StyledResetPasswordIcon />}
      footer={
        <MuiStyledModalFooterButtons>
          <Button variant="ghost" fullWidth type="submit" onClick={handleSkip}>
            Skip for now
          </Button>
          <Button onClick={handleNext} variant="interactive" fullWidth>
            <>
              <span>Continue</span>
              <ArrowRightIcon />
            </>
          </Button>
        </MuiStyledModalFooterButtons>
      }
    >
      {firstLogin
        ? 'We recommend to reset your password to be in compliance with our security parameters.'
        : 'In order to protect your account, we have added new security layers into the Onyx passwords.We recommend to reset your password to be in compliance with our new security parameters.'}
    </Card>
  );
};
