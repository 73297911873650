import { CustomUserModal } from './CustomModals/CustomUserModal';
import { DeleteUserModal } from 'shared/Modals/Users/DeleteUser/DeleteUserModal';
import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';
import { UpdateUserModal } from 'shared/Modals/Users/UpdateUser/UpdateUserModal';

export const UsersModal = ({ ...props }: IModalWithData) => {
  const UserModals: Record<string, ReactNode> = {
    CREATE: <UpdateUserModal {...props} />,
    EDIT: <UpdateUserModal {...props} />,
    DELETE: <DeleteUserModal {...props} />,
    CUSTOM: <CustomUserModal {...props} />,
  };

  return <>{UserModals[props.data.type]}</>;
};
