import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import ExchangeTableRow from './ExchangeTableRow';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Exchange, PaginationProps } from 'utils/types';
import { ParamsType } from 'hooks/useUrlParams';
import { ExchangesTableColumnsConfig } from './tableColumnsConfig';
import { NewCard } from 'components/NewCard/NewCard';

type ExchangeTableProps = {
  exchanges: Exchange[];
  loadingData: boolean;
  editAction: (exchange: Exchange) => void;
  deleteAction: (exchange: Exchange) => void;
  pagination?: PaginationProps;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
};

const doesAnyExchangeHaveActions = (exchanges: Exchange[]) => {
  return Boolean(
    exchanges.find((exchange) => exchange._actions?.update || exchange._actions?.delete)
  );
};

const ExchangeTable = ({
  exchanges,
  loadingData,
  editAction,
  deleteAction,
  pagination,
  setUrlParams,
  urlParams,
}: ExchangeTableProps) => {
  const showActionsCell = doesAnyExchangeHaveActions(exchanges);
  return (
    <>
      <NewCard
        body={
          <Table pagination={pagination} noPadding>
            <TableHeaderWithMultiSort
              showActionsCell
              defaultSortedColumns={urlParams.sort ?? []}
              columns={ExchangesTableColumnsConfig}
              onColumnSort={(columns) => {
                setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
              }}
            />
            <TableBodyWithStates
              loadingData={Boolean(loadingData)}
              hasContent={!isEmpty(exchanges)}
              noContentLabel="No exchanges at the moment."
            >
              {exchanges?.map((exchange) => (
                <ExchangeTableRow
                  key={exchange._id}
                  exchange={exchange}
                  editAction={() => {
                    editAction(exchange);
                  }}
                  deleteAction={() => {
                    deleteAction(exchange);
                  }}
                  showActionsCell={showActionsCell}
                />
              ))}
            </TableBodyWithStates>
          </Table>
        }
      />
    </>
  );
};

export default ExchangeTable;
