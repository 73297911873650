export const ETPColumnsConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', width: '30%' },
  { label: 'Name', propName: 'name', propType: 'string', width: '30%' },
  { label: 'Product Class', propName: 'type', propType: 'string', width: '16%' },
  { label: 'Last updated', propName: 'updatedAt', propType: 'date', width: '16%' },
];

export const TokensColumnsConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', width: '20%' },
  { label: 'Name', propName: 'name', propType: 'string', width: '20%' },
  { label: 'Standard', propName: 'tokenStandard', propType: 'string', width: '20%' },
  { label: 'Destination Chain', propName: 'destinationChain', propType: 'string', width: '10%' },
  { label: 'Last updated', propName: 'updatedAt', propType: 'date', width: '20%' },
];
