import { ProductStatus } from 'utils/types/product';

export const INSTRUMENTS_STATUS_LABEL: Record<ProductStatus, string> = {
  [ProductStatus.ACTIVE]: 'Active',
  [ProductStatus.PENDING]: 'Draft',
  [ProductStatus.IN_REVIEW]: 'Pending Seed',
  [ProductStatus.DELETED]: 'Deactivated',
  [ProductStatus.ARCHIVED]: 'Archived',
};

export const INSTRUMENTS_TAB_STATUS_LABEL: Record<ProductStatus, string> = {
  [ProductStatus.ACTIVE]: 'Active',
  [ProductStatus.PENDING]: 'Draft',
  [ProductStatus.IN_REVIEW]: 'Pending Seed',
  [ProductStatus.DELETED]: 'Inactive',
  [ProductStatus.ARCHIVED]: 'Archived',
};

export const INSTRUMENTS_TAB_STATUS = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  IN_REVIEW: 'inreview',
  DELETED: 'deleted',
  ARCHIVED: 'archived',
};

export type ProductTabType =
  | typeof INSTRUMENTS_TAB_STATUS.ACTIVE
  | typeof INSTRUMENTS_TAB_STATUS.DRAFT
  | typeof INSTRUMENTS_TAB_STATUS.IN_REVIEW
  | typeof INSTRUMENTS_TAB_STATUS.DELETED
  | typeof INSTRUMENTS_TAB_STATUS.ARCHIVED
  | undefined;
