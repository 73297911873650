import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { ProductCustodianWallet, WalletType } from 'utils/types/wallets';
import { ProductStatus, ProductType } from 'utils/types/product';
import { WalletModalData } from 'shared/Modals/Wallets/WalletsModal';
import { createNotification } from 'store/notifications/actions';
import {
  instrumentCustodianWalletSchemaUrl,
  instrumentUnifiedWalletSchemaUrl,
  tokenCustodianWalletSchemaUrl,
  tokenUnifiedWalletSchemaUrl,
} from 'components/Form/formSchemas';
import { useCurrentProduct } from 'hooks/useCurrentProduct';
import { useMutation } from 'react-query';
import {
  createCustodianWalletForCompanyRequest,
  editCustodianWalletForCompanyRequest,
} from 'utils/api/wallets';
import {
  walletCreatedNotifications,
  walletEditNotifications,
} from 'shared/Notifications/wallets.notifications';
import { getAuthorizedParticipantsOptions } from './utils';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

function getWalletProductSchema(walletType: WalletType, productType: ProductType) {
  if (productType === 'Token') {
    return walletType === WalletType.CUSTODIAN_WALLET
      ? tokenCustodianWalletSchemaUrl
      : tokenUnifiedWalletSchemaUrl;
  }
  // Default to ETP schemas
  return walletType === WalletType.CUSTODIAN_WALLET
    ? instrumentCustodianWalletSchemaUrl
    : instrumentUnifiedWalletSchemaUrl;
}

export const UpdateProductsWalletModal = ({
  onCloseModalAction,
  closeModal,
  data,
}: IModalWithData) => {
  const isToken = data.custom?.isToken;
  const { product } = useCurrentProduct(isToken ? 'Token' : 'ETP', onCloseModalAction);

  const dispatch = useAppDispatch();
  const { companyData } = data!;
  const walletModalData = data?.data as WalletModalData;
  const { wallet, type: walletType } = walletModalData!;
  const productData = walletModalData.productData;
  const authorizedParticiants = productData?.authorizedParticiants ?? [];
  const partners = productData?.partners ?? [];
  const custodians = productData?.custodians ?? [];
  const constituents = productData?.constituents ?? [];
  const modalType = data?.type;

  const authorizedParticipantsOptions = getAuthorizedParticipantsOptions(
    partners,
    authorizedParticiants
  );

  const schemaUrl = getWalletProductSchema(walletType, isToken ? 'Token' : 'ETP');
  const isNewWallet = Boolean(data.type === MODAL_ACTIONS.CREATE);
  const titleText = isNewWallet ? 'Add Wallet' : 'Update Wallet';
  const actionButtonText =
    product?.status === ProductStatus.ACTIVE ? titleText + ' and Save' : titleText;

  const createCustodianWalletForCompanyMutation = useMutation({
    mutationFn: (formValues: ProductCustodianWallet) => {
      const walletWithProduct = {
        ...formValues,
        product: product?._id ?? '',
        productType: isToken ? 'token' : 'etp',
      };
      return createCustodianWalletForCompanyRequest(
        formValues?.transactingCompany ?? '',
        walletWithProduct
      );
    },
    onSuccess: (response) => {
      dispatch(
        createNotification(
          walletCreatedNotifications.success(response.data.address, companyData?.name)
        )
      );
      closeModal();
      if (onCloseModalAction)
        onCloseModalAction({
          wallet: response.data as ProductCustodianWallet,
          actionType: modalType,
        });
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletCreatedNotifications.error(error.message), error));
    },
  });

  const editCustodianWalletForCompanyMutation = useMutation({
    mutationFn: (formValues: ProductCustodianWallet) => {
      const walletWithProduct = {
        ...formValues,
        product: product?._id ?? '',
        productType: isToken ? 'token' : 'etp',
      };
      return editCustodianWalletForCompanyRequest(
        `/companies/id=${formValues?.transactingCompany}/wallets/type=CUSTODIAN/id=${wallet?._id}`,
        walletWithProduct
      );
    },
    onSuccess: (response) => {
      dispatch(createNotification(walletEditNotifications.success(wallet?.address)));
      closeModal();
      if (onCloseModalAction)
        onCloseModalAction({
          wallet: response.data as ProductCustodianWallet,
          actionType: modalType,
        });
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletEditNotifications.error(error.message), error));
    },
  });

  const handleUpdate = (formValues: ProductCustodianWallet) => {
    if (isNewWallet) {
      createCustodianWalletForCompanyMutation.mutate(formValues);
    } else {
      editCustodianWalletForCompanyMutation.mutate(formValues);
    }
  };

  const isUpdating =
    createCustodianWalletForCompanyMutation.isLoading ||
    editCustodianWalletForCompanyMutation.isLoading;

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button data-qa-id="addButton" fullWidth isLoading={isUpdating} type="submit">
        {actionButtonText}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Form schemaUrl={schemaUrl} onSubmit={handleUpdate} initialValues={wallet ?? {}}>
        <Card title={titleText} label={'Wallet'} footer={Footer} onClose={closeModal} disableScroll>
          <Select
            data-qa-id="chain"
            data-qa-options-id="chain"
            name="chain"
            disabled={!isNewWallet}
          />
          <Select
            name="constituentId"
            data-qa-id="constituentId"
            data-qa-options-id="constituentId"
            options={constituents.map((constituent) => ({
              label: constituent.ticker,
              value: constituent._id,
            }))}
          />
          <Select
            name="custodianAccount"
            data-qa-id="custodianAccount"
            data-qa-options-id="custodianAccount"
            options={custodians?.map((custodian) => ({
              label: custodian.name,
              value: custodian._id,
            }))}
          />
          <Input name="address" disabled={!isNewWallet} data-qa-id="address" withClipboard />
          <Input name="description" data-qa-id="description" />
          <Input name="idAtCustodian" data-qa-id="idAtCustodian" />
          {walletType === WalletType.CUSTODIAN_WALLET && (
            <Select
              data-qa-id="custodianAccount"
              data-qa-options-id="custodianAccount"
              name="transactingCompany"
              options={authorizedParticipantsOptions}
              disabled={!isNewWallet}
            />
          )}
        </Card>
      </Form>
    </CustomModal>
  );
};
