import Footer from 'pages/Tokens/components/Footer';
import TokensWalletsTable from 'pages/Tokens/components/Form/PartnerDetails/components/WalletsInfoStep/components/TokensWalletsTable/TokensWalletsTable';
import { CUSTODIAN_WALLETS } from 'pages/Tokens/components/Form/utils';
import {
  CustodiansListProps,
  WalletsList,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import { ProductCustodianWallet, WalletStatus } from 'utils/types/wallets';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { useMemo, useState } from 'react';
import { useTokenContext } from 'contexts/tokens';
import { useTokenCustodiansQuery } from 'pages/Tokens/components/Form/PartnerDetails/components/CustodianInfoStep/hooks/useTokenCustodians';
import { useTokenWalletsQuery } from './hooks/useTokenWallets';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';

interface WalletsInfoStepProps {
  goBack: () => void;
  onSubmit: (data: WalletsList) => void;
}

function WalletsInfoStep({ goBack, onSubmit }: WalletsInfoStepProps) {
  const { data: partners, isLoading: isLoadingPartners } = useCompaniesSuggestion();
  const { currentToken, tokenFormsData } = useTokenContext();
  const {
    data: wallets,
    refetch: refetchTokenWallets,
    isLoading: loadingWallets,
  } = useTokenWalletsQuery(currentToken?._id ?? '', 'ACTIVE,DELETED');
  const {
    data: custodians,
    refetch: refetchTokenCustodians,
    isLoading: loadingCustodians,
  } = useTokenCustodiansQuery(currentToken?._id ?? '', 'ACTIVE');
  const [activeTab, setActiveTab] = useState(WALLETS_TABS_STATUS.ACTIVE);
  const custodianWallets = wallets ? wallets[CUSTODIAN_WALLETS] : [];
  const filteredWallets = useMemo(
    () =>
      custodianWallets?.filter((wallet) => {
        if (activeTab === WALLETS_TABS_STATUS.DEACTIVATED)
          return wallet.status === WalletStatus.DELETED;
        return wallet.status === WalletStatus.ACTIVE;
      }),
    [activeTab, wallets]
  );

  const handleSubmit = () => {
    onSubmit(wallets as WalletsList);
  };

  return (
    <>
      <TokensWalletsTable
        loading={loadingWallets || loadingCustodians || isLoadingPartners}
        onUpdate={() => {
          refetchTokenCustodians();
          refetchTokenWallets();
        }}
        partners={partners ?? []}
        tokensFormsData={tokenFormsData}
        onChangeTab={(tab) => setActiveTab(tab)}
        activeTab={activeTab}
        wallets={filteredWallets as ProductCustodianWallet[]}
        custodians={custodians as CustodiansListProps}
      />
      <Footer goBack={goBack} onSubmit={handleSubmit} />
    </>
  );
}

export default WalletsInfoStep;
