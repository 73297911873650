import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import keyBy from 'lodash/keyBy';
import useAppDispatch from 'hooks/useAppDispatch';
import { CompanyCustodianWallet } from 'utils/types/wallets';
import { CustomModal } from 'shared/Modals/Modal';
import { DefaultValues } from 'react-hook-form';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { Instrument, ProductStatus, Token } from 'utils/types/product';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { WalletModalData } from 'shared/Modals/Wallets/WalletsModal';
import {
  createCustodianWalletForCompanyRequest,
  editCustodianWalletForCompanyRequest,
} from 'utils/api/wallets';
import { createCustodianWalletSchemaUrl } from 'components/Form/formSchemas';
import { createNotification } from 'store/notifications/actions';
import { getConstituentsOptions, getProductOptions } from './utils';
import { useInstrumentCustodiansQuery } from 'pages/Instruments/components/Form/PartnerDetails/components/CustodianInfoStep/hooks/useInstrumentCustodians';
import { useInstrumentsQuery } from 'hooks/useInstrumentsQuery';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import {
  walletCreatedNotifications,
  walletEditNotifications,
} from 'shared/Notifications/wallets.notifications';
import { useGetTokensQuery } from 'hooks/useTokens';

export const UpdateCustodianWalletModal = ({
  onCloseModalAction,
  data,
  closeModal,
}: IModalWithData) => {
  const isToken = data.custom?.isToken;

  const dispatch = useAppDispatch();
  const { companyData, data: walletModalData } = data!;
  const wallet = (walletModalData as WalletModalData)?.wallet as CompanyCustodianWallet;
  const [selectedProductId, seSelectedProductId] = useState<string | undefined>(wallet?.product);
  const isNewWallet = Boolean(data.type === MODAL_ACTIONS.CREATE);
  const actionButtonText = isNewWallet ? 'Add Wallet' : 'Update Wallet';

  const {
    data: ETPsResponse,
    isLoading: loadingETPs,
    isFetching: fetchingETPs,
  } = useInstrumentsQuery(companyData?.id, [ProductStatus.ACTIVE], !isToken);

  const {
    data: tokens,
    isLoading: loadingTokens,
    isFetching: fetchingTokens,
  } = useGetTokensQuery({ status: [ProductStatus.ACTIVE] }, companyData?.id, Boolean(isToken));

  const products = isToken ? (tokens as Token[]) : ETPsResponse?.data;

  const productsOptions = useMemo(
    () => (products && getProductOptions(products)) ?? [],
    [products]
  );

  const constituents = useMemo(() => {
    const product = products
      ? (keyBy(products, '_id')[selectedProductId ?? ''] as Instrument | Token)
      : undefined;
    return product ? product.constituentAssets : [];
  }, [selectedProductId, products, wallet]);

  const constituentOptions = useMemo(
    () => (constituents ? getConstituentsOptions(constituents) : []),
    [constituents]
  );

  const {
    isLoading: loadingCustodians,
    isFetching: fetchingCustodians,
    data: custodians,
  } = useInstrumentCustodiansQuery(selectedProductId ?? '');

  const custodianOptions = useMemo(
    () => (custodians ? getProductOptions(custodians) : []),
    [custodians]
  );

  const createCustodianWalletForCompanyMutation = useMutation({
    mutationFn: (formValues: CompanyCustodianWallet) =>
      createCustodianWalletForCompanyRequest(companyData?.id ?? '', formValues),
    onSuccess: (response) => {
      dispatch(
        createNotification(
          walletCreatedNotifications.success(response.data.address, companyData?.name)
        )
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletCreatedNotifications.error(error.message), error));
    },
  });

  const editCustodianWalletForCompanyMutation = useMutation({
    mutationFn: (formValues: CompanyCustodianWallet) =>
      editCustodianWalletForCompanyRequest(wallet?._actions?.update?.uri ?? '', formValues),
    onSuccess: () => {
      dispatch(createNotification(walletEditNotifications.success(wallet.address)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletEditNotifications.error(error.message), error));
    },
  });

  const handleFormUpdate = async (values: DefaultValues<CompanyCustodianWallet>) => {
    if (values?.product && values?.product !== selectedProductId) {
      seSelectedProductId(values?.product);
    }
  };

  const handleUpdate = (formValues: CompanyCustodianWallet) => {
    if (isNewWallet) {
      createCustodianWalletForCompanyMutation.mutate(formValues);
    } else {
      editCustodianWalletForCompanyMutation.mutate(formValues);
    }
  };

  const isUpdating =
    createCustodianWalletForCompanyMutation.isLoading ||
    editCustodianWalletForCompanyMutation.isLoading;

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button data-qa-id="addButton" fullWidth isLoading={isUpdating} type="submit">
        {actionButtonText}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Form
        schemaUrl={createCustodianWalletSchemaUrl}
        onSubmit={handleUpdate}
        onChange={handleFormUpdate}
        initialValues={wallet ?? {}}
        loading={
          loadingCustodians ||
          fetchingCustodians ||
          loadingETPs ||
          fetchingETPs ||
          loadingTokens ||
          fetchingTokens
        }
      >
        <Card title={actionButtonText} label={'Wallet'} footer={Footer} onClose={closeModal}>
          <Select
            data-qa-id="chain"
            data-qa-options-id="chain"
            name="chain"
            disabled={!isNewWallet}
          />
          <Select
            name="product"
            data-qa-id="product"
            data-qa-options-id="product"
            disabled={!isNewWallet}
            options={productsOptions}
          />
          <Select
            name="constituentId"
            data-qa-id="constituentId"
            data-qa-options-id="constituentId"
            disabled={!selectedProductId}
            options={constituentOptions}
          />
          <Input name="address" disabled={!isNewWallet} data-qa-id="address" withClipboard />
          <Input name="description" data-qa-id="description" />
          <Select
            name="custodianAccount"
            data-qa-id="custodianAccount"
            data-qa-options-id="custodianAccount"
            disabled={!selectedProductId}
            options={custodianOptions}
          />
          <Input name="idAtCustodian" data-qa-id="idAtCustodian" />
        </Card>
      </Form>
    </CustomModal>
  );
};
