export const INSTRUMENT_DETAILS_TAB = 'ETP Details';
export const PARTNER_DETAILS_TAB = 'Partner Details';

export const producTabs = [
  {
    name: INSTRUMENT_DETAILS_TAB,
    param: INSTRUMENT_DETAILS_TAB,
  },
  {
    name: PARTNER_DETAILS_TAB,
    param: PARTNER_DETAILS_TAB,
  },
];
