import {
  CUSTODIAN_INFO_STEP,
  PARTNERS_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import {
  CustodiansListProps,
  PartnerDetailsFormProps,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import isEmpty from 'lodash/isEmpty';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppModal from 'hooks/useAppModal';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { CustodianStatus } from 'utils/constants/custodians';
import { ETPWalletsTableRow } from './ETPWalletsTableRow';
import { ETPsWalletsTableColumnsConfig } from './tableColumnsConfig';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { CompanySuggestionDto } from 'utils/types';
import { ProductCustodianWallet, WalletType } from 'utils/types/wallets';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { WalletsInfoStepTabs } from 'pages/Instruments/components/Form/PartnerDetails/components/WalletsInfoStep/components/WalletsInfoStepTabs';
import { createNotification } from 'store/notifications/actions';
import { infoNotification } from 'shared/Notifications/general.notifications';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import Grid from '@mui/material/Grid';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import Box from '@mui/material/Box';

interface WalletsTableProps {
  instrumentFormsData: PartnerDetailsFormProps;
  loading: boolean;
  onUpdate: () => void;
  partners: CompanySuggestionDto[];
  onChangeTab: (data: WALLETS_TABS_STATUS) => void;
  activeTab: WALLETS_TABS_STATUS;
  wallets: ProductCustodianWallet[];
  custodians: CustodiansListProps;
}

function ETPsWalletsTable({
  loading,
  onUpdate,
  partners,
  instrumentFormsData,
  onChangeTab,
  activeTab,
  wallets,
  custodians,
}: WalletsTableProps) {
  const dispatch = useAppDispatch();
  const openModal = useAppModal();
  const handleChangeTab = (tab: WALLETS_TABS_STATUS) => {
    onChangeTab(tab);
  };

  const openWalletModal = (
    type: MODAL_ACTIONS,
    walletType: WalletType,
    wallet?: ProductCustodianWallet
  ) => {
    if (
      !custodians ||
      custodians?.length === 0 ||
      instrumentFormsData[CONSTITUENT_STEP]?.length === 0 ||
      !instrumentFormsData[CONSTITUENT_STEP]
    ) {
      dispatch(
        createNotification(
          infoNotification("You can't add wallet if custodians or constituents are empty")
        )
      );
    } else {
      openModal(
        {
          modalName: WALLET_MODAL,
          modalData: {
            data: {
              wallet,
              type: walletType,
              productData: {
                authorizedParticiants:
                  instrumentFormsData[PARTNERS_STEP]?.authorizedParticipants ?? [],
                partners: partners,
                custodians: custodians?.filter((custodian) => {
                  return custodian.status === CustodianStatus.ACTIVE;
                }),
                constituents: instrumentFormsData[CONSTITUENT_STEP],
              },
            },
            companyData: {
              id: (wallet as ProductCustodianWallet)?.transactingCompany ?? '',
              name:
                partners.find(
                  (partner) =>
                    partner._id === (wallet as ProductCustodianWallet)?.transactingCompany
                )?.name ?? '',
            },
            type: type,
          },
        },
        {
          onCloseModalAction: () => onUpdate(),
        }
      );
    }
  };

  const openDeleteWalletModal = (walletType: WalletType, wallet?: ProductCustodianWallet) => {
    openModal(
      {
        modalName: WALLET_MODAL,
        modalData: {
          data: {
            wallet,
            type: walletType,
          },
          companyData: {
            id: (wallet as ProductCustodianWallet)?.transactingCompany,
            name:
              partners.find(
                (partner) => partner._id === (wallet as ProductCustodianWallet)?.transactingCompany
              )?.name ?? '',
          },
          type: MODAL_ACTIONS.DELETE,
        },
      },
      {
        onCloseModalAction: () => onUpdate(),
      }
    );
  };

  return (
    <Table
      tabsComponent={
        <Box paddingLeft={2}>
          <WalletsInfoStepTabs
            active={activeTab}
            onChangeTab={handleChangeTab}
          ></WalletsInfoStepTabs>{' '}
        </Box>
      }
      tabsComponentUnderToolbar={true}
      toolbarTools={[
        <Grid marginTop={1} marginBottom={1} container display={'flex'} alignItems={'center'}>
          <Grid item xs={12} md={6}>
            <ProductConfigHeader title="Wallets" />
          </Grid>
          <Grid item xs={12} md={6} display={'flex'} justifyContent={'flex-end'}>
            <StyledTableTopButton
              data-qa-id="addWalletButton"
              variant="secondary"
              onClick={() => openWalletModal(MODAL_ACTIONS.CREATE, WalletType.CUSTODIAN_WALLET)}
              type="button"
              size="medium"
            >
              <span>Add Wallet</span>
              <PlusIcon />
            </StyledTableTopButton>
          </Grid>
        </Grid>,
      ]}
      noPadding
    >
      <TableHeaderWithMultiSort showActionsCell={true} columns={ETPsWalletsTableColumnsConfig} />
      <TableBodyWithStates
        loadingData={loading}
        hasContent={!isEmpty(wallets)}
        noContentLabel="No connected wallets."
      >
        {wallets?.map((wallet) => (
          <ETPWalletsTableRow
            key={wallet._id}
            wallet={wallet as ProductCustodianWallet}
            editAction={() => {
              openWalletModal(MODAL_ACTIONS.EDIT, WalletType.CUSTODIAN_WALLET, wallet);
            }}
            deleteAction={() => openDeleteWalletModal(WalletType.CUSTODIAN_WALLET, wallet)}
            activateAction={() => {
              openWalletModal(MODAL_ACTIONS.CUSTOM, WalletType.CUSTODIAN_WALLET, wallet);
            }}
            custodianName={
              instrumentFormsData[CUSTODIAN_INFO_STEP]?.find(
                (custodian) => custodian._id === (wallet as ProductCustodianWallet).custodianAccount
              )?.name ?? ''
            }
            partners={partners}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
}

export default ETPsWalletsTable;
