import { useState } from 'react';

interface OptionsProps {
  onClose?: () => void;
}

function useToggle(initialState: boolean = false, options?: OptionsProps): [boolean, () => void] {
  const [isOn, setIsOn] = useState(initialState);
  const toggleIsOn = () => {
    if (isOn && options?.onClose) {
      options.onClose();
    }

    setIsOn(!isOn);
  };

  return [isOn, toggleIsOn];
}

export default useToggle;
