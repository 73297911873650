import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestUser } from 'utils/api/auth';

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { rejectWithValue }) => {
  try {
    const response = await requestUser();
    return response.data;
  } catch (err: any) {
    const error = err as Error;
    return rejectWithValue(error.message);
  }
});
