export const TOKEN_DETAILS_TAB = 'Token Details';
export const PARTNER_DETAILS_TAB = 'Partner Details';

export const tokenTabs = [
  {
    name: TOKEN_DETAILS_TAB,
    param: TOKEN_DETAILS_TAB,
  },
  {
    name: PARTNER_DETAILS_TAB,
    param: PARTNER_DETAILS_TAB,
  },
];
