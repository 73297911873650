import { styled as MuiStyled } from '@mui/material';

import { Column } from 'components/Grid';
import { TableCell } from 'components/Table';

export const StyledColumn = MuiStyled(Column)`
  align-items: center;
  display: flex;
`;

export const StyledTableCell = MuiStyled(TableCell)`
  width: 50%;
`;

export const StyledEntityName = MuiStyled('p')`
  color: ${({ theme }) => theme.palette.secondary.light};
  margin: 8px 0;
`;
