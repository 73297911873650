import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import Tag from 'components/Tag';
import { Partner } from 'utils/types';
import { PartnerTypeTitles } from 'utils/constants/partners';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { dateFormat } from 'shared/Tables/table.utils';
import { format, parseISO } from 'date-fns';
import { StyledButton } from './PartnersTable.styles';

type PatnerTableRowProps = {
  partner: Partner;
  editAction?: () => void;
  deleteAction?: () => void;
  showActionsCell: boolean;
};

const PartnerTableRow = ({
  partner,
  editAction,
  deleteAction,
  showActionsCell,
}: PatnerTableRowProps) => {
  return (
    <MuiStyledTableRow data-qa-id={`${partner.name}-row`} key={partner.name} onClick={editAction}>
      <MuiStyledTableCell data-qa-id={`${partner.name}-name`}>{partner.name}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${partner.name}-partnerType`}>
        <Tag label={PartnerTypeTitles[partner.type]} size="small" />
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${partner.name}-updated`}>
        {partner.updatedAt && format(parseISO(partner.updatedAt), dateFormat)}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell
        data-qa-id="actionBtn"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {showActionsCell && partner.status !== 'DELETED' && (
          <>
            {(partner._actions?.update || partner._actions?.delete) && (
              <TablePopoverMenu id={partner._id ?? partner.name} className="actionsCell">
                <ul>
                  {partner._actions?.update && (
                    <li>
                      <StyledButton onClick={editAction} fullWidth variant="ghost">
                        Edit Partner
                      </StyledButton>
                    </li>
                  )}
                  {partner._actions?.delete && (
                    <li>
                      <StyledButton onClick={deleteAction} fullWidth variant="ghost">
                        Delete Partner
                      </StyledButton>
                    </li>
                  )}
                </ul>
              </TablePopoverMenu>
            )}
          </>
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};

export default PartnerTableRow;
