import { ProductTabType, TOKENS_TAB_STATUS } from 'pages/Tokens/Tokens.constants';
import { ProductStatus } from 'utils/types/product';

export const getProductStatus = (tabValue: ProductTabType): ProductStatus => {
  switch (tabValue) {
    case TOKENS_TAB_STATUS.ACTIVE:
      return ProductStatus.ACTIVE;
    case TOKENS_TAB_STATUS.DRAFT:
      return ProductStatus.PENDING;
    case TOKENS_TAB_STATUS.IN_REVIEW:
      return ProductStatus.IN_REVIEW;
    case TOKENS_TAB_STATUS.DELETED:
      return ProductStatus.DELETED;
    case TOKENS_TAB_STATUS.ARCHIVED:
      return ProductStatus.ARCHIVED;
    default:
      return ProductStatus.ACTIVE;
  }
};
