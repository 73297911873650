import { useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import property from 'lodash/property';
import { TableHeaderColumn } from 'shared/Tables/Table.types';

function sortElements<T>(list: T[], colPropName?: keyof T, colPropType?: keyof T) {
  if (colPropName) {
    if (colPropType === 'date') {
      return list.sort((itemOne: T, itemTwo: T) => {
        return (
          new Date(property(colPropName as string)(itemOne) as string).getTime() -
          new Date(property(colPropName as string)(itemTwo) as string).getTime()
        );
      });
    } else {
      return sortBy(list, property(colPropName as string) ?? '');
    }
  }
  return list;
}

export function useSortHook<T>(initialList: T[]) {
  const [sortedList, setSortedList] = useState<T[]>([]);
  const [sortCol, setSortCol] = useState<TableHeaderColumn>();
  const [sortReverse, setSortReverse] = useState(false);

  useEffect(() => {
    if (sortReverse) {
      setSortedList(
        sortElements(
          initialList,
          sortCol?.propName as keyof T,
          sortCol?.propType as keyof T
        ).reverse()
      );
    } else {
      setSortedList(
        sortElements(initialList, sortCol?.propName as keyof T, sortCol?.propType as keyof T)
      );
    }
  }, [initialList, sortCol, sortReverse]);

  const handleSortProp = (col: TableHeaderColumn) => {
    if (col.propName === sortCol?.propName) {
      setSortReverse(!sortReverse);
    } else {
      setSortReverse(false);
      setSortCol(col);
    }
  };

  return { sortedList, handleSortProp };
}
