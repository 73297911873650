import Button from 'components/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import MultiSelect from 'components/Select/MultiSelect';
import Select from 'components/Select';
import timeOptions, { getTimeOptionsAfter } from 'utils/select-options/timeOptions';
import { Calendar } from 'utils/types/calendar';
import { DefaultValues, SubmitHandler, useFormState } from 'react-hook-form';
import { Exchange } from 'utils/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { exchangeSchemaUrl } from 'components/Form/formSchemas';
import { mapCalendarToOptions } from 'utils/calendar';
import { useMemo, useState } from 'react';
import Card from 'components/Card';
import { ExchangeFormDataModel, toFormModel } from 'pages/Admin/ExchangesPage/utils';
import { StyledMarketRow } from './ExchangeForm.styles';

type ExchangeFormProps = {
  calendars: Calendar[];
  exchange?: Exchange;
  onCancel?: () => void;
  onSubmit: (values: ExchangeFormDataModel) => void;
  loading?: boolean;
  isUpdating?: boolean;
};

export const ExchangeForm = ({
  exchange,
  onCancel,
  onSubmit,
  calendars,
  loading,
  isUpdating,
}: ExchangeFormProps) => {
  const isCreateMode = !exchange;
  const [marketOpen, setMarketOpen] = useState<string | undefined>(exchange?.marketOpen);
  const calendarOptions = useMemo(() => mapCalendarToOptions(calendars), [calendars]);
  const marketCloseOptions = Boolean(marketOpen) ? getTimeOptionsAfter(marketOpen as string) : [];

  const handleOnSubmit: SubmitHandler<ExchangeFormDataModel> = (props) => {
    onSubmit(props);
  };

  const handleOnChange = ({ marketOpen }: DefaultValues<ExchangeFormDataModel>) => {
    setMarketOpen(marketOpen);
  };

  return (
    <Form
      initialValues={isCreateMode ? {} : toFormModel(exchange)}
      onChange={handleOnChange}
      onSubmit={handleOnSubmit}
      schemaUrl={exchangeSchemaUrl}
      loading={loading}
    >
      <Card
        title={isCreateMode ? 'Create New Exchange' : 'Edit Exchange'}
        label="Exchanges"
        onClose={onCancel}
        footer={
          <Footer isEditModel={!isCreateMode} onCancel={onCancel} isLoading={Boolean(isUpdating)} />
        }
      >
        <Input name="name" label="Name" />
        <MultiSelect
          name="countries"
          label="Countries"
          data-qa-id="countries"
          data-qa-options-id="countries"
        />
        <MultiSelect
          name="calendars"
          options={calendarOptions}
          data-qa-id="CalendarsOptionDropdown"
          data-qa-options-id="CalendarsOptionDropdown"
        />
        <StyledMarketRow>
          <Select
            name="marketOpen"
            helperText="*Must be before market close"
            options={timeOptions}
            data-qa-id="MarketOpenDropdown"
            data-qa-options-id="MarketOpenDropdown"
          />
          <Select
            name="marketClose"
            options={marketCloseOptions}
            data-qa-id="MarketCloseDropdown"
            data-qa-options-id="MarketCloseDropdown"
          />
        </StyledMarketRow>
      </Card>
    </Form>
  );
};

const Footer = ({
  isEditModel,
  onCancel,
  isLoading,
}: {
  isEditModel: boolean;
  onCancel?: () => void;
  isLoading: boolean;
}) => {
  const data = useFormState();

  return (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        type="button"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        data-qa-id="createExchangesButton"
        fullWidth
        type="submit"
        disabled={!data.isValid}
        isLoading={isLoading}
      >
        {isEditModel ? 'Save Exchange' : 'Create Exchange'}
      </Button>
    </MuiStyledModalFooterButtons>
  );
};
