import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import { ChipVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';

export const chipPalette = (themeVariant: ThemeTokens): ChipVariantsInterface => ({
  default: {
    backgroundColor: themeVariant.static.background.default.standard,
    border: `1px solid ${themeVariant.interactive.outline.secondary.default}`,
    color: themeVariant.static.text.default.standard,
    hover: {
      backgroundColor: themeVariant.static.background.default.weak,
      color: themeVariant.static.text.default.strong,
    },
    focus: {
      backgroundColor: themeVariant.static.background.default.standard,
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  green: {
    backgroundColor: themeVariant.static.support.weak.positive,
    border: `1px solid ${themeVariant.static.support.weak.positive}`,
    color: themeVariant.static.text.sentiment.positive,

    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  red: {
    backgroundColor: themeVariant.static.support.weak.negative,
    border: `1px solid ${themeVariant.static.support.weak.negative}`,
    color: themeVariant.static.text.sentiment.negative,

    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  blue: {
    backgroundColor: themeVariant.static.support.weak.info,
    border: `1px solid ${themeVariant.static.support.weak.info}`,
    color: themeVariant.static.support.standard.info,

    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  yellow: {
    backgroundColor: themeVariant.static.support.weak.notice,
    border: `1px solid ${themeVariant.static.support.weak.notice}`,
    color: themeVariant.static.support.standard.notice,

    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  inverse: {
    backgroundColor: themeVariant.interactive.fill.secondary.hover,
    border: `1px solid ${themeVariant.interactive.outline.inverse.hover}`,
    color: themeVariant.static.text.inverse.strong,
    hover: {
      backgroundColor: themeVariant.interactive.fill.secondary.enabled,
    },
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.outline.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.static.text.default.weak,
    },
  },
});
