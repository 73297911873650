import Modal from 'styled-react-modal';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledFieldsContainer = MuiStyled(Box)`
  width: '560px';
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacers['spacing-32']};
`;

export const ModalDescription = MuiStyled(Box)`
  padding-block: ${({ theme }) => `${theme.spacers['spacing-24']} ${theme.spacers['spacing-16']}`};
`;

export const StyledModal = Modal.styled`
  width: 560px;
`;

export const StyledApiSignature = MuiStyled(Box)<{ hidden: boolean }>(({ hidden = false }) => ({
  ...(hidden && {
    display: 'none',
  }),
}));
