// types
import { OrderFormProps, Order, Deliverable } from 'utils/types/orders';
import { User } from 'utils/types';
import { Pcf } from 'utils/types/pcfs';

export type SettlementType = 'FOP' | 'DVP';

export type PricingType = 'NAV_PLUS' | 'NAV_GUARANTEED';

export type EditOrderProps = {
  isSubmitting?: boolean;
  loading: boolean;
  onChangeSeedDeliverable?: (deliverable: Deliverable) => void;
  onSubmit: (order: OrderFormProps) => void;
  pcf?: Pcf;
  user: User | null;
  changeOrder: (data: OrderFormProps | undefined) => void;
  order?: Order[];
};

export const NavOptionsTitles: { [key: string]: string } = {
  NAV_PLUS: 'NAV +',
  NAV_GUARANTEED: 'NAV Guaranteed',
};

export const DeliveryTypeTitles: { [key: string]: string } = {
  CASH: 'Cash',
  IN_KIND: 'In Kind',
};

export const CreationRedemptionTitles: { [key: string]: string } = {
  CREATION: 'Creation',
  REDEMPTION: 'Redemption',
};
