import { fetchAllExternalContacts } from 'utils/api/contacts';
import { getAllExternalContacts } from 'utils/constants/reactQueries';
import { useQuery } from 'react-query';
import { ParamsType } from 'hooks/useUrlParams';

export const useAllExternalContactsQuery = (enabled?: Boolean, params?: ParamsType) => {
  return useQuery(
    [getAllExternalContacts, params],
    () => fetchAllExternalContacts(params).then(({ data }) => data),
    {
      enabled: Boolean(enabled),
    }
  );
};
