// types
import { variantTypes } from './Tag.types';
// styles, assets
import { StyledArrowDown, MuiChip } from './Tag.styles';
import Chip from '@mui/material/Chip';
import { IconButton } from '@mui/material';
import { ReactComponent as Close } from 'assets/close.svg';

interface TagProps {
  disabled?: boolean;
  filterable?: boolean;
  expandable?: boolean;
  label: string;
  variant?: variantTypes;
  onClose?: () => void;
  size?: 'small' | 'medium';
}

const variantToColorMap: Record<string, Partial<React.ComponentProps<typeof Chip>>> = {
  '01': {
    color: 'primary',
  },
  '02': {
    color: 'secondary',
  },
  '03': {
    color: 'success',
  },
  '04': {
    color: 'error',
  },
  '05': {
    color: 'info',
  },
  '06': {
    color: 'default',
  },
};

const Tag = ({
  variant = '01',
  disabled = false,
  filterable = false,
  expandable = false,
  label = '',
  onClose = () => {},
  size,
  ...props
}: TagProps) => {
  return (
    <MuiChip
      label={label}
      onDelete={filterable && !disabled ? onClose : undefined}
      deleteIcon={
        filterable && !disabled ? (
          <IconButton>
            <Close />
          </IconButton>
        ) : undefined
      }
      icon={expandable ? <StyledArrowDown /> : undefined}
      disabled={disabled}
      expandable={expandable ? 'true' : 'false'}
      variant="filled"
      size={size}
      {...variantToColorMap[variant]}
      {...props}
    />
  );
};

export default Tag;
