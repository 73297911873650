import Select from 'components/Select';
import { Row } from 'components/Grid';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledFilterSelect = MuiStyled(Select)`
  width: 240px;
  text-transform: capitalize;
  .select-options-container {
    margin-top: -${({ theme }) => theme.spacers['spacing-02']};
    right: 0;
  }
`;

export const TableCellContent = MuiStyled(Box)`
  align-items: center;
  display: flex;
  gap: 8px;
`;

// export const StyledToolbarsWrapper = MuiStyled(Box)`
//   width: 100%;
//   gap: 8px;
// `;

export const StyledToollbarBottomRow = MuiStyled(Row)`
  justify-content: space-between;
  padding-top: 8px;
  button {
    height: 40px;
  }
`;

export const StyledToollbarTopRow = MuiStyled(Row)`
  justify-content: end;
  gap: 8px;
`;
