export const CalendarCountryTitles: { [key: string]: string } = {
  AU: 'Australia',
  CH: 'Switzerland',
  GB: 'United Kingdom',
  LI: 'Liechtenstein',
  US: 'United States',
};

export enum CalendarEventType {
  HOLIDAYS = 'HOLIDAYS',
}

export const TIME_ZONES = [
  'America/Chicago',
  'America/New_York',
  'Australia/Sydney',
  'Europe/London',
  'Europe/Zurich',
] as const;

export type TimeZone = (typeof TIME_ZONES)[number];
