import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactComponent as ChartArrow } from 'assets/chart-arrow.svg';

export default function ProductConfigHeader({ title }: { title: string }) {
  return (
    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
      <Box width={'24px'}>
        <ChartArrow />
      </Box>
      {/* @TODO Add theme colors */}
      <Typography color="#3B4853" variant="subheadingMedium">
        {title}
      </Typography>
    </Box>
  );
}
