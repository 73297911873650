import { Box, styled as MuiStyled, alpha } from '@mui/material';
import { Container } from 'components/Grid';
import loginBg_1440_1600 from 'assets/background_1440_1660.svg';
import loginBg_1440 from 'assets/background_1440.svg';
import loginBg_1600 from 'assets/background_1660.svg';

const containerPadding = '2rem';

export const StyledDashboardContainer = MuiStyled(Container)(({ theme }) => ({
  padding: 0,
  margin: 0,
  height: '100vh',
  backgroundColor: alpha(theme.customPalette.static.text.divider.default.weak, 0.1),
}));

export const BackgroundImageContainer = MuiStyled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 276px;
  background-size: cover;
  background-position: top center;
  z-index: -1;
  background-image: url(${loginBg_1440});
  @media (min-width: 1440px) and (max-width: 1600px) {
    background-image: url(${loginBg_1440_1600});
  }
  @media (min-width: 1600px) {
    background-image: url(${loginBg_1600});
  }
`;

export const Content = MuiStyled(Box)`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: ${containerPadding};
`;
