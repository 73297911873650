import AddContactToContactListModal from './AddContactToContactListModal/AddContactToContactListModal';
import { ContactList } from 'utils/types/contacts';
import { DELETE_CONTACTS_FROM_CONTACT_LIST_MODAL } from 'shared/Modals/constants';
import { DeleteContactListsModal } from './DeleteContactListsModal/DeleteContactListsModal';
import { EditContactListModal } from './EditContactListModal/EditContactListModal';
import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';
import { RemovePartnerFromContactListModal } from './RemovePartnerFromContactListModal/RemovePartnerFromContactListModal';

export interface ContactListModalData {
  contactsLists?: ContactList;
  apiUri: string;
}

export const ContactListModal = ({ ...props }: IModalWithData) => {
  const DeleteComponent =
    props.data.custom?.type !== DELETE_CONTACTS_FROM_CONTACT_LIST_MODAL ? (
      <DeleteContactListsModal {...props} />
    ) : (
      <RemovePartnerFromContactListModal {...props} />
    );

  const ContactListModals: Record<string, ReactNode> = {
    CUSTOM: <AddContactToContactListModal {...props} />,
    CREATE: <EditContactListModal {...props} />,
    EDIT: <EditContactListModal {...props} />,
    DELETE: DeleteComponent,
  };

  return <>{ContactListModals[props.data.type]}</>;
};
