import { Navigate } from 'react-router-dom';
import { getWeakToken } from 'utils/user/storage';

interface PrivateDashboardRouteProps {
  children: JSX.Element;
}

function PrivateWeakRouteGuard({ children }: PrivateDashboardRouteProps) {
  const weakToken = getWeakToken() ?? null;

  const element = weakToken ? children : <Navigate to="/login" replace />;

  return element;
}

export default PrivateWeakRouteGuard;
