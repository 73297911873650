import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { instrumentsActions } from 'store/instruments/slice';
import { createNotification } from 'store/notifications/actions';
import { loadInstrumentCustodians } from 'utils/api/instruments';
import { CustodianStatus } from 'utils/constants/custodians';
import { instrumentCustodiansQuery } from 'utils/constants/reactQueries';

export const useInstrumentCustodiansQuery = (instrumentId: string, custodianStatus?: string) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [instrumentCustodiansQuery, instrumentId],
    async () => {
      try {
        const res = await loadInstrumentCustodians(instrumentId, custodianStatus);
        const activeCustodians = res?.filter(
          (custodian) => custodian.status === CustodianStatus.ACTIVE
        );
        dispatch(instrumentsActions.populateInstrumentCustodians(activeCustodians));
        return res;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(instrumentId),
    }
  );
};
