import { useDirtySteps } from 'store/instruments/selectors';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, YES_OR_NO_MODAL } from 'shared/Modals/constants';
import { instrumentsActions } from 'store/instruments/slice';
import { useTokenContext } from 'contexts/tokens';
import { useNavigate } from 'react-router-dom';

export const useValidateNavigation = (): {
  validToNavigate: Boolean;
  openInvalidNavigationModal: (data: any) => void;
  clearInvalidData: () => void;
} => {
  const navigate = useNavigate();
  const openModal = useAppModal();
  const { tokenFormIsDirty, setCurrentToken } = useTokenContext();
  const dirtySteps = useDirtySteps();
  const isInstrumentFormDirty = Boolean(Object.values(dirtySteps).some((dirty) => Boolean(dirty)));
  const { removeCurrentInstrument } = instrumentsActions;

  const openInvalidNavigationModal = (data?: string) => {
    openModal(
      {
        modalName: YES_OR_NO_MODAL,
        modalData: {
          data: data,
          type: MODAL_ACTIONS.CUSTOM,
        },
      },
      {
        onCloseModalAction: (path) => {
          clearInvalidData();
          navigate(path as string);
        },
      }
    );
  };

  const clearInvalidData = () => {
    removeCurrentInstrument();
    setCurrentToken(null);
  };

  return {
    validToNavigate: Boolean(!isInstrumentFormDirty) && !tokenFormIsDirty,
    openInvalidNavigationModal,
    clearInvalidData,
  };
};
