import { NavReviewItem, NavReviewItemWithProduct, NavReviewItemsRows } from 'utils/types/nav';
import { useNavReviewItemQuery } from './useNavReviewItemQuery';
import { NavDifferenceRow } from 'shared/Modals/NavReviewModals/NavReviewModal';
import { useEffect, useMemo, useState } from 'react';
import { useProductData } from './useProductData';
import { mapNavReviewItemTransactions } from 'shared/Modals/NavReviewModals/utils/mapNavReviewItemTransactions';

export type NavModalTransactions = {
  [key in NavReviewItemsRows]: NavDifferenceRow;
};

export const useNavReviewModalData = (navReviewData: NavReviewItemWithProduct) => {
  const [navReviewItem, setNavReviewItem] = useState<NavReviewItem>();

  const {
    data: navReviewItemRes,
    isLoading,
    isFetching,
  } = useNavReviewItemQuery(navReviewData.portfolioId, navReviewData._id);

  const { data: navReviewItemProduct, isLoading: isLoadingProduct } = useProductData(
    navReviewData.product._id
  );

  useEffect(() => {
    setNavReviewItem(structuredClone(navReviewItemRes));
  }, [navReviewItemRes, isFetching, isLoading]);

  const mappedTransactions = useMemo(() => {
    return mapNavReviewItemTransactions(navReviewItem);
  }, [navReviewItem]);

  return {
    mappedTransactions,
    navReviewItem,
    product: navReviewItemProduct,
    isLoading: isLoading || isFetching || isLoadingProduct,
    updateDeltaNavReviewItem: (item: NavReviewItem) => {
      setNavReviewItem(item);
    },
  };
};
