import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getCountriesOptionsQuery } from 'utils/constants/reactQueries';
import { fetchCities, fetchCountries } from 'utils/api/location';
import { OptionType } from 'components/Select/Select.types';
import { isUndefined } from 'lodash';

export const useCountriesOptionsQuery = () => {
  const dispatch = useAppDispatch();
  return useQuery([getCountriesOptionsQuery], async () => {
    try {
      const { data } = await fetchCountries();

      const options: OptionType<number>[] = data.map((country) => ({
        value: country.id,
        label: country.name,
      }));

      return options;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    }
  });
};

export const useCitiesOptionsQuery = (countryId?: number) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getCountriesOptionsQuery, countryId],
    async () => {
      try {
        const { data } = await fetchCities(countryId);

        const options: OptionType<number>[] = data.map((city) => ({
          value: city.id,
          label: city.name,
        }));

        return options;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: !isUndefined(countryId),
    }
  );
};
