import { useEffect, useState } from 'react';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Column, Row } from 'components/Grid';
import {
  StyledContainer,
  StyledDetailContainer,
  StyledLabel,
  StyledLogoWrapper,
} from 'pages/Dashboard/Dashboard.styles';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { apiUrls } from 'utils/constants/apiUrls';
import CircularProgress from 'components/CircularProgress';
import { Typography } from '@mui/material';
import { getOnyxVersion } from 'components/ProjectVersion';

export const Dashboard = () => {
  const [data, setData] = useState({ etps: 0, partners: 0, users: 0 });
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getMetrics().then(() => setLoading(false));
  }, []);

  const getMetrics = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${apiUrls.dashboard}/metrics`);
      setData(response.data);
    } catch (error) {
      return errorHandler(error);
    }
  };

  return (
    <StyledContainer>
      <StyledLogoWrapper>
        <Logo width={192} height={96} />
      </StyledLogoWrapper>
      <Row>
        <Column cols={4} gutter={'right'}>
          <StyledDetailContainer>
            <Typography variant="subheadingMedium">ETPs</Typography>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography variant="subheadingMedium">{data.etps}</Typography>
            )}
          </StyledDetailContainer>
        </Column>
        <Column cols={4} gutter={'noXGutter'}>
          <StyledDetailContainer>
            <Typography variant="subheadingMedium">Partners</Typography>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography variant="subheadingMedium">{data.partners}</Typography>
            )}
          </StyledDetailContainer>
        </Column>
        <Column cols={4} gutter={'left'}>
          <StyledDetailContainer>
            <Typography variant="subheadingMedium">Users</Typography>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography variant="subheadingMedium">{data.users}</Typography>
            )}
          </StyledDetailContainer>
        </Column>
      </Row>
      <Row>
        <Column cols={12}>
          <StyledLabel>Welcome to Onyx {getOnyxVersion()}</StyledLabel>
        </Column>
      </Row>
    </StyledContainer>
  );
};
