import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types/orders';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { ProductType } from 'utils/types';
import { TableBody } from '@mui/material';
import { formatToCurrency } from 'utils/formatting';

export const ExpectedDeliverablesTableColumnsConfig = [
  { label: 'Currency', propName: 'ticker', propType: 'string', sort: false },
  { label: 'Quantity', propName: 'amount', propType: 'string', sort: false },
  { label: 'Value (base ccy)', propName: 'value', propType: 'string', sort: false },
  { label: 'Price (T-1 NAV)', propName: 'priceT1', propType: 'string', sort: false },
  { label: 'FX Rate base to delivery', propName: 'fxRateUSD', propType: 'string', sort: false },
  {
    label: 'Expected deliverables',
    propName: 'expectedTotalWithCash',
    propType: 'string',
    sort: false,
  },
];

interface ExpectedDeliverablesExpectedTableProps {
  productType: ProductType;
  order?: Order;
}

export const ExpectedDeliverablesExpectedTable = ({
  productType,
  order,
}: ExpectedDeliverablesExpectedTableProps) => {
  const deliverables = order?.deliveries?.expected;
  const columns = ExpectedDeliverablesTableColumnsConfig;

  const tokensColumns = [
    { label: 'Ticker', propName: 'ticker', propType: 'string' },
    { label: 'Quantity', propName: 'quantity', propType: 'number' },
  ];

  return (
    <>
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort columns={columns} />
          <TableBody>
            {deliverables?.map((deliverable: Deliverable) => (
              <MuiStyledTableRow key={deliverable.ticker}>
                <MuiStyledTableCell key="ticker">{deliverable?.ticker}</MuiStyledTableCell>
                <MuiStyledTableCell key="coinQty">{deliverable.amount}</MuiStyledTableCell>
                <MuiStyledTableCell key="value">
                  {formatToCurrency(deliverable.totalInCurrency, deliverable.currency)}
                </MuiStyledTableCell>
                <MuiStyledTableCell key="priceT1Nav">
                  {formatToCurrency(deliverable.price, deliverable.currency)}
                </MuiStyledTableCell>
                <MuiStyledTableCell key="fxRateToUSD">{deliverable.fxRate}</MuiStyledTableCell>
                <MuiStyledTableCell key="expectedTotalWithCash">
                  {formatToCurrency(deliverable.totalInDeliveryCurrency, deliverable.currency)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort
            columns={tokensColumns}
            columnAlignment={{ quantity: 'right' }}
          />
          <TableBody>
            {deliverables?.length ? (
              deliverables?.map((deliverable: Deliverable, index) => (
                <MuiStyledTableRow key={index}>
                  <MuiStyledTableCell key="ticker">{deliverable?.ticker}</MuiStyledTableCell>
                  <MuiStyledTableCell align="right" key="coinQty">
                    {deliverable.amount}
                  </MuiStyledTableCell>
                </MuiStyledTableRow>
              ))
            ) : (
              <MuiStyledTableRow>
                <MuiStyledTableCell key="ticker">-</MuiStyledTableCell>
                <MuiStyledTableCell align="right" key="cointQty">
                  -
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};
