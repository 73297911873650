import InstrumentForm from 'pages/Instruments/components/Form/ETPForm';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  INSTRUMENT_DETAILS_TAB,
  PARTNER_DETAILS_TAB,
  producTabs,
} from 'pages/Instruments/helpers/productTabs';
import { ETPPageHeader } from 'pages/Instruments/components/ETPPageHeader';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { getEtpDetailsUrl, getPartnerDetailsUrl } from 'pages/Instruments/components/Form/utils';
import { instrumentsActions } from 'store/instruments/slice';
import { loadInstrument } from 'store/instruments/actions';
import { privateRoutesUrls } from 'router/constants';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

function UpdateInstrument() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { removeCurrentInstrument } = instrumentsActions;
  const { error, instrument, loading } = useCurrentInstrument();

  const isCreateEtpDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.newEtpProductsEtpDetails)
  );
  const isEditEtpDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.editEtpProductsEtpDetails)
  );
  const isEtpDetailsRoute = isCreateEtpDetailsRoute || isEditEtpDetailsRoute;
  const activeTab = isEtpDetailsRoute ? INSTRUMENT_DETAILS_TAB : PARTNER_DETAILS_TAB;

  useEffect(() => {
    if (!error && params.id && params.id !== instrument?._id && !loading) {
      dispatch(loadInstrument({ id: params.id, withWallets: true, withCustodians: true }));
    }
  }, [dispatch, params.id, instrument?._id, error, loading]);

  useEffect(() => {
    return () => {
      dispatch(removeCurrentInstrument());
    };
  }, []);

  const handleChangeTab = (tab: string) => {
    if (tab === INSTRUMENT_DETAILS_TAB) {
      navigate(getEtpDetailsUrl(params.id));
    } else {
      navigate(getPartnerDetailsUrl(params.id));
    }
  };

  return (
    <>
      <TitleArea
        showBackButton={true}
        title={<ETPPageHeader instrument={instrument} />}
        tabConfig={{
          tabs: producTabs,
          setUrlParamsWithDefaults: (data) => {
            handleChangeTab(data.tab as string);
          },
          active: activeTab,
        }}
      />
      <InstrumentForm activeTab={activeTab} />
    </>
  );
}

export default UpdateInstrument;
