/* eslint-disable complexity */
import Table, { TableRow, TableCell } from 'components/Table';
import {
  useCurrentInstrumentFormData,
  useDirtyInstrumentFormData,
  useFormDataDirtyFields,
} from 'store/instruments/selectors';
import { PartnersStepDataProps } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import { PARTNERS_STEP } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { CompanySuggestionDto } from 'utils/types';
import { renderPartners } from 'shared/Modals/TouchedProductModal/helpers';
import { capitalize } from 'lodash';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import formatFieldName from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/utils/formatFieldName';
import { TableBody, TableHead } from '@mui/material';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';

const getPartnerTableRow = (
  fieldName: string,
  partners: CompanySuggestionDto[],
  oldFormData: PartnersStepDataProps,
  newFormData: PartnersStepDataProps,
  deleteUpdatedField: (fieldName: string) => void
) => {
  switch (fieldName) {
    case 'issuer':
      return (
        <TableRow key={fieldName}>
          <TableCell>{formatFieldName(fieldName)}</TableCell>
          <TableCell>
            {partners?.find((partner) => partner._id === oldFormData?.issuer)?.name}
          </TableCell>
          <TableCell>
            {partners?.find((partner) => partner._id === newFormData?.issuer)?.name}
          </TableCell>
          <TableCell>
            <TrashIcon
              onClick={() => {
                deleteUpdatedField(fieldName);
              }}
            />
          </TableCell>
        </TableRow>
      );
    case 'authorizedParticipants':
      return (
        <TableRow key={fieldName}>
          <TableCell>{formatFieldName(fieldName)}</TableCell>
          <TableCell>
            {renderPartners(oldFormData?.authorizedParticipants, partners ?? [])}
          </TableCell>
          <TableCell>
            {renderPartners(newFormData?.authorizedParticipants, partners ?? [])}
          </TableCell>
          <TableCell>
            <TrashIcon
              onClick={() => {
                deleteUpdatedField(fieldName);
              }}
              style={{ paddingLeft: 0 }}
            />
          </TableCell>
        </TableRow>
      );
    case 'calculationAgents':
      return (
        <TableRow key={fieldName}>
          <TableCell>{formatFieldName(fieldName)}</TableCell>
          <TableCell>{renderPartners(oldFormData?.calculationAgents, partners ?? [])}</TableCell>
          <TableCell>{renderPartners(newFormData?.calculationAgents, partners ?? [])}</TableCell>
          <TableCell>
            <TrashIcon
              onClick={() => {
                deleteUpdatedField(fieldName);
              }}
              style={{ paddingLeft: 0 }}
            />
          </TableCell>
        </TableRow>
      );
    case 'pcfUploaders':
      return (
        <TableRow key={fieldName}>
          <TableCell>PCF Uploaders</TableCell>
          <TableCell>{renderPartners(oldFormData?.pcfUploaders, partners ?? [])}</TableCell>
          <TableCell>{renderPartners(newFormData?.pcfUploaders, partners ?? [])}</TableCell>
          <TableCell>
            <TrashIcon
              onClick={() => {
                deleteUpdatedField(fieldName);
              }}
              style={{ paddingLeft: 0 }}
            />
          </TableCell>
        </TableRow>
      );
    case 'custodians':
      return (
        <TableRow key={fieldName}>
          <TableCell>Custodians</TableCell>
          <TableCell>{oldFormData?.custodians?.map(capitalize).join(';')}</TableCell>
          <TableCell>{newFormData?.custodians?.map(capitalize).join(';')}</TableCell>
          <TableCell>
            <TrashIcon
              onClick={() => {
                deleteUpdatedField(fieldName);
              }}
              style={{ paddingLeft: 0 }}
            />
          </TableCell>
        </TableRow>
      );
    case 'technicalListingAgent':
      return (
        <TableRow key={fieldName}>
          <TableCell>{formatFieldName(fieldName)}</TableCell>
          <TableCell>
            {partners?.find((partner) => partner._id === oldFormData?.technicalListingAgent)?.name}
          </TableCell>
          <TableCell>
            {partners?.find((partner) => partner._id === newFormData?.technicalListingAgent)?.name}
          </TableCell>
          <TableCell>
            <TrashIcon
              onClick={() => {
                deleteUpdatedField(fieldName);
              }}
              style={{ paddingLeft: 0 }}
            />
          </TableCell>
        </TableRow>
      );
  }
};

export const PartnerDetailsDifferencesSection = () => {
  const { data: partners } = useCompaniesSuggestion();
  const { deleteUpdatedField } = useSaveETPHook();
  const dirtyInstrumentFormsData = useDirtyInstrumentFormData(PARTNERS_STEP);
  const instrumentFormsData = useCurrentInstrumentFormData(PARTNERS_STEP);
  const formDataDirtyFields = useFormDataDirtyFields(PARTNERS_STEP);

  return formDataDirtyFields.length ? (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell min-width="20%">Name</TableCell>
            <TableCell width="35%">Old Value</TableCell>
            <TableCell width="35%">Proposed Value</TableCell>
            <TableCell width="32px"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formDataDirtyFields?.map((selectedValue, index) =>
            getPartnerTableRow(
              selectedValue,
              partners ?? [],
              instrumentFormsData as PartnersStepDataProps,
              dirtyInstrumentFormsData as PartnersStepDataProps,
              deleteUpdatedField
            )
          )}
        </TableBody>
      </Table>
    </>
  ) : null;
};
