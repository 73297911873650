import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getTokenOrder, loadOrder as loadOrderRequest } from 'utils/api/orders';
import {
  getCurrentOrderQueryKey,
  getCurrentTokenOrderQueryKey,
} from 'utils/constants/reactQueries';

export const useCurrentOrder = (orderId: string) => {
  const dispatch = useAppDispatch();

  return useQuery(
    [getCurrentOrderQueryKey, orderId],
    async () => {
      try {
        return await loadOrderRequest(orderId);
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message, 'Error'), error));
        throw error; // Rethrow the error so React Query knows the query failed
      }
    },
    {
      onSuccess: (data) => {
        console.log(`Order (id: ${data._id}) has been retrieved`);
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const useCurrentTokenOrder = (orderId: string) => {
  const dispatch = useAppDispatch();

  return useQuery(
    [getCurrentTokenOrderQueryKey, orderId],
    async () => {
      try {
        return await getTokenOrder(orderId);
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message, 'Error'), error));
        throw error; // Rethrow the error so React Query knows the query failed
      }
    },
    {
      onSuccess: (data) => {
        console.log(`Order (id: ${data._id}) has been retrieved`);
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};
