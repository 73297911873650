import { TableShowMoreCell } from 'components/Table/TableShowMoreCell';
import { StyledIcon } from 'layouts/DashboardContainer/SideNavigationMenu.style';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { useState } from 'react';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import { PartnerContactsDetailsTable } from './PartnerContactsDetailsTable/PartnerContactsDetailsTable';
import { Contact, PartnerContact } from 'utils/types/contacts';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell, MuiStyledTableCollapseCell } from 'components/Table/TableCell';
import Collapse from '@mui/material/Collapse';

interface PartnerContactsTableRowProps {
  partner: PartnerContact;
  editAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
}

export const PartnerContactsTableRow = ({
  partner,
  editAction,
  deleteAction,
}: PartnerContactsTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <MuiStyledTableRow key={`${partner._id}_${partner.name}`}>
        <MuiStyledTableCellChevron
          style={{ width: '5%' }}
          onClick={() => setIsExpanded((prevState) => !prevState)}
        >
          <StyledIcon>{isExpanded ? <ChevronUp /> : <ChevronDown />}</StyledIcon>
        </MuiStyledTableCellChevron>
        <MuiStyledTableCell style={{ width: '40%' }}>{partner.name}</MuiStyledTableCell>
        <TableShowMoreCell items={partner?.contactLists?.map(({ name }) => name)} colSpan={4} />
      </MuiStyledTableRow>
      <MuiStyledTableRow>
        <MuiStyledTableCollapseCell colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <PartnerContactsDetailsTable
              contacts={partner.contacts}
              editAction={(contact) => {
                editAction(contact);
              }}
              deleteAction={(contact) => {
                deleteAction(contact);
              }}
            />
          </Collapse>
        </MuiStyledTableCollapseCell>
      </MuiStyledTableRow>
    </>
  );
};
