import { TABLE_CELL_ALIGNMENTS } from 'shared/Tables/table.utils';

export const ContactsListColumnsConfig = [
  { label: 'Distribution List Name', propName: 'name', propType: 'string', width: '40%' },
  {
    label: 'Type',
    propName: 'type',
    propType: 'string',
    showInfoIcon: true,
    width: '26%',
  },
  {
    label: 'Number of contacts',
    propName: 'contactsCount',
    propType: 'number',
    width: '26%',
    sort: false,
  },
];

export const ContactsListColumnsAlignments: { [key: string]: TABLE_CELL_ALIGNMENTS } = {
  type: 'right',
  contactsCount: 'right',
};
