import Loader from 'components/Loader';
import Notification from 'components/Notification';
import PCFApprovalConfirmationModal from 'pages/Ledger/PCF/PCFDetails/PCFApprovalConfirmationModal';
import PCFDetailsAP from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/PCFDetailsAP';
import PCFDetailsIssuer from 'pages/Ledger/PCF/PCFDetails/PCFDetailsIssuer/PCFDetailsIssuer';
import PCFDetailsStatusBar from 'pages/Ledger/PCF/PCFDetails/PCFDetailsIssuer/PCFDetailsStatusBar';
import UploadPCFModal from 'shared/Modals/PCF/UploadPCFModal';
import useAppDispatch from 'hooks/useAppDispatch';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { DateValue } from 'components/DatepickerSingleDate';
import { PCFDetailsPageHeader } from './PCFDetailsPageHeader';
import { PCFDetailsToolbarActionsHeader } from './PCFDetailsToolbarActionsHeader';
import { StyledDetailsContainer } from 'pages/Ledger/PCF/components/PCFStyles';
import {
  approvePCF as approvePCFRequest,
  refreshSyntheticCalculation,
} from 'utils/api/portfolio-composition';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { format, parseISO } from 'date-fns';
import { privateRoutesUrls } from 'router/constants';
import { usePCFDetailsQuery } from 'pages/Ledger/PCF/usePCFDetailsQuery';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useProductTradingStatus } from 'hooks/useIsProductTradingToday';
import { useState } from 'react';
import { useUserPermissions } from 'store/user/selectors';
import { TitleArea } from 'components/TitleArea/TitleArea';

export const PCFDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const permissions = useUserPermissions();
  const [isRefreshingSynthetic, setIsRefreshingSynthetic] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<Boolean>(false);
  const [isApprovingPCF, setIsApprovingPCF] = useState(false);

  const { data: pcf, isLoading, isError, refetch } = usePCFDetailsQuery(params);

  const { data: isTradingToday, isLoading: isTradingStatusLoading } = useProductTradingStatus(
    params?.productId!,
    params.valuationDate!
  );

  const openDocument = (url?: string) => {
    if (!url) {
      dispatch(createNotification(errorNotification('Document not available', 'Download error')));
    } else {
      window.open(url, '_');
    }
  };

  const refreshOnyxData = async () => {
    setIsRefreshingSynthetic(true);

    try {
      await refreshSyntheticCalculation(pcf?._id!);
      refetch();
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    }

    setIsRefreshingSynthetic(false);
  };

  const approvePCF = async () => {
    setIsApproveModalOpen(false);
    setIsApprovingPCF(true);

    try {
      await approvePCFRequest(pcf!._actions!.approve!.uri!);
      dispatch(
        createNotification(
          successNotification(
            `PCF for ${pcf?.product.ticker} approved successfully`,
            'PCF Approved'
          )
        )
      );
      refetch();
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    }

    setIsApprovingPCF(false);
  };

  const date =
    params.valuationDate && typeof params.valuationDate === 'string'
      ? parseISO(params.valuationDate)
      : null;

  const handleDateChange = (date: DateValue) => {
    const formattedDate = date && format(date, DEFAULT_DATE_FORMAT);
    navigate(
      generatePath(privateRoutesUrls.dashboardRoutes.pcfDetails, {
        productId: params.productId,
        valuationDate: formattedDate,
      })
    );
  };

  return (
    <>
      <TitleArea title={<PCFDetailsPageHeader pcf={pcf} />} showBackButton={true} />
      {isLoading && isTradingStatusLoading ? (
        <Loader />
      ) : (
        <StyledDetailsContainer>
          {isError ? (
            <Notification
              messageMaxWidth={true}
              multiline={'false'}
              title={`Failed to load PCF`}
              variant="error"
            />
          ) : (
            <PCFDetailsStatusBar pcf={pcf} />
          )}
          {!isTradingToday && (
            <Notification
              messageMaxWidth={true}
              multiline={'false'}
              title={`Holiday`}
              message={'Due to a holiday there is no PCF to be shown'}
              variant="info"
            />
          )}
          <PCFDetailsToolbarActionsHeader
            pcf={pcf}
            date={date}
            handleDateChange={handleDateChange}
            openDocument={openDocument}
            isRefreshingSynthetic={isRefreshingSynthetic}
            isApprovingPCF={isApprovingPCF}
            setIsApproveModalOpen={setIsApproveModalOpen}
            refreshOnyxData={refreshOnyxData}
            isTradingToday={isTradingToday}
          />
          {permissions?.canComparePcfs ? (
            <PCFDetailsIssuer pcf={pcf} isLoading={isLoading} />
          ) : (
            <PCFDetailsAP pcf={pcf} />
          )}
          <UploadPCFModal refetch={refetch} />
          {isApproveModalOpen && (
            <PCFApprovalConfirmationModal
              onApprove={approvePCF}
              onCancel={() => setIsApproveModalOpen(false)}
            />
          )}
        </StyledDetailsContainer>
      )}
    </>
  );
};
