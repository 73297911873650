import { Autocomplete, TextField, Checkbox, FormControl, FormLabel } from '@mui/material';
import {
  GroupedOptionsType,
  OptionType,
  OptionValueType,
  OnChangeType,
} from 'components/Select/Select.types';
import transformOptions from 'components/Select/helpers/transformOptions';
import { ComponentPropsWithoutRef } from 'react';
import NewTag from 'components/NewTag/NewTag';
export interface NewMultiSelectProps<T extends OptionValueType>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange' | 'value' | 'size'> {
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  errorMessage?: string;
  isInvalid?: boolean;
  label?: string;
  options?: OptionType<T>[] | GroupedOptionsType<T>;
  onChange?: OnChangeType<T>;
  value?: string[];
  withFilter?: boolean;
  onFilter?: (inputValue?: string) => void;
  filteredOptions?: OptionType<T>[] | GroupedOptionsType<T>;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  width?: string;
  minWidth?: string | number;
  name?: string;
}

export const NewMultiSelect = <T extends OptionValueType>({
  className,
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  disabled,
  errorMessage,
  isInvalid,
  label,
  onChange,
  options,
  placeholder,
  value = [],
  fullWidth = false,
  size = 'large',
  width,
  minWidth,
  name,
  ...props
}: NewMultiSelectProps<T>) => {
  const items = transformOptions(options);

  const isOptionEqualToValue = (option: OptionType<T>, value: OptionType<T>) => {
    return option.value === value.value;
  };

  const selectedItems = items.filter((item) => {
    return item.value !== undefined && value.some((v) => v !== undefined && v === item.value);
  });

  const handleOnChange = (event: React.SyntheticEvent, selectedOptions: OptionType<T>[]) => {
    const selectedValues = selectedOptions.map((option) => option.value as T);
    const primarySelection = selectedValues[0];

    if (onChange) {
      onChange(primarySelection, selectedValues);
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={isInvalid}
      data-qa-id={dataQaId}
      disabled={disabled}
    >
      {label && <FormLabel sx={{ marginBottom: '4px' }}>{label}</FormLabel>}
      <Autocomplete
        data-qa-id={dataQaId}
        multiple
        disabled={disabled}
        id="checkboxes-tags-demo"
        options={items}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={selectedItems}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={handleOnChange}
        renderTags={(value: OptionType<T>[], getTagProps) =>
          value.map((option: OptionType<T>, index: number) => (
            <NewTag label={option.label} variant="default" {...getTagProps({ index })} />
          ))
        }
        renderOption={(props, option, { selected }) => {
          const { ...optionProps } = props;
          return (
            <li data-qa-options-id={dataQaOptionsId} {...optionProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </li>
          );
        }}
        sx={{
          width: fullWidth ? '100%' : undefined,
          minWidth: minWidth,
          '& .MuiAutocomplete-inputRoot': {
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder={value.length ? '' : placeholder}
            InputProps={{
              ...params.InputProps,
              sx: {
                padding: '4px',
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default NewMultiSelect;
