import { UserPermissions } from 'utils/types/permissions';
import { TableHeaderColumn } from 'shared/Tables/Table.types';

export const getOrdersTableColumnsConfig = (
  shouldDisplayActions?: Boolean,
  shouldDisplayActualDeliverables?: Boolean,
  permissions?: {
    [key in UserPermissions]: boolean;
  }
): TableHeaderColumn[] => [
  { label: 'Product', propName: 'product.ticker', propType: 'string', minWidth: '120px' },
  ...(permissions?.isIssuer || permissions?.isSystemAdmin
    ? [
        {
          label: 'AP name',
          propName: 'companyName',
          propType: 'string',
          sort: false,
        },
      ]
    : []),
  { label: 'Order Type', propName: 'type', propType: 'string', sort: false, minWidth: '96px' },
  {
    label: 'Delivery Type',
    propName: 'deliveryType',
    propType: 'string',
    sort: false,
    minWidth: '112px',
  },
  {
    label: 'Order ID',
    propName: 'externalId',
    propType: 'string',
    sort: false,
    minWidth: '220px',
  },
  { label: "# of CU'S", propName: 'numberOfUnits', propType: 'string', minWidth: '112px' },
  { label: 'Amount', propName: 'amount', propType: 'string', sort: false, minWidth: '128px' },
  { label: 'Deal Date', propName: 'dealDate', propType: 'date', minWidth: '96px' },
  { label: 'Settlement Date', propName: 'settlement.date', propType: 'date', minWidth: '160px' },
  { label: 'Status', propName: 'status', propType: 'string', sort: false },
  {
    label: 'Expected Deliverables',
    propName: 'deliveries.expected',
    propType: 'string',
    sort: false,
  },
  ...(shouldDisplayActualDeliverables
    ? [
        {
          label: 'Actual Deliverables',
          propName: 'deliveries.actual',
          propType: 'string',
          sort: false,
        },
      ]
    : []),
  ...(shouldDisplayActions && permissions?.canDeferOrder
    ? [
        {
          label: '',
          sort: false,
          propName: 'actions',
          propType: 'string',
        },
      ]
    : []),
];

export const getTokenOrdersTableColumnsConfig = (
  shouldDisplayActions?: Boolean,
  shouldDisplayActualDeliverables?: Boolean,
  permissions?: {
    [key in UserPermissions]: boolean;
  }
): TableHeaderColumn[] => [
  // Product
  {
    label: 'Product',
    propName: 'product.ticker',
    propType: 'string',
    minWidth: '80px',
  },
  // Counter-Party
  ...(permissions?.isIssuer || permissions?.isSystemAdmin
    ? [
        {
          label: 'Counter-Party',
          propName: 'companyName',
          propType: 'string',
          sort: false,
        },
      ]
    : []),
  // Order Type
  { label: 'Order Type', propName: 'type', propType: 'string', sort: false, minWidth: '96px' },
  // Order Identifer
  {
    label: 'Order ID',
    propName: 'externalId',
    propType: 'string',
    sort: false,
    minWidth: '220px',
  },
  // # of Tokens
  { label: '# of Tokens', propName: 'numberOfUnits', propType: 'string', minWidth: '112px' },
  // Deal Date
  { label: 'Deal Date', propName: 'dealDate', propType: 'date', minWidth: '96px' },
  // Settlement Date
  { label: 'Settlement Date', propName: 'settlement.date', propType: 'date', minWidth: '160px' },
  // Status
  { label: 'Status', propName: 'status', propType: 'string', sort: false },
  // Expect Deliverables
  {
    label: 'Expected Deliverables',
    propName: 'deliveries.expected',
    propType: 'string',
    sort: false,
  },
  // Actual Deliverables
  ...(shouldDisplayActualDeliverables
    ? [
        {
          label: 'Actual Deliverables',
          propName: 'deliveries.actual',
          propType: 'string',
          sort: false,
        },
      ]
    : []),
];
