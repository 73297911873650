import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Order, OrderStatus } from 'utils/types/orders';
import { OrdersFiltersData } from 'utils/constants/orders';
import { OrdersTableRow } from './OrdersTableRow';
import { ProductType } from 'utils/types/product';
import { useMemo } from 'react';
import { useUserPermissions } from 'store/user/selectors';
import {
  getOrdersTableColumnsConfig,
  getTokenOrdersTableColumnsConfig,
} from './tableColumnsConfig';

interface OrdersTableProps {
  productType: ProductType;
  orders: Order[];
  deleteAction: (order: Order) => void;
  editAction: (order: Order) => void;
  loadingData: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<OrdersFiltersData>>;
  urlParams: OrdersFiltersData;
}

export const OrdersTable = ({
  productType,
  orders,
  editAction,
  loadingData,
  setUrlParams,
  urlParams,
}: OrdersTableProps) => {
  const permissions = useUserPermissions();

  const shouldDisplayActualDeliverables = useMemo(() => {
    return Boolean(orders.find((order) => order.status === OrderStatus.SETTLED));
  }, [orders]);

  const shouldDisplayActions = useMemo(() => {
    return Boolean(
      orders.find(
        (order) => order.status !== OrderStatus.SETTLED && order.status !== OrderStatus.CANCELED
      )
    );
  }, [orders]);
  const tableColumns = useMemo(
    () =>
      productType === 'ETP'
        ? getOrdersTableColumnsConfig(
            shouldDisplayActions,
            shouldDisplayActualDeliverables,
            permissions
          )
        : getTokenOrdersTableColumnsConfig(
            shouldDisplayActions,
            shouldDisplayActualDeliverables,
            permissions
          ),
    [
      permissions?.isIssuer,
      permissions?.isSystemAdmin,
      shouldDisplayActualDeliverables,
      shouldDisplayActions,
    ]
  );

  return (
    <Table noPadding>
      <TableHeaderWithMultiSort
        defaultSortedColumns={urlParams.sort ?? []}
        columns={tableColumns}
        columnAlignment={{
          amount: 'right',
          numberOfUnits: 'right',
        }}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loadingData)}
        hasContent={!isEmpty(orders)}
        noContentLabel="No orders at the moment."
      >
        {orders?.map((order, index) => {
          return (
            <OrdersTableRow
              productType={productType}
              key={index + order._id}
              order={order}
              editAction={() => editAction(order)}
              tableColumns={tableColumns}
            />
          );
        })}
      </TableBodyWithStates>
    </Table>
  );
};
