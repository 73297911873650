import { NotificationWithoutId } from 'utils/types';
import { PusherNotificationMessage } from 'utils/types/pusher';
import { PusherNotificationSeverity } from 'utils/constants/pusher';

// @see types/Notification
const notificationTitleMap: Record<PusherNotificationSeverity, string> = {
  [PusherNotificationSeverity.ERROR]: 'Error',
  [PusherNotificationSeverity.INFO]: 'Info',
  [PusherNotificationSeverity.SUCCESS]: 'Success',
  [PusherNotificationSeverity.WARNING]: 'Warning',
};

const notificationTypeMap: Record<
  PusherNotificationSeverity,
  'info' | 'success' | 'warning' | 'error'
> = {
  [PusherNotificationSeverity.ERROR]: 'error',
  [PusherNotificationSeverity.INFO]: 'info',
  [PusherNotificationSeverity.SUCCESS]: 'success',
  [PusherNotificationSeverity.WARNING]: 'warning',
};

export const transform = (msg: PusherNotificationMessage): NotificationWithoutId => ({
  message: msg.text,
  title: notificationTitleMap[msg.severity],
  type: notificationTypeMap[msg.severity] as 'info' | 'success' | 'warning' | 'error',
});
