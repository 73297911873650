import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Button from 'components/Button';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ClickAwayListener, IconButton, Popper, Stack, Typography } from '@mui/material';
import { MuiStyledContainer } from './DateRangePicker.styles';

export type DateRangeValue = [Date | null, Date | null];

export interface DataRangePickerProps {
  onChange: (value: DateRangeValue) => void;
  startDate: Date | null;
  endDate: Date | null;
  maxDate?: Date;
  disableClearButton?: boolean;
}

const inputDateFormat = 'dd/MM/yyyy';

function DateRangePicker({
  onChange,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  maxDate,
  disableClearButton,
}: DataRangePickerProps) {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDateRangeEmpty = !Boolean(startDate) && !Boolean(endDate);
  const [datePickerError, setDatePickerError] = useState<string | null>(null);
  const [startDatePickerError, setStartDatePickerError] = useState<string | null>(null);
  const [endDatePickerError, setEndDatePickerError] = useState<string | null>(null);

  const handleStartDateChange = (date: Date | null) => {
    setDatePickerError(null);
    setStartDate(date);
  };
  const handleEndDateChange = (date: Date | null) => {
    setDatePickerError(null);
    setEndDate(date);
  };

  const handleApplyClick = () => {
    if (startDate && endDate && endDate < startDate) {
      setDatePickerError('End date cannot be before start date');
    } else {
      setDatePickerError(null);
      if (onChange) onChange([startDate, endDate]);
      setAnchorEl(null);
    }
  };

  const clearDatePicker = () => {
    setStartDate(null);
    setEndDate(null);
    setDatePickerError(null);
    onChange([null, null]);
  };

  const handlePoperClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const clickAwayHandler = () => setAnchorEl(null);

  // mapping of Mui Datepicker errors to more useful error messages
  const datePickerErrorMessages = {
    maxDate: 'Date cannot be in the future',
    invalidDate: 'Date is invalid',
  };

  return (
    <Box>
      <IconButton onClick={handlePoperClick} className="date-picker-calendar-button">
        <CalendarIcon />
      </IconButton>
      <ClickAwayListener
        onClickAway={clickAwayHandler}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <MuiStyledContainer>
            <Stack spacing={2}>
              <Stack>
                <DatePicker
                  label="Start date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format={inputDateFormat}
                  maxDate={maxDate}
                  onError={(e) => (e ? setStartDatePickerError(e) : setStartDatePickerError(null))}
                />
                {/* start date error messaging */}
                {startDatePickerError && (
                  <Typography color="error" variant="captionSmall">
                    {startDatePickerError in datePickerErrorMessages
                      ? datePickerErrorMessages[
                          startDatePickerError as keyof typeof datePickerErrorMessages
                        ]
                      : startDatePickerError}
                  </Typography>
                )}
              </Stack>
              <Stack>
                <DatePicker
                  label="End date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format={inputDateFormat}
                  maxDate={maxDate}
                  onError={(e) => (e ? setEndDatePickerError(e) : setEndDatePickerError(null))}
                />
                {/* end date error messaging */}
                {endDatePickerError && (
                  <Typography color="error" variant="captionSmall">
                    {endDatePickerError in datePickerErrorMessages
                      ? datePickerErrorMessages[
                          endDatePickerError as keyof typeof datePickerErrorMessages
                        ]
                      : endDatePickerError}
                  </Typography>
                )}
                {/* start & end date error messaging */}
                {datePickerError && (
                  <Typography color="error" variant="captionSmall">
                    {datePickerError}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button
                  disabled={isDateRangeEmpty || disableClearButton}
                  fullWidth
                  onClick={clearDatePicker}
                  size="small"
                  variant="tertiary"
                >
                  Clear
                </Button>
                <Button
                  disabled={
                    isDateRangeEmpty || Boolean(startDatePickerError) || Boolean(endDatePickerError)
                  }
                  fullWidth
                  onClick={handleApplyClick}
                  size="small"
                  variant="interactive"
                >
                  Apply
                </Button>
              </Stack>
            </Stack>
          </MuiStyledContainer>
        </Popper>
      </ClickAwayListener>
    </Box>
  );
}

export default DateRangePicker;
