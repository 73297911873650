// types
import { Instrument } from 'utils/types';
import { ProductStatus } from 'utils/types/product';

// styles
import CircularProgress from 'components/CircularProgress';
import { StyledSavingContainer } from 'pages/Instruments/Instruments.styles';

import { ReactComponent as SaveIcon } from 'assets/save.svg';
import { useCurrentInstrument } from 'store/instruments/selectors';

const PENDING_STATUS = 'PENDING';

export function renderSaving(message: string = 'Saving...') {
  return (
    <>
      <CircularProgress color="inherit" size={16} />
      {message}
    </>
  );
}

function renderSaved(instrument: Instrument | null) {
  const savedMessage = instrument?.status === PENDING_STATUS ? 'Saved to drafts' : 'Saved';
  return (
    instrument?._id && (
      <>
        <SaveIcon />
        {savedMessage}
      </>
    )
  );
}

export const SaveProgress = () => {
  const { instrument, saving } = useCurrentInstrument();

  return (
    <StyledSavingContainer>
      {saving ? renderSaving() : renderSaved(instrument ?? null)}
    </StyledSavingContainer>
  );
};

export const ETPStepSaveProgress = () => {
  const { instrument } = useCurrentInstrument();

  return instrument?.status === ProductStatus.ACTIVE ? null : <SaveProgress />;
};
