import { useCallback, useEffect, useState } from 'react';
import {
  CUSTODIAN_INFO_STEP,
  PARTNERS_STEP,
  ETPPartnerDetailsStepType,
  REVIEW_PARTNER_DETAILS_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { FormDataType } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import getStepsStatuses from 'pages/Instruments/components/Form/helpers/getStepsStatus';
import {
  getPartnerDetailsStep,
  getPartnerDetailsSteps,
} from 'pages/Instruments/components/Form/utils';
import ProgressIndicator from 'components/ProgressIndicator';
import Review from 'pages/Instruments/components/Form/PartnerDetails/components/ReviewStep';
import WalletsInfoStep from 'pages/Instruments/components/Form/PartnerDetails/components/WalletsInfoStep/WalletsInfoStep';
import { Column, Row } from 'components/Grid';
import { CustodianInfo } from 'pages/Instruments/components/Form/PartnerDetails/components/CustodianInfoStep/CustodianInfoStep';
import { Partners } from 'pages/Instruments/components/Form/PartnerDetails/components/PartnersStep';
import {
  useActiveStep,
  useCurrentInstrumentFormData,
  useDirtySteps,
} from 'store/instruments/selectors';
import { InstrumentFormDataProps } from 'pages/Instruments/Instruments.types';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, TOUCHED_FIELDS_MODAL } from 'shared/Modals/constants';
import useAppDispatch from 'hooks/useAppDispatch';
import { instrumentsActions } from 'store/instruments/slice';
import { useLocation } from 'react-router-dom';
import { updateInitialActiveStep } from 'pages/Instruments/components/Form/helpers/updateInitialActiveStep';

function renderCurrentStep(
  activeStep: ETPPartnerDetailsStepType,
  goBack: () => void,
  goToStep: (step: ETPPartnerDetailsStepType) => void,
  onSubmit: (data: FormDataType) => void
) {
  const steps = {
    [PARTNERS_STEP]: Partners,
    [CUSTODIAN_INFO_STEP]: CustodianInfo,
    [WALLETS_INFO_STEP]: WalletsInfoStep,
    [REVIEW_PARTNER_DETAILS_STEP]: Review,
  };

  const Step = steps[activeStep];
  return <Step onSubmit={onSubmit} goBack={goBack} goToStep={goToStep} />;
}

const PartnerDetails = () => {
  const location = useLocation();
  const openModal = useAppModal();
  const dispatch = useAppDispatch();
  const { updateActiveStep } = instrumentsActions;
  const activeStep = useActiveStep('PartnerDetails') as ETPPartnerDetailsStepType;
  const dirtySteps = useDirtySteps();
  const instrumentFormsData = useCurrentInstrumentFormData() as InstrumentFormDataProps;
  const [completedSteps, setCompletedSteps] = useState<ETPPartnerDetailsStepType[]>([]);
  const isDirtyStep = Boolean(dirtySteps[activeStep]);

  useEffect(() => {
    location.state?.step && updateInitialActiveStep(location.state.step, handleNavigateToStep);
  }, []);

  const handleNavigateToStep = useCallback(
    (step: ETPPartnerDetailsStepType) => {
      setCompletedSteps([...completedSteps, activeStep]);
      dispatch(updateActiveStep(step));
    },
    [activeStep, completedSteps, dispatch, updateActiveStep]
  );

  const openActiveETPUpdatedModal = (step: ETPPartnerDetailsStepType) => {
    openModal(
      {
        modalName: TOUCHED_FIELDS_MODAL,
        modalData: {
          type: MODAL_ACTIONS.EDIT,
          data: { targetStep: step },
        },
      },
      { onCloseModalAction: (step) => handleNavigateToStep(step as ETPPartnerDetailsStepType) }
    );
  };

  const handleStepSubmit = () => {
    const targetStep = getPartnerDetailsStep(activeStep)?.nextStep ?? REVIEW_PARTNER_DETAILS_STEP;
    if (isDirtyStep) {
      openActiveETPUpdatedModal(targetStep as ETPPartnerDetailsStepType);
    } else {
      handleNavigateToStep(targetStep as ETPPartnerDetailsStepType);
    }
  };

  const goBack = () => {
    const targetStep = getPartnerDetailsStep(activeStep)?.previousStep ?? PARTNERS_STEP;
    if (isDirtyStep) {
      openActiveETPUpdatedModal(targetStep as ETPPartnerDetailsStepType);
    } else {
      handleNavigateToStep(targetStep as ETPPartnerDetailsStepType);
    }
  };

  const goToStep = (step: ETPPartnerDetailsStepType) => {
    if (isDirtyStep) {
      openActiveETPUpdatedModal(step);
    } else {
      handleNavigateToStep(step);
    }
  };

  return (
    <>
      <Row>
        <Column>
          <ProgressIndicator
            onClickStep={(stepLabel: ETPPartnerDetailsStepType) =>
              dispatch(updateActiveStep(stepLabel))
            }
            steps={getPartnerDetailsSteps(
              activeStep,
              getStepsStatuses(instrumentFormsData, activeStep, completedSteps, dirtySteps)
            )}
          />
        </Column>
      </Row>
      {renderCurrentStep(activeStep, goBack, goToStep, handleStepSubmit)}
    </>
  );
};

export default PartnerDetails;
