export const NavReviewTableColumnsConfig = [
  { label: 'Product', propName: 'product.name', propType: 'string', width: '25%' },
  { label: 'Constituent', propName: 'ticker', propType: 'string', width: '10%' },
  {
    label: 'Onyx NAV',
    propName: 'onyxNav',
    propType: 'string',
    sort: false,
    width: '18%',
  },
  {
    label: 'Accountant NAV',
    propName: 'accountantNav',
    propType: 'date',
    sort: false,
    width: '18%',
  },
  { label: 'Difference', propName: 'difference', propType: 'string', width: '18%' },
  { label: 'State', propName: 'status', propType: 'string', width: '15%' },
];

export const NavReviewTableColumnsConfigFA = [
  { label: 'Product', propName: 'product.name', propType: 'string', width: '25%' },
  { label: 'Constituent', propName: 'ticker', propType: 'string', width: '10%' },
  {
    label: 'Accountant NAV',
    propName: 'accountantNav',
    propType: 'date',
    sort: false,
    width: '18%',
  },
  { label: 'State', propName: 'status', propType: 'string', width: '15%' },
];
