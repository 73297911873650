import Card from 'components/Card';
import Button from 'components/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import { twoFAResetSchemaUrl } from 'components/Form/formSchemas';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { FC, useState } from 'react';

interface Reset2FAScreenInterface {
  handleClose: () => void;
  handleUpdate: (otpVal: string) => void;
}

export const ResetOwn2FAInitialScreen: FC<Reset2FAScreenInterface> = ({
  handleUpdate,
  handleClose,
}) => {
  const [inputVal, setInputVal] = useState('');

  const handleSubmitBackupCode = () => {
    handleUpdate(inputVal);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={handleClose}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        fullWidth
        type="submit"
        data-qa-id="updateUserButton"
        onClick={handleSubmitBackupCode}
      >
        Reset 2FA
      </Button>
    </MuiStyledModalFooterButtons>
  );
  return (
    <Form schemaUrl={twoFAResetSchemaUrl} onSubmit={handleSubmitBackupCode} initialValues={{}}>
      <Card title={'Reset 2FA'} label={'2FA'} footer={Footer}>
        Once you enter your 2FA backup codes, the 2FA reset process will begin. This process cannot
        be stopped or undone, so please make sure you're ready to proceed.
        <Input
          name="otpBackupCode"
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        />
      </Card>
    </Form>
  );
};
