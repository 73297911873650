import { createSlice } from '@reduxjs/toolkit';

const initialState: { resetIdleTime: boolean } = {
  resetIdleTime: false,
};

const idleTimeSlice = createSlice({
  name: 'idleTime',
  initialState,
  reducers: {
    resetIdletime(state) {
      state.resetIdleTime = !state.resetIdleTime;
    },
  },
});

export const { resetIdletime } = idleTimeSlice.actions;

export const idleTimeReducer = idleTimeSlice.reducer;

export default idleTimeSlice;
