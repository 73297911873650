import InputAdornment from '@mui/material/InputAdornment';
import { StyledTextField } from 'pages/Rebalance/RebalanceDetailsPage/tables/RebalanceDetailsTable/EditableTableCell/EditableTableCell.styles';
import { useState } from 'react';
import { ActionButtonsWrapper } from './ActionButtonsWrapper';

interface InputWithCustomButtonsProps {
  value: string | number;
  label?: string;
  onConfirm: (value?: string | number) => void;
  onDecline: () => void;
}

export const InputWithCustomButtons = ({
  value,
  label = '',
  onConfirm,
  onDecline,
  ...props
}: InputWithCustomButtonsProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  return (
    <StyledTextField
      type="number"
      label={label}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          onConfirm(currentValue);
        } else if (event.key === 'Escape') {
          onDecline();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ActionButtonsWrapper onConfirm={() => onConfirm(currentValue)} onDecline={onDecline} />
          </InputAdornment>
        ),
      }}
      value={currentValue}
      onChange={({ target }) => {
        setCurrentValue(target.value);
      }}
      {...props}
    />
  );
};
