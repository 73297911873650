import AppRoutes from 'router/AppRoutes';
import { AppNotifications } from 'shared/Notifications/Notifications';
import { EnvironmentTitle } from 'utils/constants/environment';
import { environment } from 'utils/env';
import { useEffect } from 'react';
import { useIdleTimeout } from 'hooks/useIdleTimeout';
import { usePusherInitializer } from './hooks/usePusherInitializer';
import Loader from 'components/Loader';
import { useIinitialRenderData } from 'hooks/useIinitialRenderData';
import { StyledNavigationContainer } from 'layouts/DashboardContainer/SideNavigationMenu.style';

function App() {
  const { isLoaded } = useIinitialRenderData();
  const envTitle = `ONYX ${EnvironmentTitle[environment]}`;
  useIdleTimeout();
  usePusherInitializer();

  useEffect(() => {
    document.title = envTitle;
  }, [envTitle]);

  return isLoaded ? (
    <>
      <AppNotifications />
      <AppRoutes />
    </>
  ) : (
    <StyledNavigationContainer>
      <Loader />
    </StyledNavigationContainer>
  );
}

export default App;
