import { useId, useState } from 'react';
import {
  StyledShowMoreContainer,
  StyledShowMoreListElement,
  StyledShowMoreTitle,
} from 'pages/Admin/ContactsPage/ContactsPage.styles';
import { IconButton, Popover } from '@mui/material';
import { ReactComponent as ArrowRightIcon } from 'assets/chevron-right.svg';
import { primary } from 'theme/utils/paletteColors';

interface ShowMoreProps {
  className?: string;
  items: string[];
}

function ShowMore({ items, ...props }: ShowMoreProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = useId();

  return (
    <div {...props}>
      <IconButton color="primary" onClick={handleClick} size="small">
        <ArrowRightIcon fill={primary.main} />
      </IconButton>
      <Popover
        id="popover-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledShowMoreContainer>
          <StyledShowMoreTitle>Contact List</StyledShowMoreTitle>
          <ul onClick={handleClose}>
            {items.map((item, index) => (
              <StyledShowMoreListElement key={`${id}_${item}_${index}`}>
                {item}
              </StyledShowMoreListElement>
            ))}
          </ul>
        </StyledShowMoreContainer>
      </Popover>
    </div>
  );
}

export default ShowMore;
