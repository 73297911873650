import { Box, Paper, Slider, styled } from '@mui/material';
import { ReactComponent as IconInfo } from 'assets/info-circle.svg';

export const StyledTooltipHeader = styled(Box)`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export const StyledTooltipContent = styled(Paper)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: ${({ theme }) => theme.customPalette.static.background.default.weak};
`;

export const StyledIconInfo = styled(IconInfo)`
  fill: white;
`;

export const StyledTooltipBody = styled(Box)`
  display: flex;
  padding: 16px;
  padding-bottom: 8px;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: ${({ theme }) => theme.customPalette.static.background.default.strong};
`;

export const StyledTimeSlider = styled(Slider)(({ theme }) => ({
  gap: '10px',
  padding: 0,
}));
