// types
import store from 'store';
import { logout as userLogout } from 'store/user/slice';
import { StorageKey } from 'utils/constants/storage';
import PusherClient from 'utils/adapters/pusher';

export const clearStore = () => {
  store.dispatch(userLogout());
};

export const getAuthorizationHeader = () => {
  const token = getToken();

  return token ? `Bearer ${JSON.parse(getToken() ?? '')}` : null;
};

const getToken = () => getStrongToken() || localStorage.getItem(StorageKey.WEAK_AUTH_TOKEN);

export const getStrongToken = () => localStorage.getItem(StorageKey.STRONG_AUTH_TOKEN);

export const getUserLoggedOut = () => localStorage.getItem(StorageKey.USER_LOGGED_OUT);

export const getWeakToken = () => localStorage.getItem(StorageKey.WEAK_AUTH_TOKEN);

export const isLoggedIn = () => Boolean(getStrongToken());

export const logout = (userActivatedLogout: boolean = false) => {
  if (!isLoggedIn()) {
    return;
  }
  localStorage.clear();
  clearStore();
  PusherClient.destroy();
  if (!userActivatedLogout) storeUserLoggedOut(true);
};

export const removeUserLoggedOut = () => localStorage.removeItem(StorageKey.USER_LOGGED_OUT);

export const removeWeakToken = () => localStorage.removeItem(StorageKey.WEAK_AUTH_TOKEN);

export function storeStrongToken(token: string) {
  localStorage.setItem(StorageKey.STRONG_AUTH_TOKEN, JSON.stringify(token));
}

export const storeUserLoggedOut = (flag: boolean) =>
  localStorage.setItem(StorageKey.USER_LOGGED_OUT, JSON.stringify(flag));

export function storeWeakToken(token: string) {
  localStorage.setItem(StorageKey.WEAK_AUTH_TOKEN, JSON.stringify(token));
}
