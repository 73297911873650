import React from 'react';
import { Chip, IconButton } from '@mui/material';
import { ReactComponent as Close } from 'assets/close.svg';
import { StyledArrowDown } from 'components/Tag/Tag.styles';

interface TagProps {
  disabled?: boolean;
  closeIcon?: boolean;
  expandable?: boolean;
  label: string;
  variant?: 'default' | 'green' | 'red' | 'blue' | 'yellow' | 'inverse';
  onClose?: () => void;
  size?: 'small' | 'medium';
  [key: string]: any;
}

const NewTag: React.FC<TagProps> = ({
  variant = 'default',
  disabled = false,
  closeIcon = false,
  expandable = false,
  label = '',
  onClose = () => {},
  size = 'medium',
  ...props
}) => {
  return (
    <Chip
      label={label}
      onDelete={closeIcon && !disabled ? onClose : undefined}
      deleteIcon={
        closeIcon && !disabled ? (
          <IconButton
            sx={{
              padding: 0,
            }}
          >
            <Close height={8} width={8} />
          </IconButton>
        ) : undefined
      }
      icon={expandable ? <StyledArrowDown /> : undefined}
      disabled={disabled}
      variant={variant}
      size={size}
      {...props}
    />
  );
};

export default NewTag;
