import { PropsWithChildren, useMemo } from 'react';

import TabNavigation from 'components/TabNavigation';
import { ParamsType } from 'hooks/useUrlParams';
import { TAB_TYPE } from 'components/TabNavigation/TabNavigation.types';

interface GenericTabProps extends PropsWithChildren {
  active: string;
  setUrlParamsWithDefaults: (params: ParamsType) => void;
  tabs: string[][];
  rootTab?: string;
  type?: TAB_TYPE;
}

function GenericTabs({
  active,
  children,
  setUrlParamsWithDefaults,
  tabs,
  rootTab,
  type,
}: GenericTabProps) {
  const mappedTabs = useMemo(
    () =>
      tabs.map(([name, param]) => ({
        name,
        param,
        onSelect: () =>
          rootTab
            ? setUrlParamsWithDefaults({ tab: rootTab, subtab: param })
            : setUrlParamsWithDefaults({ tab: param }),
      })),
    [rootTab, setUrlParamsWithDefaults, tabs]
  );

  return (
    <TabNavigation activeParam={active} tabs={mappedTabs} type={type} active="active">
      {children}
    </TabNavigation>
  );
}

export default GenericTabs;
