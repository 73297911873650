import { ExchangeLocalTickerType } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';

function renderLocalTickersValue(localTickers: ExchangeLocalTickerType[] = []) {
  return localTickers.map((localTicker) => {
    return (
      <div key={`${localTicker.name}-${localTicker.currency}`}>
        {localTicker.name} ({localTicker.currency})
      </div>
    );
  });
}

export default renderLocalTickersValue;
