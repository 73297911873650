import { ParamsType } from 'hooks/useUrlParams';
import { useQuery } from 'react-query';
import { getWalletsForCompanyRequest } from 'utils/api/wallets';
import { getWalletsQuery } from 'utils/constants/reactQueries';

export const useWalletsQuery = (
  companyId: string,
  type: 'CUSTODIAN' | 'OWN',
  params?: ParamsType
) => {
  return useQuery(
    [getWalletsQuery, companyId, params],
    () => getWalletsForCompanyRequest(companyId, type, params ?? {}).then(({ data }) => data),
    {
      enabled: Boolean(companyId),
    }
  );
};
