import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import TokenForm from 'pages/Tokens/components/Form/TokenForm';
import { TokensPageHeader } from 'pages/Tokens/components/TokensPageHeader';
import { useTokenContext } from 'contexts/tokens';
import { useTokenQuery } from 'pages/Tokens/hooks/useTokenQuery';
import Loader from 'components/Loader';
import { privateRoutesUrls } from 'router/constants';
import { getTokenDetailsUrl, getPartnerDetailsUrl } from 'pages/Tokens/components/Form/utils';
import { TitleArea } from 'components/TitleArea/TitleArea';
import {
  tokenTabs,
  TOKEN_DETAILS_TAB,
  PARTNER_DETAILS_TAB,
} from './components/Form/helpers/tokenTabs';

function Token() {
  const navigate = useNavigate();
  const params = useParams();

  const { currentToken, setCurrentToken } = useTokenContext();
  const { isLoading } = useTokenQuery(
    params,
    Boolean(params.id && params.id !== currentToken?._id)
  );
  const isCreateTokenDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.newTokenDetails)
  );
  const isEditTokenDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.editTokenDetails)
  );
  const isTokenDetailsRoute = isCreateTokenDetailsRoute || isEditTokenDetailsRoute;
  const activeTab = isTokenDetailsRoute ? TOKEN_DETAILS_TAB : PARTNER_DETAILS_TAB;

  useEffect(() => {
    return () => {
      setCurrentToken(null);
    };
  }, []);

  const handleChangeTab = (tab: string) => {
    if (tab === TOKEN_DETAILS_TAB) {
      navigate(getTokenDetailsUrl(params.id));
    } else {
      navigate(getPartnerDetailsUrl(params.id));
    }
  };

  return (
    <>
      <TitleArea
        showBackButton={true}
        title={
          <TokensPageHeader
            token={currentToken}
            isNewProduct={!Boolean(params.id && params.id !== currentToken?._id)}
          />
        }
        tabConfig={{
          tabs: tokenTabs,
          setUrlParamsWithDefaults: (data) => {
            handleChangeTab(data.tab as string);
          },
          active: activeTab,
        }}
      />
      {isLoading ? <Loader /> : <TokenForm activeTab={activeTab} />}
    </>
  );
}

export default Token;
