import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import ProductIcon from 'components/ProductIcon';
import PageTitle from 'components/PageTitle';
import { SecuritiesRegister } from 'pages/Orders/Register/types';

interface RegisterDetailsHeaderProps {
  registerProductData?: SecuritiesRegister;
}

export const RegisterDetailsHeader = ({ registerProductData }: RegisterDetailsHeaderProps) => {
  return (
    <>
      <StyledTableCellContentWithIcon>
        {registerProductData && (
          <>
            <ProductIcon
              className="product-icon"
              iconUrl={registerProductData?.fundIcon}
              ticker={registerProductData?.ticker}
              size={'40px'}
            />
            <PageTitle>{registerProductData?.ticker}</PageTitle>
            <PageTitle>{' Register Details'}</PageTitle>
          </>
        )}
      </StyledTableCellContentWithIcon>
    </>
  );
};
