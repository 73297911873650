import { StyledCounterNumber, StyledNavSingleCounterCardWrapper } from './PCFStyles';

interface PCFSingleCounterCardProps {
  number?: number;
  numberColor: string;
  label: string;
}

export const PCFSingleCounterCard = ({ number, numberColor, label }: PCFSingleCounterCardProps) => {
  return (
    <StyledNavSingleCounterCardWrapper>
      <StyledCounterNumber color={numberColor}>{number ?? 'NA'}</StyledCounterNumber>
      <div
        style={{
          color: '#1A1A1A',
          fontSize: 14,
          fontFamily: 'Inter',
          fontWeight: '400',
          letterSpacing: 0.16,
          wordWrap: 'break-word',
        }}
      >
        {label}
      </div>
    </StyledNavSingleCounterCardWrapper>
  );
};
