import useAppSelector from 'hooks/useAppSelector';

// hooks
export function useAuthenticatedUser() {
  return useAppSelector((state) => ({
    error: state.user.error,
    loading: state.user.loading,
    user: state.user.user,
  }));
}

export const useUserPermissions = () => {
  return useAppSelector((state) => state.user.user?.permissions);
};
