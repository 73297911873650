// TODO: review all api calls and remove those that could be used from _actions
export const apiUrls = {
  asset: '/asset',
  admin: '/admin',
  auth: '/auth',
  auth2Fa: '/auth/2fa',
  authLogin: '/auth/login',
  authNotifications: '/auth/notifications',
  calendars: '/calendars',
  companies: '/companies',
  dashboard: '/dashboard',
  exchanges: 'admin/exchanges',
  invitation: '/invitation',
  login: '/login',
  me: '/me',
  order: '/orders',
  orderToken: '/orders/type=token',
  orderDelegated: '/orders/delegated',
  orderTokenDelegated: '/orders/type=token/delegated',
  seedOrder: '/orders/seed',
  product: '/products',
  productsTradingToday: '/products/calendars',
  tokenProduct: 'products/type=token',
  password: '/password',
  passwordReset: '/password-reset',
  reset: '/reset',
  resetRequest: '/reset-request',
  schema: '/schema',
  twoFA: '/2fa',
  users: '/users',
  verify: '/verify',
  createContact: '/admin/contacts',
  createExternalContact: '/admin/contacts/type=external',
};
