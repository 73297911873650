import axios, { AxiosError } from 'axios';
import { ConstraintError, ResponseError } from './types';
import { UNAUTHORIZED_STATUS_CODE } from 'utils/constants/numbers';
import { UNAUTHORIZED_ERROR_MESSAGE } from 'utils/constants/errors';
import { apiUrls } from 'utils/constants/apiUrls';

function constraintErrorToString(constraintError: ConstraintError): string {
  if (Array.isArray(constraintError.constraints)) {
    return constraintError.constraints.map(constraintErrorToString).join(',');
  }

  return Object.values(constraintError.constraints).join(',');
}

export const errorHandler = (err: unknown, message?: string) => {
  const error = err as Error | AxiosError;
  const isUnauthorized = (error as AxiosError)?.response?.status === UNAUTHORIZED_STATUS_CODE;
  const isLoginUrl = (error as AxiosError)?.config.url === apiUrls.authLogin;
  const cause = (error as AxiosError)?.response?.data ?? null;
  let constraintErrors = '';

  if (isUnauthorized && !isLoginUrl) {
    message = UNAUTHORIZED_ERROR_MESSAGE;
  } else if (axios.isAxiosError(error)) {
    const data = error.response?.data as ResponseError;

    if (Array.isArray(data?.message)) {
      message = data?.message.join(', ');
    } else if (data?.message) {
      message = data?.message;
    }

    if (data?.errors) {
      constraintErrors = ':<br />' + data.errors.map(constraintErrorToString).join('<br />');
    }
  }
  throw new Error(`${message}${constraintErrors}`, {
    cause: cause,
  });
};
