import { fetchAllPartnerContacts } from 'utils/api/contacts';
import { getAllPartnerContacts } from 'utils/constants/reactQueries';
import { useQuery } from 'react-query';
import { ParamsType } from 'hooks/useUrlParams';

export const useAllPartnerContactsQuery = (params?: ParamsType, enabled?: Boolean) => {
  return useQuery(
    [getAllPartnerContacts, params],
    () =>
      fetchAllPartnerContacts({ ...params, name: params?.search ?? null, search: null }).then(
        ({ data }) => {
          return {
            partners: data.data,
            pagination: {
              page: data.page,
              pageSize: data.pageSize,
              total: data.total,
            },
            _actions: data._actions,
          };
        }
      ),
    {
      enabled: Boolean(enabled),
    }
  );
};
