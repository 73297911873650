import CircularProgress from 'components/CircularProgress';
import { StyledNoContent, StyledRebalancePageBody } from 'pages/Rebalance/RebalancePage.styles';
import { ReactComponent as NoContent } from 'assets/no-content.svg';

type RebalancePageBodyProps = {
  isLoading: boolean;
  children?: React.ReactNode;
  noContent?: boolean;
  noContentLabel?: string;
};

export const RebalancePageBody = ({
  isLoading,
  children,
  noContent,
  noContentLabel,
}: RebalancePageBodyProps) => {
  if (isLoading)
    return (
      <StyledNoContent>
        <CircularProgress />
      </StyledNoContent>
    );

  if (noContent)
    return (
      <StyledNoContent>
        <NoContent />
        <div>{noContentLabel ?? 'No content to display'}</div>
      </StyledNoContent>
    );

  return <StyledRebalancePageBody>{children}</StyledRebalancePageBody>;
};
