import CircularProgress from 'components/CircularProgress';
import MuiButton from '@mui/material/Button';
import React, { ComponentPropsWithoutRef } from 'react';
import { SizeType } from 'utils/types';
import { StyledMuiButton } from 'components/Button/Button.styles';
import { variantType } from 'components/Button/types';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  fullWidth?: boolean;
  isLoading?: boolean;
  square?: 'true' | 'false';
  size?: SizeType;
  variant?: variantType;
  justifyContent?: string;
}

const variantToColorMap: Record<variantType, Partial<React.ComponentProps<typeof MuiButton>>> = {
  primary: {
    color: 'primary',
    variant: 'contained',
  },
  secondary: {
    color: 'secondary',
    variant: 'contained',
  },
  tertiary: {
    color: 'primary',
    variant: 'outlined',
  },
  danger: {
    color: 'error',
    variant: 'contained',
  },
  dangerGhost: {
    color: 'error',
    variant: 'outlined',
  },
  ghost: {
    color: 'inherit',
    variant: 'text',
  },
  icon: {
    color: 'primary',
    variant: 'text',
  },
  support: {
    color: 'support',
    variant: 'outlined',
  },
  inverse: {
    color: 'inverse',
    variant: 'outlined',
  },
  interactive: {
    color: 'interactive',
    variant: 'contained',
  },
};

function Button({
  children,
  disabled,
  isLoading,
  variant,
  color,
  type = 'button',
  square = 'false',
  ...props
}: ButtonProps) {
  return (
    <StyledMuiButton
      data-qa-id="clickBtn"
      disabled={disabled || isLoading}
      square={square}
      disableElevation
      {...props}
      {...variantToColorMap[variant || 'interactive']}
      type={type}
    >
      {isLoading ? <CircularProgress size={24} /> : children}
    </StyledMuiButton>
  );
}

export default Button;
