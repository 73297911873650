import { StyledContentWrapper } from 'pages/Login/Login.styles';
import { ReactComponent as Logo } from 'assets/logo.svg';
import {
  StyledBullet,
  StyledBulletPointContent,
  StyledTermOfUseContainer,
  StyledTermsOfUseBody,
  StyledTermsOfUseCanvas,
  StyledTermsOfUseHeader,
  StyledTermsOfUseHeaderText,
  StyledTermsOfUseHeaderWrapper,
  StyledUpdatedHeaderNote,
} from './TermsOfUse.styles';

export const TermsOfUse = () => {
  return (
    <StyledTermOfUseContainer>
      <StyledContentWrapper>
        <StyledTermsOfUseHeaderWrapper>
          <StyledTermsOfUseHeader>
            <div>
              <Logo width={101} height={42} />
            </div>

            <StyledTermsOfUseHeaderText>Terms of Use</StyledTermsOfUseHeaderText>
          </StyledTermsOfUseHeader>
        </StyledTermsOfUseHeaderWrapper>

        <StyledTermsOfUseBody>
          <StyledTermsOfUseCanvas>
            <StyledUpdatedHeaderNote>
              Last Updated: [06/05/2024], 2024
              <br />
            </StyledUpdatedHeaderNote>
            <StyledBulletPointContent>
              <br />
              21.co (“21.co” “we,” “us,” or “ours”) is pleased you have chosen the Onyx modular
              platform (the “Platform”). These Onyx Terms of Use (“TOU”) describes the terms and
              conditions by which we offer customers, authorized users, and any individual or entity
              accessing or using the Platform or the Services (“you” or “your”) access and use of
              the Platform and its services, products, features, and functionalities (collectively,
              the “Services”). <br />
              <br />
              Any access to or use of the Services shall be subject to the terms and conditions of
              this TOU. Please read these terms carefully before you start to use the services. This
              TOU is a legal contract between you, the user, and 21.co and by using the Services,
              you accept and agree to be bound and abide by this TOU. We reserve the right to modify
              the terms of this TOU, in whole or in part, at any time and in our sole discretion. By
              continuing to use the Services, you agree to be bound by the modified terms of this
              TOU. <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              1. Limited License <br />
            </StyledBullet>
            <StyledBulletPointContent>
              21.co owns and reserve all rights, title, and interest in and to the Services,
              including all: (i) information, text, data, files, code, methods of operation, the
              compilation, assembly, and arrangement of the materials of the Services, and all other
              copyrightable material; (ii) other forms of intellectual property (all of the
              foregoing, collectively “Content”). Subject to the terms of this TOU, we grant you a
              limited, non-exclusive, non-transferable, non-sublicensable, revocable license to
              access and use the Services. The Services, including the Content, but excluding any
              tangible medium the Services may be supplied on, are licensed, not sold. This license
              is personal and do not give you any ownership rights in any of the Services or in or
              to any of their features or Content. <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              2. Updates and Modifications
              <br />
            </StyledBullet>
            <StyledBulletPointContent>
              We may provide patches, updates, modifications, or upgrades to the Services, that may
              be required to continue using the Services (including automatic or “in the background”
              updates) without notice. Such updates are subject to this TOU unless other additional
              terms are presented with the updates, in which case, those other terms shall apply. We
              may from time to time modify, alter, or suspend, our Services for such valid reasons
              as, and without limitation: improvement of our Services, (such as offering new
              Services or Content); changes necessary for maintenance or balancing, bug fixing or to
              prevent or counter exploits; changes that are necessary due to a new technical
              environment; suspected or actual intellectual property infringement, third-party
              compliance requirements; a service or feature which is part or interconnected with our
              Services; necessary enhancements for the safety of users; or other material, legal,
              regulatory, or security reasons.
              <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              3. Availability and Account <br />
            </StyledBullet>
            <StyledBulletPointContent>
              To access the Services, you may be asked to provide registration details or other
              information; information that you provide should be correct, current, and complete. If
              you choose, or are otherwise provided with a password, or any other piece of
              information as part of our security procedures, you must treat such information as
              confidential. You must immediately notify us of any unauthorized use of your
              account/password or any other breach of security. <br />
              <br />
              The Services may be offered only for a limited time, or use of certain features,
              services, or content may be unavailable to you depending on your geographic region. If
              you change geographic regions, your access to the Services may be affected if
              prohibited by the applicable laws of the new geographic region in which you are
              present.
              <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              4. Security; No Interference with Services or System
              <br />
            </StyledBullet>
            <StyledBulletPointContent>
              You shall not, and you shall not permit or encourage any third party to: (i) reverse
              engineer, disassemble, decompile, decode, decrypt, hack, any of the Services, or
              otherwise attempt to derive or gain access to the source code of the Services or any
              part thereof; (ii) attempt to bypass or break any security mechanism on any of the
              Services or any of 21.co’s, its affiliates’, its providers’, or other users’ data,
              networks, systems, applications, or computing devices accessed or used in connection
              with the Services (collectively, the “System”); (iii) access or use the Services or
              the System without authorization, including, but not limited to, an attempt to probe
              or scan any of the Services or the System, or to breach security or authentication
              measures of the Services or the System; (iv) disrupt the proper function of the
              Services or the System, including, but not limited to, overloading the Services or the
              System; (v) exceed or circumvent any technical or other limitations and restrictions
              of the Services or the System provided by 21.co, including, without limitation, any
              quota or usage restrictions, (vi) use manual or electronic means to avoid any use
              limitations placed on the Services or the System; (vii) use the Services or the System
              in a manner that poses a security or service risk to 21.co, its affiliates, its
              providers, or other users, or (viii) any other activity that could be reasonably
              interpreted as unauthorized access to, use of, or interference with the Services or
              the System.
              <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              5. Prohibited Activities
              <br />
            </StyledBullet>
            <StyledBulletPointContent>
              In connection with the System and the Services, you shall not, or permit or encourage
              any third party to: (i) violate, or assist in violation of, any law, statute,
              ordinance, rule, regulation, or sanctions programs administered in the countries where
              21.co conducts business, including but not limited to, the U.S. Department of
              Treasury’s Office of Foreign Assets Control; (ii) commit a crime, or engage in any
              other unlawful activity or publishing, distributing, or disseminating any unlawful
              material or information; (iii) violate, infringe, or misappropriate any copyright,
              trademark, parent, right of publicity or privacy or any other intellectual property or
              proprietary rights, or remove, obscure, or modify any copyright, trademark, or other
              proprietary rights notices, marks, labels, advertisements or other features contained
              on or in the Services or the System; (iv) interfere with another individual’s or
              entity’s access to or use of any of the Services or the System; (v) defame, abuse,
              extort, harass, stalk, threaten, or otherwise violate or infringe the legal rights
              (including, but not limited to, rights of privacy, publicity, and intellectual
              property) of others; (vi) harvest or otherwise collect information or data from others
              or the Services and the System; (vii) engage in activity which operates to defraud
              21.co, its users, or any other person or entity, including, but not limited to,
              providing or making any false, inaccurate, or misleading information or
              representation, creating a false identity, or otherwise impersonating another person
              or entity; (viii) hide or mask the identity of systems or devices accessing the
              Services or the System; (ix) rent, lease, lend, sell, repackage, sublicense, assign,
              distribute, publish, transfer, or otherwise make available the Services or the System
              to any unauthorized third party for any reason; (x) access the Services or the System
              in order to build a competitive product or service, or to build a product or services
              using similar ideas, features, functions, or graphics of the Services or the System;
              (xi) copy the Services or the System, or copy any ideas, features, functions, or
              graphics of the Services or the System; or (xii) prepare any derivative works based on
              the Services or the System. <br />
              <br />
              Without limiting the foregoing, nothing in the limited license granted in this TOU
              authorizes the use of Services in any manner to develop, train, enhance, or provide
              source material for any program or tool that uses algorithms or technology commonly
              known as artificial intelligence or machine learning to create or generate content
              such as, but not limited to, software code, written text, still or moving images, or
              other works based on inputs (“Generative AI Tools”). Violations of this Section 4 or,
              more broadly, any material provision of the TOU, may result in adverse action against
              you, including, without limitation: suspending your access to some or all of the
              Services, barring you from accessing the Services in the future, or taking appropriate
              legal action to enforce this TOU or our other rights under applicable law. <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              6. No Automated Access or Harmful Code
              <br />
            </StyledBullet>
            <StyledBulletPointContent>
              You shall not or permit or encourage any third party to: (i) use any robot, spider,
              scraper, or other automated means to access the System or the Services for any purpose
              without 21.co’ express written permission; or (ii) introduce, post, or upload to the
              System or the Services any Harmful Code. As used in this TOU, “Harmful Code” means
              computer code, programs, or programming devices that disrupt, modify, access, delete,
              damage, deactivate, disable, harm, or otherwise impede in any manner, including
              without limitation, aesthetic disruptions or distortions, the operation of the
              Services or the System, or any other associated software, firmware, hardware, computer
              system, or network, or any other harmful, malicious, or hidden procedures, routines or
              mechanisms that would cause the Services or the System to cease functioning or to
              damage or corrupt data, storage media, programs, equipment, or communications, or
              otherwise interfere with the operations of the Service or the System. Harmful Code
              includes, by way of example, malware known as trojan horses, viruses, worms, time
              bombs, time locks, devices, traps, access codes, and drop dead or trap door devices.{' '}
              <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              7. Violations of This TOU <br />
            </StyledBullet>
            <StyledBulletPointContent>
              An alleged or actual violation of this TOU may lead to suspension or termination to
              your access to and use of the Services (without notice or liability) or legal action
              if 21.co (in its sole discretion) determines that you has violated this TOU. 21.co is
              not liable for suspension or termination of your access to or use arising from an
              alleged or actual violation of this TOU. In addition, 21.co reserves the right to
              charge you for the costs of investigation and any remedial action related to any
              alleged or actual violation of this TOU. 21.co reserves the right to any other
              remedial action it sees fit. The failure of 21.co to enforce this TOU for whatever
              reason does not constitute a waiver of any right to do so at a later time. <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              8. Limitation of Liability <br />
            </StyledBullet>
            <StyledBulletPointContent>
              IN NO EVENT SHALL 21.CO BE LIABLE TO YOU FOR (I) ANY INDIRECT, SPECIAL, INCIDENTAL,
              EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE LOSSES OR DAMAGES, (II) ANY BREACH OF ITS
              OBLIGATIONS DUE TO A FORCE MAJEURE EVENT, ARISING OUT OF OR IN CONNECTION WITH THE
              SERVICES OR THIS TOU, (III) ANY DAMAGES FOR SYSTEM FAILURE OR MALFUNCTION, (IV) ANY
              LOSS OF PROFITS, DATA, USE, BUSINESS OR GOOD-WILL ARISING OUT OF OR IN CONNECTION WITH
              THE SERVICES OR THIS TOU, WHETHER ARISING IN CONTRACT, TORT, STRICT LIABILITY,
              STATUTE, OR ANY OTHER EQUITABLE THEORY. NOTHING IN THIS TOU IS INTENDED TO EXCLUDE ANY
              NON-EXCLUDABLE RIGHTS OR DAMAGES THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW,
              INCLUDING WITHOUT LIMITATION ANY LIABILITY FOR FRAUD, OR FOR DEATH OR ANY PERSONAL
              INJURY ARISING AS A RESULT OF NEGLIGENCE.
              <br />
              <br />
              Further, to the full extent permissible by law, 21.co’s aggregate liability arising
              out of or in connection with these Terms will not exceed the total amounts you have
              paid (if any) 21.co under this TOU during the twelve (12) months immediately preceding
              the events giving rise to such liability. These limitations and exclusions regarding
              damages apply even if any remedy fails to provide adequate compensation.
              <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              9. Warranty Disclaimer
              <br />
            </StyledBullet>
            <StyledBulletPointContent>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED TO YOU
              "AS IS," "AS AVAILABLE," AND "WITH ALL FAULTS." NEITHER 21.CO, ITS AFFILIATES, NOR ANY
              OF OUR OR THEIR RESPECTIVE OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, AGENTS, OR
              LICENSORS MAKE ANY REPRESENTATIONS, WARRANTIES, PROMISES, OR GUARANTEES OF ANY KIND
              WHATSOEVER AS TO THE SOFTWARE, CONTENT, OR OTHER SERVICES, WHETHER EXPRESS OR IMPLIED,
              STATUTORY OR OTHERWISE. SERVICES MAY NOT BE ACCURATE OR RELIABLE, UNINTERRUPTED,
              TIMELY, SECURE, ERROR-FREE, OR FREE OF VIRUSES. TO THE FULLEST EXTENT PERMITTED BY
              YOUR LOCAL LAW, 21.CO DISCLAIMS ANY IMPLIED WARRANTIES INCLUDING FOR NON-INFRINGEMENT,
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND SATISFACTORY QUALITY.
              <br />
              <br />
              Notwithstanding anything contained herein, this TOU is meant to prejudice the
              statutory rights that you may have as a consumer of the Services. Some countries,
              states, provinces or other jurisdictions do not allow the exclusion of certain
              warranties or the limitation of liability as stated in this section, so the above may
              not fully apply to you. Instead, in such jurisdictions, the exclusions and limitations
              shall apply only to the extent permitted by the laws of such jurisdictions.
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              10. Governing Law and Jurisdiction
              <br />
            </StyledBullet>
            <StyledBulletPointContent>
              This TOU is entered into in the State of New York and shall be governed by, and
              construed under, the laws of the State of New York without regard to conflict of law
              rules. <br />
              <br />
            </StyledBulletPointContent>
            <StyledBullet>
              11. Miscellaneous <br />
            </StyledBullet>
            <StyledBulletPointContent>
              This TOU, together with any documents or policies as may be linked to herein,
              comprises the entire agreement between you and us for your use of the Services. It
              supersedes any prior agreements between you and us regarding your use of the Services.
              You agree that we may assign this TOU, in whole or in part, at any time. If our
              assignment leads to a change of us as the contracting party, you have the right to
              terminate this TOU. You may not assign your rights or transfer your obligations under
              this TOU or transfer any rights to use the Services. If your habitual residence is in
              Germany, the preceding sentence does not apply to any monetary claims you may have
              against us which arise from this TOU. If any provision of this TOU is held to be
              unenforceable for any reason, such provision shall either be reformed only to the
              extent necessary to make it enforceable or removed from the TOU entirely and the
              remaining provisions of this TOU shall remain effective. Sections that by their nature
              apply after this TOU ends will survive any termination or cancellation of this TOU.
              Either party may reasonably disclose information related to this TOU or use of the
              Services as necessary to satisfy any law, regulation, legal process, or governmental
              request.
            </StyledBulletPointContent>
          </StyledTermsOfUseCanvas>
        </StyledTermsOfUseBody>
      </StyledContentWrapper>
    </StyledTermOfUseContainer>
  );
};
