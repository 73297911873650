import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { fetchCurrency } from 'store/currency/actions';
import { Fiat } from 'utils/types';

type CurrencyStateType = {
  error: string | SerializedError | null;
  loading: boolean;
  data: Fiat[] | null;
};

const initialState: CurrencyStateType = {
  error: null,
  loading: false,
  data: null,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchCurrency.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrency.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : action.error;
      });
  },
  reducers: {},
});

export const currencyReducer = currencySlice.reducer;

export default currencySlice;
