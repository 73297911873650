import { useCallback, useEffect, useState } from 'react';
import {
  TokenDetailsStepType,
  GENERAL_DETAILS_STEP,
  SUMMARY_STEP,
  CONSTITUENT_STEP,
} from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import ConstituentStep from './components/ConstituentStep/ConstituentStep';
import GeneralDetailsStep from 'pages/Tokens/components/Form/TokenDetails/components/GeneralDetailsStep/GeneralDetailsStep';
import Loader from 'components/Loader';
import ProgressIndicator from 'components/ProgressIndicator';
import SummaryStep from 'pages/Tokens/components/Form/TokenDetails/components/SummaryStep/SummaryStep';
import { Column, Row } from 'components/Grid';
import { getTokenDetailsStep, getTokenDetailsSteps } from 'pages/Tokens/components/Form/utils';
import { useTokenContext } from 'contexts/tokens';
import useAppModal from 'hooks/useAppModal';

function renderCurrentStep(
  activeStep: TokenDetailsStepType,
  goBack: () => void,
  goToStep: (step: TokenDetailsStepType) => void,
  onSubmit: () => void
) {
  const steps = {
    [CONSTITUENT_STEP]: ConstituentStep,
    [GENERAL_DETAILS_STEP]: GeneralDetailsStep,
    [SUMMARY_STEP]: SummaryStep,
  };

  const Step = steps[activeStep];

  return <Step onSubmit={onSubmit} goBack={goBack} goToStep={goToStep} />;
}

function CreateToken() {
  const openModal = useAppModal();
  const {
    completedSteps,
    isLoadingToken,
    openActiveTokenUpdatedModal,
    setCompletedSteps,
    setTokenActiveStep,
    stepsStatuses,
    tokenFormIsDirty,
  } = useTokenContext();
  const [activeStep, setActiveStep] = useState<TokenDetailsStepType>('General Details');

  useEffect(() => {
    setTokenActiveStep(activeStep);
  }, [activeStep, setTokenActiveStep]);

  const navigateToStep = useCallback(
    (step: TokenDetailsStepType) => {
      setCompletedSteps([...completedSteps, activeStep]);
      setActiveStep(step);
    },
    [activeStep, completedSteps, tokenFormIsDirty, setCompletedSteps, setActiveStep]
  );

  const handleNavigateToStep = useCallback(
    (step: TokenDetailsStepType) => {
      if (tokenFormIsDirty) {
        openActiveTokenUpdatedModal(openModal, step as TokenDetailsStepType, (step) =>
          navigateToStep(step as TokenDetailsStepType)
        );
      } else {
        navigateToStep(step);
      }
    },
    [activeStep, completedSteps, tokenFormIsDirty]
  );

  const handleStepSubmit = () => {
    const targetStep = getTokenDetailsStep(activeStep).nextStep ?? SUMMARY_STEP;
    handleNavigateToStep(targetStep as TokenDetailsStepType);
  };

  const goBack = () => {
    const targetStep = getTokenDetailsStep(activeStep).previousStep ?? GENERAL_DETAILS_STEP;
    handleNavigateToStep(targetStep as TokenDetailsStepType);
  };

  const goToStep = (step: TokenDetailsStepType) => {
    handleNavigateToStep(step);
  };

  return (
    <>
      <Row>
        <Column>
          <ProgressIndicator
            onClickStep={goToStep}
            steps={getTokenDetailsSteps(activeStep, stepsStatuses)}
          />
        </Column>
      </Row>
      {isLoadingToken ? (
        <Loader />
      ) : (
        renderCurrentStep(activeStep as TokenDetailsStepType, goBack, goToStep, handleStepSubmit)
      )}
    </>
  );
}

export default CreateToken;
