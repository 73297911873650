import { NotificationConfigType } from 'utils/types';

export const contactDeactivatedNotifications: NotificationConfigType = {
  success: (contact) => ({
    message: `Contact ${contact} has been deactivated successfully`,
    title: 'Contact deactivated',
    type: 'info',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};
