import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import useAppDispatch from 'hooks/useAppDispatch';
import { Calendar, CalendarEvent } from 'utils/types/calendar';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { apiUrls } from 'utils/constants/apiUrls';
import { createAdminCaledarEventSchemaUrl } from 'components/Form/formSchemas';
import { createNotification } from 'store/notifications/actions';
import {
  calendarEventCreatedNotification,
  calendarEventUpdatedNotification,
} from 'shared/Notifications/calendars.notifications';
import { putEntityApiCall } from 'utils/api/crudActions';
import { useMutation } from 'react-query';

export const CalendarEventCreateEditModal = ({
  closeModal,
  data,
  onCloseModalAction,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const calendarEvent = data.data as CalendarEvent;
  const calendar = data.calendarData as Calendar;
  const { companyData } = data;
  const { isAdmin } = data;
  const isNewCalendarEvent = data.type === MODAL_ACTIONS.CREATE;
  const initialValues = isNewCalendarEvent ? {} : calendarEvent;

  const uriPath = isAdmin
    ? `${apiUrls.admin}${apiUrls.calendars}/id=${calendar._id}`
    : `${apiUrls.companies}/id=${companyData?.id ?? ''}${apiUrls.calendars}/id=${calendar._id}`;

  const calendarEventCreateMutation = useMutation({
    mutationFn: (formValues: CalendarEvent) => {
      const requestPayload = { ...calendar };
      requestPayload.events = [...requestPayload.events, formValues];
      return putEntityApiCall(requestPayload, uriPath, 'Error while updating Calendar!');
    },
    onSuccess: (_res, formValues) => {
      dispatch(
        createNotification(calendarEventCreatedNotification.success(`${formValues.description}`))
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarEventCreatedNotification.error(err.message), err));
    },
  });

  const calendarEventEditMutation = useMutation({
    mutationFn: (formValues: CalendarEvent) => {
      const requestPayload = { ...calendar };

      requestPayload.events = requestPayload.events.map((event) => {
        if (event._id === calendarEvent._id) {
          event = formValues;
        }
        return event;
      });
      return putEntityApiCall(requestPayload, uriPath, 'Error while updating Calendar!');
    },
    onSuccess: (_res, formValues) => {
      dispatch(
        createNotification(calendarEventUpdatedNotification.success(`${formValues.description}`))
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarEventUpdatedNotification.error(err.message), err));
    },
  });

  const handleUpdate = (formValues: CalendarEvent) => {
    if (isNewCalendarEvent) {
      calendarEventCreateMutation.mutate(formValues);
    } else {
      calendarEventEditMutation.mutate(formValues);
    }
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button variant="secondary" fullWidth onClick={closeModal} type="button">
        Cancel
      </Button>
      <Button
        fullWidth
        type="submit"
        isLoading={calendarEventCreateMutation.isLoading || calendarEventEditMutation.isLoading}
      >
        {isNewCalendarEvent ? 'Add Holiday' : 'Save changes'}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Form
        schemaUrl={createAdminCaledarEventSchemaUrl}
        onSubmit={handleUpdate}
        initialValues={initialValues}
      >
        <Card
          title={isNewCalendarEvent ? 'New Holiday' : 'Edit Holiday'}
          label={'Holidays'}
          footer={Footer}
          onClose={closeModal}
        >
          <Input name="description" />
          <Input name="date" />
        </Card>
      </Form>
    </CustomModal>
  );
};
