import OrderDetails from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails';
import OrderForm from './OrderForm';
import { CreateOrderContainer } from 'pages/Orders/Orders/Order/Order.styles';
import { Deliverable, Order, OrderFlow, ProductType } from 'utils/types';
import { EditOrderProps } from 'pages/Orders/Orders/types';
import {
  getOrderDetailsQueryKey,
  getTokenOrderDetailsQueryKey,
} from 'utils/constants/reactQueries';
import OrderTokenForm from 'pages/Orders/Tokens/components/OrderTokenForm';

interface SingleOrderViewProps extends Omit<EditOrderProps, 'order'> {
  productType: ProductType;
  orderFlow: OrderFlow;
  order?: Order;
  seedDeliverables?: Deliverable[];
}

export const SingleOrderView = ({
  productType,
  changeOrder,
  isSubmitting,
  loading,
  onChangeSeedDeliverable,
  onSubmit,
  order,
  orderFlow,
  pcf,
  seedDeliverables,
  user,
}: SingleOrderViewProps) => {
  return (
    <CreateOrderContainer>
      <OrderDetails
        productType={productType}
        onChangeSeedDeliverable={onChangeSeedDeliverable}
        loading={loading}
        user={user}
        orderFlow={orderFlow}
        order={order}
        refreshOrderQueryKey={
          productType === 'ETP' ? getOrderDetailsQueryKey : getTokenOrderDetailsQueryKey
        }
        seedDeliverables={seedDeliverables}
      />
      {productType === 'ETP' && (
        <OrderForm
          handleOnSubmit={onSubmit}
          isSubmitting={isSubmitting}
          loading={loading}
          pcf={pcf}
          user={user}
          changeOrder={changeOrder}
          orderFlow={orderFlow}
          orderDetails={order}
          seedDeliverables={seedDeliverables}
        />
      )}
      {productType === 'Token' && (
        <OrderTokenForm
          handleOnSubmit={onSubmit}
          isSubmitting={isSubmitting}
          loading={loading}
          user={user}
          changeOrder={changeOrder}
          orderFlow={orderFlow}
          orderDetails={order}
        />
      )}
    </CreateOrderContainer>
  );
};
