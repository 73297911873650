import { useTokenContext } from 'contexts/tokens';
import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { tokenWalletsQuery } from 'utils/constants/reactQueries';
import { WalletStatus } from 'utils/types/wallets';
import { getTokenWallets } from 'utils/api/tokens';

export const useTokenWalletsQuery = (tokenId: string, walletsStatus?: string) => {
  const dispatch = useAppDispatch();
  const { setTokenWallets } = useTokenContext();
  return useQuery(
    [tokenWalletsQuery, tokenId],
    async () => {
      try {
        const res = await getTokenWallets(tokenId, walletsStatus);
        const activeCustodianWallets = res?.custodianWallets?.filter(
          (wallet) => wallet.status === WalletStatus.ACTIVE
        );
        const activeUnifiedWallets = res?.unifiedWallets?.filter(
          (wallet) => wallet.status === WalletStatus.ACTIVE
        );
        const activeWallets = {
          custodianWallets: activeCustodianWallets,
          unifiedWallets: activeUnifiedWallets,
        };
        setTokenWallets(activeWallets);
        return res;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(tokenId),
    }
  );
};
