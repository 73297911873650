export const ExchangesTableColumnsConfig = [
  { label: 'Name', propName: 'name', propType: 'string', width: '45%' },
  {
    label: 'Countries',
    propName: 'countries',
    propType: 'string',
    sort: false,
    width: '31%',
  },
  { label: 'Last Updated', propName: 'updatedAt', propType: 'date', width: '16%' },
];
