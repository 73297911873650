import { Box, styled as MuiStyled } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';

export const StyledFiltersContainer = MuiStyled(Box)`
  background-color: ${({ theme }) => theme.palette.tertiary.lighter};
  width: min-content;
  filter: drop-shadow(0px 2px 6px rgba(51, 51, 51, 0.25));
  min-width: 256px;
`;

export const StyledFiltersLabel = MuiStyled(Box)`
  ${() => fontTypes['heading-01']}
  color: ${({ theme }) => theme.palette.secondary.light};
  padding: ${({ theme }) => theme.spacers['spacing-16']};
`;

export const StyledButtonsWrapper = MuiStyled(Box)`
  padding: ${({ theme }) => theme.spacers['spacing-16']};
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-08']};
  & > * {
    flex: 1;
  }
`;

export const StyledInputWrapper = MuiStyled(Box)`
  padding: ${({ theme }) => theme.spacers['spacing-16']};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacers['spacing-32']};
`;
