import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';

export const sideNavPalette = (themeTokens: ThemeTokens, mode: 'light' | 'dark') => {
  if (mode === 'light')
    return {
      color: themeTokens.static.text.default.strong,
      svg: { color: themeTokens.static.icon.default.strong },
      selectedBackgroundColor: themeTokens.interactive.overlay.secondarySelected.enabled,
      hoverBackgroundColor: themeTokens.interactive.overlay.secondarySelected.hover,
    };

  return {
    color: themeTokens.static.text.default.strong,
    svg: { color: themeTokens.static.icon.default.strong },
    selectedBackgroundColor: themeTokens.interactive.overlay.inverseSelected.enabled,
    hoverBackgroundColor: themeTokens.interactive.overlay.inverseSelected.hover,
  };
};
