import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import PageTitle from 'components/PageTitle';
import { Partner } from 'utils/types';

interface PartnerDetailsHeaderProps {
  company: Partner | undefined;
}

export const PartnerDetailsHeader = ({ company }: PartnerDetailsHeaderProps) => {
  return (
    <>
      <StyledTableCellContentWithIcon>
        {company ? (
          <PageTitle>{company?.name} Partner Details</PageTitle>
        ) : (
          <PageTitle>Create Partner</PageTitle>
        )}
      </StyledTableCellContentWithIcon>
    </>
  );
};
