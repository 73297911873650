import PartnersTable from 'shared/Tables/PartnersTable/PartnersTable';
import useAppModal from 'hooks/useAppModal';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { PARTNERS_TABS, PARTNERS_TAB_STATUS, PARTNER_STATUS_LABEL } from 'utils/constants/partners';
import { PARTNER_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { Partner } from 'utils/types';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { compileUrl } from 'utils/url';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useCompanies } from 'pages/Partners/hooks/useCompanies';
import { useNavigate } from 'react-router-dom';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { NewCard } from 'components/NewCard/NewCard';
import Pagination from 'components/Pagination';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { capitalize } from '@mui/material';

export const Partners = () => {
  const navigate = useNavigate();
  const permissions = useUserPermissions();

  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    { page: DEFAULT_PAGE, pageSize: DEFAULT_ITEMS_PER_PAGE, tab: PARTNERS_TAB_STATUS.ACTIVE },
    privateRoutesUrls.dashboardRoutes.partners
  );
  const { data, isLoading: loading, refetch } = useCompanies(urlParams);
  const { data: partners, ...paginationResponse } = data ?? {};
  const pagination = useBackendPagination(paginationResponse, setUrlParams);
  const openModal = useAppModal();

  const partnerEditAction = (partner: Partner) => {
    const editPartnerUrl = compileUrl(privateRoutesUrls.dashboardRoutes.partnersEdit, {
      label: 'id',
      value: String(partner._id!),
    });

    navigate(editPartnerUrl);
  };

  const openPartnerModal = (type: MODAL_ACTIONS, partner?: Partner) => {
    openModal(
      {
        modalName: PARTNER_MODAL,
        modalData: {
          data: partner,
          type: type,
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const createPartnerButton = permissions?.canCreateCompany && (
    <StyledTableTopButton
      data-qa-id="createPartnerButton"
      variant="interactive"
      size="medium"
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.partnersNew)}
    >
      <span>Create Partner</span>
      <PlusIcon />
    </StyledTableTopButton>
  );
  const tabs = PARTNERS_TABS.map((tab) => ({ name: tab[0], param: tab[1] }));

  return (
    <>
      <TitleArea
        title={'Partners'}
        actionButton={createPartnerButton}
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (data) => {
            setUrlParamsWithDefaults(data);
          },
          active: urlParams.tab as PARTNER_STATUS_LABEL,
        }}
      />
      <NewCard
        header={<TableTitleWithActions title={`${capitalize(String(urlParams.tab))} Partners`} />}
        body={
          <PartnersTable
            urlParams={urlParams}
            setUrlParams={setUrlParams}
            tableProperties={{
              noPadding: true,
            }}
            partners={partners ?? []}
            loadingData={loading}
            editAction={partnerEditAction}
            deleteAction={(partner) => openPartnerModal(MODAL_ACTIONS.DELETE, partner)}
          />
        }
        footer={<Pagination {...pagination} />}
      />
    </>
  );
};
