import { useCallback, useEffect, useState } from 'react';
import {
  CUSTODIAN_INFO_STEP,
  PARTNERS_STEP,
  TokenPartnerDetailsStepType,
  REVIEW_PARTNER_DETAILS_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import Loader from 'components/Loader';
import ProgressIndicator from 'components/ProgressIndicator';
import Review from 'pages/Tokens/components/Form/PartnerDetails/components/ReviewStep/ReviewStep';
import WalletsInfoStep from 'pages/Tokens/components/Form/PartnerDetails/components/WalletsInfoStep/WalletsInfoStep';
import { Column, Row } from 'components/Grid';
import { CustodianInfo } from 'pages/Tokens/components/Form/PartnerDetails/components/CustodianInfoStep/CustodianInfoStep';
import { FormDataType } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import { TokenPartnersStep } from 'pages/Tokens/components/Form/PartnerDetails/components/ParnersStep/PartnersStep';
import { getPartnerDetailsStep, getPartnerDetailsSteps } from 'pages/Tokens/components/Form/utils';
import { useTokenContext } from 'contexts/tokens';
import useAppModal from 'hooks/useAppModal';

function renderCurrentStep(
  activeStep: TokenPartnerDetailsStepType,
  goBack: () => void,
  goToStep: (step: TokenPartnerDetailsStepType) => void,
  onSubmit: (data: FormDataType) => void
) {
  const steps = {
    [CUSTODIAN_INFO_STEP]: CustodianInfo,
    [PARTNERS_STEP]: TokenPartnersStep,
    [REVIEW_PARTNER_DETAILS_STEP]: Review,
    [WALLETS_INFO_STEP]: WalletsInfoStep,
  };

  const Step = steps[activeStep];
  return <Step onSubmit={onSubmit} goBack={goBack} goToStep={goToStep} />;
}

const PartnerDetails = () => {
  const openModal = useAppModal();

  const {
    completedSteps,
    isLoadingToken,
    openActiveTokenUpdatedModal,
    setCompletedSteps,
    setTokenActiveStep,
    stepsStatuses,
    tokenFormIsDirty,
  } = useTokenContext();
  const [activeStep, setActiveStep] = useState<TokenPartnerDetailsStepType>('Partners');

  useEffect(() => {
    setTokenActiveStep(activeStep);
  }, [activeStep]);

  const navigateToStep = useCallback(
    (step: TokenPartnerDetailsStepType) => {
      setCompletedSteps([...completedSteps, activeStep]);
      setActiveStep(step);
    },
    [activeStep, completedSteps, tokenFormIsDirty]
  );

  const handleNavigateToStep = useCallback(
    (step: TokenPartnerDetailsStepType) => {
      if (tokenFormIsDirty) {
        openActiveTokenUpdatedModal(openModal, step as TokenPartnerDetailsStepType, (step) =>
          navigateToStep(step as TokenPartnerDetailsStepType)
        );
      } else {
        navigateToStep(step);
      }
    },
    [activeStep, completedSteps, tokenFormIsDirty]
  );

  const handleStepSubmit = () => {
    const targetStep = getPartnerDetailsStep(activeStep)?.nextStep ?? REVIEW_PARTNER_DETAILS_STEP;
    handleNavigateToStep(targetStep as TokenPartnerDetailsStepType);
  };

  const goBack = () => {
    const targetStep = getPartnerDetailsStep(activeStep)?.previousStep ?? PARTNERS_STEP;
    handleNavigateToStep(targetStep as TokenPartnerDetailsStepType);
  };

  const goToStep = (step: TokenPartnerDetailsStepType) => {
    handleNavigateToStep(step);
  };

  return (
    <>
      <Row>
        <Column>
          <ProgressIndicator
            onClickStep={(stepLabel: TokenPartnerDetailsStepType) => goToStep(stepLabel)}
            steps={getPartnerDetailsSteps(activeStep, stepsStatuses)}
          />
        </Column>
      </Row>
      {isLoadingToken ? (
        <Loader />
      ) : (
        renderCurrentStep(activeStep, goBack, goToStep, handleStepSubmit)
      )}
    </>
  );
};

export default PartnerDetails;
