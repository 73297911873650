import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  InstrumentFormDataProps,
  InstrumentFormDataStepType,
} from 'pages/Instruments/Instruments.types';

// actions
import {
  loadInstrument,
  loadInstrumentCustodians,
  loadInstrumentWallets,
  saveInstrumentDraftThunk,
} from 'store/instruments/actions';

// types
import { Instrument, PaginationResponse, ProductStatus } from 'utils/types';

type InstrumentsStateType = {
  error: string | SerializedError | null;
  loading: boolean;
  loadingCustodians: boolean;
  loadingWallets: boolean;
  pagination: PaginationResponse | null;
  currentInstrument: Instrument | null;
  currentDirtyInstrumentFormData?: InstrumentFormDataProps | null;
  activeStep?: InstrumentFormDataStepType;
  saving: boolean;
  status?: ProductStatus;
};

const initialState: InstrumentsStateType = {
  error: null,
  loading: false,
  loadingCustodians: false,
  loadingWallets: false,
  currentInstrument: null,
  pagination: null,
  saving: false,
};

const instrumentsSlice = createSlice({
  name: 'instruments',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadInstrument.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(loadInstrument.fulfilled, (state, action) => {
      state.loading = false;
      state.currentInstrument = action.payload;
      delete state.currentDirtyInstrumentFormData;
    });

    builder.addCase(loadInstrument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ? action.payload : action.error;
    });

    builder.addCase(loadInstrumentCustodians.pending, (state) => {
      state.loadingCustodians = true;
    });

    builder.addCase(loadInstrumentCustodians.fulfilled, (state, action) => {
      state.loadingCustodians = false;

      if (state.currentInstrument) {
        state.currentInstrument = {
          ...state.currentInstrument,
          custodianAccounts: action.payload,
        };
      }
    });

    builder.addCase(loadInstrumentCustodians.rejected, (state, action) => {
      state.loadingCustodians = false;
      state.error = action.payload ? action.payload : action.error;
    });

    builder.addCase(loadInstrumentWallets.pending, (state) => {
      state.loadingWallets = true;
    });

    builder.addCase(loadInstrumentWallets.fulfilled, (state, action) => {
      state.loadingWallets = false;

      if (state.currentInstrument) {
        state.currentInstrument = {
          ...state.currentInstrument,
          custodianWallets: action.payload.custodianWallets,
          unifiedWallets: action.payload.unifiedWallets,
        };
      }
    });

    builder.addCase(loadInstrumentWallets.rejected, (state, action) => {
      state.loadingWallets = false;
      state.error = action.payload ? action.payload : action.error;
    });

    builder.addCase(saveInstrumentDraftThunk.pending, (state) => {
      state.saving = true;
    });

    builder.addCase(saveInstrumentDraftThunk.fulfilled, (state, action) => {
      state.saving = false;
      state.currentInstrument = {
        ...action.payload,
      };
      delete state.currentDirtyInstrumentFormData;
    });

    builder.addCase(saveInstrumentDraftThunk.rejected, (state, action) => {
      state.saving = false;
    });
  },
  reducers: {
    populateInstrumentWallets(state, action) {
      if (state.currentInstrument) {
        state.currentInstrument = {
          ...state.currentInstrument,
          custodianWallets: action.payload.custodianWallets,
          unifiedWallets: action.payload.unifiedWallets,
        };
      }
    },
    populateInstrumentCustodians(state, action) {
      if (state.currentInstrument) {
        state.currentInstrument = {
          ...state.currentInstrument,
          custodianAccounts: action.payload,
        };
      }
    },
    removeCurrentInstrument(state) {
      state.error = null;
      state.currentInstrument = null;
      state.currentDirtyInstrumentFormData = null;
    },
    populateCurrentInstrument(state, action) {
      state.currentInstrument = {
        ...action.payload,
      };
      delete state.currentDirtyInstrumentFormData;
    },
    populateCurrentDirtyInstrumentFormData(state, action) {
      state.currentDirtyInstrumentFormData = {
        ...state.currentDirtyInstrumentFormData,
        ...action.payload,
      };
    },
    updateActiveStep(state, action) {
      state.activeStep = action.payload;
    },
  },
});

export const instrumentsReducer = instrumentsSlice.reducer;
export const instrumentsActions = instrumentsSlice.actions;
