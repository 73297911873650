import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { IndexWeigthsTableRow } from './IndexWeigthsTableRow';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import isEmpty from 'lodash/isEmpty';
import { IndexWeigthsTableConfig } from './tableConfig';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import { IndexWeigthsTotalsTableRow } from './IndexWeigthsTotalsTableRow';

interface IndexWeigthsTableProps {
  rebalance?: RebalanceWithDetails;
  isLoading?: boolean;
}

export const IndexWeigthsTable = ({ isLoading = false, rebalance }: IndexWeigthsTableProps) => {
  return (
    <Table title="Change in Index Weights" noPadding>
      <TableHeaderWithMultiSort showActionsCell={false} columns={IndexWeigthsTableConfig} />
      <TableBodyWithStates
        loadingData={Boolean(isLoading)}
        hasContent={!isEmpty(rebalance?.details)}
        noContentLabel="No data at the moment."
      >
        {rebalance?.details?.map((details, index) => (
          <IndexWeigthsTableRow key={`indexWeightRow-${index}`} details={details} />
        ))}
        {rebalance?.detailsSum && <IndexWeigthsTotalsTableRow details={rebalance.detailsSum} />}
      </TableBodyWithStates>
    </Table>
  );
};
