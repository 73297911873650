import { PaginationProps } from 'utils/types';
import Select from 'components/Select';
import { MuiStyledPaginationContainer } from './Pagination.styles';
import { Pagination as MuiPagination } from '@mui/material';

function Pagination(props: PaginationProps) {
  const { itemsPerPage, itemsPerPageOptions, onChangeItemsPerPage, page, totalItems } = props;

  const numberOfPages = Math.ceil(totalItems / itemsPerPage) || 1;
  return (
    <>
      <MuiStyledPaginationContainer>
        <div>
          <Select
            isPaginationSelect
            inline
            options={
              itemsPerPageOptions ?? [
                { label: '10', value: 10 },
                { label: '50', value: 50 },
                { label: '100', value: 100 },
                { label: '250', value: 250 },
              ]
            }
            value={itemsPerPage}
            onChange={(newValue) => onChangeItemsPerPage(newValue as number)}
          />
        </div>
        <MuiPagination
          count={numberOfPages}
          page={page}
          shape="rounded"
          onChange={(_event, page) => {
            props.onChangePage(page);
          }}
        />
      </MuiStyledPaginationContainer>
    </>
  );
}

export default Pagination;
