import Button from 'components/Button';
import Card from 'components/Card';
import {
  StyledContentWrapper,
  StyledLoginContainer,
  StyledLoginLogoWrapper,
  StyledNeedHelp,
} from 'pages/Login/Login.styles';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Stack, Typography } from '@mui/material';
import { contactOnyxSupport } from 'utils/externalLinks';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <StyledLoginContainer>
      <StyledContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <Card title="Page not found">
          <Typography variant="subheadingMedium">Requested page does not exist.</Typography>
          <Stack>
            <Button variant="interactive" type="submit" onClick={() => navigate(-1)}>
              Go back
            </Button>
          </Stack>
        </Card>
        <StyledNeedHelp cols={12}>
          Need Help?{' '}
          <a href={contactOnyxSupport} target="_blank" rel="noreferrer">
            Contact Onyx support.
          </a>
        </StyledNeedHelp>
      </StyledContentWrapper>
    </StyledLoginContainer>
  );
};

export default NotFoundPage;
