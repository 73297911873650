import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { getUserStatus } from 'pages/Admin/UsersPage/helpers/getUserStatus';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { DEFAULT_PAGE, UNLIMITED_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { createNotification } from 'store/notifications/actions';
import { getUsersForCompanyRequest } from 'utils/api/users';
import { getUsersPerCompanyQuery } from 'utils/constants/reactQueries';
import { UsersTabType } from 'utils/constants/users';
import { PaginationWithDataResponse, PartnersUser } from 'utils/types';

export const useUsersPerCompanyQuery = (companyId: string, urlParams?: ParamsType) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getUsersPerCompanyQuery, companyId, urlParams],
    async () => {
      try {
        const userStatus = getUserStatus(urlParams?.tab as UsersTabType);
        const urlsParamsToUse: ParamsType = urlParams
          ? { ...urlParams, status: userStatus }
          : { page: DEFAULT_PAGE, pageSize: UNLIMITED_ITEMS_PER_PAGE };
        delete urlsParamsToUse.tab;
        const res = await getUsersForCompanyRequest(companyId, urlsParamsToUse);
        return res.data;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(companyId),
    }
  );
};

export const useUsersPerCompany = (
  companyId: string,
  refetch?: boolean,
  urlParams?: ParamsType
) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<PaginationWithDataResponse<PartnersUser[]>>({
    data: [],
  });
  const userStatus = getUserStatus(urlParams?.tab as UsersTabType);

  const { data: users, ...pagination } = data;

  const [loading, setLoading] = useState(false);
  const dataLoading = useRef(false);

  const fetchData = useCallback(async () => {
    try {
      if (companyId) {
        dataLoading.current = true;
        setLoading(true);
        const urlsParamsToUse: ParamsType = urlParams
          ? { ...urlParams, status: userStatus }
          : { page: DEFAULT_PAGE, pageSize: UNLIMITED_ITEMS_PER_PAGE };
        delete urlsParamsToUse.tab;
        const res = await getUsersForCompanyRequest(companyId, urlsParamsToUse);
        setData(res.data);
      }
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    } finally {
      dataLoading.current = false;
      setLoading(false);
    }
  }, [companyId, dispatch, urlParams, userStatus]);

  useEffect(() => {
    if (!dataLoading.current) {
      fetchData();
    }
  }, [companyId, fetchData, refetch, urlParams]);

  return { users, loading, pagination };
};
