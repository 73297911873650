import { Exchange } from 'utils/types';

export interface ExchangeFormDataModel {
  name: string;
  countries: string[];
  calendars: string[];
  marketClose?: string;
  marketOpen?: string;
}

export const toFormModel = (exchange: Exchange): ExchangeFormDataModel => ({
  calendars: exchange?.calendars?.map((cal) => cal._id) ?? [],
  countries: exchange.countries,
  name: exchange.name,
  marketClose: exchange.marketClose,
  marketOpen: exchange.marketOpen,
});
