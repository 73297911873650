import { Box, styled as MuiStyled } from '@mui/material';

export const StyledVersion = MuiStyled(Box)`
  font-size: 10px;
  padding: 0 8px;
  margin-left: 8px;
  color: #B3B3B3;
`;

const getVersion = () => {
  const metaElement = document?.querySelector('meta[name="amplify-build-id"]');
  if (metaElement instanceof HTMLMetaElement) {
    if (metaElement.content === 'AMPLIFY-BUILD-ID') {
      return null;
    }

    const items = metaElement.content.split('-');
    const [jobId, commitHash] = items;
    items[0] = String(parseInt(jobId));
    items[1] = commitHash.slice(0, 7);

    return items.join('-');
  }

  return null;
};

export const getOnyxVersion = () => {
  const metaElement = document?.querySelector('meta[name="onyx-version"]');
  if (metaElement instanceof HTMLMetaElement) {
    if (metaElement.content === 'ONYX-VERSION') {
      return '2.0';
    }
    return metaElement.content;
  }
};

export default function ProjectVersion() {
  return <StyledVersion>{getVersion()}</StyledVersion>;
}
