import { NotificationConfigType } from 'utils/types';

export const partnerCreatedNotification: NotificationConfigType = {
  success: () => ({
    message: 'Partner has been created succesfully',
    title: 'Partner created',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Partner creation failed',
    type: 'error',
  }),
};

export const partnerUpdatedNotification: NotificationConfigType = {
  success: () => ({
    title: 'Partner updated',
    message: 'Partner has been updated succesfully',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Partner update failed',
    type: 'error',
  }),
};

export const partnerDeactivatedNotification: NotificationConfigType = {
  success: (partnerName) => ({
    message: `Partner ${partnerName} has been deactivated succesfully`,
    title: 'Partner deactivated',
    type: 'info',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};
