import { FC } from 'react';
import { ReactComponent as ToggleIcon } from 'assets/Navigation/toggle-menu-icon-new.svg';
import { RotatingIcon } from 'layouts/DashboardContainer/components/SideNav/components/ToggleMenuIcon/styles';

interface ToggleIconProps {
  rotateIcon: boolean;
  onClick?: () => void;
}

export const ToggleMenuIcon: FC<ToggleIconProps> = ({ rotateIcon = false, onClick }) => (
  <RotatingIcon rotateicon={rotateIcon ? 'true' : 'false'} onClick={onClick}>
    <ToggleIcon />
  </RotatingIcon>
);
