import DeleteContactModal from 'shared/Modals/Contacts/DeleteContactModal/DeleteContactModal';
import EditContactModal from 'shared/Modals/Contacts/EditContactModal/EditContactModal';
import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';

export const ContactModal = ({ ...props }: IModalWithData) => {
  const ContactModals: Record<string, ReactNode> = {
    CREATE: <EditContactModal {...props} />,
    EDIT: <EditContactModal {...props} />,
    DELETE: <DeleteContactModal {...props} />,
  };
  return <>{ContactModals[props.data.type]}</>;
};
