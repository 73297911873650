import { AxiosError } from 'axios';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { UNAUTHORIZED_ERROR_MESSAGE } from 'utils/constants/errors';
import { logout } from 'utils/user/storage';

const unauthorizedErrorMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = action.payload;

    if (
      (typeof error === 'string' && error.toUpperCase() === UNAUTHORIZED_ERROR_MESSAGE) ||
      (error instanceof Error && error.message.toUpperCase() === UNAUTHORIZED_ERROR_MESSAGE) ||
      (error instanceof AxiosError && error.message.toUpperCase() === UNAUTHORIZED_ERROR_MESSAGE)
    ) {
      logout();
      return;
    }
  }

  return next(action);
};

export default unauthorizedErrorMiddleware;
