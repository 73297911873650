import useAppDispatch from 'hooks/useAppDispatch';
import { OrderFormProps } from 'utils/types/orders';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { ProductStatus, Token } from 'utils/types/product';
import { SingleOrderView } from 'pages/Orders/Orders/Order/components/SingleOrderView';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCreateOrder } from 'pages/Orders/Orders/Order/hooks/useCreateOrder';
import { useGetTokenOrderDetails } from 'pages/Orders/Tokens/hooks/useGetTokenOderDetails';
import { useGetTokensQuery } from 'hooks/useTokens';
import { useState } from 'react';

const CreateOrderToken = () => {
  const dispatch = useAppDispatch();
  const [updatedOrderData, setUpdatedOrderData] = useState<OrderFormProps>();
  const { user } = useAuthenticatedUser();
  const { data: tokens, isLoading: loadingTokens } = useGetTokensQuery(
    {
      status: [ProductStatus.ACTIVE, ProductStatus.IN_REVIEW, ProductStatus.PENDING],
    },
    null,
    true
  );
  const token = (tokens as Token[])?.find((i) => i._id === updatedOrderData?.productId);

  const { data: order, isLoading: loading } = useGetTokenOrderDetails(updatedOrderData, token);

  const { createTokenOrder } = useCreateOrder();

  const handleOnSubmit = async (order: OrderFormProps) => {
    createTokenOrder.mutate({
      ...order,
      settlementType: order.settlementType,
    });
  };

  const handleChangeOrder = async (orderFormData: OrderFormProps | undefined) => {
    if (orderFormData?.productId) {
      try {
        setUpdatedOrderData(orderFormData);
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message, 'Error'), error));
      }
    }
  };

  return (
    <>
      <TitleArea title={<OrderPageHeader productType="Token" isNewOrder />} showBackButton={true} />
      <SingleOrderView
        productType="Token"
        changeOrder={handleChangeOrder}
        isSubmitting={createTokenOrder.isLoading}
        loading={loadingTokens || loading}
        onSubmit={handleOnSubmit}
        order={order}
        orderFlow="create"
        user={user}
      />
    </>
  );
};

export default CreateOrderToken;
