import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { Exchange } from 'utils/types';
import dateToString from 'utils/decorators/dateToString';
import { StyledButton } from './ExchangeTableRow.styles';

type ExchangeTableRowProps = {
  exchange: Exchange;
  editAction: () => void;
  deleteAction: () => void;
  showActionsCell: boolean;
};

const ExchangeTableRow = ({
  exchange,
  editAction,
  deleteAction,
  showActionsCell = true,
}: ExchangeTableRowProps) => {
  return (
    <MuiStyledTableRow data-qa-id={`${exchange.name}-row`} key={exchange._id} onClick={editAction}>
      <MuiStyledTableCell data-qa-id={`${exchange.name}-name`}>{exchange.name}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${exchange.name}-country`}>
        {exchange.countries.join(', ')}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${exchange.name}-updated`}>
        {exchange?.updatedAt ? dateToString(exchange.updatedAt) : '-'}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell data-qa-id="editIcons">
        {showActionsCell && (
          <>
            <TablePopoverMenu id={exchange._id ?? exchange.name} className="actionsCell">
              <ul>
                {exchange._actions?.update && (
                  <li>
                    <StyledButton onClick={editAction} fullWidth variant="ghost">
                      Edit Exchange
                    </StyledButton>
                  </li>
                )}
                {exchange._actions?.delete && (
                  <li>
                    <StyledButton onClick={deleteAction} fullWidth variant="ghost">
                      Delete Exchange
                    </StyledButton>
                  </li>
                )}
              </ul>
            </TablePopoverMenu>
          </>
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};

export default ExchangeTableRow;
