import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { createNotification } from 'store/notifications/actions';
import { MuiStyledFooterWrapper } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';
import { Typography } from '@mui/material';
import { useDeleteApiKeysMutation } from 'pages/Admin/ApiKeysPage/hooks/useApiKeysQuery';
import { useAuthenticatedUser } from 'store/user/selectors';
import { ApiKey } from 'utils/types/apiKeys';

export const DeleteApiKeyModal = ({ data, closeModal }: IModalWithData) => {
  const { data: apiKey } = data;
  const {
    issuer: { name },
    _actions: {
      delete: { uri },
    },
  } = apiKey as ApiKey;

  const { user } = useAuthenticatedUser();
  const { mutate: deleteApiKey } = useDeleteApiKeysMutation(user?.organization?.companyId || '');

  const dispatch = useAppDispatch();

  function handleDelete() {
    deleteApiKey(uri, {
      onSuccess: () => {
        dispatch(
          createNotification({
            title: 'Admin',
            message: `API Key deleted`,
            type: 'success',
          })
        );
        closeModal();
      },
      onError: (error) => {
        dispatch(
          createNotification(
            {
              title: 'Admin',
              message: `API Key not deleted`,
              type: 'error',
            },
            error as Error
          )
        );
        closeModal();
      },
    });
  }

  const Footer = (
    <MuiStyledFooterWrapper>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button
        data-qa-id="deleteButton"
        variant="primary"
        fullWidth
        onClick={() => handleDelete()}
        type="button"
      >
        Delete
      </Button>
    </MuiStyledFooterWrapper>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        title={
          <Typography variant="subheadingMedium" lineHeight={'24px'}>
            {`Are you sure you want to delete API key created for ${name}?`}
          </Typography>
        }
        label="API Keys"
        footer={Footer}
        onClose={closeModal}
      />
    </CustomModal>
  );
};
