import { styled as MuiStyled, Paper, Popper } from '@mui/material';

export const MuiStyledContainer = MuiStyled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: 'max-content',
  padding: `${theme.spacers['spacing-16']} ${theme.spacers['spacing-08']}`,
  filter: 'drop-shadow(0px 2px 6px rgba(51, 51, 51, 0.25))',
}));

// giving the popper a big enough z-index to be in front of MUI pop-ups
export const MuiStyledPopper = MuiStyled(Popper)`
  z-index: 2000;
`;
