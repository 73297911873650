import { StepsListType } from 'pages/Tokens/components/Form/types';

export const GENERAL_DETAILS_STEP = 'General Details';
export const CONSTITUENT_STEP = 'Constituent';
export const SUMMARY_STEP = 'Review';

export type TokenDetailsStepType =
  | typeof GENERAL_DETAILS_STEP
  | typeof CONSTITUENT_STEP
  | typeof SUMMARY_STEP;

export const tokenDetailsStepsList: StepsListType<TokenDetailsStepType>[] = [
  {
    id: GENERAL_DETAILS_STEP,
    label: GENERAL_DETAILS_STEP,
    nextStep: CONSTITUENT_STEP,
  },
  {
    id: CONSTITUENT_STEP,
    label: CONSTITUENT_STEP,
    nextStep: SUMMARY_STEP,
    previousStep: GENERAL_DETAILS_STEP,
  },
  {
    id: SUMMARY_STEP,
    label: SUMMARY_STEP,
    previousStep: GENERAL_DETAILS_STEP,
  },
];
