import Row from 'components/Grid/Row';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledUserSettingPageHeader = MuiStyled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  span {
    color: #808080;
    align-self: flex-end;
    margin-bottom: 4px;
  }
`;

export const StyledRow = MuiStyled(Row)`
  padding: 8px 0;
  & > * {
    padding: 4px 8px 0 0;
  }
`;
