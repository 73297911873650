import {
  PopoverButton,
  PopoverContainer,
  StyledTablePopoverMenu,
} from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu.styles';
import { ReactComponent as MenuDots } from 'assets/Navigation/menu-dots.svg';
import React, { FC } from 'react';
import { PopoverOrigin } from '@mui/material/Popover';
import { Paper, ClickAwayListener } from '@mui/material';
import { interactive } from 'theme/utils/paletteColors';

interface PopoverProps {
  id: string;
  children: React.ReactNode;
  anchor?: React.ReactNode;
  anchorOrigin?: Partial<PopoverOrigin> | undefined;
  transformOrigin?: Partial<PopoverOrigin> | undefined;
  className?: string;
  disabled?: Boolean;
}

export const TablePopoverMenu: FC<PopoverProps> = ({
  id,
  children,
  anchor,
  anchorOrigin,
  transformOrigin,
  disabled,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <PopoverContainer sx={{ backgroundColor: 'transparent' }} {...props}>
        {anchor ? (
          <Paper onClick={handleClick}>{anchor}</Paper>
        ) : (
          <PopoverButton onClick={handleClick}>
            <MenuDots fill={interactive.dark} />
          </PopoverButton>
        )}
        <StyledTablePopoverMenu
          id="popover-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center', ...anchorOrigin }}
          transformOrigin={{ vertical: 'top', horizontal: 'right', ...transformOrigin }}
        >
          <Paper onClick={handleClose}>{children}</Paper>
        </StyledTablePopoverMenu>
      </PopoverContainer>
    </ClickAwayListener>
  );
};
