export const searchFilter = (
  item: Record<string, any>,
  filterBy: string[],
  search?: string | null
) => {
  if (!search) return true;

  const searchLowerCase = search.toLowerCase();

  return filterBy.some((field) => {
    if (!item[field]) return false;
    return String(item[field]).toLowerCase().includes(searchLowerCase);
  });
};

export const fieldFilter = (field?: string | number, value?: string | number | string[] | null) => {
  if (!field && !value) return true;

  if (Array.isArray(value)) {
    if (!value.length) return true;
    return value.some((item) => {
      return String(field) === String(item);
    });
  }
  if (!value) return true;

  if (String(field) === String(value)) return true;
  else return false;
};

export const getPaginationSliceValues = (
  page: number,
  itemsPerPage: number,
  totalItems?: number
) => {
  return [(page - 1) * itemsPerPage, Math.min(itemsPerPage * page, totalItems || 0)];
};
