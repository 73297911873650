import { Column } from 'components/Grid';
import Link from 'components/Link';
import TextAreaComponent from 'components/TextArea';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledModalCloseIconWrapper = MuiStyled(Box)`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: inherit;
  svg {
    margin-top: 24px;
    margin-right: 24px;
  }
`;

export const StyledTableCellWithOverrideBalance = MuiStyled(Box)`
  display: flex;
  gap: 16px;
  align-self: center;
`;

export const StyledNotificationColumn = MuiStyled(Column)`
  padding: 0;
`;

export const StyledLink = MuiStyled(Link)`
  padding: 0;
  align-self: center;
`;

export const StyledTextAreaComponent = MuiStyled(TextAreaComponent)`
  height: 100px;
`;
