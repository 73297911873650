import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { apiUrls } from 'utils/constants/apiUrls';
import { axiosInstance } from 'utils/axiosOnyx';
import { errorHandler } from 'utils/apiUtils';
import { Order, OrderFormProps, PaginationWithDataResponse } from 'utils/types';
import { Token, TokenAuthorizedMerchant } from 'utils/types/product';
import { CreateTokenDto, PartialTokenFormProps } from 'pages/Tokens/Tokens.types';
import { getTokenDetailsFormDataFormatted } from 'pages/Tokens/components/Form/utils';
import { editEntityApiCall } from './crudActions';

/* ----
GET
---- */
export async function getTokens(
  params?: ParamsType
): Promise<Token[] | PaginationWithDataResponse<Token[]>> {
  try {
    const { data: tokens } = await axiosInstance.get<PaginationWithDataResponse<Token[]>>(
      params ? `${apiUrls.tokenProduct}?${getQueryString(params)}` : apiUrls.tokenProduct
    );
    return tokens;
  } catch (err) {
    return errorHandler(err, 'Error while fetching tokens');
  }
}

export async function getToken(
  tokenId: Token['_id'],
  options: { withWallets?: boolean; withCustodians?: boolean } = {
    withWallets: false,
    withCustodians: false,
  }
) {
  const { withWallets, withCustodians } = options;

  try {
    const { data: token } = await axiosInstance.get<Token>(`${apiUrls.tokenProduct}/id=${tokenId}`);

    const walletPromise = withWallets ? getTokenWallets(tokenId) : null;
    const custodianPromise = withCustodians ? getTokenCustodians(tokenId) : null;

    let wallets = null;
    let custodianAccounts = null;

    const [walletsResponse, custodianAccountsResponse] = await Promise.allSettled([
      walletPromise,
      custodianPromise,
    ]);

    if (walletsResponse?.status === 'fulfilled') {
      wallets = walletsResponse.value;
    }

    if (custodianAccountsResponse?.status === 'fulfilled') {
      custodianAccounts = custodianAccountsResponse.value ?? [];
    }

    return {
      token,
      custodianAccounts,
      wallets,
    };
  } catch (err) {
    return errorHandler(err, 'Error while fetching token');
  }
}

export async function getTokenCustodians(
  tokenId: Token['_id'],
  status: string = 'ACTIVE'
): Promise<Token['custodianAccounts']> {
  try {
    const { data: custodianAccounts } = await axiosInstance.get<
      Token['custodianAccounts'] | PaginationWithDataResponse<Token['custodianAccounts']>
    >(
      `${apiUrls.tokenProduct}/id=${tokenId}/custodian-accounts?${getQueryString({
        status: status,
      })}`
    );

    return custodianAccounts as Token['custodianAccounts'];
  } catch (err) {
    return errorHandler(err, "Error while fetching token's custodian accounts");
  }
}

export async function getTokenWallets(
  tokenId: Token['_id'],
  status: string = 'ACTIVE'
): Promise<Pick<Token, 'custodianWallets' | 'unifiedWallets'>> {
  try {
    const { data: walletC = [] } = await axiosInstance.get<Token['custodianWallets']>(
      `${apiUrls.tokenProduct}/id=${tokenId}/wallets/type=CUSTODIAN?${getQueryString({
        status: status,
      })}`
    );

    return { custodianWallets: walletC };
  } catch (err) {
    return errorHandler(err, "Error while fetching token's wallets");
  }
}

export async function getTokenAuthorizedMerchants(tokenId: Token['_id']) {
  try {
    const { data: authorizedMerchants } = await axiosInstance.get<TokenAuthorizedMerchant[]>(
      `${apiUrls.tokenProduct}/id=${tokenId}/authorized-merchants`
    );
    return authorizedMerchants;
  } catch (err) {
    return errorHandler(err, "Error while fetching token's authorized merchants");
  }
}

export async function getTokenOrderDetails(orderProps: OrderFormProps): Promise<Order> {
  const queryList = getQueryString({
    deliveryType: orderProps.deliveryType,
    type: orderProps.type,
    numberOfUnits: orderProps.numberOfUnits,
    userId: orderProps.userId ?? '',
    companyId: orderProps.companyId ?? '',
    pricingType: orderProps.pricingType ?? '',
    cashExecutionFeeBps: orderProps.cashExecutionFeeBps ?? null,
    settlementType: orderProps.settlementType,
  });

  try {
    const { data: order } = await axiosInstance.get<Order>(
      `${apiUrls.tokenProduct}/id=${orderProps.productId}/order-details?${queryList}`
    );

    return order;
  } catch (err) {
    return errorHandler(err, 'Error while loading order details');
  }
}

/* ----
POST
---- */
export async function createToken(dto: CreateTokenDto) {
  try {
    const { data: token } = await axiosInstance.post<Token>(apiUrls.tokenProduct, dto);
    return token;
  } catch (err) {
    return errorHandler(err, 'Error while creating token');
  }
}

export async function submitTokenForApproval(tokenId: Token['_id']) {
  try {
    const { data: token } = await axiosInstance.post<Token>(
      `${apiUrls.tokenProduct}/id=${tokenId}/submit`
    );
    return token;
  } catch (err) {
    return errorHandler(err, 'Error while submitting token');
  }
}

/* ----
PATCH
---- */
export async function patchToken(uri: string, tokenProps: PartialTokenFormProps) {
  const { data: token } = await editEntityApiCall<
    ReturnType<typeof getTokenDetailsFormDataFormatted>,
    Token
  >(getTokenDetailsFormDataFormatted(tokenProps), uri, 'Error while editing token');

  return token;
}
