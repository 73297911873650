import { StyledWrapperProps } from 'components/Loader/Loader.types';

import { Box, styled as MuiStyled } from '@mui/material';

export const StyledWrapper = MuiStyled(Box)<StyledWrapperProps>`
  z-index: 1;
  position: ${(props) => (props.anchortorelative === 'true' ? 'static' : 'fixed')};
  display: flex;
  ${(props) => props.anchortorelative === 'false' && 'background-color: rgba(0, 0, 0, 0.3);'}
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
