import { FormVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';
import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';

export const formPalette = (themeVariant: ThemeTokens): FormVariantsInterface => ({
  default: {
    colorLabel: '#3B4853',
    colorPlaceholder: '#838F98',
    color: 'black',
    borderColor: '#CFD5DB',
    borderColorHover: '#B2B9C2',
  },
  active: {
    borderColor: '#FF7A37',
  },
  error: {
    color: '#D2222C',
    borderColor: '#D2222C',
  },
  disabled: {
    backgroundColor: '#F9FAFD',
    borderColor: '#E7ECF1',
  },
});
