import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { requestCurrency } from 'utils/api/assets';

export const fetchCurrency = createAsyncThunk(
  'currency/fetchCurrency',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const data = await requestCurrency();
      return data;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message), error));
      return rejectWithValue(error.message);
    }
  }
);
