export const PartnerTypeTitles: { [key: string]: string } = {
  ADMIN: 'Admin',
  AUTHORIZED_PARTICIPANT: 'Authorized Participant',
  AUTHORIZED_MERCHANT: 'Authorized Merchant',
  CALCULATING_AGENT: 'Calculating Agent',
  FUND_ACCOUNTANT: 'Fund Accountant',
  ISSUER: 'Issuer',
  PCF_UPLOADER: 'Pcf Uploader',
  TECHNICAL_LISTING_AGENT: 'Technical Listing Agent',
  CO_ISSUER: 'Co-Issuer',
};

export enum PARTNER_STATUS {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export const PARTNERS_TAB_STATUS = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

export enum PARTNER_STATUS_LABEL {
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
}

export type PartnerTabType =
  | typeof PARTNERS_TAB_STATUS.ACTIVE
  | typeof PARTNERS_TAB_STATUS.DEACTIVATED
  | undefined;

export const PARTNERS_TABS = [
  [PARTNER_STATUS_LABEL.ACTIVE, PARTNERS_TAB_STATUS.ACTIVE],
  [PARTNER_STATUS_LABEL.DEACTIVATED, PARTNERS_TAB_STATUS.DEACTIVATED],
];
