import { Theme } from '@mui/material/styles';

// import { fab } from './components/fab';
// import { card } from './components/card';
// import { tabs } from './components/tabs';
// import { list } from './components/list';
// import { alert } from './components/alert';
// import { badge } from './components/badge';
// import { paper } from './components/paper';
// import { radio } from './components/radio';
// import { appBar } from './components/appbar';
// import { drawer } from './components/drawer';
// import { dialog } from './components/dialog';
// import { avatar } from './components/avatar';
// import { rating } from './components/rating';
// import { slider } from './components/slider';
// import { select } from './components/select';
import { defaultProps } from './default-props';
// import { switches } from './components/switch';
// import { tooltip } from './components/tooltip';
// import { popover } from './components/popover';
// import { stepper } from './components/stepper';
// import { svgIcon } from './components/svg-icon';
// import { skeleton } from './components/skeleton';
// import { backdrop } from './components/backdrop';
// import { progress } from './components/progress';
// import { timeline } from './components/timeline';
// import { checkbox } from './components/checkbox';
// import { dataGrid } from './components/data-grid';
// import { treeView } from './components/tree-view';
// import { accordion } from './components/accordion';
// import { breadcrumbs } from './components/breadcrumbs';
// import { cssBaseline } from './components/css-baseline';
// import { autocomplete } from './components/autocomplete';
// import { toggleButton } from './components/toggle-button';

// ----------------------------------------------------------------------

export function customMuiOverrides(theme: Theme) {
  const components = defaultProps(theme);

  return components;
}
