export const RebalanceDetailsTableConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', minWidth: '140px', sort: false },
  {
    label: 'Balance (Pre)',
    propName: 'preBalance',
    propType: 'string',
    minWidth: '140px',
    sort: false,
  },
  {
    label: 'Price',
    propName: 'price',
    propType: 'string',
    minWidth: '180px',
    sort: false,
    showInfoIcon: true,
  },
  { label: 'Value (Pre)', propName: 'preValue', propType: 'date', width: '18%', sort: false },
  { label: ' Weight (Pre)', propName: 'preWeight', propType: 'string', width: '10%', sort: false },
  {
    label: 'Target Weights',
    propName: 'targetWeight',
    propType: 'date',
    minWidth: '180px',
    sort: false,
  },
  {
    label: 'Difference (Pre)',
    propName: 'preDifference',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  {
    label: 'Gross Trade (Cash)',
    propName: 'tradeCash',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  {
    label: 'Gross Trade (Coin)',
    propName: 'tradeCoin',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  { label: 'Balance (Post)', propName: 'postBalance', propType: 'date', width: '18%', sort: false },
  { label: 'Value (Post)', propName: 'postValue', propType: 'date', width: '18%', sort: false },
  {
    label: 'Weight (Post)',
    propName: 'postWeight',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  {
    label: 'Difference (Post)',
    propName: 'postDifference',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  { label: 'Action', propName: 'action', propType: 'date', width: '18%', sort: false },
  {
    label: 'Net Trade (Coin)',
    propName: 'actualTrade',
    propType: 'string',
    width: '18%',
    sort: false,
  },
  {
    label: 'Net Trade (Cash)',
    propName: 'actualTradeCash',
    propType: 'string',
    width: '18%',
    sort: false,
  },
  {
    label: 'Act Balance (Post)',
    propName: 'actualBalance',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  {
    label: 'Act Value (Post)',
    propName: 'actualPostValue',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  {
    label: 'Act Weight (Post)',
    propName: 'actualPostWeight',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  {
    label: 'Act Difference (Post)',
    propName: 'actualPostWeightDifference',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  {
    label: 'Currency',
    propName: 'rebalance.product.quoteCurrency',
    propType: 'string',
    width: '18%',
    sort: false,
  },
  {
    label: 'Fee (bps) ',
    minWidth: '180px',
    propName: 'fee',
    propType: 'string',
    sort: false,
  },
];
