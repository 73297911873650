import Footer from 'pages/Tokens/components/Footer';
import useAppDispatch from 'hooks/useAppDispatch';
import { CONSTITUENT_STEP } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import { ConstituentsTable } from 'shared/Tables/ConstituentsTable/ConstituentsTable';
import {
  ProductStatus,
  TokenConstituentAssetType,
  TokenConstituentAssetsProps,
} from 'utils/types/product';
import { createNotification } from 'store/notifications/actions';
import { ConstituentAssetType } from 'utils/types/product';
import { useSaveToken } from 'hooks/useTokens';
import { useTokenContext } from 'contexts/tokens';

interface ConstituentProps {
  goBack: () => void;
  onSubmit: () => void;
}

function ConstituentStep({ goBack, onSubmit }: ConstituentProps) {
  const dispatch = useAppDispatch();
  const { currentToken, tokenFormsData } = useTokenContext();
  const { saveToken } = useSaveToken();
  const constituents = (tokenFormsData[CONSTITUENT_STEP] as TokenConstituentAssetsProps) ?? [];

  const handleRemoveConstituent = (constituentToUpdate: TokenConstituentAssetType) => {
    const updatedConstituentsList = constituents?.filter((el) => {
      return el._id !== String(constituentToUpdate._id);
    });
    if (updatedConstituentsList.length === 0) {
      if (currentToken?.status === ProductStatus.ACTIVE) {
        dispatch(
          createNotification({
            message: 'Active Product should always have at least one constituent.',
            title: 'Constituents update',
            type: 'error',
          })
        );
        return;
      }
    }
    saveToken({
      [CONSTITUENT_STEP]: [...updatedConstituentsList] as ConstituentAssetType[],
    });
  };

  return (
    <>
      <ConstituentsTable
        deleteAction={handleRemoveConstituent}
        constituentsList={constituents}
        // @TODO TOKENS - Add loading to useTokenContext return?
        loading={false}
        isToken={true}
      />
      <Footer goBack={goBack} onSubmit={onSubmit} />
    </>
  );
}

export default ConstituentStep;
