import { ComponentProps, Fragment, ReactNode } from 'react';
import { Box } from '@mui/material';

import Typography from '@mui/material/Typography';
import { ReactComponent as ChartArrow } from 'assets/chart-arrow.svg';

interface TableTitleWithActionsProps extends ComponentProps<'div'> {
  title?: string;
  titleComponent?: ReactNode;
  actions?: ReactNode[];
}

const TableTitleWithActions = ({ title, actions, titleComponent }: TableTitleWithActionsProps) => {
  return (
    <Box
      paddingBottom={2}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      // @TODO add theme colors
      borderBottom={'1.25px solid #98ABB833'}
      className={'table-title-wrapper'}
    >
      {title && (
        <Box display={'flex'} alignItems={'center'} gap={'8px'} minHeight={'40px'}>
          <Box width={'24px'}>
            <ChartArrow />
          </Box>
          {/* @TODO Add theme colors */}
          <Typography color="#3B4853" variant="subheadingSmall" width={'max-content'}>
            {title}
          </Typography>
        </Box>
      )}
      {titleComponent && (
        <Box display={'flex'} alignItems={'center'} gap={'8px'} minHeight={'40px'}>
          <Box width={'24px'}>
            <ChartArrow />
          </Box>
          {/* @TODO Add theme colors */}
          <Typography color="#3B4853" variant="subheadingSmall" width={'max-content'}>
            {titleComponent}
          </Typography>
        </Box>
      )}
      {actions?.map((action, index) => (
        <Fragment key={index}>{action}</Fragment>
      ))}
    </Box>
  );
};

export default TableTitleWithActions;
