import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { tabHistorical, tabInProgress } from 'utils/constants/orders';

export const useOrdersTab = () => {
  const [searchParams] = useSearchParams();
  const tab = useMemo(() => searchParams.get('tab'), [searchParams]);

  const isHistorical = tab === tabHistorical;
  const isInPorgress = tab === tabInProgress;

  return { isHistorical, isInPorgress, tab };
};
