import Button from 'components/Button';
import { RebalancesCardTable } from './RebalancesCardTable/RebalancesCardTable';
import {
  StyledRebalanceTradesCard,
  StyledRebalancesCardFooterButtonWrapper,
  StyledRebalancesCardFooterWrapper,
  StyledNotificationContainer,
} from 'pages/Rebalance/RebalancePage.styles';
import { RebalancesCardHeader } from './RebalancesCardTable/RebalancesCardHeader';
import Notification from 'components/Notification';
import { MODAL_ACTIONS, REBALANCE_MODAL } from 'shared/Modals/constants';
import useAppModal from 'hooks/useAppModal';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import { find } from 'lodash';

export interface RebalanceTradeInterface {
  navigateToDetails: () => void;
  rebalance: RebalanceWithDetails;
  isPastTab?: boolean;
  refetchData: () => void;
}

export const RebalanceTradesCard = ({
  navigateToDetails,
  rebalance,
  isPastTab,
  refetchData,
}: RebalanceTradeInterface) => {
  const openModal = useAppModal();

  const tradesNotCalculated = rebalance.details.find((detail) =>
    find(detail, (value) => value === 'NaN')
  );

  const Footer = (
    <StyledRebalancesCardFooterWrapper>
      <StyledRebalancesCardFooterButtonWrapper>
        {rebalance._actions.approve && (
          <Button
            disabled={Boolean(tradesNotCalculated)}
            data-qa-id="approveTrades"
            fullWidth
            variant="primary"
            onClick={() => {
              openModal(
                {
                  modalName: REBALANCE_MODAL,
                  modalData: {
                    data: rebalance,
                    type: MODAL_ACTIONS.CUSTOM,
                    custom: {
                      type: 'approve',
                    },
                  },
                },
                {
                  onCloseModalAction: refetchData,
                }
              );
            }}
          >
            Approve Trades
          </Button>
        )}
        <Button
          data-qa-id="viewDetails"
          disabled={false}
          fullWidth
          isLoading={false}
          onClick={navigateToDetails}
          variant="tertiary"
        >
          View Details
        </Button>
      </StyledRebalancesCardFooterButtonWrapper>
    </StyledRebalancesCardFooterWrapper>
  );

  return (
    <StyledRebalanceTradesCard>
      <RebalancesCardHeader rebalance={rebalance} isPastTab={isPastTab} />
      <RebalancesCardTable rebalance={rebalance} />
      {tradesNotCalculated && (
        <StyledNotificationContainer>
          <Notification
            messageMaxWidth={true}
            withcountdown="false"
            multiline={'false'}
            title={`Trades haven’t been calculated`}
            message="Please check back in later"
            variant="info"
          />
        </StyledNotificationContainer>
      )}
      {Footer}
    </StyledRebalanceTradesCard>
  );
};
