import { DateTime } from 'utils/datetime';
import { Order } from 'utils/types';

export function isDeferred(order: Order) {
  const isBooked = Boolean(order.bookedAt);
  const isSettled = Boolean(order.settledAt);

  const isBookAndSettleSameDay =
    isBooked && isSettled && new DateTime(order.bookedAt).isSameDate(new DateTime(order.settledAt));

  return isBooked && !isBookAndSettleSameDay;
}
