import { Box, TableCell as MuiTableCell } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export interface TableCellProps {
  align?: 'left' | 'center' | 'right';
  nowrap?: 'true' | 'false';
  withActions?: boolean;
  pointer?: boolean;
  minWidth?: string;
  maxWidth?: string;
  disabled?: Boolean;
  error?: string;
  sizeprop?: 'small' | 'medium' | 'large';
}

export const MuiStyledTableCell = MuiStyled(MuiTableCell)<TableCellProps>(
  ({ theme, nowrap = 'false', error, sizeprop = 'medium' }) => ({
    whiteSpace: nowrap === 'true' ? 'nowrap' : 'normal',
    color: error === 'error' ? theme.palette.error.main : 'inherit',
    '&.MuiTableCell-root': {
      padding: '0 24px',
      lineHeight: 'unset',
      height: {
        small: '32px',
        medium: '48px',
        large: '68px',
      }[sizeprop],
    },
  })
);

const TableCell = MuiStyled(MuiStyledTableCell)<TableCellProps>`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  display: ${({ withActions }) => (withActions ? 'flex' : 'table-cell')};
  vertical-align: middle;
  min-width: ${({ minWidth }) => minWidth ?? 'unset'};
  max-width: ${({ maxWidth }) => maxWidth ?? 'unset'};
  text-align: ${({ align }) => align || 'left'};
  padding: ${({ theme }) => `0 ${theme.spacers['spacing-16']}`};
  ${({ nowrap }) => nowrap === 'true' && { 'white-space': 'nowrap' }};
  ${({ pointer }) => pointer && { cursor: 'pointer' }};

  & > :first-child {
    ${({ align }) => align === 'right' && { 'margin-left': 'auto' }};
  }
  svg {
    padding-left: 8px;
    vertical-align: text-top;
  }
  .MuiCircularProgress-svg {
    padding: 0;
  }

  &:hover {
    ${({ theme, disabled }) => {
      return disabled
        ? `border: 1px solid ${theme.palette.blue[300]};
            background: #F7F7F7;
            cursor: not-allowed`
        : '';
    }}
  }
`;

export const TableCheckboxCell = MuiStyled(TableCell)`
  width: 48px;
  padding: 0;
  & > * {
    margin: auto;
    width: min-content;
  }
`;

export const TableCellContent = MuiStyled(Box)`
  display: flex;
  gap: 8px;
`;

export const FULL_SPAN = 9999;

export const MuiStyledEmptyTableCell = MuiStyled(MuiTableCell)(({ theme }) => ({
  textAlign: 'center',
  height: '84px',
  verticalAlign: 'middle',
  borderBottom: `1px solid ${theme.palette.text.divider.default.standard}`,
}));

export default TableCell;

export const MuiStyledTableCheckboxCell = MuiStyled(MuiStyledTableCell)(({ theme }) => ({
  width: '48px',
  padding: '0 0 0 24px',
  '& > *': {
    margin: 'auto',
    width: 'min-content',
  },
}));

export const MuiStyledTableHeaderCell = MuiStyled(MuiTableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  backgroundColor: theme.palette.grey[200],
}));

export const MuiStyledTableCollapseCell = MuiStyled(MuiTableCell)(({ theme }) => ({
  padding: 0,
}));
