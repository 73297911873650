import { NotificationConfigType } from 'utils/types';

export const walletCreatedNotifications: NotificationConfigType = {
  success: (walletAddress, companyName) => ({
    message: `Wallet ${walletAddress} has been added to the company ${companyName}`,
    title: 'Wallet added',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const walletEditNotifications: NotificationConfigType = {
  success: (walletAddress) => ({
    message: `Wallet ${walletAddress} has been updated succesfully`,
    title: 'Wallet updated',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const walletDeactivatedNotifications: NotificationConfigType = {
  success: (walletAddress) => ({
    message: `Wallet ${walletAddress} has been deactivated succesfully`,
    title: 'Wallet deactivated',
    type: 'info',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const walletActivatedNotifications: NotificationConfigType = {
  success: (walletAddress) => ({
    message: `Wallet ${walletAddress} has been activated succesfully`,
    title: 'Wallet activated',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};
