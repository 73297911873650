import OrderCancelConfirmationModal from 'shared/Modals/Orders/OrderCancelConfirmationModal';
import { OrderStatusChangeModal } from 'shared/Modals/Orders/OrderStatusChangeModal/OrderStatusChangeModal';
import { IModalWithData } from 'shared/Modals/types';
import { Order, OrderStatus } from 'utils/types/orders';
import { ReactNode } from 'react';

export interface OrdersModalData {
  order: Order;
  targetStatus: OrderStatus;
  confirmAction?: () => void;
  refreshQueryKey?: string;
}

export interface DeferOrderModalData extends Omit<OrdersModalData, 'targetStatus'> {}

export const OrdersModal = ({ ...props }: IModalWithData) => {
  const OrderModals: Record<string, ReactNode> = {
    DELETE: <OrderCancelConfirmationModal {...props} />,
    CUSTOM: <OrderStatusChangeModal {...props} />,
  };

  return <>{OrderModals[props.data.type]}</>;
};
