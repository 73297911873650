export const ProductExchangesColumnConfig = [
  {
    label: 'Exchange',
    propName: 'name',
    propType: 'string',
    sort: false,
    width: '100%',
    colSpan: 2,
  },
];
