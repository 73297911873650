import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { ProductBase } from 'utils/types';
import { createEntityApiCall } from 'utils/api/crudActions';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const ArchiveProductModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const product = data.data as ProductBase;

  const productTypeLabel = data.custom?.isToken ? 'Token' : 'ETP';

  const archiveProductMutation = useMutation({
    mutationFn: (instrument: ProductBase) =>
      createEntityApiCall(null, instrument._actions?.archive?.uri ?? ''),
    onSuccess: () => {
      dispatch(
        createNotification(
          successNotification(
            `${product.name} ${productTypeLabel} has been archived`,
            productTypeLabel
          )
        )
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleArchive = () => {
    archiveProductMutation.mutate(product);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="interactive"
        fullWidth
        onClick={handleArchive}
        type="submit"
        data-qa-id="archiveButton"
        isLoading={archiveProductMutation.isLoading}
      >
        Archive
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        label={productTypeLabel}
        title={`Are you sure you want to archive ${product.name}?`}
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
