import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { loadNewAssets } from 'utils/api/assets';
import { getExternalConstituentAssets } from 'utils/constants/reactQueries';

export const useExternalConstituentAssets = () => {
  const dispatch = useAppDispatch();
  return useQuery([getExternalConstituentAssets], async () => {
    try {
      const res = await loadNewAssets();
      return res;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    }
  });
};
