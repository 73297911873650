import { StepsListType } from 'pages/Tokens/components/Form/types';

// Partners details steps
export const PARTNERS_STEP = 'Partners';
export const CUSTODIAN_INFO_STEP = 'Custodian Info';
export const WALLETS_INFO_STEP = 'Wallets Info';
export const REVIEW_PARTNER_DETAILS_STEP = 'Review';

export type TokenPartnerDetailsStepType =
  | typeof PARTNERS_STEP
  | typeof CUSTODIAN_INFO_STEP
  | typeof WALLETS_INFO_STEP
  | typeof REVIEW_PARTNER_DETAILS_STEP;

export const partnerStepsList: StepsListType<TokenPartnerDetailsStepType>[] = [
  {
    id: PARTNERS_STEP,
    label: PARTNERS_STEP,
    nextStep: CUSTODIAN_INFO_STEP,
  },
  {
    id: CUSTODIAN_INFO_STEP,
    label: CUSTODIAN_INFO_STEP,
    nextStep: WALLETS_INFO_STEP,
    previousStep: PARTNERS_STEP,
  },
  {
    id: WALLETS_INFO_STEP,
    label: WALLETS_INFO_STEP,
    nextStep: REVIEW_PARTNER_DETAILS_STEP,
    previousStep: CUSTODIAN_INFO_STEP,
  },
  {
    id: REVIEW_PARTNER_DETAILS_STEP,
    label: REVIEW_PARTNER_DETAILS_STEP,
    previousStep: WALLETS_INFO_STEP,
  },
];
