import { Instrument, Token } from 'utils/types/product';
import { Actions } from '.';

export enum Chain {
  BITCOIN = 'BITCOIN',
  ETHEREUM = 'ETHEREUM',
  CARDANO = 'CARDANO',
  BITCOINCASH = 'BITCOINCASH',
  LITECOIN = 'LITECOIN',
  STELLARLUMENS = 'STELLARLUMENS',
  NEO = 'NEO',
  TEZOS = 'TEZOS',
  EOS = 'EOS',
  COSMOS = 'COSMOS',
  SOLANA = 'SOLANA',
  AVALANCHE = 'AVALANCHE',
  ALGORAND = 'ALGORAND',
}

export enum WalletStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum WalletType {
  CUSTODIAN_WALLET = 'CUSTODIAN',
  OWN_WALLET = 'OWN',
}
interface Wallet {
  _id?: string;
  address: string;
  chain: Chain;
  description: string;
  status: WalletStatus;
  _actions?: Actions<'activate' | 'delete' | 'update'>;
}

export interface CompanyOwnWallet extends Wallet {
  assets: string[];
  products: string[];
}

export interface CompanyCustodianWallet extends Wallet {
  product?: string;
  constituentId: string;
  custodianAccount: string;
  idAtCustodian: string;
  productName?: Instrument['name'] | Token['name'];
}

export interface ProductCustodianWallet extends CompanyCustodianWallet {
  transactingCompany: string;
}

export type WalletUnionType = CompanyOwnWallet | CompanyCustodianWallet | ProductCustodianWallet;
