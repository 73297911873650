import { OptionType } from 'components/Select/Select.types';
import { useMemo, useState } from 'react';

export const useAddressFilters = (
  countriesOptions?: OptionType<number>[],
  citiesOptions?: OptionType<number>[]
) => {
  const [countriesFilter, setCountriesFilter] = useState<string | undefined>();
  const [citiesFilter, setCitiesFilter] = useState<string | undefined>();

  const filteredCountriesOptions = useMemo(
    () =>
      countriesOptions?.filter((country) =>
        countriesFilter
          ? country.label.toLowerCase().startsWith(countriesFilter.toLowerCase())
          : true
      ) ?? [],
    [countriesFilter, countriesOptions]
  );

  const filteredCitiesOptions = useMemo(
    () =>
      citiesOptions?.filter((city) =>
        citiesFilter ? city.label.toLowerCase().startsWith(citiesFilter.toLocaleLowerCase()) : true
      ) ?? [],
    [citiesFilter, citiesOptions]
  );

  return { filteredCountriesOptions, filteredCitiesOptions, setCountriesFilter, setCitiesFilter };
};
