const spaceTokens = {
  'spacing-02': '0.125rem',
  'spacing-04': '0.25rem',
  'spacing-08': '0.5rem',
  'spacing-12': '0.75rem',
  'spacing-16': '1rem',
  'spacing-24': '1.5rem',
  'spacing-32': '2rem',
  'spacing-40': '2.5rem',
  'spacing-48': '3rem',
  'spacing-64': '4rem',
  'spacing-80': '5rem',
  'spacing-96': '6rem',
};

export default spaceTokens;
