import { PortfolioComposition } from 'utils/types/pcfs';
import Table, { TableCell, TableRow } from 'components/Table';
import { Box, TableBody, Typography } from '@mui/material';
import { NewCard } from 'components/NewCard/NewCard';

interface PCFEtpInformationProps {
  pcf?: PortfolioComposition;
}

function PCFEtpInformation({ pcf }: PCFEtpInformationProps) {
  return (
    <NewCard
      header={
        <Box padding={'16px'}>
          <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
            ETP Information
          </Typography>
          <Typography variant="bodyMedium">{pcf?.product?.name}</Typography>
        </Box>
      }
      body={
        <Table noPadding>
          <TableBody>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell align="right">{pcf?.product.baseCurrency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product ISIN</TableCell>
              <TableCell align="right">{pcf?.product.isin}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="right">{pcf?.product.name}</TableCell>
            </TableRow>
            {pcf?.currencyPrices?.map((currencyPrice) => (
              <TableRow key={currencyPrice.baseCurrency}>
                <TableCell>Total NAV {currencyPrice.baseCurrency}</TableCell>
                <TableCell align="right">
                  {
                    pcf?.official?.totalNav?.find(
                      (total) => total.currency === currencyPrice.baseCurrency
                    )?.value
                  }
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Total Units Outstanding</TableCell>
              <TableCell align="right">{pcf?.official?.totalUnitsOutstanding}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Creation Unit Size</TableCell>
              <TableCell align="right">{pcf?.product?.unitSize}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      }
    />
  );
}

export default PCFEtpInformation;
