import { Children, ComponentPropsWithoutRef, MouseEvent, ReactElement, useState } from 'react';

// types
import { variantType } from 'components/Button/types';
import { SizeType } from 'utils/types';

// components
import Button from 'components/Button';

// styles
import { StyledGroupedButtonsLabel } from 'components/GroupButton/GroupButton.styles';
import { Popover } from '@mui/material';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';

interface GroupButtonProps extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  children: ReactElement<typeof Button> | ReactElement<typeof Button>[];
  label: string;
  square?: 'true' | 'false';
  size?: SizeType;
  variant?: variantType;
}

function GroupButton({
  children,
  label,
  size,
  square = 'false',
  variant,
  ...props
}: GroupButtonProps) {
  const [anchorButton, setAnchorButton] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorButton);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorButton(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorButton(null);
  };

  return (
    <div {...props}>
      <Button fullWidth onClick={handleClick} size={size} square={square} variant={variant}>
        <StyledGroupedButtonsLabel>{label}</StyledGroupedButtonsLabel>
        <ArrowDown />
      </Button>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorButton}
      >
        <ul>
          {Children.map(children, (child) => (
            <li onClick={handleClose}>{child}</li>
          ))}
        </ul>
      </Popover>
    </div>
  );
}

export default GroupButton;
