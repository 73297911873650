export const darkThemeColors = {
  neutral: {
    0: '#FFFFFF',
    10: '#F9FBFD',
    20: '#F0F4F7',
    30: '#D2DBE4',
    40: '#BFC8D2',
    50: '#838F98',
    60: '#3B4853',
    70: '#242C33',
    80: '#1A1E23',
    90: '#101012',
    100: '#0D0D0F',
    110: '#0E0F10',
  },
  yellow: {
    10: '#FEF9EF',
    20: '#FBEED2',
    30: '#F8DCA6',
    40: '#F4CB79',
    50: '#F1B94D',
    60: '#EDA820',
    70: '#C48A18',
    80: '#9A6C11',
    90: '#714E09',
    100: '#2F2104',
  },
  gold: {
    10: '#FAF7F3',
    20: '#F5EDE2',
    30: '#EFE3D1',
    40: '#EAD5BB',
    50: '#B6A48E',
    60: '#837260',
    70: '#4F453A',
    80: '#4F4133',
    90: '#2E261E',
    100: '#1F1A14',
  },
  orange: {
    10: '#FFF8F3',
    20: '#FFE4D6',
    30: '#FFC2A3',
    40: '#FFAA80',
    50: '#FF8F57',
    60: '#FF7A37',
    70: '#D55810',
    80: '#AF430D',
    90: '#662200',
    100: '#331100',
  },
  red: {
    10: '#FFEEEE',
    20: '#FFCCCC',
    30: '#FF9999',
    40: '#FF6666',
    50: '#FF3333',
    60: '#D2222C',
    70: '#CC0000',
    80: '#990000',
    90: '#660000',
    100: '#330000',
  },
  cyan: {
    10: '#F1FCFC',
    20: '#D6F5F5',
    30: '#ADEBEB',
    40: '#84E1E1',
    50: '#5BD7D7',
    60: '#32CDCD',
    70: '#28A4A4',
    80: '#1E7B7B',
    90: '#145252',
    100: '#0A2929',
  },
  green: {
    10: '#F1FFF5',
    20: '#D7F4DE',
    30: '#B0E8BD',
    40: '#88DD9C',
    50: '#61D17B',
    60: '#47BC62',
    70: '#2E9E48',
    80: '#227736',
    90: '#145222',
    100: '#0A2911',
  },
  blue: {
    10: '#F1F7FF',
    20: '#CEE1FD',
    30: '#9DC3FB',
    40: '#6CA4F9',
    50: '#3B86F7',
    60: '#0A68F5',
    70: '#0853C4',
    80: '#063E93',
    90: '#042A62',
    100: '#021531',
  },
};
