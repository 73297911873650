import { AxiosError, AxiosResponse } from 'axios';
import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { UserForm } from 'shared/Modals/Users/UpdateUser/UpdateUserModal';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import {
  CompanySuggestionDto,
  GetCompaniesSuggestionQueryParams,
  PaginationWithDataResponse,
  Partner,
  PartnersUser,
} from 'utils/types';

export const requestCompanies = async (params?: ParamsType) => {
  try {
    return await axiosInstance.get<PaginationWithDataResponse<Partner[]>>(
      params ? `/companies?${getQueryString(params)}` : `/companies`
    );
  } catch (err) {
    return errorHandler(err);
  }
};

export const requestCompany = async (id: string): Promise<AxiosResponse<Partner, AxiosError>> => {
  try {
    return await axiosInstance.get(`/companies/id=${id}`);
  } catch (err) {
    return errorHandler(err);
  }
};

export const requestPostCompany = async (
  newPartner: Partner
): Promise<AxiosResponse<Partner, AxiosError>> => {
  try {
    return await axiosInstance.post(`/companies`, newPartner);
  } catch (err) {
    return errorHandler(err, 'Error while creating company');
  }
};

export const requestPatchCompany = async (
  partner: Partner
): Promise<AxiosResponse<Partner, AxiosError>> => {
  try {
    return await axiosInstance.patch(`/companies/id=${partner._id}`, partner);
  } catch (err) {
    return errorHandler(err, 'Error while updating company');
  }
};

export const deleteCompanyRequest = async (
  uriPath: string
): Promise<AxiosResponse<Partner, AxiosError>> => {
  try {
    return await axiosInstance.delete(uriPath);
  } catch (err) {
    return errorHandler(err, 'Error while deleting company');
  }
};

export const createUserForCompanyRequest = async (
  companyId: string,
  user: UserForm
): Promise<AxiosResponse<PartnersUser, AxiosError>> => {
  try {
    return await axiosInstance.post(`/companies/id=${companyId}/users`, user);
  } catch (err) {
    return errorHandler(err, 'Error occurred while creating a user in the company.');
  }
};

export const updateUserRequest = async (
  uriPath: string,
  user: UserForm
): Promise<AxiosResponse<PartnersUser, AxiosError>> => {
  try {
    return await axiosInstance.patch(uriPath, user);
  } catch (err) {
    return errorHandler(err, 'Error occurred while updating a user in the company.');
  }
};

export const getCompaniesSuggestions = async (
  params?: GetCompaniesSuggestionQueryParams
): Promise<AxiosResponse<CompanySuggestionDto[], AxiosError>> => {
  try {
    return await axiosInstance.get(`/companies/suggestions`, { params: params });
  } catch (err) {
    return errorHandler(err, 'Error while loading companies suggestions');
  }
};
