import Button from 'components/Button';
import { capitalize } from 'lodash';
import { dateFormat } from 'shared/Tables/table.utils';
import { format, parseISO } from 'date-fns';
import Tag from 'components/Tag';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { PartnersUser } from 'utils/types';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { isEmpty } from 'lodash';

type Props = {
  user: PartnersUser;
  editAction: () => void;
  deleteAction: () => void;
  resendInvitationAction: () => void;
  reset2FA: () => void;
  showActionsCell: boolean;
};

export const UsersTableRow = ({
  user,
  editAction,
  deleteAction,
  resendInvitationAction,
  reset2FA,
  showActionsCell = true,
}: Props) => {
  return (
    <MuiStyledTableRow data-qa-id={`${user.email}-row`} key={user._id} onClick={editAction}>
      <MuiStyledTableCell data-qa-id={`${user.email}-name`}>
        {user.firstName} {user.lastName}{' '}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${user.email}-email`}>{user.email} </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${user.email}-role`}>
        <Tag size="small" label={capitalize(user.role)} />
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${user.email}-created`}>
        {format(parseISO(user.createdAt), dateFormat)}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell data-qa-id="actionBtn">
        {showActionsCell && !isEmpty(user._actions) && (
          <TablePopoverMenu id={user._id ?? user.createdAt} className="actionsCell">
            <ul>
              {user?._actions?.update && (
                <Button data-qa-id="editUser" fullWidth variant="ghost" onClick={editAction}>
                  Edit User
                </Button>
              )}
              {user?._actions?.resendInvitation && (
                <Button
                  data-qa-id="resendInvitation"
                  fullWidth
                  variant="ghost"
                  onClick={resendInvitationAction}
                >
                  Resend Invitation
                </Button>
              )}
              {user?._actions?.reset2fa && (
                <Button onClick={reset2FA} fullWidth variant="ghost">
                  Reset 2FA
                </Button>
              )}
              {user?._actions?.delete && (
                <Button
                  data-qa-id="deleteUser"
                  fullWidth
                  variant="dangerGhost"
                  onClick={deleteAction}
                >
                  Deactivate User
                </Button>
              )}
            </ul>
          </TablePopoverMenu>
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
