export interface TabProps {
  name: string;
  param?: string;
  onSelect?: (tabName: string) => void;
  size?: 'small' | 'medium' | 'large';
}

export enum TAB_TYPE {
  DEFAULT = 'default',
  CONTAINER = 'container',
  DEFAULT_NO_BORDER = 'defaultNoBorder',
}
