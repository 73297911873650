import { createSlice, SerializedError } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

// actions
import { loadExchanges } from 'store/exchanges/actions';

// types
import { Exchange, ExchangeList } from 'utils/types';

type ExchangesStateType = {
  error: string | SerializedError | null;
  loading: boolean;
  exchanges: ExchangeList | null;
};

const initialState: ExchangesStateType = { error: null, loading: false, exchanges: null };

const exchangesSlice = createSlice({
  name: 'exchanges',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadExchanges.pending, (state) => {
      state.error = null;
      state.loading = true;
    });

    builder.addCase(loadExchanges.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.exchanges = keyBy(action.payload, '_id');
    });

    builder.addCase(loadExchanges.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ? action.payload : action.error;
    });
  },
  reducers: {
    addNewExchange(state, action: { payload: Exchange }) {
      if (!state.exchanges) {
        state.exchanges = {
          [action.payload._id]: action.payload,
        };
        return;
      }
      state.exchanges![action.payload._id] = action.payload;
    },
    updateExchange(state, action: { payload: Exchange }) {
      if (!state.exchanges) {
        return;
      }
      Object.assign(state.exchanges[action.payload._id], action.payload);
    },
    deleteExchange(state, action) {
      delete state.exchanges?.[action.payload._id];
    },
  },
});

export const { addNewExchange, updateExchange, deleteExchange } = exchangesSlice.actions;

export const exchangesReducer = exchangesSlice.reducer;
