/* eslint-disable complexity */
import Card from 'components/Card';
import CircularProgress from 'components/CircularProgress';
import CutoffInfo from 'pages/Orders/Orders/Order/components/CutoffInfo';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import ProductSelector from 'components/ProductSelector';
import Select from 'components/Select';
import { Deliverable, Order, OrderFlow, OrderFormProps } from 'utils/types/orders';
import { DeliveryTypeTitles, NavOptionsTitles } from 'pages/Orders/Orders/types';
import { ORDER_IN_KIND } from 'utils/constants';
import { OrderFormContainer, StyledDelegateButton } from 'pages/Orders/Orders/Order/Order.styles';
import { Pcf } from 'utils/types/pcfs';
import { ProductStatus } from 'utils/types/product';
import { User } from 'utils/types';
import { getExchangeWorkingHoursInformation } from 'pages/Orders/Orders/utils/getExchangeWorkingHoursInformation';
import { isFormReadyForSubmission } from 'pages/Orders/Orders/utils/isFormReadyForSubmission';
import { orderDelegatedSchemaUrl, orderSchemaUrl } from 'components/Form/formSchemas';
import { useAuthorizedParticipants } from 'pages/Orders/Orders/Order/hooks/useAuthorizedParticipants';
import { useEffect, useMemo, useState } from 'react';
import { useUserPermissions } from 'store/user/selectors';
import { useUsersPerCompany } from 'pages/Partners/hooks/useUsersPerCompany';
import { useLocation } from 'react-router-dom';

interface Props {
  changeOrder: (data: OrderFormProps | undefined) => void;
  handleOnSubmit: (data: OrderFormProps) => void;
  isSubmitting?: boolean;
  loading: boolean;
  orderDetails?: Order;
  orderFlow?: OrderFlow;
  pcf?: Pcf;
  seedDeliverables?: Deliverable[];
  user: User | null;
}

const OrderForm = ({
  handleOnSubmit,
  isSubmitting,
  changeOrder,
  orderFlow = 'create',
  orderDetails,
  seedDeliverables,
  loading,
}: Props) => {
  const location = useLocation();
  const seedProductId = location?.state?.seedProductId; // Passed to form as initial value to improve "Seed Product" flow
  const permissions = useUserPermissions();
  const { instrumentAPs, loading: loadingAPs } = useAuthorizedParticipants(
    seedProductId ?? orderDetails?.product?._id
  );

  const [companyId, setCompanyId] = useState<string>();
  const { users } = useUsersPerCompany(companyId ?? '');

  const [orderFormData, setOrderFormData] = useState<OrderFormProps>();
  const isDelegatedOrder =
    (permissions?.canCreateOrderDelegated || permissions?.canCreateTokenOrderDelegated) &&
    orderFlow === 'delegate';

  const exchangeWorkingHoursInfo = useMemo(
    () =>
      getExchangeWorkingHoursInformation(
        orderDetails?.product,
        orderDetails?.deliveryType,
        isDelegatedOrder
      ),
    [orderDetails?.product, orderDetails?.deliveryType, isDelegatedOrder]
  );
  const usersOptions = useMemo(
    () =>
      users?.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user._id })) ??
      [],
    [users]
  );

  const deliveryTypeOptions = orderDetails?.deliveryTypeOptions?.map((option) => ({
    label: DeliveryTypeTitles[option],
    value: option,
  }));

  const settlementTypeOptions = orderDetails?.settlementTypeOptions?.map((option) => ({
    label: option,
    value: option,
  }));

  const pricingTypeoptions =
    orderDetails?.pricingTypeOptions?.map((option) => {
      return { value: option, label: NavOptionsTitles[option] };
    }) ?? [];

  const formSubmissionEnabled = useMemo(
    () =>
      orderFormData &&
      orderDetails &&
      isFormReadyForSubmission(
        orderFlow === 'delegate',
        exchangeWorkingHoursInfo.isClosed,
        companyId,
        orderDetails,
        seedDeliverables,
        Boolean(orderFormData.pricingType)
      ),
    /* eslint-disable react-hooks/exhaustive-deps */
    [seedDeliverables, orderDetails, exchangeWorkingHoursInfo.isClosed, orderFlow]
  );

  useEffect(() => {
    if (orderFormData) {
      orderFormData.companyId && setCompanyId(orderFormData.companyId);
      const order = structuredClone(orderFormData);
      if (order.pricingType && order?.deliveryType !== 'CASH') {
        delete order.pricingType;
      }
      changeOrder(order);
    }
  }, [orderFormData]);

  const Footer = (
    <StyledDelegateButton
      disabled={!formSubmissionEnabled}
      size="large"
      type="submit"
      variant="interactive"
    >
      {orderFlow === 'delegate' ? 'Delegate Order' : 'Place Order'}
      {isSubmitting && <CircularProgress size={24} />}
    </StyledDelegateButton>
  );

  return (
    <OrderFormContainer>
      <Form
        schemaUrl={isDelegatedOrder ? orderDelegatedSchemaUrl : orderSchemaUrl}
        onBlur={(formData) => {
          setOrderFormData(formData as OrderFormProps);
        }}
        onSubmit={handleOnSubmit}
        loading={loading || isSubmitting}
        initialValues={seedProductId && { productId: seedProductId }}
      >
        <Card label="Create Order" title="Order Details" footer={Footer}>
          <ProductSelector
            label="Product"
            clearable
            name="productId"
            data-qa-id="orderIndex"
            data-qa-options-id="orderIndex"
            permissionFilter={
              // If user is allowed to create delegated Order,
              // we should use createDelegatedOrder action from product _actions object
              isDelegatedOrder ? 'createDelegatedOrder' : 'createOrder'
            }
            resetOnChange={['companyId', 'userId', 'deliveryType', 'settlementType']}
            disabled={Boolean(seedProductId)}
            status={
              isDelegatedOrder
                ? [ProductStatus.ACTIVE, ProductStatus.IN_REVIEW]
                : [ProductStatus.ACTIVE]
            }
            fullWidth
          />
          {orderFlow === 'delegate' && (
            <>
              <Select
                data-qa-id="company"
                data-qa-options-id="company"
                name="companyId"
                options={instrumentAPs}
                disabled={loading || loadingAPs}
                resetOnChange={['userId']}
              />
              <Select
                data-qa-id="user"
                data-qa-options-id="user"
                name="userId"
                options={usersOptions}
                disabled={!companyId || loading}
              />
            </>
          )}
          <Select
            data-qa-id="creationRedemption"
            data-qa-options-id="creationRedemption"
            name="type"
            disabled={loading}
          />
          <Input data-qa-id="creationUnits" name="numberOfUnits" disabled={loading} />
          <Select
            data-qa-id="deliveryType"
            data-qa-options-id="deliveryType"
            name="deliveryType"
            options={deliveryTypeOptions}
            disabled={loading}
          />
          <Select
            data-qa-id="settlementType"
            data-qa-options-id="settlementType"
            name="settlementType"
            value={orderDetails?.product?.standardSettlement}
            options={settlementTypeOptions}
            disabled={loading}
          />
          {orderDetails?.deliveryType === 'CASH' && (
            <>
              <Select
                data-qa-id="navOptions"
                data-qa-options-id="navOptions"
                name="pricingType"
                options={pricingTypeoptions}
                disabled={loading}
              />
              {orderFlow === 'delegate' && (
                <Input
                  data-qa-id="cashExecutionFeeBps"
                  name="cashExecutionFeeBps"
                  disabled={loading}
                />
              )}
            </>
          )}
          {!isDelegatedOrder && orderDetails?.product && (
            <CutoffInfo
              deliveryType={orderDetails?.deliveryType || ORDER_IN_KIND}
              isClosed={exchangeWorkingHoursInfo.isClosed}
              opensAt={exchangeWorkingHoursInfo.openTime}
            />
          )}
        </Card>
      </Form>
    </OrderFormContainer>
  );
};

export default OrderForm;
