import { CONTACTS_TAB_VALUE } from 'utils/constants/contacts';
import { useAllContactsQuery } from './api/useAllContacts';
import { useAllExternalContactsQuery } from './api/useAllExternalContacts';
import { ParamsType } from 'hooks/useUrlParams';

export const useContactsTabData = (params?: ParamsType) => {
  const allExternalContactsData = useAllExternalContactsQuery(
    params?.subtab === CONTACTS_TAB_VALUE.EXTERNAL,
    params
  );

  const allOwnContactsData = useAllContactsQuery(
    params?.subtab === CONTACTS_TAB_VALUE.YOUR,
    params
  );

  switch (params?.subtab) {
    case CONTACTS_TAB_VALUE.EXTERNAL:
      return allExternalContactsData;
    case CONTACTS_TAB_VALUE.YOUR:
      return allOwnContactsData;
  }
};
