import ActivateCustodianModal from './ActivateCustodianModal/ActivateCustodianModal';
import DeleteCustodianModal from './DeleteCustodianModal/DeleteCustodianModal';
import { EditCustodianModal } from 'shared/Modals/Custodian/EditCustodianModal/EditCustodianModal';
import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';
import { CustodianAccount } from 'utils/types';

export interface CustodianModalData {
  custodian: CustodianAccount | null;
  custodians: CustodianAccount[];
  partnersCustodians?: string[];
}

export const CustodianModal = ({ ...props }: IModalWithData) => {
  const CustodianModals: Record<string, ReactNode> = {
    CUSTOM: <ActivateCustodianModal {...props} />,
    CREATE: <EditCustodianModal {...props} />,
    EDIT: <EditCustodianModal {...props} />,
    DELETE: <DeleteCustodianModal {...props} />,
  };

  return <>{CustodianModals[props.data.type]}</>;
};
