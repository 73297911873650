import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { createNotification } from 'store/notifications/actions';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import Input from 'components/Input';
import Select from 'components/Select';
import { Box, Typography } from '@mui/material';
import { useCompanies } from 'pages/Partners/hooks/useCompanies';
import { useEffect, useState } from 'react';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCreateApiKeysMutation } from 'pages/Admin/ApiKeysPage/hooks/useApiKeysQuery';

export const CreateApiKeyModal = ({ closeModal }: IModalWithData) => {
  const [issuerOptions, setIssuerOptions] = useState<
    { label: string; value: string | undefined }[]
  >([]);
  const [selectedIssuerValue, setSelectedIssuerValue] = useState('');
  const [apiKeyToken, setApiKeyToken] = useState('');
  const [apiKeyTokenCopied, setApiKeyTokenCopied] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAuthenticatedUser();
  const { data: issuers, isLoading: loadingCompanies } = useCompanies({ type: 'ISSUER' });
  const { mutate: createApiKey } = useCreateApiKeysMutation(user?.organization?.companyId ?? '');

  useEffect(() => {
    const issuerOptions =
      issuers &&
      issuers?.data.map((issuer) => {
        return {
          label: issuer.name,
          value: issuer._id,
        };
      });

    setIssuerOptions(issuerOptions || []);
  }, [issuers]);

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Box display={'flex'} justifyContent={'flex-end'}>
        {/* "Done" button is disabled if the user has created a key but hasn't copied it */}
        <Button
          disabled={Boolean(apiKeyToken && !apiKeyTokenCopied)}
          data-qa-id="doneButton"
          variant="secondary"
          fullWidth
          onClick={closeModal}
          type="button"
        >
          Done
        </Button>
      </Box>
    </MuiStyledModalFooterButtons>
  );

  async function handleCreate() {
    createApiKey(selectedIssuerValue, {
      onSuccess: (data) => {
        setApiKeyToken(data?.token || '');

        dispatch(
          createNotification({
            title: 'Admin',
            message: `API Key added`,
            type: 'success',
          })
        );
      },
      onError: (err) => {
        createNotification(
          {
            title: 'Admin',
            message: `API Key not added`,
            type: 'error',
          },
          err as Error
        );
      },
    });
  }
  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        title="Add API Key"
        label={'Admin'}
        footer={Footer}
        // "Close" icon is hidden if the user has created a key but hasn't copied it
        {...(apiKeyToken && !apiKeyTokenCopied ? {} : { onClose: closeModal })}
        disableScroll
      >
        <Select
          options={issuerOptions}
          label="Create key for"
          name="createKeyFor"
          placeholder="Select Issuer"
          disabled={loadingCompanies}
          onChange={(val) => {
            if (val) {
              setSelectedIssuerValue(val);
            }
          }}
          value={selectedIssuerValue}
        />
        <Box display="grid" gridTemplateColumns="3fr 1fr" alignItems={'center'} gap={1}>
          <Input
            label="API Key"
            name="apiKey"
            value={apiKeyToken}
            withClipboard={Boolean(apiKeyToken)}
            onClick={() => {
              if (apiKeyToken) {
                setApiKeyTokenCopied(true);
              }
            }}
          />
          {/* Create API key button is disabled if user hasn't selected an issuer or has already created a key (input is populated) */}
          <Button
            disabled={Boolean(!selectedIssuerValue || apiKeyToken)}
            style={{ alignSelf: 'flex-end', height: '48px' }}
            data-qa-id="activateButton"
            variant="primary"
            onClick={handleCreate}
            type="button"
          >
            Generate Key
          </Button>
          {/* Show warning message if user has created a key but hasn't copied it */}
          {Boolean(apiKeyToken && !apiKeyTokenCopied) && (
            <Typography color={'error'} variant="labelSmall">
              WARNING: API Key will never be shown again. Copy and save it.
            </Typography>
          )}
        </Box>
      </Card>
    </CustomModal>
  );
};
