import { AxiosError, AxiosResponse } from 'axios';
import { axiosInstance } from 'utils/axiosOnyx';
import { errorHandler } from 'utils/apiUtils';
import { ParamsType } from 'hooks/useUrlParams';
import { PaginationWithDataResponse } from 'utils/types';
import { ApiKey } from 'utils/types/apiKeys';

export const getApiKeys = async (
  companyId: string,
  params?: ParamsType
): Promise<AxiosResponse<ApiKey[], AxiosError>> => {
  try {
    return await axiosInstance.get(`/companies/id=${companyId}/tokens`);
  } catch (err) {
    return errorHandler(err, 'Error while loading API Keys');
  }
};

export const createApiKey = async (
  companyId: string,
  issuer: string,
  params?: ParamsType
): Promise<AxiosResponse<ApiKey, AxiosError>> => {
  try {
    return await axiosInstance.post(`/companies/id=${companyId}/tokens`, { issuer });
  } catch (err) {
    return errorHandler(err, 'Error while creating API Key');
  }
};

export const deleteApiKey = async (
  deleteUri: string,
  params?: ParamsType
): Promise<AxiosResponse<PaginationWithDataResponse<any[]>, AxiosError>> => {
  try {
    return await axiosInstance.delete(`/companies${deleteUri}`);
  } catch (err) {
    return errorHandler(err, 'Error while deleting API Key');
  }
};
