import Box from '@mui/material/Box';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { SecuritiesRegister } from 'pages/Orders/Register/types';
import { Typography } from '@mui/material';
import { renderOrderValue } from 'shared/Tables/table.utils';

export interface RegisterInformationProps {
  register?: SecuritiesRegister;
}

const RegisterInformationColumnsConfig = [
  { label: 'Counter Party', propName: 'counterParty', propType: 'string', sort: false },
  { label: 'Date', propName: 'date', propType: 'string', sort: false },
  {
    label: 'Number of Securities',
    propName: 'numberOfSecurities',
    propType: 'string',
    sort: false,
  },
  {
    label: 'Total Securities Outstanding',
    propName: 'totalSecuritiesOutstanding',
    propType: 'string',
    sort: false,
  },
];

export const RegisterInformation = ({ register }: RegisterInformationProps) => {
  return (
    <Box component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Orders</Typography>
      </MuiStyledSectionHeader>
      <Table>
        <TableHeaderWithMultiSort
          columns={RegisterInformationColumnsConfig}
          columnAlignment={{
            date: 'right',
            numberOfSecurities: 'right',
            totalSecuritiesOutstanding: 'right',
          }}
        />
        <TableBodyWithStates
          hasContent={Boolean(register?.entries?.length)}
          noContentLabel="No entries at the moment."
        >
          {register?.entries?.map((entry, index) => (
            <MuiStyledTableRow key={entry.dealDate + index}>
              <MuiStyledTableCell align="left">
                {renderOrderValue(Boolean(entry.counterParty), entry.counterParty)}
              </MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(entry.dealDate), entry.dealDate)}
              </MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(entry.changeInSecuritiesAmount),
                  entry.changeInSecuritiesAmount
                )}
              </MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(entry.accumulatedAmount), entry.accumulatedAmount)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        </TableBodyWithStates>
      </Table>
    </Box>
  );
};
