import { useCallback, useEffect, useRef } from 'react';
import { useResetIdleTime } from 'store/idleTime/selectors';
import { idleTime } from 'utils/env';
import { isLoggedIn, logout } from 'utils/user/storage';

export const useIdleTimeout = () => {
  const resetIdleTime = useResetIdleTime();
  const idleTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const resetIdleTimeout = useCallback(() => {
    clearTimeout(idleTimeoutRef.current);
    idleTimeoutRef.current = setTimeout(() => {
      logout();
    }, idleTime);
  }, []);

  const addWindowEvents = useCallback((callbackFunction: () => void) => {
    window.addEventListener('click', callbackFunction);
    window.addEventListener('keyup', callbackFunction);
  }, []);

  const removeWindowEvents = useCallback((callbackFunction: () => void) => {
    window.removeEventListener('click', callbackFunction);
    window.removeEventListener('keyup', callbackFunction);
  }, []);

  useEffect(() => {
    const isAuthenticated = isLoggedIn();
    if (isAuthenticated) {
      resetIdleTimeout();
      addWindowEvents(resetIdleTimeout);
    } else {
      clearTimeout(idleTimeoutRef.current);
      removeWindowEvents(resetIdleTimeout);
    }
    return () => {
      clearTimeout(idleTimeoutRef.current);
      removeWindowEvents(resetIdleTimeout);
    };
  }, [addWindowEvents, removeWindowEvents, resetIdleTime, resetIdleTimeout]);

  return resetIdleTimeout;
};
