import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { Deliverable, Order, OrderFlow, OrderStatus } from 'utils/types/orders';
import { EditOrderProps } from 'pages/Orders/Orders/types';
import { useButtonsActions } from 'pages/Orders/Orders/hooks/useButtonsActions';
import { isFuture } from 'utils/date';
import ActualDeliverables from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/ActualDeliverables/ActualDeliverables';
import Button from 'components/Button';
import DealInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/DealInformation/index';
import ExpectedDeliverables from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/ExpectedDeliverables/ExpectedDeliverables';
import ProductInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/ProductInformation';
import SettlementInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/SettlmentInformation';
import Terms from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/Terms';
import WalletsInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/WalletsInformation';
import { StyledTableToolbar } from 'components/Table/Table.styles';
import {
  StyledInformationContainer,
  StyledBottomRowLeft,
  StyledBottomRowRight,
  StyledToolbarsWrapper,
  StyledToollbarBottomRow,
  StyledToollbarTopRow,
  StyledTitle,
  StyledExpectedDeliverablesTableContainer,
} from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { StyledTableTopButton, StyledDownloadButton } from 'shared/Tables/table.styles';
import ProductIcon from 'components/ProductIcon';
import TransactionSummary from './components/sections/TransactionSummary';
import useAppModal from 'hooks/useAppModal';
import { ProductType } from 'utils/types/product';
import { Typography } from '@mui/material';
import { NewCard } from 'components/NewCard/NewCard';

interface SingleOrderTableProps extends Omit<EditOrderProps, 'onSubmit' | 'changeOrder' | 'order'> {
  productType: ProductType;
  orderFlow: OrderFlow;
  downloadCsv?: () => void;
  downloadPdf?: () => void;
  onSubmit?: () => void;
  order?: Order;
  refreshOrderQueryKey?: string;
  seedDeliverables?: Deliverable[];
}

const checkIfOrderCanBeCanceled = (orderStatus?: OrderStatus | '') => {
  return (
    orderStatus === OrderStatus.PENDING ||
    orderStatus === OrderStatus.CONFIRMED ||
    orderStatus === OrderStatus.READY
  );
};

const OrderDetails = ({
  productType,
  onChangeSeedDeliverable,
  order,
  user,
  downloadCsv,
  downloadPdf,
  orderFlow,
  refreshOrderQueryKey,
  seedDeliverables,
}: SingleOrderTableProps) => {
  const openModal = useAppModal();
  const showToolbar = Boolean(order?._id);
  const showToolbarActions =
    orderFlow === 'confirm' && checkIfOrderCanBeCanceled(order?.status ?? '');
  const actions = useButtonsActions(order || ({} as Order));

  const title = (
    <StyledTitle>
      <ProductIcon iconUrl={order?.product?.fundIcon} ticker={order?.product?.ticker ?? ''} />
      <Typography variant="subheadingMedium">{order?.externalId ?? ''}</Typography>
    </StyledTitle>
  );

  const downloadPDF = (
    <StyledDownloadButton label="Download Data" variant="secondary">
      <Button
        disabled={!Boolean(order?.documents?.confirmationPdf?.url)}
        fullWidth
        onClick={downloadPdf}
        size="medium"
        variant="ghost"
      >
        Download PDF
      </Button>
      <Button fullWidth onClick={downloadCsv} size="medium" variant="ghost">
        Download CSV
      </Button>
    </StyledDownloadButton>
  );

  const SingleOrderTableToolbar = (
    <StyledToolbarsWrapper>
      <StyledToollbarTopRow justifyContent="start">{title}</StyledToollbarTopRow>
      <StyledToollbarBottomRow>
        <StyledBottomRowLeft>
          {showToolbarActions &&
            actions.map(
              (action, index) =>
                action.permission && (
                  <StyledTableTopButton
                    disabled={
                      productType !== 'Token' &&
                      action.status === OrderStatus.SETTLED &&
                      isFuture(order?.settlement?.date!)
                    }
                    onClick={() => {
                      if (order) {
                        openModal({
                          modalName: action.modal,
                          modalData: {
                            data: {
                              targetStatus: action.status,
                              order,
                              refreshQueryKey: refreshOrderQueryKey,
                            },
                            type: MODAL_ACTIONS.CUSTOM,
                            custom: {
                              isToken: productType === 'Token',
                            },
                          },
                        });
                      }
                    }}
                    size="small"
                    variant={action.variant}
                    type="button"
                    key={index}
                  >
                    {action.label}
                  </StyledTableTopButton>
                )
            )}
        </StyledBottomRowLeft>
        <StyledBottomRowRight>{downloadPDF}</StyledBottomRowRight>
      </StyledToollbarBottomRow>
    </StyledToolbarsWrapper>
  );

  return (
    <NewCard
      sx={{ padding: '16px', overflow: 'hidden', width: '100%' }}
      header={showToolbar && <StyledTableToolbar tools={[SingleOrderTableToolbar]} />}
      body={
        <StyledExpectedDeliverablesTableContainer>
          <StyledInformationContainer>
            <DealInformation
              productType={productType}
              user={user}
              order={order}
              orderFlow={orderFlow}
            />
            {productType === 'ETP' && order?.status && order.deliveryType === 'CASH' && (
              <TransactionSummary order={order} />
            )}
            <ExpectedDeliverables
              productType={productType}
              onChangeSeedDeliverable={onChangeSeedDeliverable}
              order={order}
              seedDeliverables={seedDeliverables}
            />
            <ActualDeliverables productType={productType} order={order} />
            <ProductInformation productType={productType} order={order} />
            <WalletsInformation productType={productType} order={order} />
            {productType === 'ETP' && (
              <SettlementInformation productType={productType} order={order} />
            )}
            <Terms productType={productType} order={order} user={user} />
          </StyledInformationContainer>
        </StyledExpectedDeliverablesTableContainer>
      }
    />
  );
};

export default OrderDetails;
