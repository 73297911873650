import {
  CUSTODIAN_INFO_STEP,
  PARTNERS_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { CUSTODIAN_WALLETS, UNIFIED_WALLETS } from 'pages/Instruments/components/Form/utils';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { ProductConstituentAssetsProps } from 'utils/types/product';
import { ProductCustodianWallet } from 'utils/types/wallets';
import { CustodianAccount } from 'utils/types/product';

export enum CompanyRole {
  IS_PCF_UPLOADER = 'isPcfUploader',
}

export interface PartnersStepDataProps {
  issuer?: string;
  pcfUploaders?: string[];
  authorizedParticipants?: string[];
  custodians?: string[];
  calculationAgents?: string[];
  technicalListingAgent?: string;
}

export type CustodiansListProps = CustodianAccount[];

export type WalletsType = typeof CUSTODIAN_WALLETS | typeof UNIFIED_WALLETS;

export type WalletsList = {
  custodianWallets?: ProductCustodianWallet[];
  unifiedWallets?: ProductCustodianWallet[];
};

export interface PartnerDetailsFormProps {
  [PARTNERS_STEP]?: PartnersStepDataProps;
  [CUSTODIAN_INFO_STEP]?: CustodiansListProps;
  [WALLETS_INFO_STEP]?: WalletsList;
  [CONSTITUENT_STEP]?: ProductConstituentAssetsProps;
}

export type FormDataType = PartnersStepDataProps | CustodiansListProps | WalletsList;
