import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';

// hooks
import useToggle from 'hooks/useToggle';
import useEvents from 'components/Select/hooks/useEvents';

// types
import {
  GroupedOptionsType,
  OptionType,
  OptionValueType,
  OnChangeType,
} from 'components/Select/Select.types';
import { PopperPlacementType } from '@mui/material/Popper';

// helpers
import transformOptions from './helpers/transformOptions';

// components
import {
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

// styles, assets
import { MuiMultiSelectChips, MuiMultiSelectValueWrapper } from 'components/Select/Select.styles';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

export interface MultiSelectProps<T extends OptionValueType>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange' | 'value' | 'size'> {
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  errorMessage?: string;
  isInvalid?: boolean;
  label?: string;
  options?: OptionType<T>[] | GroupedOptionsType<T>;
  onChange?: OnChangeType<T>;
  value?: T[];
  optionsPlacement?: PopperPlacementType;
  withFilter?: boolean;
  onFilter?: (inputValue?: string) => void;
  filteredOptions?: OptionType<T>[] | GroupedOptionsType<T>;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
}

function MultiSelect<T extends OptionValueType>({
  className,
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  disabled,
  errorMessage,
  isInvalid,
  label,
  onChange,
  options,
  placeholder,
  value,
  fullWidth = true,
  size = 'large',
}: MultiSelectProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLButtonElement>(null);
  const [isOptionsOpen, toggleOptions] = useToggle(false);
  const items = transformOptions(options);

  const handleOnRemoveItem = (selectedItemValue: T | undefined) => {
    if (onChange) {
      const groupedOptions = Array.isArray(options) ? groupBy(options, 'value') : options;
      const flatOptions = ([] as OptionType<T>[]).concat(
        ...Object.values(groupedOptions ?? {}).flatMap((options) => options)
      );
      const selectedItem = flatOptions.find((item) => item.value === selectedItemValue);
      if (selectedItem) {
        const newValue = value?.filter((item) => !isEqual(item, selectedItem.value));
        onChange(selectedItem.value as T, newValue as T[]);
      }
    }
  };

  useEffect(() => {
    if (isInvalid) {
      innerRef?.current?.setCustomValidity('invalid');
    } else {
      innerRef?.current?.setCustomValidity('');
    }
  }, [isInvalid]);

  useEvents(isOptionsOpen, containerRef, toggleOptions, optionsRef);

  const handleChange = (event: SelectChangeEvent<T[]>) => {
    const newSelectedValues = event.target.value as T[];
    if (onChange) {
      const newValue = newSelectedValues.map((selectedValue) => {
        const option = items.find((option) => isEqual(option.value, selectedValue));
        return option ? option.value : selectedValue;
      });
      onChange(newValue[0] as T, newValue as T[]);
    }
  };

  const inputSizeProps = {
    small: { size: 'small', height: 32 },
    medium: { size: 'medium', height: 40 },
    large: { size: 'medium', height: 48 },
  }[size];

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      disabled={disabled}
      error={!!errorMessage}
    >
      <Stack spacing="4px">
        {label && <FormLabel>{label}</FormLabel>}
        <Select
          data-qa-id={dataQaId}
          className={className}
          multiple
          displayEmpty
          value={value || []}
          placeholder={placeholder}
          onChange={(value) => handleChange(value as SelectChangeEvent<T[]>)}
          input={
            <OutlinedInput
              notched={false}
              style={{
                height: value && value?.length >= 2 ? 'auto' : `${inputSizeProps.height}px`,
              }}
              size={size}
              id="select-multiple-chip"
            />
          }
          renderValue={(selected) => {
            if (!selected || (Array.isArray(selected) && selected.length === 0)) {
              return (
                <Typography color="grey.500" variant="labelMedium">
                  {placeholder ?? label}
                </Typography>
              );
            }
            return (
              <MuiMultiSelectValueWrapper>
                {selected.map((value, index) => {
                  const label =
                    items?.find((option: OptionType<T>) => isEqual(option.value as T, value))
                      ?.label || '';

                  if (label) {
                    return (
                      <MuiMultiSelectChips
                        disabled={disabled}
                        key={label + index}
                        label={label}
                        clickable
                        style={{ color: 'red' }}
                        color="primary"
                        sizeprop={size}
                        onDelete={() => handleOnRemoveItem(value)}
                        deleteIcon={
                          <IconButton onMouseDown={(e) => e.stopPropagation()}>
                            <CloseIcon />
                          </IconButton>
                        }
                      />
                    );
                  }
                })}
              </MuiMultiSelectValueWrapper>
            );
          }}
        >
          {items?.map((option: OptionType<T>, index) => (
            <MenuItem key={index} value={String(option.value)} data-qa-options-id={dataQaOptionsId}>
              <Checkbox
                checked={Boolean(value?.find((selectedValue) => selectedValue === option.value))}
              />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </FormControl>
  );
}

export default MultiSelect;
