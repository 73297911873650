import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustodianModalData } from 'shared/Modals/Custodian/CustodianModal';
import { IModalWithData } from 'shared/Modals/types';
import { ProductStatus } from 'utils/types/product';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { apiUrls } from 'utils/constants/apiUrls';
import { createNotification } from 'store/notifications/actions';
import { editEntityApiCall } from 'utils/api/crudActions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { useMutation } from 'react-query';
import { CustomModal } from 'shared/Modals/Modal';

const ActivateCustodianModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const { instrument } = useCurrentInstrument();
  const { custodian } = data?.data as CustodianModalData;
  const activateUriPath = `${apiUrls.product}/custodian-accounts/id=${custodian?._id}/activate`;

  const activateCustodianMutation = useMutation({
    mutationFn: () => {
      return editEntityApiCall(custodian, activateUriPath);
    },
    onSuccess: () => {
      dispatch(
        createNotification({
          message: 'Custodian Account has been activated.',
          title: 'Custodian List Updated',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleSubmit = () => {
    activateCustodianMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="interactive"
        fullWidth
        onClick={handleSubmit}
        type="submit"
        data-qa-id="deleteButton"
        isLoading={activateCustodianMutation.isLoading}
      >
        {instrument?.status === ProductStatus.ACTIVE ? 'Activate and Save' : 'Activate'}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        label={'Custodian Account'}
        title={`Are you sure you want to activate ${custodian?.name}?`}
        footer={Footer}
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};

export default ActivateCustodianModal;
