import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { fetchProductTradingStatus } from 'utils/api/calendars';

const getProductTradingStatusKey = (productId: string, valuationDate: string) => [
  'productTradingStatus',
  productId,
  valuationDate,
];

export function useProductTradingStatus(productId: string, valuationDate: string) {
  const dispatch = useAppDispatch();

  return useQuery(
    getProductTradingStatusKey(productId, valuationDate),
    () => fetchProductTradingStatus(productId, valuationDate),
    {
      onError: (err) => {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error?.message ?? ''), error));
      },
    }
  );
}
