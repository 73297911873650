import { ContactList } from 'utils/types/contacts';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import Button from 'components/Button';

interface ContactsListTableRowProps {
  editAction: () => void;
  editListInfoAction: () => void;
  contactList: ContactList;
  addContactsToListAction: () => void;
  deleteListAction: () => void;
}

const ContactsListTableRow = ({
  editAction,
  contactList,
  editListInfoAction,
  addContactsToListAction,
  deleteListAction,
}: ContactsListTableRowProps) => {
  const actions = contactList._actions;

  return (
    <MuiStyledTableRow data-qa-id={`${contactList.name}-row`} key={contactList._id}>
      <MuiStyledTableCell
        data-qa-id={`${contactList.name}-name`}
        onClick={() => editAction()}
        pointer
      >
        {contactList.name}
      </MuiStyledTableCell>
      <MuiStyledTableCell
        data-qa-id={`${contactList.name}-type`}
        onClick={() => editAction()}
        pointer
        align={'right'}
      >
        {contactList.type}
      </MuiStyledTableCell>
      <MuiStyledTableCell
        data-qa-id={`${contactList.name}-count`}
        onClick={() => editAction()}
        pointer
        align={'right'}
      >
        {contactList.contactsCount}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell data-qa-id="editIcons">
        <TablePopoverMenu id={contactList._id ?? ''} className="actionsCell">
          {actions?.update && (
            <Button
              data-qa-id="editContactsList"
              fullWidth
              variant="ghost"
              onClick={editListInfoAction}
            >
              Edit Distribution List
            </Button>
          )}
          {actions?.assign && (
            <Button
              data-qa-id="addContactToContactsList"
              fullWidth
              variant="ghost"
              onClick={addContactsToListAction}
            >
              Add contact to Distribution List
            </Button>
          )}
          {actions?.delete && (
            <Button
              data-qa-id="deleteContactsList"
              fullWidth
              variant="dangerGhost"
              onClick={deleteListAction}
            >
              Delete Distribution List
            </Button>
          )}
        </TablePopoverMenu>
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};

export default ContactsListTableRow;
