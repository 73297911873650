export enum PusherNotificationSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum PusherNotificationStyle {
  MODAL = 'MODAL',
  POPUP = 'POPUP',
  TOAST = 'TOAST',
}

export enum PusherNotificationTopic {
  GENERAL = 'GENERAL',
  ORDER = 'ORDER',
  ORDER_UPDATE = 'ORDER_UPDATE',
  TRANSACTION = 'TRANSACTION',
  TRANSACTION_UPDATE = 'TRANSACTION_UPDATE',
}
