/* eslint-disable complexity */
import Button from 'components/Button';
import Input from 'components/Input';
import MultiSelect from 'components/Select/MultiSelect';
import ProductSelector from 'components/ProductSelector';
import Select from 'components/Select';
import SingleDatePicker from 'components/DatepickerSingleDate';
import TextArea from 'components/TextArea';
import TokenProductSelector from 'components/TokenProductSelector';
import Upload from 'components/Upload';
import { Checkbox } from 'components/BooleanInputs';
import { Children, FocusEvent, cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { OptionType } from 'components/Select/Select.types';
import { Toggle } from 'components/BooleanInputs';
import { UseFormType } from './useForm.types';
import { format, parse } from 'date-fns';
import { get, omit } from 'lodash';
import { getOptions } from 'components/Form/helpers/selectOptions';
import NewMultiSelect from 'components/NewMultiSelect/NewMultiSelect';

function getFileList(fundIcon: string) {
  const match = fundIcon.match(/\/([^/]+)$/);
  const fileName = match && match[1];

  if (fileName) {
    const newDataTransfer = new DataTransfer();
    const newFile = new File([], fileName);
    newDataTransfer.items.add(newFile);
    return newDataTransfer.files;
  }

  return null;
}

function useForm<T extends FieldValues>({
  control,
  formProps,
  register,
  formState: { errors, isValid },
  onBlur,
  resetField,
}: UseFormType<T>): (children: ReactNode) => ReactNode {
  const processChildren = (children: ReactNode) =>
    Children.map<ReactNode, ReactNode>(children, (child): ReactNode => {
      if (!isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        return cloneElement(child as ReactElement, {
          children: processChildren(child.props.children),
          ...(child.type === Button && {
            disabled: child.props.type !== 'button' && (child.props.disabled || !isValid),
          }),
        });
      }

      const name = child.props.name;

      const sharedProps = {
        label: formProps.labels[name],
        errorMessage: get(errors, `${name}.message`),
        isInvalid: Boolean(get(errors, `${name}`)),
        placeholder: formProps.placeholders[name],
      };

      switch (child.type) {
        case Button:
          return cloneElement(child);
        case MultiSelect: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) =>
                cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  options: child.props.options || formProps.enums[name],
                  onChange: (_: any, values: any) => {
                    onChange(values);
                    onBlur();
                  },
                  value: value,
                })
              }
            />
          );
        }
        case NewMultiSelect: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) =>
                cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  options: child.props.options || formProps.enums[name],
                  onChange: (_: any, values: any) => {
                    onChange(values);
                    onBlur();
                  },
                  value: value,
                })
              }
            />
          );
        }
        case Select: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => {
                const options = getOptions(
                  child,
                  formProps,
                  name,
                  child.props.allCaps,
                  child.props.capitalize
                );

                const filteredOptions = Array.isArray(child.props.excludeOptions)
                  ? options.filter(
                      (option: OptionType<T>) => !child.props.excludeOptions.includes(option.value)
                    )
                  : options;

                return cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  options: filteredOptions,
                  onChange: (value: any) => {
                    onChange(value);
                    onBlur();
                    if (child.props.resetOnChange) {
                      child.props.resetOnChange.forEach((value: Path<T>) => resetField(value));
                    }
                    if (child.props.onChange) {
                      child.props.onChange(value);
                    }
                  },
                  value: value || child.props.value,
                });
              }}
            />
          );
        }
        case ProductSelector: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => {
                return cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  onChange: (value: any) => {
                    onChange(value);
                    if (child.props.resetOnChange) {
                      child.props.resetOnChange.forEach((value: Path<T>) => resetField(value));
                    }

                    onBlur();
                  },
                  value: value,
                });
              }}
            />
          );
        }
        case TokenProductSelector: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => {
                return cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  onChange: (value: any) => {
                    onChange(value);
                    onBlur();
                    if (child.props.resetOnChange) {
                      child.props.resetOnChange.forEach((value: Path<T>) => resetField(value));
                    }
                  },
                  value: value,
                });
              }}
            />
          );
        }
        case Upload: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onBlur: fieldOnBlur, onChange, value } }) => {
                return cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  options: child.props.options || formProps.enums[name],
                  onChange,
                  onBlur: () => {
                    onBlur();
                    fieldOnBlur();
                  },
                  files: typeof value === 'string' ? getFileList(value) : value,
                });
              }}
            />
          );
        }
        case Input: {
          const isNumberType =
            formProps.types[name] === 'number' || formProps.types[name] === 'integer';

          const formFieldProps = register(name, {
            setValueAs: (value: any) => {
              if (isNumberType) {
                return isNumberType && (isNaN(Number(value)) || value === '')
                  ? undefined
                  : Number(value);
              }
              return value;
            },
          });

          if (child.props?.type === 'date') {
            return (
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => {
                  return cloneElement(child, {
                    ...sharedProps,
                    ...child.props,
                    onChange: (event: FocusEvent<HTMLInputElement>) => {
                      const formatedValue =
                        event.target.value &&
                        format(new Date(event.target.value), DEFAULT_DATE_FORMAT);

                      onChange(formatedValue);
                      onBlur();
                      if (child.props.onChange) {
                        child.props.onChange(formatedValue);
                      }
                    },
                    value: value && format(new Date(value), DEFAULT_DATE_FORMAT),
                  });
                }}
              />
            );
          }

          return cloneElement(child, {
            ...omit(formFieldProps, ['onBlur']),
            ...sharedProps,
            ...child.props,
            onBlur: (event: FocusEvent<HTMLInputElement>) => {
              onBlur();
              formFieldProps.onBlur(event);
            },
            onChange: (event: FocusEvent<HTMLInputElement>) => {
              if (child.props.onChange) {
                child.props.onChange(event);
              }
              formFieldProps.onChange(event);
            },
            type: child.props.type || formProps.types[name],
          });
        }
        case TextArea: {
          const formFieldProps = register(name, {
            valueAsNumber:
              formProps.types[name] === 'number' || formProps.types[name] === 'integer',
          });
          return cloneElement(child, {
            ...omit(formFieldProps, ['onBlur']),
            ...sharedProps,
            ...child.props,
            onBlur: (event: FocusEvent<HTMLInputElement>) => {
              onBlur();
              formFieldProps.onBlur(event);
            },
            onChange: (event: FocusEvent<HTMLInputElement>) => {
              if (child.props.onChange) {
                child.props.onChange(event);
              }
              formFieldProps.onChange(event);
            },
            type: child.props.type || formProps.types[name],
          });
        }
        case Toggle: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value: formValue } }) => {
                const value =
                  typeof formValue === 'undefined' ? formProps.defaultValues[name] : formValue;

                return cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  onChange: (value: any) => {
                    onChange(value);
                    onBlur();
                  },
                  ...(child.props.switchLabelWithTitle && {
                    title: sharedProps.label,
                    label: Boolean(value) ? 'Yes' : 'No',
                  }),
                  checked: value,
                });
              }}
            />
          );
        }
        case Checkbox: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value: formValue } }) => {
                const value =
                  typeof formValue === 'undefined' ? formProps.defaultValues[name] : formValue;
                return cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  onChange: (value: any) => {
                    onChange(value);
                    onBlur();
                  },
                  label: child.props.label ?? sharedProps.label,
                  checked: value,
                });
              }}
            />
          );
        }
        case SingleDatePicker: {
          return (
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => {
                return cloneElement(child, {
                  ...sharedProps,
                  ...child.props,
                  onChange: (date: Date) => {
                    onChange(date ? format(date, DEFAULT_DATE_FORMAT) : null);
                    onBlur();
                  },
                  date: value ? parse(value, DEFAULT_DATE_FORMAT, new Date()) : null,
                });
              }}
            />
          );
        }
        default: {
          return child;
        }
      }
    });

  return processChildren;
}

export default useForm;
