import Button from 'components/Button';
import { styled as MuiStyled } from '@mui/material';

interface PartnerActionsTableCellProps {
  nowrap?: boolean;
  padding?: number;
}

export const PartnerActionsTableCell = MuiStyled('td')<PartnerActionsTableCellProps>`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  color: ${({ theme }) => theme.palette.secondary.light}; theme.
  display: flex;
  height: inherit;
  vertical-align: middle;
  text-align: center;
  padding-right: 16px;
  ${({ nowrap }) => nowrap && { 'white-space': 'nowrap' }};
  min-width: 168px;
  margin: auto;
`;

export const StyledButton = MuiStyled(Button)`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
