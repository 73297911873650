import useAppDispatch from 'hooks/useAppDispatch';
import { PartialInstrumentFormProps } from 'pages/Instruments/Instruments.types';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { ProductConstituentAssetsProps, TokenConstituentAssetsProps } from 'utils/types/product';
import { useMutation } from 'react-query';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { createNotification } from 'store/notifications/actions';
import { saveInstrumentApiCall } from 'utils/api/instruments';
import { ProductType } from 'utils/types/product';
import { instrumentsActions } from 'store/instruments/slice';
import { useTokenContext } from 'contexts/tokens';
import { PartialTokenFormProps } from 'pages/Tokens/Tokens.types';
import { patchToken } from 'utils/api/tokens';

export function useCurrentProduct(productType: ProductType, onSuccessCallback?: () => void) {
  const dispatch = useAppDispatch();
  const { instrument } = useCurrentInstrument();
  const { currentToken, setCurrentToken, tokenToFormData } = useTokenContext();
  const { populateCurrentInstrument } = instrumentsActions;
  const etpConstituents =
    (useCurrentInstrumentFormData(CONSTITUENT_STEP) as ProductConstituentAssetsProps) ?? [];
  const tokenConstituents =
    (tokenToFormData(currentToken)[CONSTITUENT_STEP] as TokenConstituentAssetsProps) ?? [];

  const etpMutation = useMutation({
    mutationFn: (instrumentProps: PartialInstrumentFormProps) => {
      return saveInstrumentApiCall(instrument?._actions?.update?.uri ?? '', instrumentProps);
    },
    onSuccess: (res) => {
      dispatch(
        createNotification({
          message: `Product ${res.ticker} has been updated successfully`,
          title: 'Product Updated',
          type: 'success',
        })
      );

      dispatch(populateCurrentInstrument({ ...res }));
      onSuccessCallback && onSuccessCallback(); // close modal
    },
    onError: (err: Error) => {
      dispatch(
        createNotification(
          {
            message: `Error ${err.message ?? ''}`,
            title: 'Asset not added',
            type: 'error',
          },
          err
        )
      );
    },
  });

  const tokenMutation = useMutation({
    mutationFn: (tokenProps: PartialTokenFormProps) => {
      return patchToken(currentToken?._actions?.update?.uri ?? '', tokenProps);
    },
    onSuccess: (res) => {
      dispatch(
        createNotification({
          message: `Product ${res.ticker} has been updated successfully`,
          title: 'Product Updated',
          type: 'success',
        })
      );

      setCurrentToken(res);
      onSuccessCallback && onSuccessCallback(); // close modal
    },
    onError: (err: Error) => {
      dispatch(
        createNotification(
          {
            message: `Error ${err.message ?? ''}`,
            title: 'Asset not added',
            type: 'error',
          },
          err
        )
      );
    },
  });

  if (productType === 'ETP') {
    return {
      product: instrument,
      constituents: etpConstituents,
      etpMutation,
      tokenMutation: null,
    };
  } else {
    return {
      product: currentToken,
      constituents: tokenConstituents,
      etpMutation: null,
      tokenMutation,
    };
  }
}
