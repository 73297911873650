import Button from 'components/Button';
import { TableCell } from 'components/Table';
import { styled as MuiStyled } from '@mui/material';

export const StyledTableCell = MuiStyled(TableCell)`
  padding: 0;
`;

export const StyledButtonWithIcon = MuiStyled(Button)`
  gap: 8px;
  padding: 0 1.5rem;
  svg:not(:only-child):first-child {
    margin-right: 0;
  }
`;

export const MuiStyledButtonWithIcon = MuiStyled(Button)(({ theme }) => ({
  gap: theme.spacers['spacing-16'],
  padding: '0 1.5rem',
  'svg:not(:only-child):first-child': {
    marginRight: 0,
  },
}));

export const StyledProductExchangeTitleTableCell = MuiStyled(TableCell)`
  span {
    margin-right: 16px;
  }
`;
