import { AxiosError } from 'axios';
import Loader from 'components/Loader';
import InvitationLinkErorrPage from 'pages/SetPassword/InvitationLinkError';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { privateWeakRoutesUrls } from 'router/constants';
import { verifyInvitation } from 'utils/api/auth';
import { apiUrls } from 'utils/constants/apiUrls';
import { storeWeakToken } from 'utils/user/storage';
import { SetPasswordFlows } from 'pages/SetPassword/SetPassword.constants';
import isEmpty from 'lodash/isEmpty';

function SetPasswordLandingPage() {
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState<AxiosError>();
  const [loading, setLoading] = useState<boolean>(Boolean(token));
  const tokenRef = useRef<string>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (token && token !== tokenRef.current) {
      tokenRef.current = token;

      let passwordFlow: string;
      let verificationBody: { [key: string]: string } = {};
      let uriTokenVerificationPath: string | undefined;

      if (location.pathname.includes(SetPasswordFlows.resetPasswordFlow)) {
        passwordFlow = SetPasswordFlows.resetPasswordFlow;
        uriTokenVerificationPath = `${apiUrls.verify}${apiUrls.passwordReset}`;
        verificationBody = {
          verificationToken: token,
        };
      } else if (location.pathname.includes(SetPasswordFlows.verifyInvitationFlow)) {
        passwordFlow = SetPasswordFlows.verifyInvitationFlow;
        uriTokenVerificationPath = `${apiUrls.verify}${apiUrls.invitation}`;
        verificationBody = {
          invitationToken: token,
        };
      }

      if (uriTokenVerificationPath && !isEmpty(verificationBody)) {
        verifyInvitation(uriTokenVerificationPath, verificationBody)
          ?.then((response) => {
            storeWeakToken(response.data.weakToken);
            navigate(privateWeakRoutesUrls.setPassword, {
              state: { data: response.data, passwordFlow },
            });
          })
          .catch((err) => {
            setErrorMessage(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [token, navigate, location.pathname]);

  return loading ? (
    <Loader />
  ) : (
    <InvitationLinkErorrPage
      errorMessage={{
        title: errorMessage?.message ?? 'Invalid invitation',
        content: 'The provided invitation is invalid (or has expired)',
      }}
    />
  );
}

export default SetPasswordLandingPage;
