import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import MultiSelect from 'components/Select/MultiSelect';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { COMPANY_CONTACT_TYPE, Contact } from 'utils/types/contacts';
import { Column, Row } from 'components/Grid';
import { ContactModalData } from 'shared/Modals/Contacts/types';
import { CustomModal } from 'shared/Modals/Modal';
import { DEFAULT_PAGE, UNLIMITED_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { StyledFieldsContainer } from 'shared/Modals/Custodian/CustodianModal.styles';
import { contactSchemaUrl } from 'components/Form/formSchemas';
import { createEntityApiCall, editEntityApiCall } from 'utils/api/crudActions';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useMutation } from 'react-query';
import { useContactsListsQuery } from 'pages/Admin/ContactsPage/hooks/api/useContactsListsQuery';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

const EditContactModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const contactData = data.data as ContactModalData;
  const contact = contactData.contact;
  const apiUri = contactData.apiUri;
  const isNew = data?.type === MODAL_ACTIONS.CREATE;
  const isExternalContact =
    contact?.type === COMPANY_CONTACT_TYPE.UNAFFILIATED ||
    apiUri === '/admin/contacts/type=external';
  const isAffiliatedContact =
    contact?.type === COMPANY_CONTACT_TYPE.AFFILIATED || apiUri === '/admin/contacts';

  const { user } = useAuthenticatedUser();
  const company = {
    label: user?.organization.name ?? '',
    value: user?.organization.companyId ?? '',
  };

  const { data: contactsListData, isLoading: isLoadingContactLists } = useContactsListsQuery(
    {
      pageSize: UNLIMITED_ITEMS_PER_PAGE,
      page: DEFAULT_PAGE,
    },
    isExternalContact
  );
  const contactsList = contactsListData?.data;
  const contactListsOptions =
    contactsList?.map((list) => ({ label: list.name, value: list._id })) ?? [];

  const createEditContactMutation = useMutation({
    mutationFn: (contact: Contact) => {
      return isNew
        ? createEntityApiCall<Contact>(
            {
              ...contact,
              email: contact.email.toLowerCase(),
            },
            apiUri
          )
        : editEntityApiCall<Contact>(contact, apiUri);
    },
    onSuccess: (_res, contact) => {
      onCloseModalAction && onCloseModalAction(contact, data?.type);
      dispatch(
        createNotification({
          message: isNew
            ? `Contact ${contact.name ?? ''} has been created.`
            : `Contact ${contact.name ?? ''} has been updated.`,
          title: 'Contact',
          type: 'success',
        })
      );
      closeModal();
    },
    onError: (error: Error) => {
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const handleSubmit = async (updatedContact: Contact) => {
    createEditContactMutation.mutate(updatedContact);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        type="submit"
        fullWidth
        isLoading={createEditContactMutation.isLoading}
      >
        {isNew ? 'Add' : 'Save'}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Form<Contact>
        initialValues={{
          ...contact,
          contactListIds: contact?.contactLists?.map((list) => list.id),
        }}
        onSubmit={handleSubmit}
        schemaUrl={contactSchemaUrl}
      >
        <Card
          footer={Footer}
          label="Contact Info"
          title={isNew ? 'Add New Contact' : 'Edit Contact'}
          onClose={closeModal}
        >
          <StyledFieldsContainer>
            <Row>
              <Column cols={6} md={{ cols: 4 }}>
                <Input data-qa-id="name" data-qa-options-id="name" name="name" />
              </Column>
              <Column cols={6} md={{ cols: 4 }}>
                {isExternalContact && (
                  <MultiSelect
                    data-qa-id="distributionLists"
                    data-qa-options-id="distributionLists"
                    name="contactListIds"
                    label="Distribution Lists"
                    placeholder="Select"
                    options={contactListsOptions}
                    disabled={isLoadingContactLists}
                  />
                )}
                {isAffiliatedContact && (
                  <Select
                    data-qa-id="associatedPartner"
                    data-qa-options-id="associatedPartner"
                    name="associatedPartner"
                    label="Associated Partner"
                    placeholder={company.label}
                    options={[company]}
                    value={company.value}
                    disabled
                  />
                )}
              </Column>
              <Column cols={6} md={{ cols: 4 }}>
                <Input data-qa-id="email" data-qa-options-id="email" name="email" />
              </Column>
              <Column cols={6} md={{ cols: 4 }}>
                <Input data-qa-id="phone" data-qa-options-id="phone" name="phone" />
              </Column>
            </Row>
          </StyledFieldsContainer>
        </Card>
      </Form>
    </CustomModal>
  );
};

export default EditContactModal;
