import Card from '@mui/material/Card';
import { Box, CardContent, CardActions } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { OnCloseContainer } from './NewCard.styles';

interface CardProps extends React.PropsWithChildren {
  classes?: string;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  maxCardHeight?: string;
  maxBodyHeight?: string;
  footerBorder?: boolean;
  onClose?: () => void;
  sx?: {
    [key: string]: any;
  };
  sxBody?: {
    [key: string]: any;
  };
}

export const NewCard = ({
  classes,
  header,
  body,
  footer,
  maxCardHeight,
  maxBodyHeight,
  footerBorder = false, // not needed when wrapping tables so default is false
  onClose,
  sx,
  sxBody,
}: CardProps) => {
  return (
    <Card
      classes={classes}
      sx={{
        maxHeight: maxCardHeight,
        padding: '24px',
        ...sx,
      }}
    >
      {onClose && (
        <OnCloseContainer>
          <CloseIcon />
        </OnCloseContainer>
      )}
      {header && <Box minHeight={'40px'}>{header}</Box>}
      {body && <CardContent sx={{ maxHeight: maxBodyHeight, ...sxBody }}>{body}</CardContent>}
      {footer && (
        <CardActions sx={{ borderTop: footerBorder ? '1.25px solid #98ABB833' : 'none' }}>
          {footer}
        </CardActions>
      )}
    </Card>
  );
};
