import { Box, styled as MuiStyled } from '@mui/material';
import { columnType, numberOfColsTypes, offsetTypes } from 'components/Grid/Grid.types';
import breakPoints from 'theme/utils/breakPoints';

const XS_COLUMNS = 4;
const MD_COLUMNS = 8;
const LG_COLUMNS = 12;
const XL_COLUMNS = 12;
const XXL_COLUMNS = 12;

interface ColumnProps {
  cols?: numberOfColsTypes;
  lg?: columnType;
  md?: columnType;
  offset?: offsetTypes;
  xl?: columnType;
  xs?: columnType;
  xxl?: columnType;
  gutter?:
    | 'all'
    | 'left'
    | 'right'
    | 'noXGutter'
    | 'noYGutter'
    | 'noGutter'
    | 'onlyLeft'
    | 'onlyRight';
}

function getCols(
  breakPointCols: columnType | undefined,
  cols: numberOfColsTypes,
  maxColumns: numberOfColsTypes
): numberOfColsTypes {
  if (breakPointCols && breakPointCols.cols) {
    return breakPointCols.cols;
  }

  return cols > maxColumns ? maxColumns : cols;
}

function getGutter(gutter: string, gutterOption: ColumnProps['gutter'] = 'all') {
  const gutters = {
    all: gutter,
    left: `${gutter} 0 ${gutter} ${gutter}`,
    right: `${gutter} ${gutter} ${gutter} 0`,
    noXGutter: `${gutter} 0`,
    noYGutter: `0 ${gutter}`,
    noGutter: `0`,
    onlyLeft: `0 ${gutter} 0 0`,
    onlyRight: `0 0 0 ${gutter}`,
  };

  return gutters[gutterOption];
}

const Column = MuiStyled(Box)<ColumnProps>`
  // X-Small devices up to 384px
  box-sizing: border-box;
  padding: ${({ theme, gutter }) => getGutter(theme.spacers['spacing-16'], gutter)};
  margin-left: ${({ offset = 0, xs }) => `${(100 / XS_COLUMNS) * (xs?.offset ?? offset)}%`};
  width: ${({ cols = XS_COLUMNS, xs }) => `${(100 / XS_COLUMNS) * getCols(xs, cols, XS_COLUMNS)}%`};

  // Medium devices 736px and up
  @media (min-width: ${() => breakPoints.md}) {
    margin-left: ${({ offset = 0, md }) => `${(100 / MD_COLUMNS) * (md?.offset ?? offset)}%`};
    width: ${({ cols = MD_COLUMNS, md }) =>
      `${(100 / MD_COLUMNS) * getCols(md, cols, MD_COLUMNS)}%`};
  }

  // Large devices 1284px and up
  @media (min-width: ${({ theme }) => breakPoints.lg}) {
    margin-left: ${({ offset = 0, lg }) => `${(100 / LG_COLUMNS) * (lg?.offset ?? offset)}%`};
    width: ${({ cols = LG_COLUMNS, lg }) =>
      `${(100 / LG_COLUMNS) * getCols(lg, cols, LG_COLUMNS)}%`};
  }

  // X-Large devices 1440px and up
  @media (min-width: ${() => breakPoints.xl}) {
    margin-left: ${({ offset = 0, xl }) => `${(100 / XL_COLUMNS) * (xl?.offset ?? offset)}%`};
    width: ${({ cols = XL_COLUMNS, xl }) =>
      `${(100 / XL_COLUMNS) * getCols(xl, cols, XL_COLUMNS)}%`};
  }

  // XX-Large devices 1632px and up
  @media (min-width: ${() => breakPoints.xxl}) {
    margin-left: ${({ offset = 0, xxl }) => `${(100 / XXL_COLUMNS) * (xxl?.offset ?? offset)}%`};
    width: ${({ cols = XXL_COLUMNS, xxl }) =>
      `${(100 / XXL_COLUMNS) * getCols(xxl, cols, XXL_COLUMNS)}%`};
  }
`;

export default Column;
