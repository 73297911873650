import { Instrument } from 'utils/types';
import { NavReviewItem, NavReviewItemsRows, TransactionType } from 'utils/types/nav';

export const filterTableRows = (navItem?: NavReviewItem, product?: Instrument | undefined) => {
  const tableRows = [
    NavReviewItemsRows.NET_ORDERS,
    NavReviewItemsRows.STAKING_REWARDS,
    NavReviewItemsRows.STAKING_FEES,
    NavReviewItemsRows.MANAGEMENT_FEE,
    NavReviewItemsRows.REBOOK_BALANCE_ADJUST,
    NavReviewItemsRows.REBALANCE_TRADES,
  ];

  const hideStaking = !product?.constituentAssets?.find((ca) => ca.isStaking === true);
  const hideRebookAdjustment = !navItem?.transactions?.find(
    (tr) => tr.type === TransactionType.REBOOK_BALANCE_ADJUST
  );

  return tableRows.filter((row) => {
    if (
      hideStaking &&
      [NavReviewItemsRows.STAKING_REWARDS, NavReviewItemsRows.STAKING_FEES].includes(row)
    )
      return false;
    if (hideRebookAdjustment && NavReviewItemsRows.REBOOK_BALANCE_ADJUST === row) return false;
    if (row === NavReviewItemsRows.REBALANCE_TRADES && navItem?.totals?.tradeValue === undefined)
      return false;
    return row;
  });
};
