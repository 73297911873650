import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';

export const tabActive = 'ACTIVE';
export const tabDeactivated = 'DELETED';

export enum WALLETS_TABS_STATUS {
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
}

export enum WalletStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DELETED',
}

export type WalletTabType = typeof tabActive | typeof tabDeactivated;

export const defaultWalletsFiltersData = {
  status: tabActive,
  tab: tabActive,
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_ITEMS_PER_PAGE,
};

export const WALLETS_TABS = [
  [WALLETS_TABS_STATUS.ACTIVE, tabActive],
  [WALLETS_TABS_STATUS.DEACTIVATED, tabDeactivated],
];
