import Notification from 'components/Notification';
import { Link } from 'react-router-dom';
import { RebalanceConstituent, RebalanceWithDetails } from 'utils/types/rebalance';
import {
  StyledMessageWithLink,
  StyledNotificationsWrapper,
} from 'pages/Rebalance/RebalanceDetailsPage/RebalanceDetailsPage.styles';
import { DateTime } from 'utils/datetime';
import { getPartnerDetailsUrl } from 'pages/Instruments/components/Form/utils';
import { WALLETS_INFO_STEP } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, REBALANCE_MODAL } from 'shared/Modals/constants';
import { privateRoutesUrls } from 'router/constants';
import { Typography, useTheme } from '@mui/material';

interface RebalanceNotificationInterface {
  rebalance: RebalanceWithDetails | undefined;
}

export const RebalanceNotification = ({ rebalance }: RebalanceNotificationInterface) => {
  const theme = useTheme();
  const openModal = useAppModal();
  const newConstituents = rebalance?.constituents?.filter((val) => val.toAddNewCrypto);
  const updateWalletAddresses = rebalance?.constituents?.find(
    (val) => val.toAdd || val.toAddNewCrypto
  );
  const openUpdateConstituentModal = (constituent: RebalanceConstituent) => {
    rebalance?.constituents &&
      openModal(
        {
          modalName: REBALANCE_MODAL,
          modalData: {
            data: { rebalance, constituent: constituent },
            type: MODAL_ACTIONS.CUSTOM,
            custom: {
              type: 'missing-constituents',
            },
          },
        },
        {
          onCloseModalAction: () => {},
        }
      );
  };

  return (
    <StyledNotificationsWrapper>
      <>
        {!rebalance?.approvedAt &&
          rebalance?.nextRebalanceDate === new DateTime().toDateString() && (
            <Notification
              messageMaxWidth={true}
              withcountdown="false"
              multiline={'false'}
              title={`Rebalance date is today (${new DateTime().format('dd/MM/yyyy')})`}
              message="Please approve this rebalance by the end of the day"
              variant="warning"
            />
          )}
        {rebalance?.approvedAt && rebalance.trades?.length > 0 && (
          <Notification
            messageMaxWidth={true}
            withcountdown="false"
            multiline={'false'}
            message="Trades have been approved and sent to OMS"
            variant="success"
          />
        )}
        {Boolean(rebalance?.pendingOrders?.length) &&
          !rebalance?.approvedAt &&
          !rebalance?.skipAt && (
            <Notification
              messageMaxWidth={true}
              withcountdown="false"
              multiline={'false'}
              messageComponent={
                <StyledMessageWithLink>
                  {`There are orders for the fund that have not been settled or cancelled. Please settle or cancel all `}
                  <span>
                    <Link to={privateRoutesUrls.dashboardRoutes.orders}>{'orders.'}</Link>
                  </span>
                </StyledMessageWithLink>
              }
              variant="error"
            />
          )}
        {rebalance?.approvedAt && Boolean(updateWalletAddresses) && (
          <Notification
            messageMaxWidth={true}
            withcountdown="false"
            multiline={'false'}
            messageComponent={
              <StyledMessageWithLink>
                {`Please update the `}
                <span>
                  <Link
                    to={getPartnerDetailsUrl(rebalance?.product._id)}
                    state={{ step: WALLETS_INFO_STEP }}
                  >
                    {'Wallet addresses'}
                  </Link>
                </span>{' '}
                for the new constituents. Without these orders cannot be placed post rebalance.
              </StyledMessageWithLink>
            }
            variant="warning"
          />
        )}
        {Boolean(newConstituents?.length) &&
          newConstituents?.map((constituent) => (
            <Notification
              messageMaxWidth={true}
              withcountdown="false"
              multiline={'false'}
              messageComponent={
                <StyledMessageWithLink>
                  {`New Constituent ${constituent.ticker} was added to ETP. Since this asset is being added to ONYX for
                  the first time, please `}
                  <Typography
                    color={theme.palette.blue[600]}
                    onClick={() => openUpdateConstituentModal(constituent)}
                  >
                    {'Specify key information'}
                  </Typography>
                </StyledMessageWithLink>
              }
              variant="warning"
            />
          ))}
      </>
    </StyledNotificationsWrapper>
  );
};
