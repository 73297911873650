import useAppSelector from 'hooks/useAppSelector';
import { ActiveModalsType } from 'shared/Modals/types';

export const useActiveModal = () => {
  return useAppSelector((state) => state.modals.active);
};

export const useActiveModalData = (modalsName?: ActiveModalsType) => {
  return useAppSelector((state) => modalsName && state.modals.modals[modalsName]);
};
