import { ReactComponent as CheckIcon } from 'assets/check.svg';
import {
  StyledActionsWrapper,
  StyledCloseIcon,
} from 'pages/Rebalance/RebalanceDetailsPage/tables/RebalanceDetailsTable/EditableTableCell/EditableTableCell.styles';

interface ActionButtonsWrapperProps {
  onConfirm: () => void;
  onDecline: () => void;
}

export const ActionButtonsWrapper = ({
  onConfirm,
  onDecline,
  ...props
}: ActionButtonsWrapperProps) => {
  return (
    <StyledActionsWrapper {...props}>
      <CheckIcon onClick={onConfirm} fontSize={24} />
      <StyledCloseIcon onClick={onDecline} fontSize={24} />
    </StyledActionsWrapper>
  );
};
