import { Box, useTheme } from '@mui/material';
import { StyledSlider } from './TimeBar.styles';
import { green } from 'theme/colors/baseColors';

export const TimeSlider = ({
  withingWorkingHours,
  workingHoursRangeLength,
  nonWorkingHoursRangeLength,
  sliderValue,
}: {
  withingWorkingHours: Boolean;
  workingHoursRangeLength: number;
  nonWorkingHoursRangeLength: number;
  sliderValue: number;
}) => {
  const theme = useTheme();

  return (
    <>
      {withingWorkingHours ? (
        <StyledSlider
          value={sliderValue}
          slidercolor={green[30]}
          sx={{ width: `${workingHoursRangeLength}%` }}
        />
      ) : (
        <Box width={`${workingHoursRangeLength}%`} bgcolor={green[30]} height={'6px'}></Box>
      )}
      {!withingWorkingHours ? (
        <StyledSlider
          value={sliderValue}
          sx={{ width: `${nonWorkingHoursRangeLength}%` }}
          slidercolor={theme.customPalette.interactive.overlay.primarySelected.pressed}
        />
      ) : (
        <Box
          width={`${nonWorkingHoursRangeLength}%`}
          bgcolor={theme.customPalette.interactive.overlay.primarySelected.pressed}
          height={'6px'}
        ></Box>
      )}
    </>
  );
};
