import { Box, styled as MuiStyled } from '@mui/material';

export const StyledUnderlinedDiv = MuiStyled(Box)`
  text-decoration: underline;
`;

export const StyledFullWalletAddress = MuiStyled(Box)`
  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
`;
