import { Instrument, ProductType, Token } from 'utils/types/product';
import { ETPTableRow } from './rows/ETPTableRow';
import { TokenTableRow } from './rows/TokenTableRow';

interface ProductsTableRowProps {
  productType: ProductType;
  product?: Instrument | Token;
  editAction?: () => void;
  deleteAction?: () => void;
  archiveAction?: () => void;
  activateAction?: (createSeedOrder?: Boolean) => void;
  reactivateAction?: () => void;
  showActionsCell?: boolean;
}

export const ProductsTableRow = ({ product, ...props }: ProductsTableRowProps) => {
  return props.productType === 'ETP' ? (
    <ETPTableRow product={product as Instrument} {...props} />
  ) : (
    <TokenTableRow product={product as Token} {...props} />
  );
};
