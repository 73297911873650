import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { City, Country } from 'utils/types/location';

export const fetchCountries = async () => {
  try {
    return await axiosInstance.get<Country[]>('location/country');
  } catch (err) {
    return errorHandler(err);
  }
};

export const fetchCities = async (id?: number) => {
  try {
    return await axiosInstance.get<City[]>(`/location/country/id=${id}/city`);
  } catch (err) {
    return errorHandler(err);
  }
};
