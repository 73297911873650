import { useEffect, useState } from 'react';
import { intervalToDuration, formatDuration } from 'date-fns';

// components
import Notification from 'components/Notification';
import { StyledCutoffContainer } from 'pages/Orders/Orders/Order/Order.styles';

const ONE_MINUTE = 1000 * 60;

interface CutoffInfoProps {
  deliveryType: string;
  isClosed: boolean;
  opensAt: Date;
}

const CutoffInfo = ({ isClosed, opensAt, deliveryType }: CutoffInfoProps) => {
  const [interval, setIntervalMessage] = useState(
    formatDuration(intervalToDuration({ start: new Date(), end: opensAt }))
  );

  useEffect(() => {
    if (isClosed) {
      const countdown = setInterval(() => {
        setIntervalMessage(formatDuration(intervalToDuration({ start: new Date(), end: opensAt })));
      }, ONE_MINUTE);

      return () => clearInterval(countdown);
    }
  }, [isClosed, opensAt]);

  return isClosed ? (
    <StyledCutoffContainer>
      <Notification
        title={`${deliveryType} order market opens in ${interval}`}
        variant="warning"
        withcountdown="false"
      />
    </StyledCutoffContainer>
  ) : null;
};

export default CutoffInfo;
