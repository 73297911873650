import { useNavigate } from 'react-router-dom';
import { Token } from 'utils/types/product';
import { privateRoutesUrls } from 'router/constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ProductStatus } from 'utils/types/product';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import {
  warningNotification,
  successNotification,
} from 'shared/Notifications/general.notifications';
import { TOKENS_TAB_STATUS } from 'pages/Tokens/Tokens.constants';
import { getQueryString } from 'hooks/useUrlParams';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, TOKEN_MODAL } from 'shared/Modals/constants';
import { useMutation } from 'react-query';
import { submitTokenForApproval } from 'utils/api/tokens';

function useOnSubmit(token: Token | null) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openModal = useAppModal();

  const submittokenApprovalMutation = useMutation({
    mutationFn: (token: Token) => {
      return submitTokenForApproval(token._id);
    },
    onSuccess: (res) => {
      dispatch(
        createNotification(
          successNotification(
            `Token details have been submitted and saved`,
            `Product ${res.ticker} saved to Pending Review`
          )
        )
      );
      navigate(
        privateRoutesUrls.dashboardRoutes.tokens +
          `?${getQueryString({
            tab: TOKENS_TAB_STATUS.IN_REVIEW,
            page: DEFAULT_PAGE,
            pageSize: DEFAULT_ITEMS_PER_PAGE,
            status: ProductStatus.IN_REVIEW,
          })}`
      );
    },
    onError: (err: Error, data) => {
      dispatch(
        createNotification(
          warningNotification(
            err.message || `Product ${data.ticker} couldn't be submitted`,
            'Products'
          )
        )
      );
    },
  });

  const onActivateToken = async () => {
    if (token?._id) {
      if (token?._id) {
        openModal({
          modalName: TOKEN_MODAL,
          modalData: {
            data: token,
            type: MODAL_ACTIONS.CREATE,
            custom: {
              isToken: true,
            },
          },
        });
      }
    }
  };

  const onSubmitForApproval = async () => {
    if (token?._id) {
      submittokenApprovalMutation.mutate(token);
    }
  };

  return { onSubmitForApproval, onActivateToken };
}

export default useOnSubmit;
