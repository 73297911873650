import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from 'store';
import { resetIdletime } from 'store/idleTime/slice';
import { getAuthorizationHeader, logout } from 'utils/user/storage';
import { apiUrls } from 'utils/constants/apiUrls';
import { UNAUTHORIZED_STATUS_CODE } from 'utils/constants/numbers';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config && config.headers) {
    const authorizationHeader = getAuthorizationHeader();

    if (authorizationHeader) {
      config.headers.Authorization = authorizationHeader;
    }
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    store.dispatch(resetIdletime());
    return response;
  },
  (reject: AxiosError) => {
    if (
      reject.response?.status === UNAUTHORIZED_STATUS_CODE &&
      reject.response?.config.url !== apiUrls.authLogin
    ) {
      logout();
    } else {
      store.dispatch(resetIdletime());
    }

    return Promise.reject(reject);
  }
);
