import { createAsyncThunk } from '@reduxjs/toolkit';
import { debounce } from 'lodash';

// store
import { AppDispatch, RootState } from 'store';

// actions
import { createNotification } from 'store/notifications/actions';

// types
import { Instrument, SaveProductDraftOptions } from 'utils/types';
import { PartialInstrumentFormProps } from 'pages/Instruments/Instruments.types';
import { ProductStatus } from 'utils/types/product';

// helpers
import { errorNotification } from 'shared/Notifications/general.notifications';

// api
import {
  loadInstrument as loadInstrumentRequest,
  loadInstrumentCustodians as loadInstrumentCustodiansRequest,
  loadInstrumentWallets as loadInstrumentWalletsRequest,
  createInstrument as createInstrumentRequest,
  saveInstrumentApiCall as saveInstrumentDraftRequest,
} from 'utils/api/instruments';

export const loadInstrument = createAsyncThunk<
  Instrument,
  { id: string; withWallets?: boolean; withCustodians?: boolean },
  { rejectValue: string }
>('intruments/loadInstrument', async ({ id, withWallets, withCustodians }, { rejectWithValue }) => {
  try {
    const instrument = await loadInstrumentRequest(id, {
      withWallets,
      withCustodians,
    });
    return instrument;
  } catch (err) {
    const error = err as Error;
    return rejectWithValue(error.message);
  }
});

export const loadInstrumentCustodians = createAsyncThunk<
  Instrument['custodianAccounts'],
  string | undefined,
  { rejectValue: string }
>('intruments/loadInstrumentCustodians', async (id, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const { currentInstrument } = state.instruments;
    const instrumentId = id || currentInstrument?._id;
    if (instrumentId) {
      const custodians = await loadInstrumentCustodiansRequest(instrumentId);
      return custodians;
    } else {
      return rejectWithValue('No instrument set to load custodians');
    }
  } catch (err) {
    const error = err as Error;
    return rejectWithValue(error.message);
  }
});

export const loadInstrumentWallets = createAsyncThunk<
  Pick<Instrument, 'custodianWallets' | 'unifiedWallets'>,
  string | undefined,
  { rejectValue: string }
>('intruments/loadInstrumentWallets', async (id, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const { currentInstrument } = state.instruments;
    const instrumentId = id || currentInstrument?._id;
    if (instrumentId) {
      const wallets = await loadInstrumentWalletsRequest(instrumentId);
      return wallets;
    } else {
      return rejectWithValue('No instrument set to load wallets');
    }
  } catch (err) {
    const error = err as Error;
    return rejectWithValue(error.message);
  }
});

export const saveInstrumentDraftThunk = createAsyncThunk<
  Instrument,
  { id?: Instrument['_id']; instrumentProps: PartialInstrumentFormProps },
  { rejectValue: string }
>(
  'intruments/saveInstrumentDraft',
  async ({ id, instrumentProps }, { dispatch, rejectWithValue }) => {
    try {
      let instrumentId = id;
      if (!instrumentId) {
        const newInstrument = await createInstrumentRequest({
          name: instrumentProps['General Details']?.name,
          productType: instrumentProps['General Details']?.productType,
          ticker: instrumentProps['General Details']?.ticker,
        });
        instrumentId = newInstrument._id;
        return newInstrument;
      }
      const instrument = await saveInstrumentDraftRequest(
        `/products/id=${instrumentId}`,
        instrumentProps
      );
      if (instrument.status === ProductStatus.ACTIVE) {
        dispatch(
          createNotification({
            message: `Product ${instrument.ticker} has been updated successfully`,
            title: 'Product Updated',
            type: 'success',
          })
        );
      }
      return instrument;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
      return rejectWithValue(error.message);
    }
  }
);

const DEBOUNCE_WAIT = 500;

const debouncedSaveInstrumentDraftThunk = debounce(
  async (
    instrumentProps: PartialInstrumentFormProps,
    dispatch: AppDispatch,
    options: SaveProductDraftOptions
  ) => {
    const { id, loadCustodians, loadWallets } = options;
    await dispatch(saveInstrumentDraftThunk({ id, instrumentProps }));
    const loadCustodiansAction = loadCustodians ? dispatch(loadInstrumentCustodians(id)) : null;
    const loadWalletsAction = loadWallets ? dispatch(loadInstrumentWallets(id)) : null;

    await Promise.all([loadCustodiansAction, loadWalletsAction]);
  },
  DEBOUNCE_WAIT
);

export const saveInstrumentAction =
  (instrumentProps: PartialInstrumentFormProps, options: SaveProductDraftOptions) =>
  (dispatch: AppDispatch) =>
    debouncedSaveInstrumentDraftThunk(instrumentProps, dispatch, options);
