import { outlinedInputClasses, formHelperTextClasses, formLabelClasses } from '@mui/material';
import { Theme } from '@mui/material/styles';

export function form(theme: Theme) {
  const form = theme.customComponents.form;

  return {
    // Form labels
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: form.default.colorLabel,
          ...theme.typography.labelMedium,
          [`&.${formLabelClasses.focused}`]: {
            color: form.default.colorLabel,
          },
        },
      },
    },
    // Input field borders
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${form.default.borderColorHover}`,
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${form.active.borderColor}`,
            },
          },
          [`&.${outlinedInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${form.error.borderColor}`,
            },
          },
          [`&.${outlinedInputClasses.disabled}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${form.disabled.borderColor}`,
            },
          },
        },
        input: {
          ...theme.typography.labelMedium,
          lineHeight: 'none', // ensure copy on Select components is centered aligned
          '&::placeholder': {
            ...theme.typography.labelMedium,
          },
        },
      },
    },
    // Form error messages
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          [`&.${formHelperTextClasses.error}`]: {
            color: form.error.color,
          },
        },
      },
    },
  };
}
