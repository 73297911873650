import { ActivateProductFromSeedModal } from './ActivateProduct/ActivateProductFromSeedModal';
import { ActivateProductModal } from './ActivateProduct/ActivateProductModal';
import { ArchiveProductModal } from './ArchiveProduct/ArchiveProductModal';
import { CreateETPSeedOrderModal } from './CreateETPSeedOrder/CreateETPSeedOrder';
import { DeactivateProductModal } from './DeleteProduct/DeactivateProductModal';
import { IModalWithData } from 'shared/Modals/types';
import { ProductStatus, ProductType } from 'utils/types/product';
import { ReactNode } from 'react';

export const ACTIVATE_ETP_MODAL_SEED = 'seed';

interface ProductModalProps extends IModalWithData {
  productType: ProductType;
}

export const ProductModal = ({ ...props }: ProductModalProps) => {
  const DeleteComponent =
    props.data.custom?.type === ProductStatus.ARCHIVED ? (
      <ArchiveProductModal {...props} />
    ) : (
      <DeactivateProductModal {...props} />
    );

  const CreateComponent =
    props.data.custom?.type === ACTIVATE_ETP_MODAL_SEED ? (
      <ActivateProductFromSeedModal {...props} />
    ) : (
      <ActivateProductModal {...props} />
    );

  const ProductModals: Record<string, ReactNode> = {
    CREATE: CreateComponent,
    DELETE: DeleteComponent,
    CUSTOM: <CreateETPSeedOrderModal {...props} />,
  };

  return <>{ProductModals[props.data.type]}</>;
};
