import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';
import { CreateApiKeyModal } from './CreateApiKeyModal';
import { DeleteApiKeyModal } from './DeleteApiKeyModal';

export const ApiKeyModal = ({ ...props }: IModalWithData) => {
  const ApiKeyModals: Record<string, ReactNode> = {
    CREATE: <CreateApiKeyModal {...props} />,
    DELETE: <DeleteApiKeyModal {...props} />,
  };

  return <>{ApiKeyModals[props.data.type]}</>;
};
