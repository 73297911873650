import { DefaultValues } from 'react-hook-form';
import { FC } from 'react';
import { useInstrumentStepFormData } from 'store/instruments/selectors';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';
import { PartnerType } from 'utils/types/partner';
import { PARTNERS_STEP } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import {
  CompanyRole,
  PartnersStepDataProps,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import { instrumentPartnerSchemaUrl } from 'components/Form/formSchemas';
import Footer from 'pages/Instruments/components/Footer';
import Form from 'components/Form/Form';
import MultiSelect from 'components/Select/MultiSelect';
import Select from 'components/Select';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';

import { CompanySuggestionDto } from 'utils/types';

interface PartnersStepProps {
  onSubmit: (data: PartnersStepDataProps) => void;
}

function mapOptions(partners?: CompanySuggestionDto[]) {
  return partners?.map((partner) => ({
    label: partner.name,
    value: partner._id,
  }));
}

function filterAndMapOptions(
  types: PartnerType[],
  partners: CompanySuggestionDto[] | undefined,
  role?: CompanyRole
) {
  return mapOptions(
    partners?.filter(
      (partner) => types.includes(partner.type) && (!role || partner.roles?.includes(role))
    )
  );
}

export const Partners: FC<PartnersStepProps> = ({ onSubmit }: PartnersStepProps) => {
  const { data: partners, isLoading } = useCompaniesSuggestion();
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(PARTNERS_STEP) as PartnersStepDataProps;
  const saveValues = (data: DefaultValues<PartnersStepDataProps>) => {
    saveInstrumentDraft({
      [PARTNERS_STEP]: data as PartnersStepDataProps,
    });
  };

  const issuersOptions = filterAndMapOptions([PartnerType.ISSUER], partners);
  const technicalListingAgentOptions = filterAndMapOptions(
    [PartnerType.TECHNICAL_LISTING_AGENT],
    partners
  );

  const authorizedParticipantsOptions = filterAndMapOptions(
    [PartnerType.AUTHORIZED_PARTICIPANT],
    partners
  );

  const pcfUploaderOptions = filterAndMapOptions(
    [PartnerType.CALCULATING_AGENT, PartnerType.FUND_ACCOUNTANT],
    partners,
    CompanyRole.IS_PCF_UPLOADER
  );

  const fundAccountantAgentsOptions = filterAndMapOptions([PartnerType.FUND_ACCOUNTANT], partners);

  return (
    <Form<PartnersStepDataProps>
      initialValues={formData}
      onChange={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentPartnerSchemaUrl}
    >
      <FormGridContainer>
        <FormGridItem>
          <ProductConfigHeader title="Partner Details" />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <ETPStepSaveProgress />
        </FormGridItem>
        <FormGridItem>
          <Select
            disabled={isLoading}
            name="issuer"
            options={issuersOptions}
            data-qa-id="issuer"
            data-qa-options-id="issuer"
            capitalize={false}
          />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            data-qa-id="pcfLoaders"
            data-qa-options-id="pcfLoaders"
            disabled={isLoading}
            name="pcfUploaders"
            options={pcfUploaderOptions}
          />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            data-qa-id="authorizedParticipants"
            data-qa-options-id="authorizedParticipants"
            disabled={isLoading}
            name="authorizedParticipants"
            options={authorizedParticipantsOptions}
          />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            disabled={isLoading}
            name="custodians"
            data-qa-id="custodians"
            data-qa-options-id="custodians"
          />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            disabled={isLoading}
            name="calculationAgents"
            options={fundAccountantAgentsOptions}
            data-qa-id="calculatingAgents"
            data-qa-options-id="calculatingAgents"
          />
        </FormGridItem>
        <FormGridItem>
          <Select
            disabled={isLoading}
            name="technicalListingAgent"
            options={technicalListingAgentOptions}
            data-qa-id="technicalListingAgent"
            data-qa-options-id="technicalListingAgent"
            capitalize={false}
          />
        </FormGridItem>
      </FormGridContainer>
      <Footer isFirstStep />
    </Form>
  );
};
