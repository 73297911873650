function dispatchFilesChange(ref: React.RefObject<HTMLInputElement>, filesList: DataTransfer) {
  // Not currently testable because of https://github.com/jsdom/jsdom/issues/1272#issuecomment-486088445
  const nativeValueSetter = Object.getOwnPropertyDescriptor(
    HTMLInputElement.prototype,
    'files'
  )?.set;

  nativeValueSetter?.call(ref.current, filesList.files);

  var eventTrigger = new Event('change', { bubbles: true });
  ref.current?.dispatchEvent(eventTrigger);
}

export default dispatchFilesChange;
