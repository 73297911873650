import Loader from 'components/Loader';
import OrderDetails from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails';
import React, { useCallback } from 'react';
import downloadOrderCsv from 'utils/orders/downloadOrderCsv';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { StyledOrderDetailsPageContainer } from 'pages/Orders/Orders/Order/Order.styles';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { getCurrentTokenOrderQueryKey } from 'utils/constants/reactQueries';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCurrentTokenOrder } from 'pages/Orders/Orders/Order/hooks/useCurrentOrder';
import { useOrdersActions } from 'pages/Orders/Orders/hooks/useOrdersActions/useOrdersActions';
import { useParams } from 'react-router-dom';
import { usePusherInitializer } from 'hooks/usePusherInitializer';

export const OrderTokenView = () => {
  const { id: orderId } = useParams();
  const { user } = useAuthenticatedUser();

  const { isLoading: loading, data: order, refetch } = useCurrentTokenOrder(orderId!);

  const { downloadPdf } = useOrdersActions(orderId);

  const handleOrderUpdate = useCallback(() => {
    console.log(`Order ${orderId} needs to be refreshed`);
    refetch().then(() => {
      console.log(`Order ${orderId} has been refreshed`);
    });
  }, [orderId, refetch]);

  usePusherInitializer(handleOrderUpdate);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <TitleArea
        title={<OrderPageHeader productType="Token" order={order} />}
        showBackButton={true}
      />
      {order && (
        <StyledOrderDetailsPageContainer>
          <OrderDetails
            productType="Token"
            loading={loading}
            user={user}
            order={order}
            orderFlow="confirm"
            downloadCsv={() => downloadOrderCsv(order, user!, 'Token')}
            downloadPdf={() => downloadPdf(order)}
            refreshOrderQueryKey={getCurrentTokenOrderQueryKey}
          />
        </StyledOrderDetailsPageContainer>
      )}
    </>
  );
};
