import { ExchangeLocalTickerType } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';

interface ExchangeLocalTickerProps {
  localTicker: ExchangeLocalTickerType;
  onEdit: (localTicker: ExchangeLocalTickerType) => void;
  onDelete: (_?: any) => void;
}

function ExchangeLocalTicker({ localTicker, onEdit, onDelete }: ExchangeLocalTickerProps) {
  return (
    <MuiStyledTableRow
      onClick={(event) => {
        onEdit(localTicker);
        event.stopPropagation();
      }}
    >
      <MuiStyledTableCell width="29%">{localTicker.name}</MuiStyledTableCell>
      <MuiStyledTableCell width="28%">{localTicker.currency}</MuiStyledTableCell>
      <MuiStyledTableCell width="29%">{localTicker.listingDate}</MuiStyledTableCell>
      <MuiStyledActionsTableCell
        width="8%"
        onClick={(event: { stopPropagation: () => void }) => {
          event?.stopPropagation();
        }}
      >
        <TrashIcon type="button" onClick={onDelete} className="editIcons" />
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
}

export default ExchangeLocalTicker;
