// hooks
import { useLocation, useNavigate } from 'react-router-dom';

// styles, assets
import { StyledLoginContainer, StyledLoginLogoWrapper } from 'pages/Login/Login.styles';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { useState } from 'react';
import { StyledUpdatePasswordContentWrapper } from 'pages/Login/UpdatePassword/UpdatePassword.styles';
import { getInitialScreenBasedOnFlow } from 'pages/Login/UpdatePassword/UpdatePassword.utils';

//components
import { RecommendToUpdatePasswordScreen } from 'pages/Login/UpdatePassword/components/RecommendToUpdatePasswordScreen';
import { EmailForResetLink } from 'pages/Login/UpdatePassword/components/EmailForResetLink';
import { LoginFlows } from 'pages/Login/UpdatePassword/UpdatePassword.interfaces';

type TwoFaFlowScreens = 'RECOMMEND_TO_UPDATE_PASSWORD_SCREEN' | 'EMAIL_FOR_RESET_LINK_SCREEN';

export const UpdatePassword = () => {
  const location = useLocation();
  let flow: LoginFlows = 'FORGOT_PASSWORD';
  let loginEmailValue: string | undefined;
  const lastLogin: string | boolean = false;
  if (location?.state) {
    loginEmailValue = location.state.loginEmailValue;
    flow = location.state.flow;
  }
  const navigate = useNavigate();
  const [updatePasswordFlowScreen, setUpdatePasswordFlowScreen] = useState<TwoFaFlowScreens>(
    getInitialScreenBasedOnFlow(flow)
  );

  const handleSkip = () => {
    navigate('/', { replace: true });
  };

  const UpdatePasswordScreens = {
    RECOMMEND_TO_UPDATE_PASSWORD_SCREEN: (
      <RecommendToUpdatePasswordScreen
        handleNext={() => setUpdatePasswordFlowScreen('EMAIL_FOR_RESET_LINK_SCREEN')}
        handleSkip={handleSkip}
        firstLogin={!lastLogin}
      />
    ),
    EMAIL_FOR_RESET_LINK_SCREEN: <EmailForResetLink email={loginEmailValue} />,
  };

  return (
    <StyledLoginContainer>
      <StyledUpdatePasswordContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        {UpdatePasswordScreens[updatePasswordFlowScreen]}
      </StyledUpdatePasswordContentWrapper>
    </StyledLoginContainer>
  );
};
