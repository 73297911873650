import ProductIcon from 'components/ProductIcon';
import { ReactNode } from 'react';

function renderFileNames(files: FileList): string {
  return Array.from(files || [])
    .map((file) => file.name)
    .join(',');
}

const specialRenders: { [fieldName: string]: (value: any) => string } = {
  fundIcon: renderFileNames,
};

function renderFieldValue(
  value: any,
  fieldName?: string,
  productName?: string
): string | ReactNode {
  if (fieldName && specialRenders[fieldName]) {
    return <ProductIcon ticker={productName ?? 'NA'} iconUrl={value} size={'24px'} />;
  }

  if (Array.isArray(value)) {
    return value.map((v) => renderFieldValue(v)).join(',');
  }

  if (typeof value === 'boolean') {
    return value ? 'True' : 'False';
  }

  return value && typeof value === 'object' ? value.value : value;
}

export default renderFieldValue;
