import { ChipProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { defaultChip } from './variants/defaultChip';
import { greenChip } from './variants/greenChip';
import { redChip } from './variants/redChip';
import { blueChip } from './variants/blueChip';
import { yellowChip } from './variants/yellowChip';
import { inverseChip } from './variants/inverseChip';

export function chip(theme: Theme) {
  return {
    MuiChip: {
      variants: [
        ...defaultChip(theme),
        ...greenChip(theme),
        ...redChip(theme),
        ...blueChip(theme),
        ...yellowChip(theme),
        ...inverseChip(theme),
      ],
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ChipProps & { expandable?: boolean } }) => ({
          borderRadius: '6px',
          display: 'inline-flex',
          padding: '6px 8px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          '& .MuiChip-label': {
            paddingRight: ownerState.expandable ? 0 : theme.spacers['spacing-12'],
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '100%',
          },
        }),
      },
    },
  };
}
