import yesOrNo from 'utils/decorators/yesOrNo';
import { ConstituentAssetType } from 'utils/types/product';

function renderConstituentValue(constituent: ConstituentAssetType): string {
  const staking = constituent.isStaking ? constituent.stakingFee : 'no';
  return [
    constituent.name,
    `Staking: ${staking}`,
    `Interest: ${yesOrNo(constituent.interestGenerating)}`,
    `Leveraged: ${yesOrNo(constituent.isLeveraged)}`,
    `Leveraged ratio: ${constituent.leverageRatio}`,
  ].join(', ');
}

export default renderConstituentValue;
