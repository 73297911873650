import Box from '@mui/material/Box';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { Grid, Typography } from '@mui/material';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { StockSplit } from 'pages/Orders/Register/types';
import { StyledButtonRow } from 'pages/Orders/Register/Register.styles';

export interface HistoricalStockSplitsProps {
  splits?: StockSplit[];
  addStockSplitButton?: JSX.Element;
}

const HistoricalStockSplitsColumnsConfig = [
  { label: 'Date', propName: 'date', propType: 'string', sort: false },
  {
    label: 'Split ratio',
    propName: 'splitRatio',
    propType: 'string',
    sort: false,
  },
  {
    label: 'Shares before stock split',
    propName: 'sharesBeforeStockSplit',
    propType: 'string',
    sort: false,
  },
  {
    label: 'Shares after stock split',
    propName: 'sharesAfterStockSplit',
    propType: 'string',
    sort: false,
  },
];

export const HistoricalStockSplits = ({
  splits,
  addStockSplitButton,
}: HistoricalStockSplitsProps) => {
  return (
    <Box component="section">
      <Table
        toolbarTools={[
          <>
            <Grid marginTop={1} marginBottom={1} container display={'flex'} alignItems={'center'}>
              <Grid item xs={12} md={6}>
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <Typography color="#3B4853" variant="subheadingMedium">
                    Historical Stock Splits
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} display={'flex'} justifyContent={'flex-end'}>
                {addStockSplitButton && <StyledButtonRow>{addStockSplitButton}</StyledButtonRow>}
              </Grid>
            </Grid>
          </>,
        ]}
      >
        <TableHeaderWithMultiSort
          columns={HistoricalStockSplitsColumnsConfig}
          columnAlignment={{
            date: 'left',
            splitRatio: 'right',
            sharesBeforeStockSplit: 'right',
            sharesAfterStockSplit: 'right',
          }}
        />
        <TableBodyWithStates
          hasContent={Boolean(splits?.length)}
          noContentLabel="No entries at the moment."
        >
          {splits?.map((entry, index) => (
            <MuiStyledTableRow key={entry.date + index}>
              <MuiStyledTableCell align="left">
                {renderOrderValue(Boolean(entry.date), entry.date)}
              </MuiStyledTableCell>
              <MuiStyledTableCell align="right">{entry.splitRatio}</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(entry.numberOfSharesBefore), entry.numberOfSharesBefore)}
              </MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(entry.numberOfSharesAfter), entry.numberOfSharesAfter)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        </TableBodyWithStates>
      </Table>
    </Box>
  );
};
