import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { Exchange } from 'utils/types';
import { ExchangeForm } from 'shared/Modals/Exchange/ExchangeForm';
import { ExchangeFormDataModel } from 'pages/Admin/ExchangesPage/utils';
import { IModalWithData } from 'shared/Modals/types';
import { createNotification } from 'store/notifications/actions';
import { editExchange } from 'utils/api/exchanges';
import { exchangeEditNotifications } from 'shared/Notifications/exchange.notifications';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useCalendars } from 'pages/Admin/CalendarsPage/hooks/useCalendars';
import { useMutation } from 'react-query';

const UpdateExchangeModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const permissions = useUserPermissions();
  const loggedInUser = useAuthenticatedUser();
  const { calendars, loading } = useCalendars(
    loggedInUser.user?.organization.companyId,
    permissions?.canViewSystemCalendar
  );
  const exchange = data?.data as Exchange;

  const editExchangeMutation = useMutation({
    mutationFn: (exchangeFormData: ExchangeFormDataModel) =>
      editExchange(exchange?._id ?? '', exchangeFormData),
    onSuccess: (data) => {
      dispatch(createNotification(exchangeEditNotifications.success(data?.name)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(exchangeEditNotifications.error(error.message), error));
    },
  });

  const isLoading = loading || editExchangeMutation.isLoading;

  return (
    <CustomModal open onCloseModal={closeModal}>
      <ExchangeForm
        calendars={calendars}
        exchange={exchange}
        loading={isLoading}
        isUpdating={editExchangeMutation.isLoading}
        onCancel={closeModal}
        onSubmit={editExchangeMutation.mutate}
      />
    </CustomModal>
  );
};

export default UpdateExchangeModal;
