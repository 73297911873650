import { Box, styled as MuiStyled } from '@mui/material';

export const StyledLoader = MuiStyled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  textAlign: 'center',
}));

export const StyledTooltip = MuiStyled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
});

export const StyledFormWrapper = MuiStyled('form')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledFormLoader = MuiStyled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;
