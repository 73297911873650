import { privateDashboardRoutes, privateRoutesUrls } from 'router/constants';
import { AppRoute } from './types';
import { PartnerType } from 'utils/types/partner';
import { User } from 'utils/types';

export const filterRoutesBasedOnPermissionsAndFeatureFlags = (
  permissions: { [key: string]: boolean } = {}
) =>
  privateDashboardRoutes
    .filter(
      (element) =>
        !element.permissions?.length ||
        element.permissions?.reduce((result, permission) => {
          return result && permissions[permission];
        }, true)
    )
    .filter((element) => !element.featureFlag);

export const customFilterBasedOnCompanyType = (user: User, filteredRoutes: AppRoute[]) => {
  if (
    user?.organization.type === PartnerType.AUTHORIZED_PARTICIPANT ||
    user?.organization.type === PartnerType.AUTHORIZED_MERCHANT ||
    user?.organization.type === PartnerType.FUND_ACCOUNTANT ||
    user?.organization.type === PartnerType.TECHNICAL_LISTING_AGENT ||
    user?.organization.type === PartnerType.ISSUER
  ) {
    return filterDashboard(filteredRoutes) ?? [];
  }
  return filteredRoutes;
};

const filterDashboard = (routes: AppRoute[]) => {
  return routes.filter((route) => route.path !== privateRoutesUrls.dashboardRoutes.dashboard);
};
