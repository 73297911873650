import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { instrumentsActions } from 'store/instruments/slice';
import { createNotification } from 'store/notifications/actions';
import { loadInstrumentWallets } from 'utils/api/instruments';
import { instrumentWalletsQuery } from 'utils/constants/reactQueries';
import { WalletStatus } from 'utils/types/wallets';

export const useInstrumentWalletsQuery = (instrumentId: string, walletsStatus?: string) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [instrumentWalletsQuery, instrumentId],
    async () => {
      try {
        const res = await loadInstrumentWallets(instrumentId, walletsStatus);
        const activeCustodianWallets = res?.custodianWallets?.filter(
          (wallet) => wallet.status === WalletStatus.ACTIVE
        );
        const activeUnifiedWallets = res?.unifiedWallets?.filter(
          (wallet) => wallet.status === WalletStatus.ACTIVE
        );
        const activeWallets = {
          custodianWallets: activeCustodianWallets,
          unifiedWallets: activeUnifiedWallets,
        };
        dispatch(instrumentsActions.populateInstrumentWallets(activeWallets));
        return res;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(instrumentId),
    }
  );
};
