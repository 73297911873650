import useAppSelector from 'hooks/useAppSelector';
import {
  InstrumentFormDataStepType,
  PartialInstrumentFormProps,
} from 'pages/Instruments/Instruments.types';
import {
  EtpDetailsStepType,
  GENERAL_DETAILS_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import {
  PARTNERS_STEP,
  ETPPartnerDetailsStepType,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { diffFields } from 'pages/Instruments/helpers/getDiffFields';
import parseInstrumentToFormData from 'pages/Instruments/helpers/parseInstrumentToFormData';

export function useCurrentInstrument() {
  return useAppSelector(({ instruments }) => ({
    error: instruments.error,
    instrument: instruments.currentInstrument,
    loading: instruments.loading,
    loadingCustodians: instruments.loadingCustodians,
    loadingWallets: instruments.loadingWallets,
    saving: instruments.saving,
  }));
}

export function useCurrentInstrumentFormData(step?: InstrumentFormDataStepType) {
  return useAppSelector(({ instruments }) => {
    return step
      ? parseInstrumentToFormData(instruments.currentInstrument)[step]
      : parseInstrumentToFormData(instruments.currentInstrument);
  });
}

export function useDirtyInstrumentFormData(step?: EtpDetailsStepType | ETPPartnerDetailsStepType) {
  return useAppSelector(({ instruments }) => {
    if (instruments.currentDirtyInstrumentFormData)
      return step
        ? instruments.currentDirtyInstrumentFormData[step]
        : instruments.currentDirtyInstrumentFormData;
    return null;
  });
}

export function useInstrumentStepFormData(
  step?: InstrumentFormDataStepType
): PartialInstrumentFormProps | null | undefined {
  return useAppSelector(({ instruments }) => {
    if (step) {
      if (
        instruments.currentDirtyInstrumentFormData &&
        instruments.currentDirtyInstrumentFormData[step]
      ) {
        return instruments.currentDirtyInstrumentFormData[step];
      }
      return parseInstrumentToFormData(instruments.currentInstrument)[step];
    }
    return null;
  });
}

export function useFormDataDirtyFields(step: InstrumentFormDataStepType) {
  return useAppSelector(({ instruments }) => {
    if (
      instruments.currentDirtyInstrumentFormData &&
      instruments.currentDirtyInstrumentFormData[step]
    ) {
      return diffFields(
        instruments.currentDirtyInstrumentFormData[step],
        parseInstrumentToFormData(instruments.currentInstrument)[step]
      );
    }
    return [];
  });
}

export function useActiveStep(activeTab?: 'ETPDetails' | 'PartnerDetails') {
  return useAppSelector(({ instruments }) => {
    if (activeTab === 'ETPDetails') {
      if (
        [
          'General Details',
          'Market',
          'Benchmark',
          'Constituent',
          'Exchanges',
          'Factsheet',
          'Review',
        ].includes(instruments.activeStep ?? '')
      ) {
        return instruments.activeStep;
      } else {
        return GENERAL_DETAILS_STEP;
      }
    }
    if (activeTab === 'PartnerDetails') {
      if (
        ['Partners', 'Custodian Info', 'Wallets Info', 'Review'].includes(
          instruments.activeStep ?? ''
        )
      ) {
        return instruments.activeStep;
      } else {
        return PARTNERS_STEP;
      }
    }
    return instruments.activeStep;
  });
}

export function useDirtySteps() {
  return useAppSelector(({ instruments }) => {
    const dirtyfieldObject: {
      [key: string]: boolean;
    } = {};
    const currentInstrumentFormData = parseInstrumentToFormData(instruments.currentInstrument);
    Object.keys(currentInstrumentFormData).forEach((step) => {
      if (instruments.currentDirtyInstrumentFormData) {
        const currentInstrumentStepFormData =
          currentInstrumentFormData[step as InstrumentFormDataStepType];
        const currentActiveInstrumentDirtyFormData =
          instruments.currentDirtyInstrumentFormData[step as InstrumentFormDataStepType];
        dirtyfieldObject[step] = Boolean(
          diffFields(currentInstrumentStepFormData, currentActiveInstrumentDirtyFormData).length
        );
      } else {
        dirtyfieldObject[step] = false;
      }
    });

    return dirtyfieldObject;
  });
}
