import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Contact } from 'utils/types/contacts';
import { ExternalContactsTableColumnsConfig } from './tableColumnsConfig';
import { ExternalContactsTableRow } from './ExternalContactsTableRow';
import { PaginationProps } from 'utils/types';
import { ParamsType } from 'hooks/useUrlParams';
import { fieldFilter, getPaginationSliceValues, searchFilter } from 'utils/filters';

interface ExternalContactsTableProps {
  contacts: Contact[];
  isLoading: Boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  editAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
  pagination: PaginationProps;
}

export const ExternalContactsTable = ({
  contacts,
  deleteAction,
  editAction,
  isLoading,
  pagination,
  setUrlParams,
  urlParams,
}: ExternalContactsTableProps) => {
  const externalContacts = contacts ?? [];
  const { page, itemsPerPage } = pagination;
  const filteredContacts = externalContacts
    .filter((item) => searchFilter(item, ['name', 'email'], urlParams?.search))
    .filter(
      (item) =>
        item.contactLists.some((list) => fieldFilter(list.id, urlParams?.contactLists)) ||
        !urlParams?.contactLists ||
        (Array.isArray(urlParams?.contactLists) && !urlParams?.contactLists.length)
    );
  const paginationValues = getPaginationSliceValues(page, itemsPerPage, filteredContacts?.length);

  return (
    <>
      <TableHeaderWithMultiSort
        defaultSortedColumns={urlParams.sort ?? []}
        columns={ExternalContactsTableColumnsConfig}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(isLoading)}
        hasContent={!isEmpty(externalContacts?.slice(...paginationValues))}
        noContentLabel="No Contacts."
      >
        {externalContacts?.slice(...paginationValues).map((contact, index) => (
          <ExternalContactsTableRow
            key={contact.email}
            contact={contact}
            editAction={(contact) => {
              editAction(contact);
            }}
            deleteAction={deleteAction}
          />
        ))}
      </TableBodyWithStates>
    </>
  );
};
