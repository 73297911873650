import { LinkOwnProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import spaceTokens from 'theme/utils/spacers';

export function link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: LinkOwnProps }) => {
          return {
            gap: spaceTokens['spacing-08'],
            fontWeight: 500,
            minWidth: 'unset',
            margin: '2px 4px',
            padding: '2px 0',
            textUnderlineOffset: '2px',
            textDecoration: 'none',
            ...(ownerState.underline === 'none' && {
              padding: '2px 4px',
              borderRadius: theme.spacing(0.5),
            }),
            ...(ownerState.underline !== 'none' && {
              borderBottom: `2px solid inherit`,
              fontWeight: 400,
            }),
            '&:hover': {
              cursor: 'pointer',
            },
            '&:focus': {
              borderRadius: '6px',
              boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
              background: theme.customPalette.interactive.overlay.inverse.enabled,
            },
          };
        },
      },
    },
  };
}
