import { Fragment } from 'react';

import { camelCase } from 'lodash';

// types, constants
import { CASH, IN_KIND } from 'utils/constants';

// utils
import timeOptions from 'utils/select-options/timeOptions';

// styles
import Select from 'components/Select';
import { v1CutoffCompatibility } from 'utils/env';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridItem from 'components/Form/components/FormGridItem';

interface CutoffProps {
  allowedDeliveries?: string[];
  isCreate: boolean;
}

type FieldsControllerType = {
  timeName: string;
};

const v1CompatibleTimeOptions = (allowedDelivery: string) => {
  if (allowedDelivery === IN_KIND) {
    const fourPm = '16:00';
    return [{ label: fourPm, value: fourPm }];
  }

  const threePm = '15:00';
  return [{ label: threePm, value: threePm }];
};

function renderDeliveryCutoff(
  allowedDelivery: string,
  isCreate: boolean,
  fieldsControllers: FieldsControllerType
) {
  const { timeName } = fieldsControllers;
  const disable = !isCreate && v1CutoffCompatibility;
  const options =
    isCreate && v1CutoffCompatibility ? v1CompatibleTimeOptions(allowedDelivery) : timeOptions;

  return (
    <Fragment key={allowedDelivery}>
      <FormGridItem>
        <Select
          data-qa-id={`cutoffTime-${allowedDelivery}`}
          disabled={disable}
          name={timeName}
          options={options}
        />
      </FormGridItem>
    </Fragment>
  );
}

function renderCutOff({ allowedDeliveries, isCreate }: CutoffProps) {
  const cashAndInkindDeliveries = allowedDeliveries?.filter((delivery) =>
    [CASH, IN_KIND].includes(delivery)
  );

  return (
    <>
      <FormGridItem marginTop={2} md={12}>
        <ProductConfigHeader title="Cut-off times" />
      </FormGridItem>
      {cashAndInkindDeliveries?.map((allowedDelivery) =>
        renderDeliveryCutoff(allowedDelivery, isCreate, {
          timeName: camelCase(`${allowedDelivery}CutOffTime`),
        })
      )}
    </>
  );
}

export default renderCutOff;
