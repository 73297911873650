import { Calendar } from 'utils/types/calendar';
import { PaginationWithDataResponse } from 'utils/types';
import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { axiosInstance } from 'utils/axiosOnyx';
import { errorHandler } from 'utils/apiUtils';

export const requestCalendars = async (uriPath: string, params?: ParamsType) => {
  try {
    return await axiosInstance.get<PaginationWithDataResponse<Calendar[]>>(
      params ? `${uriPath}?${getQueryString(params)}` : uriPath
    );
  } catch (err) {
    return errorHandler(err, 'Error while fetching Calendars');
  }
};

export const fetchProductTradingStatus = async (productId: string, valuationDate: string) => {
  const { data: isTradingToday } = await axiosInstance.get<boolean>(
    `products/calendars/productId=${productId}/valuationDate=${valuationDate}`
  );
  return isTradingToday;
};
