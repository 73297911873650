import { ReactNode, useState } from 'react';
import { ModalRouterContext } from './Modal.context';
interface ModalRouterProps {
  children?: ReactNode | undefined;
}

export const ModalRouterContextProvider = ({ children }: ModalRouterProps) => {
  const [onCloseModalAction, setOnCloseModalAction] = useState<(data?: any) => void>();
  const updateOnCloseModalAction = (callback?: (data?: any) => void) => {
    setOnCloseModalAction(() => {
      return callback;
    });
  };

  return (
    <ModalRouterContext.Provider
      value={{
        onCloseModalAction: onCloseModalAction,
        updateOnCloseModalAction: updateOnCloseModalAction,
      }}
    >
      {children}
    </ModalRouterContext.Provider>
  );
};
