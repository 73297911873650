import useAppDispatch from 'hooks/useAppDispatch';
import { Params } from 'react-router-dom';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { getToken } from 'utils/api/tokens';
import { getTokenQueryString } from 'utils/constants/reactQueries';
import { useQuery } from 'react-query';
import { useTokenContext } from 'contexts/tokens';

// @TODO TOKENS update to use Tokens when BE is updated
export const useTokenQuery = (params: Readonly<Params<string>>, enabled: boolean) => {
  const dispatch = useAppDispatch();
  const { setCurrentToken, setTokenCustodians, setTokenWallets } = useTokenContext();

  return useQuery(
    [getTokenQueryString, params],
    async () => {
      try {
        const dataResponse = await getToken(params?.id ?? '', {
          withWallets: true,
          withCustodians: true,
        });
        setCurrentToken(dataResponse.token);
        setTokenCustodians(dataResponse.custodianAccounts);
        setTokenWallets(dataResponse.wallets);
        return dataResponse;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(enabled),
    }
  );
};
