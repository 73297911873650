import { TableHeaderColumn } from 'shared/Tables/Table.types';

export const EditContactsListColumnsConfig: TableHeaderColumn[] = [
  {
    label: 'Name',
    propName: 'name',
    propType: 'string',
    sort: false,
    width: '50%',
    colSpan: 2,
  },
  {
    label: 'Number of associated contacts',
    propName: 'numberOfAssociatedContacts',
    propType: 'number',
    sort: false,
    width: '50%',
    colSpan: 2,
  },
];
