import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

interface IndicatorProps {
  active?: 'true' | 'false';
  disabled?: boolean;
}

export const MuiStyledIndicatorContainer = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '4px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  borderRadius: '10px',
  background: theme.customPalette.static.background.default.strong,
  gap: '4px',
}));

export const MuiStyledIndicator = MuiStyled(Paper)<IndicatorProps>(
  ({ theme, active = 'false', disabled }) => ({
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    color: theme.customPalette.static.text.default.standard,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '100%', // 14px
    letterSpacing: '-0.14px',
    background: theme.customPalette.static.background.default.strong,
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
      '& svg': {
        color: theme.customPalette.interactive.fill.primary.enabled,
        fill: theme.customPalette.interactive.fill.primary.enabled,
        stroke: 'none',
      },
    },
    ...(disabled && {
      color: theme.palette.grey[300],
      '&:hover': {
        cursor: 'not-allowed',
      },
      '& svg': {
        stroke: theme.palette.grey[300],
        marginRight: theme.spacing(1),
      },
    }),
    ...(active === 'true' && {
      borderColor: theme.customPalette.interactive.fill.primary.enabled,
      background: theme.customPalette.static.background.default.weak,
      boxShadow: '0px 1px 2px 0px rgba(53, 63, 71, 0.06)',
      '& svg': {
        color: theme.customPalette.interactive.fill.primary.enabled,
        fill: theme.customPalette.interactive.fill.primary.enabled,
        stroke: 'none',
      },
    }),
    '&:focus-visible': {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  })
);
