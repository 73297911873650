import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { MuiStyledMuiLink } from './Link.styles';

export interface LinkProps extends ComponentPropsWithoutRef<'a'> {
  text: string | ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  underlined?: boolean;
  href?: string;
}

const Link = ({ text, disabled, ...props }: LinkProps) => (
  <MuiStyledMuiLink disabled={disabled} {...props}>
    {text}
  </MuiStyledMuiLink>
);

export default Link;
