import { Instrument, Token } from 'utils/types';

import ProductIcon from 'components/ProductIcon';
import { Box, Typography, useTheme } from '@mui/material';

interface ProductProps {
  instrument?: Pick<Instrument, 'ticker' | 'fundIcon'>;
  token?: Pick<Token, 'ticker' | 'fundIcon'>;
  size?: string;
}

function Product({ instrument, token, size }: ProductProps) {
  const theme = useTheme();
  return (
    <>
      {instrument && (
        <Box display="flex" alignItems="center" gap={1}>
          <ProductIcon iconUrl={instrument.fundIcon} ticker={instrument.ticker} size={size} />
          <Typography variant="labelMedium" color={theme.palette.text.primary}>
            {instrument.ticker}
          </Typography>
        </Box>
      )}
      {token && (
        <Box display="flex" alignItems="center" gap={1}>
          <ProductIcon iconUrl={token.fundIcon} ticker={token.ticker} size={size} />
          <Typography variant="labelMedium" color={theme.palette.text.primary}>
            {token.ticker}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default Product;
