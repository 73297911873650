/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual, union } from 'lodash';

export const diffFields = (
  obj1?: { [key: string]: any } | null,
  obj2?: { [key: string]: any } | null
) => {
  if (obj1 && obj2) {
    const diffInFieldsInOne = Object.keys(obj1).filter(
      (key) => !isEqual(String(obj1[key]), String(obj2[key]))
    );
    const diffInFieldsInTwo = Object.keys(obj2).filter(
      (key) => !isEqual(String(obj1[key]), String(obj2[key]))
    );

    return union(diffInFieldsInOne, diffInFieldsInTwo);
  }
  return [];
};
