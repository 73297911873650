import { Theme } from '@mui/material/styles';
import { CheckboxProps, checkboxClasses } from '@mui/material/Checkbox';

export function checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: CheckboxProps }) => {
          const { color, checked, disabled } = ownerState;
          const styles: any = {};

          // DEFAULT & UNCHECKED
          if (color === 'default' && !checked) {
            styles[`&.${checkboxClasses.root}`] = {
              color: theme.palette.text.default.strong,
            };
            styles['&:hover'] = {
              color: theme.customPalette.interactive.fill.primary.hover,
            };
          }

          // DEFAULT & CHECKED
          if (color === 'default' && checked) {
            styles[`&.${checkboxClasses.root}`] = {
              color: theme.customPalette.interactive.fill.primary.enabled,
            };
            styles['&:hover'] = {
              color: theme.customPalette.interactive.fill.primary.hover,
            };
          }

          // ERROR
          if (color === 'error') {
            styles[`&.${checkboxClasses.root}`] = {
              color: theme.customPalette.interactive.fill.danger.enabled,
            };
            styles['&:hover'] = {
              color: theme.customPalette.interactive.fill.danger.hover,
            };
          }

          // DISABLED
          if (disabled) {
            styles[`&.${checkboxClasses.root}`] = {
              color: theme.customPalette.interactive.outline.secondary.disabled,
            };
            styles['&:hover'] = {
              color: theme.customPalette.interactive.outline.secondary.hover,
            };
          }

          return styles;
        },
      },
    },
  };
}
