import { RefObject, useEffect } from 'react';

const ARROW_DOWN = 'ArrowDown';
const ARROW_UP = 'ArrowUp';
const ESCAPE_KEY = 'Escape';
const TAB = 'Tab';

function useEvents(
  isOptionsOpen: boolean,
  ref: RefObject<HTMLDivElement>,
  toggleOptions: () => void,
  optionsRef: RefObject<HTMLElement>
) {
  // Mimic HTML select behavior when ESC is pressed
  useEffect(() => {
    if (!isOptionsOpen) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      const isNavigatingOutLastItem =
        event.key === TAB &&
        !event.shiftKey &&
        document.activeElement &&
        !document.activeElement.nextElementSibling;
      if (event.key === ESCAPE_KEY || isNavigatingOutLastItem) {
        event.preventDefault();
        toggleOptions();
      } else if (event.key === ARROW_DOWN && document.activeElement) {
        (document.activeElement.nextElementSibling as HTMLElement)?.focus();
      } else if (event.key === ARROW_UP && document.activeElement) {
        (document.activeElement.previousElementSibling as HTMLElement)?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isOptionsOpen, toggleOptions]);

  // Mimic HTML select behavior when the select loses the focus
  useEffect(() => {
    const handleClickOutsideOptions = (event: MouseEvent) => {
      if (
        isOptionsOpen &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        toggleOptions();
      }
    };

    document.addEventListener('mousedown', handleClickOutsideOptions);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideOptions);
    };
  }, [isOptionsOpen, optionsRef, toggleOptions]);
}

export default useEvents;
