import { NotificationWithoutId } from 'utils/types';

export const errorNotification = (
  message?: string,
  title?: string | number
): NotificationWithoutId => ({
  message: message ?? 'Something went wrong',
  title: title ? `${title}` : 'Error',
  type: 'error',
});

export const successNotification = (message: string, title: string): NotificationWithoutId => ({
  message,
  title,
  type: 'success',
});

export const warningNotification = (message: string, title: string): NotificationWithoutId => ({
  message,
  title,
  type: 'warning',
});

export const infoNotification = (message: string, title?: string): NotificationWithoutId => ({
  message,
  title: title ?? 'Info',
  type: 'info',
});
