export enum FeatureFlags {
  PCF_UPLOAD = 'REACT_APP_PCF_UPLOAD',
}

/**
 * Checks whether a feture is enabled
 *
 * @param { string - FeatureFlags } feature - The flag to check
 *
 * @returns { boolean } Whether the feature is enabled
 *
 *
 * @example
 * $ export REACT_APP_PCF_UPLOAD=true
 *
 * console.log(isFeatureEnabled(FeatureFlags.PCF_UPLOAD)) // Output: true
 */
function isFeatureEnabled(feature: FeatureFlags) {
  return /^true$/i.test(process.env[feature] || '');
}

export default isFeatureEnabled;
