import { Box, styled as MuiStyled } from '@mui/material';

interface RowProps {
  gap?: string;
}

const Row = MuiStyled(Box)<RowProps>`
  gap: ${({ gap }) => gap ?? 'unset'};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export default Row;
