import { Actions, PaginationWithDataResponse } from '.';
import { Deliverable } from 'utils/types/orders';
import { Instrument } from 'utils/types/product';

export interface PCFOverviewStatusBarInfo {
  total: number;
  totalConfirmed: number;
  totalPendingApproval: number;
  totalPendingUpload: number;
}

export interface GetAllPCFResponse
  extends PaginationWithDataResponse<PortfolioComposition[]>,
    PCFOverviewStatusBarInfo {
  total: number;
}

export interface ConstituentPerCreationUnit {
  amount: number;
  ticker: string;
}

export interface ConstituentPrice {
  price: number;
  currency: string;
  ticker: string;
  priceAt: Date;
}

/**
 * Base currency needs to be same as the product's base one
 */
export interface CurrencyPrice {
  price: number;
  quoteCurrency: string;
  baseCurrency: string;
  priceAt: Date;
}

export interface CompositionBalance {
  ticker: string;
  balance: number;
}

export interface CompositionBalanceApproved extends CompositionBalance {
  approvedAt: string;
}

export interface PortfolioCompositionDocument {
  // TODO: add when we start documents generation
  createdAt: Date;
  url: string;
}

export interface PortfolioCompositionDocuments {
  officialPdf: PortfolioCompositionDocument;
  officialExcel: PortfolioCompositionDocument;
  statements: PortfolioCompositionDocument[];
}

export enum PortfolioCompositionEventStatus {
  APPROVE = 'APPROVE',
  OFFICIAL_PCF_UPLOADED = 'OFFICIAL_PCF_UPLOADED',
}

export interface PortfolioCompositionEvent {
  event: PortfolioCompositionEventStatus;
  user: string;
  message: string;
}

export interface PortfolioCompositionConstituent {}

export interface PortfolioCompositionProduct {
  _id: string;
  issuer: {
    id: string;
  };
  baseCurrency: string;
  isin: string;
  name: string;
  ticker: string;
  fundIcon: string;
  underling?: {
    name?: string;
    isin?: string;
  };
  unitSize: number;
}

export type OfficialPortfolioCompositionConstituentExposure = {
  exposure: number;
  price: number;
  ticker: string;
};

export interface OfficialPortfolioCompositionConstituent {
  name: string;
  weight: number;
  ticker: string;
  price: number;
  priceAt: Date;
  currency: string;
  amountPerCreationUnit: number; // NAV_IN_KIND / (Total units / CU size)
  exposure?: OfficialPortfolioCompositionConstituentExposure;
}

export type NavValueInCurrency = {
  value: string;
  currency: string;
};

export interface OfficialPortfolioComposition {
  _id: string;
  valuationDate: string;
  effectiveDate: string;
  settlementDate: string;
  product: {
    name: string;
    isin: string;
    issuer: {
      name: string;
      programme: string;
    };
    currency: string;
    unitSize: number;
    underling: {
      name: string;
      isin: string;
    };
  };
  totalNav: NavValueInCurrency[];
  totalNavFxRates: CurrencyPrice[];
  totalProjectedNavCreationUnit: NavValueInCurrency[];
  totalProjectedNavCreationUnitFxRates: CurrencyPrice[];
  totalProjectedNavPerUnit: NavValueInCurrency[];
  totalProjectedNavPerUnitFxRates: CurrencyPrice[];

  totalUnitsOutstanding: number;
  constituents: OfficialPortfolioCompositionConstituent[];
}

export interface SyntheticPortfolioCompositionConstituent {
  ticker: string;

  netAssetValue: number;

  priceInBaseCurrency: number;

  netAssetValueInBaseCurrency: number;

  netAssetValuePerUnit: number;
  netAssetValuePerUnitInBaseCurrency: number;

  netAssetValuePerCreationUnit: number;
  netAssetValuePerCreationUnitInBaseCurrency: number;

  projectedManagementFee: number;
  projectedManagementFeePerUnit: number;
  projectedManagementFeePerCreationUnit: number;

  projectedNetAssetValue: number;
  projectedNetAssetValuePerUnit: number;
  projectedNetAssetValuePerUnitInBaseCurrency: number;
  projectedNetAssetValuePerCreationUnit: number;
  projectedNetAssetValuePerCreationUnitInBaseCurrency: number;

  weight: number;
}

export interface SyntheticTotalInCurrency {
  netAssetValue: number;
  currency: string;
}

export interface SyntheticPortfolioComposition {
  _id: string;
  constituents: SyntheticPortfolioCompositionConstituent[];
  totalProjectedNavCreationUnit: NavValueInCurrency[];
  totalProjectedNavPerUnit: NavValueInCurrency[];
  settledOrdersTotalUnits: number;
  totalUnitsOutstanding: number;
  totals: SyntheticTotalInCurrency[];
  unsettledOrdersTotalUnits: number;
}

export enum TransactionLedgerType {
  STAKE = 'STAKE',
  ORDER_CREATION = 'ORDER_CREATION',
  ORDER_REDEMPTION = 'ORDER_REDEMPTION',
  FEE = 'FEE',
}

export interface TransactionLedger {
  amount: number;
  createdBy: string;
  description: string;
  ticker: string;
  type: TransactionLedgerType;
  updatedAt: Date;
}

export enum PortfolioCompositionStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

interface FiatComparisonError {
  currency: string;
  message: string;
}

export interface PortfolioCompositionErrors {
  totalNav: { errors: FiatComparisonError[] };
  totalProjectedNavCreationUnit: { errors: FiatComparisonError[] };
  totalProjectedNavPerUnit: { errors: FiatComparisonError[] };
  totalUnitsOutstanding: string[];
  constituent: {
    errors: {
      message: string;
      property: keyof OfficialPortfolioCompositionConstituent;
      ticker: string;
    }[];
  };
}

export interface PortfolioComposition {
  _id: string;
  _actions: Actions<'approve' | 'upload'>;
  errors: PortfolioCompositionErrors;
  valuationDate: string; // YYYY-MM-DD
  effectiveDate: string; // YYYY-MM-DD
  settlementDate: string; // YYYY-MM-DD
  isPendingUpload: boolean;
  isRebalanceApproved?: boolean;
  isRebalanceDay?: boolean;

  // Net Asset Values
  balances: {
    lastClosingNav?: CompositionBalance[]; // From ledger or last trading pcf
    currentClosingNavFundAccountant: CompositionBalance[]; // Notified via kafka (we need to add official date in event)
    currentClosingNav: CompositionBalanceApproved[]; // Filled after approved
  };

  transactions: TransactionLedger[]; // Transactions that affect balance (fee here)

  // Prices at market close
  constituentPrices: ConstituentPrice[]; // Quoted in base currency of product
  currencyPrices: CurrencyPrice[]; // In base currency of product

  // General product information
  product: PortfolioCompositionProduct;

  // Comparison
  synthetic?: SyntheticPortfolioComposition;
  official?: OfficialPortfolioComposition;

  documents?: PortfolioCompositionDocuments;

  status: PortfolioCompositionStatus;
  tags?: string[];

  // Audit Log
  createdAt: Date;
  updatedAt: Date;

  events?: PortfolioCompositionEvent[];
}

// OLD PCF TYPE
export type Pcf = {
  _id: string;
  product?: Instrument;
  instrumentId: Instrument['_id'];
  valuationDate: string;
  effectiveDate: string;
  settlementDate: string;
  totalUnitsOutstanding: number;
  totalNav: number;
  navPerUnit: number;
  navPerCreationUnit: number;
  deliverables: Deliverable[];
  state: string;
  extraData: {
    [key: string]: any;
    officialConversionRate: {
      AUD: number;
      CHF: number;
      EUR: number;
      GBP: number;
      USD: number;
    };
    conversionRateCryptoCompare: {
      AUD: number;
      CHF: number;
      EUR: number;
      GBP: number;
      JPY: number;
      SEK: number;
      USD: number;
    };
  };
};

export type PcfList = {
  [instrumentId: Instrument['_id']]: Pcf;
};
