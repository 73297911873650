import TabNavigation from 'components/TabNavigation';
import { PropsWithChildren } from 'react';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';

interface WalletsStepTabProps extends PropsWithChildren {
  active: string;
  onChangeTab?: (activeTab: WALLETS_TABS_STATUS) => void;
}

export const WalletsStepTabs = ({ active, children, onChangeTab }: WalletsStepTabProps) => {
  const tabs = [
    {
      name: WALLETS_TABS_STATUS.ACTIVE,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(WALLETS_TABS_STATUS.ACTIVE);
        }
      },
    },
    {
      name: WALLETS_TABS_STATUS.DEACTIVATED,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(WALLETS_TABS_STATUS.DEACTIVATED);
        }
      },
    },
  ];

  return (
    <TabNavigation active={active} tabs={tabs}>
      {children}
    </TabNavigation>
  );
};
