import Tag from 'components/Tag';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledSavingContainer = MuiStyled(Box)`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-08']};
  height: 100%;
  justify-content: center;
`;

export const StyledCellContent = MuiStyled(Box)`
  align-items: center;
  display: flex;
`;

export const StyledStatusTag = MuiStyled(Tag)`
  margin-left: 8px;
`;
