import Footer from 'pages/Tokens/components/Footer';
import Form from 'components/Form/Form';
import MultiSelect from 'components/Select/MultiSelect';
import Select from 'components/Select';
import {
  CompanyRole,
  PartnersStepDataProps,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import { DefaultValues } from 'react-hook-form';
import { FC } from 'react';
import { PARTNERS_STEP } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import { Partner, PartnerType } from 'utils/types/partner';
import { TokenStepSaveProgress } from 'pages/Tokens/components/SaveProgress';
import { tokenPartnerSchemaUrl } from 'components/Form/formSchemas';
import { useRequestCompaniesQuery } from 'pages/Admin/ContactsPage/hooks/api/useRequestCompaniesQuery';
import { useSaveToken } from 'hooks/useTokens';
import { useTokenContext } from 'contexts/tokens';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';

interface PartnersStepProps {
  onSubmit: (data: PartnersStepDataProps) => void;
}

function mapOptions(partners?: Partner[]) {
  return partners?.map((partner) => ({
    label: partner.name,
    value: partner._id,
  }));
}

function filterAndMapOptions(types: PartnerType[], partners: Partner[] | null, role?: CompanyRole) {
  return mapOptions(
    partners?.filter(
      (partner) => types.includes(partner.type) && (!role || partner.roles?.includes(role))
    )
  );
}

export const TokenPartnersStep: FC<PartnersStepProps> = ({ onSubmit }: PartnersStepProps) => {
  const { data: partnersData, isLoading: isLoadingPartners } = useRequestCompaniesQuery(true);
  const partners = partnersData?.data.data ?? [];
  const { saveToken, isSaving } = useSaveToken();
  const { tokenFormsData, isLoadingToken } = useTokenContext();
  const partnersStepFormData = tokenFormsData[PARTNERS_STEP] as PartnersStepDataProps;
  const issuersOptions = filterAndMapOptions([PartnerType.ISSUER], partners);
  const authorizedParticipantsOptions = filterAndMapOptions(
    [PartnerType.AUTHORIZED_MERCHANT],
    partners
  );

  const saveValues = (data: DefaultValues<PartnersStepDataProps>) => {
    saveToken({
      [PARTNERS_STEP]: data as PartnersStepDataProps,
    });
  };

  const isLoading = isLoadingPartners || isLoadingToken;

  return (
    <Form<PartnersStepDataProps>
      initialValues={partnersStepFormData}
      onChange={saveValues}
      onSubmit={onSubmit}
      schemaUrl={tokenPartnerSchemaUrl}
      loading={isLoading}
    >
      <FormGridContainer>
        <FormGridItem>
          <ProductConfigHeader title="Partner Details" />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <TokenStepSaveProgress saving={isSaving} />
        </FormGridItem>
        <FormGridItem>
          <Select
            disabled={isLoading}
            name="issuer"
            options={issuersOptions}
            data-qa-id="issuer"
            data-qa-options-id="issuer"
            capitalize={false}
          />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            data-qa-id="authorizedParticipants"
            data-qa-options-id="authorizedParticipants"
            disabled={isLoading}
            name="authorizedParticipants"
            options={authorizedParticipantsOptions}
          />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            disabled={isLoading}
            name="custodians"
            data-qa-id="custodians"
            data-qa-options-id="custodians"
          />
        </FormGridItem>
      </FormGridContainer>
      <Footer isFirstStep />
    </Form>
  );
};
