import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { loadOrderDetails } from 'utils/api/instruments';
import { getOrderDetailsQueryKey } from 'utils/constants/reactQueries';
import { Instrument, OrderFormProps } from 'utils/types';

export const useLoadOrderDetails = (orderProps?: OrderFormProps, instrument?: Instrument) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getOrderDetailsQueryKey, { orderProps, instrument }],
    async () => {
      if (orderProps?.productId && orderProps) {
        try {
          const instrumentsResponse = await loadOrderDetails({
            ...orderProps,
            settlementType: orderProps.settlementType || instrument?.standardSettlement!,
          });
          return {
            ...instrumentsResponse,
            product: {
              ...instrumentsResponse.product,
              cutoffs: instrument?.cutoffs,
              allowedDeliveries: instrument?.allowedDeliveries,
              standardSettlement: instrument?.standardSettlement,
            },
          };
        } catch (err) {
          const error = err as Error;
          dispatch(createNotification(errorNotification(error.message ?? ''), error));
        }
      }
    },
    {
      enabled: Boolean(orderProps?.productId && orderProps),
    }
  );
};
