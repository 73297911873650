import { CalendarEventCreateEditModal } from 'shared/Modals/Calendar/CalendarEventCreateEdit/CalendarEventCreateEdit';
import { CalendarEventDeleteModal } from 'shared/Modals/Calendar/CalendarEventDelete/CalendarEventDelete';
import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';

export const CalendarEventModal = ({ ...props }: IModalWithData) => {
  const CalendarEventModals: Record<string, ReactNode> = {
    CREATE: <CalendarEventCreateEditModal {...props} />,
    EDIT: <CalendarEventCreateEditModal {...props} />,
    DELETE: <CalendarEventDeleteModal {...props} />,
  };
  return <>{CalendarEventModals[props.data.type]}</>;
};
