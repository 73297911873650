// actions
import { removeNotification } from 'store/notifications/actions';
//components
import Notification from 'components/Notification';
// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useNotifications } from 'store/notifications/selectors';
//styles
import { StyledNotificationPopUpContainer } from 'components/Notification/Notification.styles';
//types
import { Notification as NotificationType } from 'utils/types';
//utils
import { map } from 'lodash';

function renderNotifications(
  notifications: { [id: string]: NotificationType },
  onClose: (id: string) => void
) {
  return map(notifications, (notification) => (
    <Notification
      key={notification.id}
      onClose={() => onClose(notification.id)}
      variant={notification.type}
      message={notification.message}
      title={notification.title}
    />
  ));
}

export const AppNotifications = () => {
  const dispatch = useAppDispatch();
  const notifications = useNotifications();
  return (
    <StyledNotificationPopUpContainer>
      {renderNotifications(notifications, (notificationId: string) =>
        dispatch(removeNotification(notificationId))
      )}
    </StyledNotificationPopUpContainer>
  );
};
