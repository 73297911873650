import { TableCell, TableRow } from 'components/Table';
import { formatAsPercentage, getColorForNumber } from 'utils/formatting';
import { RebalanceDetails } from 'utils/types/rebalance';
import { StyledColoredTableCell } from 'pages/Rebalance/RebalancePage.styles';

interface IndexWeigthsTableRowProps {
  details: RebalanceDetails;
}

export const IndexWeigthsTableRow = ({ details }: IndexWeigthsTableRowProps) => {
  return (
    <TableRow data-qa-id={`${details.ticker}-row`}>
      <TableCell data-qa-id={`${details.ticker}-ticker`}>{details.ticker}</TableCell>
      <TableCell data-qa-id={`${details.preWeight}-type`}>
        {formatAsPercentage(details.preWeight)}
      </TableCell>
      <TableCell data-qa-id={`${details.postWeight}-type`}>
        {formatAsPercentage(details.postWeight)}
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${details.preDifference}-type`}
        color={getColorForNumber(details.preDifference)}
      >
        {formatAsPercentage(details.preDifference)}
      </StyledColoredTableCell>
      <TableCell data-qa-id={`${details.targetWeight}-type`}>
        {formatAsPercentage(details.targetWeight)}
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${details.postDifference}-type`}
        color={getColorForNumber(details.postDifference)}
      >
        {formatAsPercentage(details.postDifference)}
      </StyledColoredTableCell>
    </TableRow>
  );
};
