import Popper from '@mui/material/Popper';
import { styled as MuiStyled, Stack, Grid, Chip, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';

interface SizeProps {
  sizeprop?: 'small' | 'medium' | 'large';
}

export const MuiStyledPopper = MuiStyled(Popper)(({ theme }) => ({
  zIndex: 40,
}));

export const MuiSingleSelectValueWrapper = MuiStyled(Stack)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const MuiSingleSelectGridContainer = MuiStyled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacers['spacing-04'],
  '& > .MuiGrid-item': {
    padding: theme.spacers['spacing-04'],
  },
}));

export const StyledGrid = MuiStyled(Grid)(({ theme }) => ({
  flexGrow: 1,
}));

export const StyledGridItem = MuiStyled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const MuiMultiSelectValueWrapper = MuiStyled(Box)(({ theme }) => ({
  gap: theme.spacers['spacing-04'],
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

export const MuiMultiSelectChips = MuiStyled(Chip)<SizeProps>(({ theme, sizeprop = 'medium' }) => ({
  svg: {
    fill: 'currentColor',
    color: '#1a1a1a',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  height: {
    small: '20px',
    medium: '24px',
    large: '32px',
  }[sizeprop],
}));

export const StyledInputLabel = MuiStyled(InputLabel)<SizeProps>(({ sizeprop = 'medium' }) => ({
  '&.MuiInputLabel-root': {
    transform: {
      small: 'translate(16px, 4px) scale(1)',
      medium: 'translate(16px, 8px) scale(1)',
      large: 'translate(16px, 12px) scale(1)',
    }[sizeprop],
  },
  '&.MuiInputLabel-shrink': {
    transform: {
      small: 'translate(14px, -6px) scale(0.75)',
      medium: 'translate(14px, -8px) scale(0.75)',
      large: 'translate(14px, -10px) scale(0.75)',
    }[sizeprop],
  },
}));
