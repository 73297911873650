import { AxiosError, AxiosResponse } from 'axios';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';

export const createEntityApiCall = async <T extends any>(
  entity: T | null,
  apiUri: string,
  customErrorResponse?: string
): Promise<AxiosResponse<T, AxiosError>> => {
  try {
    return await axiosInstance.post(apiUri, entity);
  } catch (err) {
    return errorHandler(err, customErrorResponse ?? 'Error happened during entity creation.');
  }
};

export const createEntityApiCallWithCustomResponse = async <T, R>(
  apiUri: string,
  entity?: T | null,
  customErrorResponse?: string
): Promise<AxiosResponse<R, AxiosError>> => {
  try {
    return await axiosInstance.post(apiUri, entity);
  } catch (err) {
    return errorHandler(err, customErrorResponse ?? 'Error happened during entity creation.');
  }
};

export const editEntityApiCall = async <T, R = T>(
  entity: T,
  apiUri: string,
  customErrorResponse?: string
): Promise<AxiosResponse<R, AxiosError>> => {
  try {
    return await axiosInstance.patch(apiUri, entity);
  } catch (err) {
    return errorHandler(err, customErrorResponse ?? 'Error happened during entity update.');
  }
};

export const putEntityApiCall = async <T, R = T>(
  entity: T,
  apiUri: string,
  customErrorResponse?: string
): Promise<AxiosResponse<R, AxiosError>> => {
  try {
    return await axiosInstance.put(apiUri, entity);
  } catch (err) {
    return errorHandler(err, customErrorResponse ?? 'Error happened during entity update.');
  }
};

export const deleteEntityApiCall = async <T>(
  uriPath: string,
  customErrorResponse?: string
): Promise<AxiosResponse<T, AxiosError>> => {
  try {
    return await axiosInstance.delete(uriPath);
  } catch (err) {
    return errorHandler(err, customErrorResponse ?? 'Error while deleting entity.');
  }
};

export const getEntityApiCall = async <T>(
  apiUri: string,
  customErrorResponse?: string
): Promise<AxiosResponse<T, AxiosError>> => {
  try {
    return await axiosInstance.get(apiUri);
  } catch (err) {
    return errorHandler(err, customErrorResponse ?? 'Error happened while fetching data.');
  }
};
