import { Deliverable, Order } from 'utils/types/orders';
import { ExpectedSeedDeliverables } from './components/ExpectedSeedDeliverables';
import { StyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { ExpectedOrderDeliverables } from './components/ExpectedOrderDeliverables';
import { ProductType } from 'utils/types/product';
import { ExpectedDeliverablesExpectedTable } from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/ExpectedDeliverables//ExpectedDeliverablesTable';
import { Typography } from '@mui/material';

export interface ExpectedDeliverablesProps {
  productType: ProductType;
  onChangeSeedDeliverable?: (deliverables: Deliverable) => void;
  order?: Order;
  seedDeliverables?: Deliverable[];
}

const ExpectedDeliverables = ({
  productType,
  onChangeSeedDeliverable: onChangeSeedDeliverable,
  order,
  seedDeliverables,
}: ExpectedDeliverablesProps) => {
  const isSeedOrder = Boolean(order) && !Boolean(order?._id) && !order?.hasPortfolioComposition;

  return (
    <section>
      <StyledSectionHeader>
        <Typography variant="subheadingMedium">Expected Deliverables</Typography>
      </StyledSectionHeader>
      {productType === 'ETP' &&
        (isSeedOrder ? (
          <ExpectedSeedDeliverables
            onChangeSeedDeliverable={onChangeSeedDeliverable}
            seedDeliverables={seedDeliverables}
          />
        ) : (
          <ExpectedOrderDeliverables productType={productType} order={order} />
        ))}
      {productType === 'Token' && (
        <ExpectedDeliverablesExpectedTable productType={productType} order={order} />
      )}
    </section>
  );
};

export default ExpectedDeliverables;
