import {
  StyledCounterNumber,
  StyledNavSingleCounterCardWrapper,
} from 'pages/Ledger/NavReviewPage/components/styles';

interface NavSingleCounterCardProps {
  number?: number;
  numberColor: string;
  label: string;
}

export const NavSingleCounterCard = ({ number, numberColor, label }: NavSingleCounterCardProps) => {
  return (
    <StyledNavSingleCounterCardWrapper>
      <StyledCounterNumber color={numberColor}>{number ?? 0}</StyledCounterNumber>
      <div>{label}</div>
    </StyledNavSingleCounterCardWrapper>
  );
};
