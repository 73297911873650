import Button from 'components/Button';
import Card from 'components/Card';
import Notification from 'components/Notification';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { IModalWithData } from 'shared/Modals/types';
import { UserForm } from 'shared/Modals/Users/UpdateUser/UpdateUserModal';

interface ExistingContactProps extends IModalWithData {
  user: UserForm;
  handleUpdate: (formValues: UserForm) => void;
}

export const ExistingContact = ({ user, handleUpdate, closeModal }: ExistingContactProps) => {
  const handleSubmit = () => {
    handleUpdate({ ...user, replaceContact: true });
  };
  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button data-qa-id="addButton" type="submit" fullWidth onClick={handleSubmit}>
        Merge & Confirm Invite
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <Card
      footer={Footer}
      label="Duplicate contact"
      title={`Email ${user?.email} has been identified as an existing contact`}
      onClose={closeModal}
    >
      <Notification
        message={`Inviting this user will merge them with the contact "${user?.email}" to avoid duplicate notifications.`}
        variant="warning"
        messageMaxWidth
      />
    </Card>
  );
};
