import { alpha } from '@mui/material/styles';
import { grey } from 'theme/colors/baseColors';
import { ThemeComponents, ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import {
  common,
  danger,
  error,
  info,
  interactive,
  inverse,
  primary,
  secondary,
  success,
  support,
  tertiary,
  warning,
} from './paletteColors';

// ----------------------------------------------------------------------

interface CustomShadows {
  z1: string;
  z4: string;
  z8: string;
  z12: string;
  z16: string;
  z20: string;
  z24: string;
  //
  primary: string;
  secondary: string;
  tertiary: string;
  support: string;
  inverse: string;
  danger: string;
  info: string;
  success: string;
  warning: string;
  error: string;
  interactive: string;
  //
  card: string;
  dialog: string;
  dropdown: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: CustomShadows;
    customPalette: ThemeTokens;
    customComponents: ThemeComponents;
  }
  interface ThemeOptions {
    customShadows?: CustomShadows;
    customPalette: ThemeTokens;
    customComponents: ThemeComponents;
  }
}

// ----------------------------------------------------------------------

export function customShadows(mode: 'light' | 'dark') {
  const color = mode === 'light' ? grey[500] : common.black;

  const transparent = alpha(color, 0.16);

  return {
    z1: `0 1px 2px 0 ${transparent}`,
    z4: `0 4px 8px 0 ${transparent}`,
    z8: `0 8px 16px 0 ${transparent}`,
    z12: `0 12px 24px -4px ${transparent}`,
    z16: `0 16px 32px -4px ${transparent}`,
    z20: `0 20px 40px -4px ${transparent}`,
    z24: `0 24px 48px 0 ${transparent}`,
    //
    card: `0 0 2px 0 ${alpha(color, 0.2)}, 0 12px 24px -4px ${alpha(color, 0.12)}`,
    dropdown: `0 0 2px 0 ${alpha(color, 0.24)}, -20px 20px 40px -4px ${alpha(color, 0.24)}`,
    dialog: `-40px 40px 80px -8px ${alpha(common.black, 0.24)}`,
    //
    primary: `0 8px 16px 0 ${alpha(primary.main, 0.24)}`,
    info: `0 8px 16px 0 ${alpha(info.main, 0.24)}`,
    secondary: `0 8px 16px 0 ${alpha(secondary.main, 0.24)}`,
    success: `0 8px 16px 0 ${alpha(success.main, 0.24)}`,
    warning: `0 8px 16px 0 ${alpha(warning.main, 0.24)}`,
    error: `0 8px 16px 0 ${alpha(error.main, 0.24)}`,
    danger: `0 8px 16px 0 ${alpha(danger.main, 0.24)}`,
    inverse: `0 8px 16px 0 ${alpha(inverse.main, 0.24)}`,
    support: `0 8px 16px 0 ${alpha(support.main, 0.24)}`,
    tertiary: `0 8px 16px 0 ${alpha(tertiary.main, 0.24)}`,
    interactive: `0 8px 16px 0 ${alpha(interactive.main, 0.24)}`,
  };
}
