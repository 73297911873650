import Button from 'components/Button';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';
import { StyledPageHeader } from './WalletsStep.styles';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { WalletUnionType, WalletType } from 'utils/types/wallets';
import { WalletsStepTables } from './components/WalletsStepTables';
import { WalletsStepTabs } from './components/WalletsStepTabs';
import { useCallback, useMemo, useState } from 'react';
import { useCompanyQuery } from 'pages/Partners/hooks/useCompanyQuery';
import { useParams } from 'react-router-dom';
import { queryClient } from 'utils/api/queries/queryClient';
import { getWalletsPerCompanyQuery } from 'utils/constants/reactQueries';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';

export const WalletsStep = () => {
  const { id: partnerId } = useParams();
  const { data: partnerData } = useCompanyQuery(partnerId ?? '');
  const companyID = partnerData?._id ?? '';
  const canAddCustodianWallet = partnerData?._actions?.addCustodianWallet;
  const canAddOwnWallet = partnerData?._actions?.addOwnWallet;

  const openModal = useAppModal();
  const [activeTab, setActiveTab] = useState(WALLETS_TABS_STATUS.ACTIVE);
  const handleChangeTab = (tab: WALLETS_TABS_STATUS) => {
    setActiveTab(tab);
  };

  const openWalletModal = useCallback(
    (type: MODAL_ACTIONS, walletType: WalletType, wallet?: WalletUnionType) => {
      openModal(
        {
          modalName: WALLET_MODAL,
          modalData: {
            data: { wallet, type: walletType },
            companyData: {
              id: companyID,
              name: partnerData?.name,
            },
            type: type,
            custom: {
              isToken: partnerData?.type === 'AUTHORIZED_MERCHANT' ? true : false,
            },
          },
        },
        {
          onCloseModalAction: () => {
            queryClient.invalidateQueries(getWalletsPerCompanyQuery + walletType);
          },
        }
      );
    },
    [companyID, openModal, partnerData?.name]
  );

  const createWalletButton = useMemo(
    () => (
      <TablePopoverMenu
        id={'walletsPopover'}
        anchor={
          <StyledTableTopButton
            type="button"
            data-qa-id="createNewWalletButton"
            size="medium"
            key="toolbar-button"
            disabled={!Boolean(canAddCustodianWallet && canAddOwnWallet)}
            variant="secondary"
          >
            <span>Add Wallet</span>
            <ArrowDown />
          </StyledTableTopButton>
        }
        anchorOrigin={{
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
        }}
      >
        <ul>
          <Button
            data-qa-id="newCustodianWallet"
            fullWidth
            variant="ghost"
            onClick={() => openWalletModal(MODAL_ACTIONS.CREATE, WalletType.CUSTODIAN_WALLET)}
          >
            <span>Create Issuer Wallet</span>
          </Button>
          <Button
            data-qa-id="newCompanyWallet"
            fullWidth
            variant="ghost"
            onClick={() => openWalletModal(MODAL_ACTIONS.CREATE, WalletType.OWN_WALLET)}
          >
            <span>Create {partnerData?.name} Wallet</span>
          </Button>
        </ul>
      </TablePopoverMenu>
    ),
    [canAddCustodianWallet, canAddOwnWallet, openWalletModal, partnerData?.name]
  );

  return (
    <>
      <StyledPageHeader>
        <ProductConfigHeader title="Wallets" />
        {createWalletButton}
      </StyledPageHeader>

      {/* <Box paddingLeft={2}> */}
      <WalletsStepTabs active={activeTab} onChangeTab={handleChangeTab}>
        <WalletsStepTables walletsStatus={activeTab} />
      </WalletsStepTabs>
      {/* </Box> */}
    </>
  );
};
