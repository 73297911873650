import TokenDetails from 'pages/Tokens/components/Form/TokenDetails/TokenDetails';
import PartnerDetails from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails';
import { StyledContainer } from 'pages/Partners/Partner.styles';
import { TOKEN_DETAILS_TAB, PARTNER_DETAILS_TAB } from './helpers/tokenTabs';

function renderCurrentTab(activeTab: typeof TOKEN_DETAILS_TAB | typeof PARTNER_DETAILS_TAB) {
  const tabs = {
    [TOKEN_DETAILS_TAB]: TokenDetails,
    [PARTNER_DETAILS_TAB]: PartnerDetails,
  };
  const Tab = tabs[activeTab];
  return <Tab />;
}

function TokenForm({ activeTab }: { activeTab: 'Token Details' | 'Partner Details' }) {
  return <StyledContainer>{renderCurrentTab(activeTab)}</StyledContainer>;
}

export default TokenForm;
