import { ETPPartnerDetailsStepType } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';

export const updateInitialActiveStep = (
  step: ETPPartnerDetailsStepType,
  handleNavigateToStep: (step: ETPPartnerDetailsStepType) => void,
  callback?: () => void
) => {
  handleNavigateToStep(step);
  if (callback) callback();
};
