// types
import { Asset, Fiat } from 'utils/types';

// utils
import { axiosInstance } from 'utils/axiosOnyx';
import { apiUrls } from 'utils/constants/apiUrls';
import { ConstituentAssetPayload } from 'utils/types/product';
import { errorHandler } from 'utils/apiUtils';

export async function loadNewAssets() {
  try {
    const { data: assets } = await axiosInstance.get<Asset[]>(`${apiUrls.asset}/new`);
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading new assets');
  }
}

export async function loadAssets() {
  try {
    const { data: assets } = await axiosInstance.get<Asset[]>(`${apiUrls.asset}`);
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading assets');
  }
}

export async function postAssets(requestBody: ConstituentAssetPayload) {
  try {
    const { data: assets } = await axiosInstance.post<ConstituentAssetPayload[]>(
      apiUrls.asset,
      requestBody
    );
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while creating assets');
  }
}

export async function requestCurrency() {
  try {
    const { data: assets } = await axiosInstance.get<Fiat[]>(`${apiUrls.asset}/fiat`);
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading new assets');
  }
}
