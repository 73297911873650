import { useEffect, useState, useCallback } from 'react';
import PusherClient from 'utils/adapters/pusher';
import useAppDispatch from 'hooks/useAppDispatch';
import { PusherNotificationMessage } from 'utils/types/pusher';
import { PusherNotificationTopic } from 'utils/constants/pusher';
import { transform } from 'shared/Notifications/pusher.notifications';
import { createNotification } from 'store/notifications/actions';
import { isLoggedIn } from 'utils/user/storage';
import { useLocation } from 'react-router-dom';

let globalOrderUpdateCallback: (() => void) | null = null;

export const usePusherInitializer = (onOrderUpdate?: () => void) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isNotificationServiceConnected, setIsNotificationServiceConnected] =
    useState<boolean>(false);

  const handlePusherMessage = useCallback(
    (msg: PusherNotificationMessage) => {
      if (msg.topic === PusherNotificationTopic.ORDER_UPDATE) {
        console.log('Order update received:', msg);
        globalOrderUpdateCallback && globalOrderUpdateCallback();
      }
      dispatch(createNotification(transform(msg)));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isLoggedIn() && !PusherClient.isConnected()) {
      PusherClient.initialize(() => setIsNotificationServiceConnected(true), handlePusherMessage);
      console.log('Listening for notifications...');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationServiceConnected, location]);

  useEffect(() => {
    if (onOrderUpdate) {
      globalOrderUpdateCallback = onOrderUpdate;
      return () => {
        globalOrderUpdateCallback = null;
      };
    }
  }, [onOrderUpdate]);
};
