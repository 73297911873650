import { TabProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export function tabs(theme: Theme) {
  const tab = theme.customComponents.tab;

  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
        root: {
          marginBottom: theme.spacing(3),
          '& .MuiTabs-scroller': {
            flex: 'none',
          },
          '& .MuiTabs-flexContainer': {
            marginRight: 0,
            '& .MuiButtonBase-root': {
              marginRight: 0,
            },
          },
        },
      },
    },
    MuiTab: {
      root: {
        marginBottom: theme.spacing(3),
        '& .MuiTabs-flexContainer': {
          marginRight: 0,
          '& .MuiButtonBase-root': {
            marginRight: 0,
          },
        },
      },
      styleOverrides: {
        root: ({
          ownerState,
        }: {
          ownerState: TabProps & {
            active?: 'true' | 'false';
            size?: 'small' | 'medium' | 'large';
            selected: boolean;
          };
        }) => {
          const heights: Record<string, number> = {
            large: 48,
            medium: 40,
            small: 32,
          };
          const selected = Boolean(ownerState.selected || ownerState.active === 'true');
          const height = heights[ownerState.size || 'small'];
          return {
            gap: 8,
            '& .MuiBox-root': {
              fontSize: '14px',
              color: tab.color,
              padding: '0 2px',
            },
            ...(height && {
              height: height,
              lineHeight: '16px',
              minHeight: height,
            }),
            '&.Mui-disabled': {
              cursor: 'not-allowed',
            },
            '&.Mui-selected': {
              color: tab.active.color,
            },
            ...(selected && {
              color: tab.active.color,
              borderBottom: tab.active.borderBottom,
              '.MuiBox-root': {
                color: tab.active.color,
              },
            }),

            '&:hover': {
              ...(ownerState.disabled && {
                cursor: 'not-allowed',
              }),
              ...(selected && {
                background: tab.active.hover.backgroundColor,
              }),
              ...(!selected && {
                backgroundColor: tab.inactive.hover.backgroundColor,
              }),
            },
            '&:focus-visible': {
              ...(!selected && {
                backgroundColor: tab.inactive.focusVisible.backgroundColor,
                '.MuiBox-root': {
                  backgroundColor: tab.inactive.hover.backgroundColor,
                  borderRadius: '6px',
                  boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
              }),
              ...(selected && {
                '.MuiBox-root': {
                  borderRadius: '6px',
                  boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
                  color: tab.active.color,
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
              }),
            },
          };
        },
      },
    },
  };
}
