import { Box, styled as MuiStyled } from '@mui/material';

export const StyledNavReviewInfoBarWrapper = MuiStyled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const StyledLeftItem = MuiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledNumberBox = MuiStyled(Box)`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const StyledTagWrapper = MuiStyled(Box)`
  display: flex;
  align-items: center;
`;
