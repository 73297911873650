import Card from 'components/Card';
import { FC } from 'react';
import { ReactComponent as IconSuccess } from 'assets/success.svg';
import Column from 'components/Grid/Column';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import copyToClipboard from 'utils/copyToClipboard';
import {
  StyledQRTextWrapper,
  StyledRightBottomButton,
  StyledSuccessTextWrapper,
} from './TwoFaSetup.styles';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Typography } from '@mui/material';

export const SuccessfullSetup: FC<{
  handleFinishSetup: () => void;
  twoFAOTPBackupCode: string;
}> = ({ handleFinishSetup, twoFAOTPBackupCode }) => {
  return (
    <Card
      title={
        <StyledSuccessTextWrapper>
          You have successfully enrolled authentication device <IconSuccess />
        </StyledSuccessTextWrapper>
      }
      label="2FA Setup"
      footer={
        <MuiStyledModalFooterButtons>
          <StyledRightBottomButton variant="interactive" type="submit" onClick={handleFinishSetup}>
            Finish
          </StyledRightBottomButton>
        </MuiStyledModalFooterButtons>
      }
    >
      <Column>
        2FA Backup Code for this device:
        <StyledQRTextWrapper>
          <Typography variant="bodyMedium">{twoFAOTPBackupCode}</Typography>
          <CopyIcon onClick={() => copyToClipboard(twoFAOTPBackupCode)} />
        </StyledQRTextWrapper>
      </Column>
    </Card>
  );
};
