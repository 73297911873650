import { CalendarConnectModal } from 'shared/Modals/Calendar/CalendarConnectModal/CalendarConnectModal';
import { CalendarCreateEditModal } from 'shared/Modals/Calendar/CalendarCreateEdit/CalendarCreateEdit';
import { CalendarDeleteModal } from 'shared/Modals/Calendar/CalendarDelete/CalendarDelete';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { ReactNode } from 'react';

export const CalendarModal = ({ ...props }: IModalWithData) => {
  const CalendarModals: Record<MODAL_ACTIONS, ReactNode> = {
    CUSTOM: <CalendarConnectModal {...props} />,
    CREATE: <CalendarCreateEditModal {...props} />,
    EDIT: <CalendarCreateEditModal {...props} />,
    DELETE: <CalendarDeleteModal {...props} />,
  };

  return <>{CalendarModals[props.data.type]}</>;
};
