import { useTokenContext } from 'contexts/tokens';
import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getTokenCustodians } from 'utils/api/tokens';
import { CustodianStatus } from 'utils/constants/custodians';
import { tokenCustodiansQuery } from 'utils/constants/reactQueries';

export const useTokenCustodiansQuery = (tokenId: string, custodianStatus?: string) => {
  const dispatch = useAppDispatch();
  const { setTokenCustodians } = useTokenContext();

  return useQuery(
    [tokenCustodiansQuery, tokenId],
    async () => {
      try {
        const res = await getTokenCustodians(tokenId, custodianStatus);
        const activeCustodians = res?.filter(
          (custodian) => custodian.status === CustodianStatus.ACTIVE
        );
        setTokenCustodians(activeCustodians);
        return res;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(tokenId),
    }
  );
};
