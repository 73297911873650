import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import useAppModal from 'hooks/useAppModal';
import { CUSTODIAN_INFO_TABS_STATUS, CustodianStatus } from 'utils/constants/custodians';
import { CUSTODIAN_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { CustodianAccount } from 'utils/types/product';
import { CustodianStepInfoTabs } from 'pages/Instruments/components/Form/PartnerDetails/components/CustodianInfoStep/components/CustodianInfoStepTabs';
import { CustodiansListProps } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import { CustodiansTableColumnsConfig } from './tableColumnsConfig';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { useMemo, useState } from 'react';
import { CustodianTableRow } from './index';
import Grid from '@mui/material/Grid';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import Box from '@mui/material/Box';

interface CustodiansTableProps {
  allCustodians: CustodiansListProps;
  partnersCustodians: string[];
  loadingCustodians: boolean;
  onCustodianListUpdate: () => void;
}

function CustodiansTable({
  loadingCustodians,
  allCustodians,
  partnersCustodians,
  onCustodianListUpdate,
}: CustodiansTableProps) {
  const openModal = useAppModal();
  const [activeTab, setActiveTab] = useState(CUSTODIAN_INFO_TABS_STATUS.ACTIVE);
  const custodians = useMemo(() => {
    return allCustodians?.filter((custodian) => {
      if (activeTab === CUSTODIAN_INFO_TABS_STATUS.DEACTIVATED)
        return custodian.status === CustodianStatus.DEACTIVATED;
      return custodian.status === CustodianStatus.ACTIVE;
    });
  }, [activeTab, allCustodians]);

  const handleChangeTab = (tab: CUSTODIAN_INFO_TABS_STATUS) => {
    setActiveTab(tab);
  };

  const openEditCustodianModal = (type: MODAL_ACTIONS, custodian?: CustodianAccount) => {
    openModal(
      {
        modalName: CUSTODIAN_MODAL,
        modalData: {
          data: {
            custodian: custodian ?? null,
            custodians: allCustodians?.filter(
              (custodian) => custodian.status === CustodianStatus.ACTIVE
            ),
            partnersCustodians: partnersCustodians,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: onCustodianListUpdate,
      }
    );
  };

  const openDeleteCustodianModal = (custodian: CustodianAccount) => {
    openModal(
      {
        modalName: CUSTODIAN_MODAL,
        modalData: {
          data: {
            custodian,
            custodians,
          },
          type: MODAL_ACTIONS.DELETE,
        },
      },
      {
        onCloseModalAction: onCustodianListUpdate,
      }
    );
  };

  return (
    <Table
      tabsComponentUnderToolbar={true}
      toolbarTools={[
        <>
          <Grid marginTop={1} marginBottom={1} container display={'flex'} alignItems={'center'}>
            <Grid item xs={12} md={6}>
              <ProductConfigHeader title="Custodian Accounts" />
            </Grid>
            <Grid item xs={12} md={6} display={'flex'} justifyContent={'flex-end'}>
              <StyledTableTopButton
                variant="secondary"
                onClick={() => openEditCustodianModal(MODAL_ACTIONS.CREATE)}
                type="button"
                size="medium"
              >
                <span>Add Custodian</span>
                <PlusIcon />
              </StyledTableTopButton>
            </Grid>
          </Grid>
        </>,
      ]}
      tabsComponent={
        <Box paddingLeft={2}>
          <CustodianStepInfoTabs
            active={activeTab}
            onChangeTab={handleChangeTab}
          ></CustodianStepInfoTabs>
        </Box>
      }
      noPadding
    >
      <TableHeaderWithMultiSort showActionsCell={true} columns={CustodiansTableColumnsConfig} />
      <TableBodyWithStates
        loadingData={Boolean(loadingCustodians)}
        hasContent={!isEmpty(custodians)}
        noContentLabel="No custodians in the list."
      >
        {custodians?.map((custodian) => (
          <CustodianTableRow
            key={custodian._id}
            custodian={custodian}
            editAction={() => {
              openEditCustodianModal(MODAL_ACTIONS.EDIT, custodian);
            }}
            deleteAction={() => {
              openDeleteCustodianModal(custodian);
            }}
            activateAction={() => {
              openEditCustodianModal(MODAL_ACTIONS.CUSTOM, custodian);
            }}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
}

export default CustodiansTable;
