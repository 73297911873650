import { Box, styled as MuiStyled } from '@mui/material';
import breakPoints from 'theme/utils/breakPoints';

const Container = MuiStyled(Box)`
  box-sizing: border-box;
  padding: ${({ theme }) => `0 ${theme.spacers['spacing-16']}`};
  width: 100%;

  // XX-Large devices 1632px and up
  @media (min-width: ${() => breakPoints.xxl}) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export default Container;
