import Button from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import Input from 'components/Input';
import Select from 'components/Select';
import mapAssetsToOptions from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentModal/utils/mapAssetsToOptions';
import parseDataToAsset from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentModal/utils/parseDataToAsset';
import useAppDispatch from 'hooks/useAppDispatch';
import { Column, Row } from 'components/Grid';
import { ConstituentAssetType } from 'utils/types/product';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { ProductStatus } from 'utils/types/product';
import { StyledFieldsContainer } from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentStep.styles';
import { isEmpty } from 'lodash';
import { loadNewAssets } from 'store/assets/actions';
import { useAssets } from 'store/assets/selectors';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { useEffect, useState } from 'react';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

interface NewConstituentProps {
  onSubmit: (data: ConstituentAssetType[]) => void;
  selectedConstituents: ConstituentAssetType['ticker'][];
  toggleModal: () => void;
  isLoading: boolean;
}

function NewConstituent({
  onSubmit,
  selectedConstituents,
  toggleModal,
  isLoading,
}: NewConstituentProps) {
  const { instrument } = useCurrentInstrument();
  const dispatch = useAppDispatch();
  const { assets, loadingNewAssets, error: loadingAssetsError } = useAssets();

  const options = mapAssetsToOptions(assets?.new, selectedConstituents);
  const [filter, setFilter] = useState<string>();
  const filterRegex = filter && new RegExp(filter, 'i');
  const filteredOptions = options.filter(
    (option) => !filterRegex || filterRegex.test(option.label)
  );
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (isEmpty(assets?.new) && !loadingAssetsError) {
      dispatch(loadNewAssets());
    }
  }, [assets, dispatch, loadingAssetsError]);

  const handleNewConstituentSubmit = (data: FieldValues) => {
    if (assets?.new) {
      onSubmit([
        parseDataToAsset({
          ...data,
          ...assets.new[data.constituent],
        }),
      ]);
    }
  };

  return loadingNewAssets ? (
    <Row>
      <Column offset={5}>
        <CircularProgress />
      </Column>
    </Row>
  ) : (
    <form id="new-constituent" onSubmit={handleSubmit(handleNewConstituentSubmit)}>
      <StyledFieldsContainer>
        <Controller
          control={control}
          name="constituent"
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
            <Select
              data-qa-id="constituents"
              data-qa-options-id="constituents"
              errorMessage={error?.message}
              filterable
              isInvalid={invalid}
              label="Constituent"
              name="ticker"
              onChange={onChange}
              onFilter={setFilter}
              options={filteredOptions}
              placeholder="Select"
              value={value}
            />
          )}
          rules={{ required: 'Select an asset' }}
        />
        <Input
          {...register('rounding', {
            min: {
              value: 0,
              message: 'Please enter a positive non-decimal number',
            },
            required: 'Please enter a positive non-decimal number',
            valueAsNumber: true,
          })}
          errorMessage={errors.rounding?.message as string}
          isInvalid={Boolean(errors.rounding)}
          label="Rounding"
          name="rounding"
          placeholder="Rounding"
          type="number"
        />
      </StyledFieldsContainer>
      <MuiStyledModalFooterButtons>
        <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          data-qa-id="addButton"
          disabled={!isValid}
          fullWidth
          type="submit"
          isLoading={isLoading}
        >
          {instrument?.status === ProductStatus.ACTIVE ? 'Add and Save' : 'Add'}
        </Button>
      </MuiStyledModalFooterButtons>
    </form>
  );
}

export default NewConstituent;
