export const getPCFsTableColumnsConfig = (isIssuer: boolean, canSeeCompare: boolean) => [
  { label: 'Product', propName: 'product.ticker', propType: 'string', width: '16%' },
  {
    label: 'Effective Date',
    propName: 'effectiveDate',
    propType: 'date',
    width: '16%',
  },
  {
    label: 'Onyx Projected NAV',
    propName: 'onyxProjectedNav',
    propType: 'string',
    hideColumn: !canSeeCompare,
    width: '16%',
    sort: false,
  },
  {
    label: 'Official Projected NAV',
    propName: 'officialProjectedNav',
    propType: 'string',
    width: '16%',
    sort: false,
  },
  { label: 'State', propName: 'status', propType: 'string', width: '14%' },
  {
    label: 'Deliverables',
    propName: 'deliverables',
    propType: 'string',
    hideColumn: isIssuer,
    width: '14%',
    sort: false,
  },
];
