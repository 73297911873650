import { NotificationConfigType } from 'utils/types';

export const exchangeCreatedNotifications: NotificationConfigType = {
  success: (exchangeName) => ({
    message: `The exchange ${exchangeName} was successfully created`,
    title: 'Exchange created',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const exchangeEditNotifications: NotificationConfigType = {
  success: (exchangeName) => ({
    message: `The exchange ${exchangeName} was successfully updated`,
    title: 'Exchange updated',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const exchangeDeletedNotifications: NotificationConfigType = {
  success: (exchangeName) => ({
    message: `The exchange ${exchangeName} was successfully deleted`,
    title: 'Exchange deleted',
    type: 'info',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};
