import { isBefore, parse } from 'date-fns';
import { AnySchemaObject, SchemaValidateFunction } from 'ajv';
import { DataValidationCxt } from 'ajv/dist/types';

function validateIsTimeBefore() {
  const validate: SchemaValidateFunction = (
    schema: string,
    data: string,
    parentSchema?: AnySchemaObject,
    dataCxt?: DataValidationCxt
  ) => {
    const endTime = (dataCxt?.rootData as Record<string, any>)[schema];

    const start = parse(data, 'HH:mm', new Date());
    const end = parse(endTime, 'HH:mm', new Date());
    const isValid = isBefore(start, end);

    if (!isValid) {
      validate.errors = validate.errors || [];
      validate.errors.push({
        keyword: 'isTimeBefore',
        message: 'Must be before end time',
        params: {
          endTime,
        },
      });
    }

    return isValid;
  };

  return {
    keyword: 'isTimeBefore',
    validate,
    errors: true,
  };
}

export default validateIsTimeBefore;
