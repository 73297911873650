import { StepsListType } from 'pages/Instruments/components/Form/types';

export const GENERAL_DETAILS_STEP = 'General Details';
export const MARKET_STEP = 'Market';
export const BENCHMARK_STEP = 'Benchmark';
export const CONSTITUENT_STEP = 'Constituent';
export const EXCHANGE_STEP = 'Exchanges';
export const REVIEW_STEP = 'Review';
export const FACTSHEET_STEP = 'Factsheet';
export const SUMMARY_STEP_LABEL = 'Summary';

export type EtpDetailsStepType =
  | typeof GENERAL_DETAILS_STEP
  | typeof MARKET_STEP
  | typeof BENCHMARK_STEP
  | typeof CONSTITUENT_STEP
  | typeof EXCHANGE_STEP
  | typeof FACTSHEET_STEP
  | typeof REVIEW_STEP;

export const etpDetailsStepsList: StepsListType<EtpDetailsStepType>[] = [
  {
    id: GENERAL_DETAILS_STEP,
    label: GENERAL_DETAILS_STEP,
    nextStep: MARKET_STEP,
  },
  {
    id: MARKET_STEP,
    label: MARKET_STEP,
    nextStep: CONSTITUENT_STEP,
    previousStep: GENERAL_DETAILS_STEP,
  },
  {
    id: BENCHMARK_STEP,
    label: BENCHMARK_STEP,
    isDisabled: true,
  },
  {
    id: CONSTITUENT_STEP,
    label: CONSTITUENT_STEP,
    nextStep: EXCHANGE_STEP,
    previousStep: MARKET_STEP,
  },
  {
    id: EXCHANGE_STEP,
    label: EXCHANGE_STEP,
    nextStep: FACTSHEET_STEP,
    previousStep: CONSTITUENT_STEP,
  },
  {
    id: FACTSHEET_STEP,
    label: FACTSHEET_STEP,
    nextStep: REVIEW_STEP,
    previousStep: EXCHANGE_STEP,
  },
  {
    id: REVIEW_STEP,
    label: REVIEW_STEP,
    previousStep: FACTSHEET_STEP,
  },
];

export const indexEtpDetailsStepsList: StepsListType<EtpDetailsStepType>[] = [
  {
    id: GENERAL_DETAILS_STEP,
    label: GENERAL_DETAILS_STEP,
    nextStep: MARKET_STEP,
  },
  {
    id: MARKET_STEP,
    label: MARKET_STEP,
    nextStep: BENCHMARK_STEP,
    previousStep: GENERAL_DETAILS_STEP,
  },
  {
    id: BENCHMARK_STEP,
    label: BENCHMARK_STEP,
    nextStep: CONSTITUENT_STEP,
    previousStep: MARKET_STEP,
  },
  {
    id: CONSTITUENT_STEP,
    label: CONSTITUENT_STEP,
    nextStep: EXCHANGE_STEP,
    previousStep: BENCHMARK_STEP,
  },
  {
    id: EXCHANGE_STEP,
    label: EXCHANGE_STEP,
    nextStep: FACTSHEET_STEP,
    previousStep: CONSTITUENT_STEP,
  },
  {
    id: FACTSHEET_STEP,
    label: FACTSHEET_STEP,
    nextStep: REVIEW_STEP,
    previousStep: EXCHANGE_STEP,
  },
  {
    id: REVIEW_STEP,
    label: REVIEW_STEP,
    previousStep: FACTSHEET_STEP,
  },
];
