import { NavReviewItem, NavReviewItemsRows, TransactionType } from 'utils/types/nav';
import { NavModalTransactions } from 'shared/Modals/NavReviewModals/hook/useNavReviewModalData';

export const mapNavReviewItemTransactions = (
  navReviewItemWithTransactions?: NavReviewItem
): NavModalTransactions => {
  const mappedTransactions: NavModalTransactions = {
    [NavReviewItemsRows.OPENING_NAV]: {
      onyxValue: navReviewItemWithTransactions?.totals?.openBalance,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.openBalance,
      difference: navReviewItemWithTransactions?.difference,
      _id: NavReviewItemsRows.OPENING_NAV,
    },
    [NavReviewItemsRows.MANAGEMENT_FEE]: {
      onyxValue: navReviewItemWithTransactions?.totals?.managementFee,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.managementFee,
      transactions: [],
      _id: NavReviewItemsRows.MANAGEMENT_FEE,
    },
    [NavReviewItemsRows.NET_ORDERS]: {
      onyxValue: navReviewItemWithTransactions?.totals?.netOrders,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.netOrders,
      transactions: [],
      _id: NavReviewItemsRows.NET_ORDERS,
    },
    [NavReviewItemsRows.STAKING_FEES]: {
      onyxValue: navReviewItemWithTransactions?.totals?.stakingFees,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.stakingFees,
      transactions: [],
      _id: NavReviewItemsRows.STAKING_FEES,
    },
    [NavReviewItemsRows.STAKING_REWARDS]: {
      onyxValue: navReviewItemWithTransactions?.totals?.stakingRewards,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.stakingRewards,
      transactions: [],
      _id: NavReviewItemsRows.STAKING_REWARDS,
    },
    [NavReviewItemsRows.REBOOK_BALANCE_ADJUST]: {
      onyxValue: Number(
        navReviewItemWithTransactions?.transactions?.find(
          (trx) => trx.type === TransactionType.REBOOK_BALANCE_ADJUST
        )?.amount
      ),
      transactions: [],
      _id: NavReviewItemsRows.REBOOK_BALANCE_ADJUST,
    },
    [NavReviewItemsRows.REBALANCE_TRADES]: {
      onyxValue: navReviewItemWithTransactions?.totals?.tradeValue,
      transactions: [],
      _id: NavReviewItemsRows.REBALANCE_TRADES,
    },
  };
  navReviewItemWithTransactions?.transactions?.forEach((transaction) => {
    switch (transaction.type) {
      case TransactionType.ETP_CREATION:
        mappedTransactions[NavReviewItemsRows.NET_ORDERS]?.transactions?.push(transaction);
        return;
      case TransactionType.ETP_REDEMPTION:
        mappedTransactions[NavReviewItemsRows.NET_ORDERS]?.transactions?.push(transaction);
        return;
      case TransactionType.EARN_FEE:
        mappedTransactions[NavReviewItemsRows.STAKING_FEES]?.transactions?.push(transaction);
        return;
      case TransactionType.EARN_REWARD:
        mappedTransactions[NavReviewItemsRows.STAKING_REWARDS]?.transactions?.push(transaction);
        return;
      case TransactionType.REBOOK_BALANCE_ADJUST:
        mappedTransactions[NavReviewItemsRows.REBOOK_BALANCE_ADJUST]?.transactions?.push(
          transaction
        );
        return;
      default:
        return;
    }
  });
  return mappedTransactions;
};
