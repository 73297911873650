import { TableRow } from 'components/Table';
import { formatAsPercentage } from 'utils/formatting';
import { RebalanceDetails } from 'utils/types/rebalance';
import { TotalTableCell } from 'pages/Rebalance/RebalanceDetailsPage/RebalanceDetailsPage.styles';

interface IndexWeigthsTableRowProps {
  details: Partial<RebalanceDetails>;
}

export const IndexWeigthsTotalsTableRow = ({ details }: IndexWeigthsTableRowProps) => {
  return (
    <TableRow data-qa-id={`total-row`}>
      <TotalTableCell data-qa-id={`total-cell`}>Total</TotalTableCell>
      <TotalTableCell data-qa-id={`${details.preWeight}-type`}>
        {formatAsPercentage(details.preWeight)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${details.postWeight}-type`}>
        {formatAsPercentage(details.postWeight)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${details.preDifference}-type`}>
        {formatAsPercentage(details.preDifference)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${details.targetWeight}-type`}>
        {formatAsPercentage(details.targetWeight)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${details.postDifference}-type`}>
        {formatAsPercentage(details.postDifference)}
      </TotalTableCell>
    </TableRow>
  );
};
