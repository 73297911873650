import { useEffect, useState, useMemo } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { isEmpty, compact } from 'lodash';

// types
import { ConstituentAssetType } from 'utils/types/product';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useAssets } from 'store/assets/selectors';

// actions
import { loadAssets } from 'store/assets/actions';

// helpers
import mapAssetsToOptions from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentModal/utils/mapAssetsToOptions';
import parseDataToAsset from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentModal/utils/parseDataToAsset';

// components
import Button from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import MultiSelect from 'components/Select/MultiSelect';
import { Column, Row } from 'components/Grid';

// styles
import { StyledFieldsContainer } from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentStep.styles';
import Select from 'components/Select';
import { ProductStatus } from 'utils/types/product';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { OptionType } from 'components/Form/Form.types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

interface ExistingConstituentsProps {
  onSubmit: (data: ConstituentAssetType[]) => void;
  selectedConstituents: ConstituentAssetType['ticker'][];
  toggleModal: () => void;
  singleAsset: boolean;
  isLoading: boolean;
}

export const ExistingConstituents = ({
  onSubmit,
  selectedConstituents,
  toggleModal,
  singleAsset,
  isLoading,
}: ExistingConstituentsProps) => {
  const dispatch = useAppDispatch();
  const { instrument } = useCurrentInstrument();
  const { assets, loadingExistingAssets, error: loadingAssetsError } = useAssets();
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });
  const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([]);
  const assetsOptions = useMemo(() => {
    const options = mapAssetsToOptions(assets?.existing, selectedConstituents);
    setFilteredOptions(options);
    return options;
  }, [assets, selectedConstituents]);

  useEffect(() => {
    if (isEmpty(assets?.existing) && !loadingAssetsError) {
      dispatch(loadAssets());
    }
  }, [assets, dispatch, loadingAssetsError]);

  const handleFilter = (inputValue: string | undefined) => {
    if (inputValue) {
      const filteredAssets = assetsOptions.filter((asset) => {
        const combined = `${asset.label} ${String(asset.value)}`.toLowerCase();
        return combined.includes(inputValue.toLowerCase());
      });
      setFilteredOptions(filteredAssets);
    } else {
      setFilteredOptions(assetsOptions);
    }
  };
  const handleExistingConstituentSubmit = (data: FieldValues) => {
    let assetsToAdd: ConstituentAssetType[];
    if (singleAsset && assets?.existing) {
      assetsToAdd = [parseDataToAsset(assets.existing[data.constituents])];
    } else {
      assetsToAdd = data.constituents.map(
        (newAsset: string) => assets?.existing && parseDataToAsset(assets.existing[newAsset])
      );
    }
    if (assetsToAdd) onSubmit(compact(assetsToAdd));
  };

  return loadingExistingAssets ? (
    <Row>
      <Column offset={5}>
        <CircularProgress />
      </Column>
    </Row>
  ) : (
    <form onSubmit={handleSubmit(handleExistingConstituentSubmit)}>
      <StyledFieldsContainer>
        <Controller
          control={control}
          name="constituents"
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) =>
            singleAsset ? (
              <Select
                data-qa-id="constituents"
                data-qa-options-id="constituents"
                errorMessage={error?.message}
                filterable
                isInvalid={invalid}
                label="Constituents"
                name="ticker"
                onChange={onChange}
                options={filteredOptions}
                placeholder="Select"
                value={value}
              />
            ) : (
              <>
                <MultiSelect
                  data-qa-id="constituents"
                  data-qa-options-id="constituents"
                  errorMessage={error?.message}
                  isInvalid={invalid}
                  label="Constituents"
                  name="ticker"
                  onChange={(_, values?: string[]) => onChange(values)}
                  options={assetsOptions}
                  placeholder="Click to Select or Enter Text to Search Here"
                  value={value}
                  withFilter
                  onFilter={handleFilter}
                  filteredOptions={filteredOptions}
                />
              </>
            )
          }
          rules={{ required: 'Select a constituent' }}
        />
      </StyledFieldsContainer>
      <MuiStyledModalFooterButtons>
        <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          data-qa-id="addButton"
          disabled={!isValid}
          fullWidth
          type="submit"
          isLoading={isLoading}
        >
          {instrument?.status === ProductStatus.ACTIVE ? 'Add and Save' : 'Add'}
        </Button>
      </MuiStyledModalFooterButtons>
    </form>
  );
};
