import { ChangeEvent, ComponentPropsWithRef, forwardRef, useRef, useState } from 'react';

// utils
import dispatchFilesChange from 'components/Upload/utils/dispatchFilesChange';

// styles, assets
import {
  MuiStyledUploadContainer,
  MuiStyledHeader,
  MuiStyledHelperText,
  MuiStyledUploadDropArea,
  MuiStyledUploadInput,
  StyledFileItem,
  StyledFileName,
} from './Upload.styles';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { NewButton } from 'components/Button/NewButton';
import { ReactComponent as UploadIcon } from 'assets/upload.svg';
import { ReactComponent as IconFile } from 'assets/file.svg';
import { ReactComponent as IconTrash } from 'assets/trash.svg';

export interface UploadProps extends ComponentPropsWithRef<'input'> {
  label?: string;
  helperText?: string;
  files?: FileList;
}

const Upload = forwardRef<HTMLInputElement, UploadProps>(
  ({ disabled, helperText, label, onChange, ...props }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>(Array.from(props.files || []));

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFiles(Array.from(event.target.files || []));

      if (onChange) {
        onChange(event);
      }
    };

    const openFilePicker = () => innerRef?.current?.click();
    const removeFile = (fileName: string) => {
      return () => {
        const newFilesList = new DataTransfer();

        files?.forEach((file) => {
          if (file.name !== fileName) {
            newFilesList.items.add(file);
          }
        });

        dispatchFilesChange(innerRef, newFilesList);
      };
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      dispatchFilesChange(innerRef, event.dataTransfer);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    return (
      <MuiStyledUploadContainer>
        <MuiStyledHeader aria-disabled={disabled}>
          <Typography variant="labelLarge">{label}</Typography>
          <MuiStyledHelperText>{helperText}</MuiStyledHelperText>
        </MuiStyledHeader>
        <MuiStyledUploadDropArea
          aria-label="file-upload-area"
          aria-disabled={disabled}
          onClick={openFilePicker}
          role="button"
          tabIndex={0}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div>
            <div style={{ maxWidth: '16px', margin: '0 auto' }}>
              <UploadIcon />
            </div>
            <Typography variant="labelMedium">Drag & drop files here or</Typography>
          </div>
          <NewButton variant="inverse">Browse files</NewButton>
          <MuiStyledUploadInput
            {...props}
            aria-label="file-upload"
            disabled={disabled}
            onChange={handleOnChange}
            ref={innerRef}
            type="file"
          />
        </MuiStyledUploadDropArea>
        <Box component="ul">
          {files.map((file) => (
            <StyledFileItem key={file.name}>
              <StyledFileName>
                <div style={{ maxWidth: '16px' }}>
                  <IconFile />
                </div>
                {file.name}
              </StyledFileName>
              <IconButton disabled={disabled} onClick={removeFile(file.name)}>
                <div style={{ maxWidth: '16px' }}>
                  <IconTrash />
                </div>
              </IconButton>
            </StyledFileItem>
          ))}
        </Box>
      </MuiStyledUploadContainer>
    );
  }
);

export default Upload;
