import { Box, styled as MuiStyled } from '@mui/material';

import { StyledFallbackIcon } from 'components/ProductSelector/ProductSelector.styles';
import { useState } from 'react';

const StyledIcon = MuiStyled(Box)`
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
`;

const DEFAULT_SIZE = '20px';

interface ProductIconProps extends React.ComponentPropsWithoutRef<'div'> {
  className?: string;
  iconUrl?: string;
  ticker?: string;
  size?: string;
}

const ProductIcon = ({ className, iconUrl, ticker, size = DEFAULT_SIZE }: ProductIconProps) => {
  const [showFallback, setShowFallback] = useState(false);

  const onError = (res: any) => {
    if (res) {
      setShowFallback(true);
    }
  };
  return (
    <StyledIcon className={className}>
      {iconUrl && !showFallback && (
        <img src={iconUrl} width={size} height={size} onError={onError} alt="Icon" />
      )}
      {(!iconUrl || showFallback) && (
        <StyledFallbackIcon size={size}>{ticker?.substring(0, 2)}</StyledFallbackIcon>
      )}
    </StyledIcon>
  );
};

export default ProductIcon;
