import { Theme } from '@mui/material/styles';
import { SwitchProps } from '@mui/material/Switch';

export function switches(theme: Theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: SwitchProps }) => {
          const { color, disabled } = ownerState;
          const commonTrackStyles = {
            opacity: 1,
            borderRadius: 14,
          };

          return {
            width: 58,
            height: 38,
            padding: '9px 13px 9px 12px',
            '& .MuiSwitch-thumb': {
              width: 14,
              height: 14,
              boxShadow: 'none',
              color: theme.palette.common.white,
            },
            '& .MuiSwitch-track': {
              ...commonTrackStyles,
              backgroundColor: theme.customPalette.interactive.fill.tertiary.enabled,
              ...(color === 'error' && {
                backgroundColor: theme.customPalette.interactive.fill.danger.enabled,
              }),
              ...(disabled && {
                backgroundColor: theme.customPalette.interactive.outline.secondary.disabled,
              }),
            },
            '& .MuiSwitch-switchBase': {
              left: 3,
              padding: 12,
              '&.Mui-checked': {
                transform: 'translateX(13px)',
                '& .MuiSwitch-thumb': {
                  color: theme.palette.common.white,
                },
                '&+.MuiSwitch-track': {
                  ...commonTrackStyles,
                  backgroundColor: theme.customPalette.interactive.fill.primary.enabled,
                  ...(color === 'error' && {
                    backgroundColor: theme.customPalette.interactive.fill.danger.enabled,
                  }),
                },
                '&.Mui-disabled': {
                  '&+.MuiSwitch-track': {
                    backgroundColor: theme.customPalette.interactive.outline.secondary.disabled,
                  },
                },
                '&:hover': {
                  '&+.MuiSwitch-track': {
                    backgroundColor: theme.customPalette.interactive.fill.primary.hover,
                    ...(color === 'error' && {
                      backgroundColor: theme.customPalette.interactive.fill.danger.hover,
                    }),
                  },
                  '&.Mui-disabled': {
                    '&+.MuiSwitch-track': {
                      backgroundColor: theme.customPalette.interactive.outline.secondary.hover,
                    },
                  },
                },
              },
            },
          };
        },
      },
    },
  };
}
