import { AxiosError, AxiosResponse } from 'axios';
import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { PaginationWithDataResponse, PartnersUser } from 'utils/types';

export const getUsersForCompanyRequest = async (id: string, params?: ParamsType) => {
  try {
    return await axiosInstance.get<PaginationWithDataResponse<PartnersUser[]>>(
      params ? `/companies/id=${id}/users?${getQueryString(params)}` : `/companies/id=${id}/users`
    );
  } catch (err) {
    return errorHandler(err, 'Error while loading users');
  }
};

export const deleteUserRequest = async (
  uriPath: string
): Promise<AxiosResponse<PartnersUser, AxiosError>> => {
  try {
    return await axiosInstance.delete(uriPath);
  } catch (err) {
    return errorHandler(err, 'Error while deleting User');
  }
};

export const updateUserInvitationRequest = async (
  user: PartnersUser
): Promise<AxiosResponse<PartnersUser, AxiosError>> => {
  try {
    return await axiosInstance.post(`/users/id=${user._id}/resend-invitation`, user);
  } catch (err) {
    return errorHandler(err, 'An error occurred while updating user invitation.');
  }
};
