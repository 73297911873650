//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useState, useEffect, useRef, useCallback } from 'react';

//utils
import { createNotification } from 'store/notifications/actions';
import { requestCalendars } from 'utils/api/calendars';
import { Calendar } from 'utils/types/calendar';
import { apiUrls } from 'utils/constants/apiUrls';
import { ParamsType } from 'hooks/useUrlParams';
import { PaginationWithDataResponse } from 'utils/types';
import { DEFAULT_PAGE, UNLIMITED_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { useQuery } from 'react-query';
import { getCalendarsQuery } from 'utils/constants/reactQueries';

export const useCalendarsQuery = (
  companyId?: string,
  isAdmin?: boolean,
  urlParams?: ParamsType
) => {
  const dispatch = useAppDispatch();

  return useQuery(
    [getCalendarsQuery, companyId, urlParams],
    async () => {
      try {
        const uriPath = isAdmin
          ? `${apiUrls.admin}${apiUrls.calendars}`
          : `${apiUrls.companies}/id=${companyId}${apiUrls.calendars}`;
        const responsData = await requestCalendars(
          uriPath,
          urlParams ?? { page: DEFAULT_PAGE, pageSize: UNLIMITED_ITEMS_PER_PAGE }
        ).then(({ data }) => {
          const { data: calendars, ...pagination } = data;
          return {
            calendars,
            pagination,
          };
        });
        return responsData;
      } catch (err) {
        const error = err as Error;
        dispatch(
          createNotification(
            {
              message: `Error ${error.message ?? ''}`,
              title: 'Calendars not loaded',
              type: 'error',
            },
            error
          )
        );
      }
    },
    {
      enabled: Boolean(companyId),
    }
  );
};

export const useCalendars = (companyID?: string, isAdmin?: boolean) => {
  const [data, setData] = useState<PaginationWithDataResponse<Calendar[]>>({ data: [] });
  const { data: calendars, ...pagination } = data;
  const dispatch = useAppDispatch();
  const { user } = useAuthenticatedUser();
  const [loading, setLoading] = useState<boolean>(false);
  const dataLoading = useRef(false);

  const fetchData = useCallback(async () => {
    if (companyID) {
      try {
        const uriPath = isAdmin
          ? `${apiUrls.admin}${apiUrls.calendars}`
          : `${apiUrls.companies}/id=${companyID}${apiUrls.calendars}`;
        dataLoading.current = true;
        setLoading(true);
        const res = await requestCalendars(uriPath, {
          page: DEFAULT_PAGE,
          pageSize: UNLIMITED_ITEMS_PER_PAGE,
        });
        setData(res.data);
      } catch (err) {
        const error = err as Error;
        dispatch(
          createNotification(
            {
              message: `Error ${error.message ?? ''}`,
              title: 'Calendars not loaded',
              type: 'error',
            },
            error
          )
        );
      } finally {
        dataLoading.current = false;
        setLoading(false);
      }
    }
  }, [companyID, dispatch, isAdmin]);

  useEffect(() => {
    if (!dataLoading.current && user) fetchData();
  }, [fetchData, user]);

  return { calendars, loading, pagination };
};
