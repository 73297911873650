import { TableHeaderColumn } from 'shared/Tables/Table.types';

export const PCFDetailsListColumnConfig: TableHeaderColumn[] = [
  {
    label: 'Name',
    propName: 'name',
    propType: 'string',
    sort: false,
    width: '33%',
  },
  {
    label: 'Onyx PCF',
    propName: 'onyxPCF',
    propType: 'string',
    sort: false,
    width: '33%',
  },
  {
    label: 'Official PCF',
    propName: 'officialPCF',
    propType: 'string',
    sort: false,
    width: '33%',
  },
];
