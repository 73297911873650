import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

// actions
import { loadNewAssets, loadAssets } from 'store/assets/actions';

// types
import { AssetList } from 'utils/types';

type AssetsStateType = {
  error: string | SerializedError | null;
  loadingExistingAssets: boolean;
  loadingNewAssets: boolean;
  assets: AssetList | null;
};

const initialState: AssetsStateType = {
  error: null,
  loadingExistingAssets: false,
  loadingNewAssets: false,
  assets: null,
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadNewAssets.pending, (state) => {
      state.error = null;
      state.loadingNewAssets = true;
    });

    builder.addCase(loadNewAssets.fulfilled, (state, action) => {
      state.error = null;
      state.loadingNewAssets = false;
      state.assets = {
        ...state.assets,
        new: keyBy(action.payload, 'ticker'),
      };
    });

    builder.addCase(loadNewAssets.rejected, (state, action) => {
      state.loadingNewAssets = false;
      state.error = action.payload ? action.payload : action.error;
    });

    builder.addCase(loadAssets.pending, (state) => {
      state.error = null;
      state.loadingExistingAssets = true;
    });

    builder.addCase(loadAssets.fulfilled, (state, action) => {
      state.error = null;
      state.loadingExistingAssets = false;
      state.assets = {
        ...state.assets,
        existing: keyBy(action.payload, 'ticker'),
      };
    });

    builder.addCase(loadAssets.rejected, (state, action) => {
      state.loadingExistingAssets = false;
      state.error = action.payload ? action.payload : action.error;
    });
  },
  reducers: {},
});

export const assetsReducer = assetsSlice.reducer;
