//components
import Card from 'components/Card';

//styles
import Button from 'components/Button';
import { StyledUploadFooter } from 'pages/Ledger/PCF/components/PCFStyles';
import { CustomModal } from 'shared/Modals/Modal';

interface UploadPCFModalProps {
  onCancel: () => void;
  onApprove: () => void;
}

function PCFApprovalConfirmationModal({ onApprove, onCancel }: UploadPCFModalProps) {
  return (
    <CustomModal open onCloseModal={onCancel}>
      <Card title="Portfolio Composition File Approval" label="PCF" onClose={onCancel}>
        <span>Are you sure you want to approve this PCF?</span>
        <StyledUploadFooter>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="interactive" onClick={onApprove}>
            Approve
          </Button>
        </StyledUploadFooter>
      </Card>
    </CustomModal>
  );
}

export default PCFApprovalConfirmationModal;
