import { TableHeaderColumn } from 'shared/Tables/Table.types';

export const getConstituentsTableColumnsConfig = (showStatus: boolean) =>
  [
    {
      label: 'Ticker',
      propName: 'ticker',
      propType: 'string',
      sort: false,
      width: '18%',
    },
    { label: 'Name', propName: 'name', propType: 'string', sort: false, width: '18%' },
    {
      label: 'Asset Class',
      propName: 'assetType',
      propType: 'string',
      sort: false,
      width: '14%',
    },
    {
      label: 'Staking',
      propName: 'stakingFee',
      propType: 'string',
      sort: false,
      width: '14%',
    },
    {
      label: 'Interest Generating',
      propName: 'interestGenerating',
      propType: 'string',
      sort: false,
      width: '14%',
    },
    {
      label: 'Leveraged',
      propName: 'isLeveraged',
      propType: 'string',
      sort: false,
      width: '14%',
    },
    showStatus && {
      label: 'Status',
      propName: 'status',
      propType: 'string',
      sort: false,
      width: '14%',
    },
  ].filter((column) => Boolean(column)) as TableHeaderColumn[];
