import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getWalletsForCompanyRequest } from 'utils/api/wallets';
import { getWalletsPerCompanyQuery } from 'utils/constants/reactQueries';

export const useWalletsPerCompanyQuery = (
  companyId: string,
  type: 'CUSTODIAN' | 'OWN',
  params?: ParamsType
) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getWalletsPerCompanyQuery + type, companyId, type, params],
    async () => {
      try {
        const res = await getWalletsForCompanyRequest(companyId, type, params ?? {});
        const { data, ...pagination } = res.data;
        return { data, pagination };
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(companyId),
    }
  );
};
