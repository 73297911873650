import { useFormContext } from 'react-hook-form';
import Button from 'components/Button';
import { Row, Column } from 'components/Grid';
import { ProductStatus } from 'utils/types/product';
import { CUSTODIAN_INFO_STEP, WALLETS_INFO_STEP } from './Form/PartnerDetails/PartnerDetails.steps';
import { useTokenContext } from 'contexts/tokens';
import { CONSTITUENT_STEP } from './Form/TokenDetails/TokenDetails.steps';

interface FooterProps {
  isFirstStep?: boolean;
  isSubmitDisabled?: boolean;
  goBack?: () => void;
  onSubmit?: () => void;
}

function Footer({ isFirstStep, goBack, isSubmitDisabled, onSubmit }: FooterProps) {
  const {
    formState: { isValid = true },
  } = useFormContext() || { formState: {} };
  const { currentToken, tokenActiveStep } = useTokenContext();

  const isActiveToken = currentToken?.status === ProductStatus.ACTIVE;
  const isStepWithTables = [CUSTODIAN_INFO_STEP, WALLETS_INFO_STEP, CONSTITUENT_STEP].includes(
    tokenActiveStep ?? ''
  );
  const offsetColumnsNextButton = isFirstStep ? 6 : undefined;
  const nextButtonType = onSubmit ? 'button' : 'submit';
  return (
    <Row>
      <Column cols={4}></Column>
      {!isFirstStep && (
        <Column offset={4} cols={2}>
          <Button
            data-qa-id="backButton"
            fullWidth
            type="button"
            variant="secondary"
            onClick={goBack}
          >
            Back
          </Button>
        </Column>
      )}
      <Column cols={2} offset={offsetColumnsNextButton}>
        <Button
          data-qa-id="nextButton"
          disabled={isSubmitDisabled || !isValid}
          fullWidth
          onClick={onSubmit}
          type={nextButtonType}
          variant="interactive"
        >
          {isActiveToken && !isStepWithTables ? 'Save' : 'Next'}
        </Button>
      </Column>
    </Row>
  );
}

export default Footer;
