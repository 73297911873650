import { Box, styled as MuiStyled } from '@mui/material';

interface RotatingIconProps {
  rotateicon: 'false' | 'true';
}

export const RotatingIcon = MuiStyled(Box)<RotatingIconProps>(
  ({ theme, rotateicon = 'false' }) => ({
    svg: {
      stroke: theme.customComponents.sideNavMenuItemPalette.svg.color,
      fill: theme.customComponents.sideNavMenuItemPalette.svg.color,
    },
    transform: rotateicon === 'true' ? 'rotate(0)' : 'rotate(180deg)',
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
  })
);
