import { ReactNode } from 'react';
import { IModalWithData } from 'shared/Modals/types';
import { CreateStockSplitModal } from './CreateStockSplitModal/CreateStockSplitModal';

export interface RegisterModalData {
  apiUri: string;
}

export const RegisterModal = ({ ...props }: IModalWithData) => {
  const RegisterModals: Record<string, ReactNode> = {
    CREATE: <CreateStockSplitModal {...props} />,
  };

  return <>{RegisterModals[props.data.type]}</>;
};
