import { styled as MuiStyled } from '@mui/material';
import { Box, Typography } from '@mui/material';

export const MuiStyledUploadContainer = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacers['spacing-16'],
  width: '100%',
}));

export const MuiStyledHeader = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacers['spacing-04'],
  '&[aria-disabled="true"] > *': {
    color: theme.palette.text.disabled,
  },
}));

export const MuiStyledHelperText = MuiStyled(Typography)(({ theme }) => ({
  ...theme.typography.captionSmall,
  color: theme.palette.text.secondary,
}));

/* -----
DROP AREA
----- */
export const MuiStyledUploadDropArea = MuiStyled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.customPalette.interactive.overlay.secondary.enabled,
  border: `2px dashed ${theme.customPalette.interactive.outline.secondary.default}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  padding: '48px 32px',
  outline: 0,
  width: '100%',
  '&:hover:not([aria-disabled="true"]), &:active:not([aria-disabled="true"]), &:focus:not([aria-disabled="true"])':
    {
      borderColor: theme.palette.secondary.main,
    },
  '&:hover:not([aria-disabled="true"]) ${StyledBrowserFiles}, &:active:not([aria-disabled="true"]) ${StyledBrowserFiles}, &:focus:not([aria-disabled="true"]) ${StyledBrowserFiles}':
    {
      color: theme.palette.action.hover,
      textDecoration: 'underline',
    },
  '&[aria-disabled="true"]': {
    backgroundColor: theme.palette.action.disabledBackground,
    borderColor: theme.customPalette.interactive.outline.secondary.disabled,
    cursor: 'not-allowed',
    '& svg': {
      fill: theme.customPalette.interactive.outline.secondary.disabled,
    },
    '& span': {
      color: theme.customPalette.interactive.outline.secondary.disabled,
    },
  },
}));

export const MuiStyledUploadInput = MuiStyled('input')({
  display: 'none',
});

/* -----
FILES
----- */
export const StyledFileItem = MuiStyled('li')`
  background: ${({ theme }) => theme.customPalette.static.background.default.weak};
  border: ${({ theme }) => theme.customPalette.interactive.outline.secondary.default};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

export const StyledFileName = MuiStyled('span')`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.customPalette.static.text.default.standard}; 
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 56px);
  svg {
    color: ${({ theme }) => theme.customPalette.static.text.default.standard}; 
  }
`;
