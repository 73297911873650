import { City, Country } from './location';
import { Actions, Links } from '.';

export type StatusType = 'ACTIVE' | 'PENDING' | 'DELETED';

export enum Status {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
}

export enum PartnerType {
  ADMIN = 'ADMIN',
  AUTHORIZED_PARTICIPANT = 'AUTHORIZED_PARTICIPANT',
  AUTHORIZED_MERCHANT = 'AUTHORIZED_MERCHANT',
  CALCULATING_AGENT = 'CALCULATING_AGENT',
  FUND_ACCOUNTANT = 'FUND_ACCOUNTANT',
  ISSUER = 'ISSUER',
  TECHNICAL_LISTING_AGENT = 'TECHNICAL_LISTING_AGENT',
  CO_ISSUER = 'CO_ISSUER',
}

export enum CompanyRole {
  IS_PCF_UPLOADER = 'isPcfUploader',
}

export type PatnerActions =
  | 'addCalendar'
  | 'addCustodianWallet'
  | 'addOwnWallet'
  | 'addUser'
  | 'delete'
  | 'update';

export interface Partner {
  _id?: string;
  bpId?: string;
  bankingInformation?: string;
  legalAgreementStartDate?: string;
  type: PartnerType;
  name: string;
  email: string;
  roles?: CompanyRole[];
  status?: StatusType;
  updatedAt?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: City;
  country?: Country;
  postalCode?: string;
  _actions?: Actions<PatnerActions>;
  _links?: Links;
}

export interface PartnerSchemaPayload extends Partner {
  countryId: number;
  cityId: number;
}

export interface PartnersUser {
  _id?: string;
  firstName: PartnerType;
  lastName: string;
  email: string;
  status?: StatusType;
  role: string;
  createdAt: string;
  _actions: Actions;
  _links?: Links;
}
