import { useTokenContext } from 'contexts/tokens';
import useAppDispatch from 'hooks/useAppDispatch';
import { diffFields } from 'pages/Instruments/helpers/getDiffFields';
import { CreateTokenDto, PartialTokenFormProps } from 'pages/Tokens/Tokens.types';
import { PARTNERS_STEP } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import { PartnerDetailsFormProps } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import { GENERAL_DETAILS_STEP } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import { TokenFormProps } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.types';
import { useMutation, useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { createToken, getTokenAuthorizedMerchants, getTokens, patchToken } from 'utils/api/tokens';
import { getTokenAuthorizedMerchantsQueryKey, getTokensQuery } from 'utils/constants/reactQueries';
import { ProductStatus, Token } from 'utils/types/product';
import { ParamsType } from './useUrlParams';

export const useGetTokensQuery = (
  params: ParamsType,
  companyId?: string | null,
  enabled?: Boolean
) => {
  const dispatch = useAppDispatch();

  return useQuery(
    [getTokensQuery, params, companyId, enabled],
    async () => {
      try {
        const data = await getTokens({
          ...params,
          authorizedParticipant: companyId ?? null,
        });
        return data;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error?.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(enabled),
    }
  );
};

export function useGetTokenAuthorizedMerchantsQuery(productId?: Token['_id']) {
  const dispatch = useAppDispatch();

  return useQuery(
    [getTokenAuthorizedMerchantsQueryKey, productId],
    async () => {
      try {
        const authorizedMerchants = await getTokenAuthorizedMerchants(productId ?? '');
        return authorizedMerchants.map((am) => ({ label: am.name, value: am.id }));
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error?.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(productId),
    }
  );
}

export const useCreateTokenMutation = () => {
  const dispatch = useAppDispatch();
  const { setCurrentToken } = useTokenContext();

  return useMutation({
    mutationFn: (data: { name?: string; ticker?: string }) => createToken(data),
    onSuccess: (token) => {
      setCurrentToken(token);
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
      throw error;
    },
  });
};

export const useUpdateTokenMutation = () => {
  const dispatch = useAppDispatch();
  const { setCurrentToken } = useTokenContext();

  return useMutation({
    mutationFn: async (data: { token: Token; tokenProps: PartialTokenFormProps }) => {
      return patchToken(data.token?._actions?.update?.uri ?? '', data.tokenProps);
    },
    onSuccess: (token) => {
      setCurrentToken(token);
      if (token.status === ProductStatus.ACTIVE) {
        dispatch(
          createNotification({
            message: `Token ${token.ticker} has been updated successfully`,
            title: 'Product Updated',
            type: 'success',
          })
        );
      }
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
      throw error;
    },
  });
};

export const useSaveToken = () => {
  const {
    currentToken,
    setTokenFormIsDirty,
    tokenActiveStep,
    setTokenFormsData,
    tokenFormsData,
    tokenToFormData,
  } = useTokenContext();

  const createTokenMutation = useCreateTokenMutation();
  const updateTokenMutation = useUpdateTokenMutation();

  const saveToken = (data: Partial<TokenFormProps & PartnerDetailsFormProps>) => {
    // TODO: We are doing this filtering since Steps with Elements are showing the modal for update
    // and we are doing Active Instrument Update on spot with updated action button
    if (
      currentToken?.status === ProductStatus.ACTIVE &&
      [GENERAL_DETAILS_STEP, PARTNERS_STEP].includes(tokenActiveStep ?? '') &&
      tokenActiveStep
    ) {
      return saveTokenToContext(
        data,
        tokenActiveStep as typeof GENERAL_DETAILS_STEP | typeof PARTNERS_STEP
      );
    } else {
      return saveTokenToRemoteAndContext(data);
    }
  };

  const saveTokenToContext = (
    updatedFormData: Partial<TokenFormProps & PartnerDetailsFormProps>,
    actStep: typeof GENERAL_DETAILS_STEP | typeof PARTNERS_STEP
  ) => {
    const currentStepPrevData = tokenToFormData(currentToken)[actStep];
    setTokenFormsData(updatedFormData);
    setTokenFormIsDirty(Boolean(diffFields(updatedFormData[actStep], currentStepPrevData)?.length));
  };

  const saveTokenToRemoteAndContext = (data: Partial<TokenFormProps & PartnerDetailsFormProps>) => {
    if (currentToken === null) {
      if (data[GENERAL_DETAILS_STEP]) {
        createTokenMutation.mutate(data[GENERAL_DETAILS_STEP] as CreateTokenDto);
      }
    } else {
      updateTokenMutation.mutate({ token: currentToken, tokenProps: data });
    }
  };

  const deleteUpdatedField = (fieldName: string) => {
    const updatedValues = {
      [tokenActiveStep as string]: {
        ...tokenFormsData[tokenActiveStep],
        [fieldName]: (tokenToFormData(currentToken)[tokenActiveStep] as { [key: string]: string })[
          fieldName
        ],
      },
    };
    setTokenFormsData({ ...tokenFormsData, ...updatedValues });
  };

  return {
    saveToken,
    saveTokenToRemoteAndContext,
    isSaving: createTokenMutation.isLoading || updateTokenMutation.isLoading,
    deleteUpdatedField,
  };
};
