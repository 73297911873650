import { useActiveModal, useActiveModalData } from 'store/modals/selectors';
import { IModal } from './types';
import useAppDispatch from 'hooks/useAppDispatch';
import { hideModal } from 'store/modals/slice';
import { ModalsList } from './ModalsList';

export const ModalRouter = ({ ...props }: IModal) => {
  const activeModal = useActiveModal();
  const modalData = useActiveModalData(activeModal?.name);
  const dispatch = useAppDispatch();
  if (!modalData) return null;

  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <ModalsList {...props} modalName={activeModal?.name} closeModal={closeModal} data={modalData} />
  );
};
