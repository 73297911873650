export const IndexWeigthsTableConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', width: '18%', sort: false },
  { label: 'Pre-Rebalance', propName: 'preWeight', propType: 'string', width: '20%', sort: false },
  {
    label: 'Post-Rebalance',
    propName: 'postWeight',
    propType: 'string',
    width: '18%',
    sort: false,
  },
  {
    label: 'Difference(Pre)',
    propName: 'Difference(Pre)',
    propType: 'date',
    width: '20%',
    sort: false,
  },
  { label: 'Target', propName: 'target', propType: 'string', width: '12%', sort: false },
  {
    label: 'Difference(Post)',
    propName: 'Difference(Post)',
    propType: 'date',
    width: '20%',
    sort: false,
  },
];
