import { ReactNode } from 'react';
import { IModalWithData } from 'shared/Modals/types';
import { ApproveRebalanceModal } from './ApproveRebalanceModal/ApproveRebalanceModal';
import { SkipRebalanceModal } from './SkipRebalanceModal/SkipRebalanceModal';
import { AddConstituentsModal } from './AddConstituents/AddConstituents';

const getCustomComponent = (props: IModalWithData) => {
  switch (props.data.custom?.type) {
    case 'approve':
      return <ApproveRebalanceModal {...props} />;
    case 'missing-constituents':
      return <AddConstituentsModal {...props} />;
    default:
      return <SkipRebalanceModal {...props} />;
  }
};

export const RebalanceModal = ({ ...props }: IModalWithData) => {
  const CustomComponent = getCustomComponent(props);
  const RebalanceModals: Record<string, ReactNode> = {
    CUSTOM: CustomComponent,
  };

  return <>{RebalanceModals[props.data.type]}</>;
};
