import { useNavigate } from 'react-router-dom';
import { Instrument } from 'utils/types';
import { privateRoutesUrls } from 'router/constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ProductStatus } from 'utils/types/product';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import {
  warningNotification,
  successNotification,
} from 'shared/Notifications/general.notifications';
import { INSTRUMENTS_TAB_STATUS } from 'pages/Instruments/Instruments.constants';
import { getQueryString } from 'hooks/useUrlParams';
import useAppModal from 'hooks/useAppModal';
import { ETP_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { useMutation } from 'react-query';
import { instrumentsActions } from 'store/instruments/slice';
import { submitForApprovalInstrument } from 'utils/api/instruments';

function useOnSubmit(instrument: Instrument | null) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openModal = useAppModal();
  const { populateCurrentInstrument } = instrumentsActions;

  const submitInstrumentApprovalMutation = useMutation({
    mutationFn: (instrument: Instrument) => {
      return submitForApprovalInstrument(instrument._id);
    },
    onSuccess: (res) => {
      dispatch(populateCurrentInstrument({ ...res }));
      createNotification(
        successNotification(
          `ETP instrument details have been submitted and saved`,
          `Product ${res.ticker} saved to Pending Seed`
        )
      );
      openModal({
        modalName: ETP_MODAL,
        modalData: {
          data: instrument,
          type: MODAL_ACTIONS.CUSTOM,
        },
      });
      navigate(
        privateRoutesUrls.dashboardRoutes.etps +
          `?${getQueryString({
            tab: INSTRUMENTS_TAB_STATUS.IN_REVIEW,
            page: DEFAULT_PAGE,
            pageSize: DEFAULT_ITEMS_PER_PAGE,
            status: ProductStatus.IN_REVIEW,
          })}`
      );
    },
    onError: (err: Error, data) => {
      dispatch(
        createNotification(
          warningNotification(
            err.message || `Product ${data.ticker} couldn't be submitted`,
            'Products'
          )
        )
      );
    },
  });

  const onActivateInstrument = async () => {
    if (instrument?._id) {
      if (instrument?._id) {
        openModal({
          modalName: ETP_MODAL,
          modalData: {
            data: instrument,
            type: MODAL_ACTIONS.CREATE,
          },
        });
      }
    }
  };

  const onCreateSeedOrder = async () => {
    if (instrument?._id) {
      openModal({
        modalName: ETP_MODAL,
        modalData: {
          data: instrument,
          type: MODAL_ACTIONS.CUSTOM,
        },
      });
    }
  };

  const onSubmitForApproval = async () => {
    if (instrument?._id) {
      submitInstrumentApprovalMutation.mutate(instrument);
    }
  };

  return { onSubmitForApproval, onCreateSeedOrder, onActivateInstrument };
}

export default useOnSubmit;
