import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getContactsListsQuery } from 'utils/constants/reactQueries';
import { fetchContactLists } from 'utils/api/contacts';

export const useContactsListsQuery = (urlParams?: ParamsType, enabled?: Boolean) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getContactsListsQuery, urlParams],
    async () => {
      try {
        const { data } = await fetchContactLists({
          ...urlParams,
        });

        return data;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(enabled),
    }
  );
};
