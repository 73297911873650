import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { ExistingContact } from './components/ExistingContact';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { PartnersUser } from 'utils/types';
import { contactAlreadyExist } from 'utils/constants/errorCodes';
import { createNotification } from 'store/notifications/actions';
import { createUserForCompanyRequest, updateUserRequest } from 'utils/api/partners';
import { createUserSchemaUrl } from 'components/Form/formSchemas';
import { updateUserReducer } from 'store/user/slice';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useMutation } from 'react-query';
import { useState } from 'react';
import {
  userCreatedNotifications,
  userEditNotifications,
} from 'shared/Notifications/users.notifications';
import { CustomModal } from 'shared/Modals/Modal';
//styles
import {
  // StyledModal,
  MuiStyledModalFooterButtons,
} from 'shared/Modals/Modal.styles';

export interface UserForm {
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
  replaceContact?: Boolean;
}

export const UpdateUserModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const athenticatedUser = useAuthenticatedUser();
  const user = data.data as PartnersUser;
  const { companyData } = data;

  const [existingContact, setExistingContact] = useState(false);
  const [existingContactData, setExistingContactData] = useState<UserForm>();

  const isNewUser = Boolean(data.type === MODAL_ACTIONS.CREATE);
  const actionButtonText = isNewUser ? 'Invite User' : 'Update User';

  const createNewUserMutation = useMutation({
    mutationFn: (userData: UserForm) =>
      createUserForCompanyRequest(companyData?.id ?? '', userData),
    onSuccess: (_, userData) => {
      dispatch(createNotification(userCreatedNotifications.success(userData?.email)));
      setExistingContact(false);
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: any, userData) => {
      if (error.cause.code === contactAlreadyExist) {
        setExistingContact(true);
        setExistingContactData(userData);
        return;
      }
      dispatch(createNotification(userCreatedNotifications.error(error.message), error));
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: (userData: UserForm) =>
      updateUserRequest(user?._actions?.update?.uri ?? '', userData),
    onSuccess: (res, userData) => {
      if (athenticatedUser.user?._id === user._id) {
        dispatch(updateUserReducer(res.data));
      }
      dispatch(
        createNotification(
          userEditNotifications.success(`${userData.firstName} ${userData.lastName}`)
        )
      );
      setExistingContact(false);
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: any, userData) => {
      if (error.cause.code === contactAlreadyExist) {
        setExistingContact(true);
        setExistingContactData(userData);
        return;
      }
      dispatch(createNotification(userEditNotifications.error(error.message), error));
    },
  });

  const handleUpdate = (formValues: UserForm) => {
    const userData = { ...formValues, email: formValues.email.toLowerCase() };
    if (isNewUser) {
      createNewUserMutation.mutate(userData);
    } else {
      updateUserMutation.mutate(userData);
    }
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        fullWidth
        type="submit"
        data-qa-id="updateUserButton"
        isLoading={createNewUserMutation.isLoading || updateUserMutation.isLoading}
      >
        {actionButtonText}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      {existingContact && existingContactData ? (
        <ExistingContact
          data={data}
          user={existingContactData}
          handleUpdate={handleUpdate}
          closeModal={closeModal}
        />
      ) : (
        <Form schemaUrl={createUserSchemaUrl} onSubmit={handleUpdate} initialValues={user ?? {}}>
          <Card title={actionButtonText} label={'Users'} footer={Footer} onClose={closeModal}>
            <Input name="firstName" />
            <Input name="lastName" />
            <Input name="email" />
            <Select name="role" data-qa-id="role" data-qa-options-id="role" />
          </Card>
        </Form>
      )}
    </CustomModal>
  );
};
