import { ParamsType } from 'hooks/useUrlParams';
import { useQuery } from 'react-query';
import { requestCompanies } from 'utils/api/partners';
import { getCompaniesQuery } from 'utils/constants/reactQueries';

export const useRequestCompaniesMappedQuery = (
  paramsWithPagination: ParamsType,
  enabled: boolean
) => {
  return useQuery(
    [getCompaniesQuery],
    () =>
      requestCompanies(paramsWithPagination).then(({ data }) =>
        data.data?.map((partner) => ({
          label: partner.name,
          value: partner._id,
          type: partner.type,
        }))
      ),
    {
      enabled: enabled,
    }
  );
};
