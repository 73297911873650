import { CreateProductExchangeModal } from './CreateProductExchangeModal/CreateProductExchangeModal';
import { DeleteProductExchangeModal } from './DeleteProductExchangeModal/DeleteProductExchangeModal';
import { EditProductExchangeModalTicker } from './EditProductExchangeModalTicker/EditProductExchangeModalTicker';
import { Exchange } from 'utils/types';
import {
  ExchangeLocalTickerType,
  ExchangeType,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';

export interface ProductExchangeModalData {
  exchanges?: Exchange[];
  exchangeToEdit?: Exchange;
  exchangeFormData?: ExchangeType;
  localTicker?: ExchangeLocalTickerType;
}

export const ProductExchangeModal = ({ ...props }: IModalWithData) => {
  const ProductExchangeModals: Record<string, ReactNode> = {
    CREATE: <CreateProductExchangeModal {...props} />,
    EDIT: <CreateProductExchangeModal {...props} />,
    CUSTOM: <EditProductExchangeModalTicker {...props} />,
    DELETE: <DeleteProductExchangeModal {...props} />,
  };

  return <>{ProductExchangeModals[props.data.type]}</>;
};
