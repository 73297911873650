import { NotificationConfigType } from 'utils/types';

export const calendarCreatedNotification: NotificationConfigType = {
  success: (message) => ({
    message: `Calendar ${message} has been created succesfully`,
    title: 'Calendar created',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Calendar creation failed',
    type: 'error',
  }),
};

export const calendarUpdatedNotification: NotificationConfigType = {
  success: (message) => ({
    title: 'Calendar updated',
    message: `Calendar ${message} has been updated succesfully`,
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Calendar update failed',
    type: 'error',
  }),
};

export const calendarDeletedNotification: NotificationConfigType = {
  success: (calendarName) => ({
    message: `Calendar ${calendarName} has been deleted succesfully`,
    title: 'Calendar deleted',
    type: 'info',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const calendarEventCreatedNotification: NotificationConfigType = {
  success: (message) => ({
    message: `Calendar Event ${message} has been created succesfully`,
    title: 'Calendar Event created',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Calendar Event creation failed',
    type: 'error',
  }),
};

export const calendarEventUpdatedNotification: NotificationConfigType = {
  success: (message) => ({
    title: 'Calendar Event updated',
    message: `Calendar Event ${message} has been updated succesfully`,
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Calendar update failed',
    type: 'error',
  }),
};

export const calendarEventDeletedNotification: NotificationConfigType = {
  success: (calendarEvent, calendarName) => ({
    message: `Calendar Event ${calendarEvent} has been deleted succesfully from ${calendarName} Calendar.`,
    title: 'Calendar deleted',
    type: 'info',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};
