/* eslint-disable complexity */
import Card from 'components/Card';
import CircularProgress from 'components/CircularProgress';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import { Order, OrderFlow, OrderFormProps } from 'utils/types/orders';
import { OrderFormContainer, StyledDelegateButton } from 'pages/Orders/Orders/Order/Order.styles';
import { ProductStatus } from 'utils/types/product';
import { User } from 'utils/types';
import { tokenOrderDelegatedSchemaUrl, tokenOrderSchemaUrl } from 'components/Form/formSchemas';
import { useEffect, useMemo, useState } from 'react';
import { useUserPermissions } from 'store/user/selectors';
import { useLocation } from 'react-router-dom';
import TokenProductSelector from 'components/TokenProductSelector';
import { useUsersPerCompany } from 'pages/Partners/hooks/useUsersPerCompany';
import { useGetTokenAuthorizedMerchantsQuery } from 'hooks/useTokens';

interface Props {
  changeOrder: (data: OrderFormProps | undefined) => void;
  handleOnSubmit: (data: OrderFormProps) => void;
  isSubmitting?: boolean;
  loading: boolean;
  orderDetails?: Order;
  orderFlow?: OrderFlow;
  user: User | null;
}

const OrderTokenForm = ({
  handleOnSubmit,
  isSubmitting,
  changeOrder,
  orderFlow = 'create',
  orderDetails,
  loading,
}: Props) => {
  const location = useLocation();
  const seedProductId = location?.state?.seedProductId; // Passed to form as initial value to improve "Seed Product" flow
  const permissions = useUserPermissions();

  const { data: tokenAuthorizedMerchantsData, isLoading: loadingAMs } =
    useGetTokenAuthorizedMerchantsQuery(seedProductId ?? orderDetails?.product?._id);
  const [tokenAuthorizedMerchants, setTokenAuthorizedMerchants] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (tokenAuthorizedMerchantsData) {
      setTokenAuthorizedMerchants(tokenAuthorizedMerchantsData);
    }
  }, [tokenAuthorizedMerchantsData]);

  const [companyId, setCompanyId] = useState<string>();
  const { users } = useUsersPerCompany(companyId ?? '');

  const [orderFormData, setOrderFormData] = useState<OrderFormProps>();
  const isDelegatedOrder = permissions?.canCreateOrderDelegated && orderFlow === 'delegate';

  const usersOptions = useMemo(
    () =>
      users?.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user._id })) ??
      [],
    [users]
  );

  useEffect(() => {
    if (orderFormData) {
      orderFormData.companyId && setCompanyId(orderFormData.companyId);
      const order = structuredClone(orderFormData);
      changeOrder(order);
    }
    if (!orderFormData?.productId) {
      setTokenAuthorizedMerchants([]);
    }
  }, [orderFormData, changeOrder]);

  const Footer = (
    <StyledDelegateButton disabled={false} size="large" type="submit" variant="primary">
      {orderFlow === 'delegate' ? 'Delegate Order' : 'Place Order'}
      {isSubmitting && <CircularProgress size={24} />}
    </StyledDelegateButton>
  );

  return (
    <OrderFormContainer>
      <Form
        schemaUrl={isDelegatedOrder ? tokenOrderDelegatedSchemaUrl : tokenOrderSchemaUrl}
        onBlur={(formData) => {
          setOrderFormData(formData as OrderFormProps);
        }}
        onSubmit={handleOnSubmit}
        loading={loading || isSubmitting}
        initialValues={seedProductId && { productId: seedProductId }}
      >
        <Card label="Create Order" title="Order Details" footer={Footer}>
          {/* @TODO TOKENS - label shoud read Product (taken from BE schema) */}
          <TokenProductSelector
            clearable
            name="productId"
            data-qa-id="orderIndex"
            data-qa-options-id="orderIndex"
            permissionFilter={
              // If user is allowed to create delegated Order,
              // we should use createDelegatedOrder action from product _actions object
              isDelegatedOrder ? 'createDelegatedOrder' : 'createOrder'
            }
            resetOnChange={['companyId', 'userId']}
            disabled={Boolean(seedProductId)}
            status={
              isDelegatedOrder
                ? [ProductStatus.ACTIVE, ProductStatus.IN_REVIEW]
                : [ProductStatus.ACTIVE]
            }
            fullWidth
          />
          {orderFlow === 'delegate' && (
            <>
              <Select
                data-qa-id="company"
                data-qa-options-id="company"
                name="companyId"
                options={tokenAuthorizedMerchants}
                disabled={loading || loadingAMs}
                resetOnChange={['userId']}
              />
              <Select
                data-qa-id="user"
                data-qa-options-id="user"
                name="userId"
                options={usersOptions}
                disabled={!companyId || loading}
              />
            </>
          )}
          <Select
            data-qa-id="creationRedemption"
            data-qa-options-id="creationRedemption"
            name="type"
            disabled={loading}
          />
          <Input data-qa-id="creationUnits" name="numberOfUnits" disabled={loading} />
        </Card>
      </Form>
    </OrderFormContainer>
  );
};

export default OrderTokenForm;
