import { Exchange, PaginationWithDataResponse } from 'utils/types';
import { axiosInstance } from 'utils/axiosOnyx';
import { apiUrls } from 'utils/constants/apiUrls';
import { errorHandler } from 'utils/apiUtils';
import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { ExchangeFormDataModel } from 'pages/Admin/ExchangesPage/utils';

export async function loadExchanges() {
  try {
    const { data: exchanges } = await axiosInstance.get<Exchange[]>(`${apiUrls.product}/exchanges`);
    return exchanges;
  } catch (err) {
    return errorHandler(err, 'Error while loading exchanges');
  }
}

export async function loadAdminExchanges(params?: ParamsType) {
  try {
    const { data: exchanges } = await axiosInstance.get<PaginationWithDataResponse<Exchange[]>>(
      params ? `${apiUrls.exchanges}?${getQueryString(params)}` : `${apiUrls.exchanges}`
    );
    return exchanges;
  } catch (err) {
    return errorHandler(err, 'Error while loading exchanges');
  }
}

export const createExchange = async (
  requestDataModel: ExchangeFormDataModel
): Promise<Exchange> => {
  try {
    const { data: exchange } = await axiosInstance.post<Exchange>(
      apiUrls.exchanges,
      requestDataModel
    );
    return exchange;
  } catch (err) {
    return errorHandler(err, 'Error while creating exchange');
  }
};

export const editExchange = async (
  exchangeId: string,
  requestDataModel: ExchangeFormDataModel
): Promise<Exchange> => {
  try {
    const { data: exchange } = await axiosInstance.put<Exchange>(
      `${apiUrls.exchanges}/id=${exchangeId}`,
      requestDataModel
    );
    return exchange;
  } catch (err) {
    return errorHandler(err, 'Error while editing exchange');
  }
};

export const deleteExchange = async (exchangeId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`${apiUrls.exchanges}/id=${exchangeId}`);
  } catch (err) {
    return errorHandler(err, 'Error while deleting exchange');
  }
};
