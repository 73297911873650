import { ORDER_IN_KIND } from 'utils/constants';
import { ExpectedDeliverablesInfoList } from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/ExpectedDeliverables//ExpectedDeliverablesInfoList';
import { ExpectedDeliverablesExpectedTable } from 'pages/Orders/Orders/Order/components/OrderDetails/components/sections/ExpectedDeliverables//ExpectedDeliverablesTable';
import { Deliverable, Order } from 'utils/types/orders';
import { ProductType } from 'utils/types';

export interface ExpectedOrderDeliverablesProps {
  productType: ProductType;
  onChangeSeedDeliverable?: (deliverables: Deliverable) => void;
  order?: Order;
  seedDeliverables?: Deliverable[];
}

export const ExpectedOrderDeliverables = ({
  productType,
  order,
}: ExpectedOrderDeliverablesProps) => {
  return order?.deliveryType === ORDER_IN_KIND || !order?._id ? (
    <ExpectedDeliverablesInfoList order={order} />
  ) : (
    <ExpectedDeliverablesExpectedTable
      productType={productType}
      order={order}
    ></ExpectedDeliverablesExpectedTable>
  );
};
