export const UsersTableColumnsConfig = [
  {
    label: 'Name',
    propName: 'firstName',
    propType: 'string',
    width: '30%',
  },
  { label: 'Email', propName: 'contact.email', propType: 'string', width: '30%' },
  { label: 'Role', propName: 'role', propType: 'string', width: '16%' },
  { label: 'Created at', propName: 'createdAt', propType: 'date', width: '16%' },
];
