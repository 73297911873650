import { Box, styled as MuiStyled } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';

export const StyledProductSelectorContainer = MuiStyled(Box)<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '240px')};
`;

export const StyledFallbackIcon = MuiStyled('span')<{ size: string }>`
  ${() => fontTypes['label-01']};
  background-color: ${({ theme }) => theme.palette.secondary.light};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  display: block;
  height: ${({ size = '16px' }) => size};
  line-height: ${({ size = '16px' }) => size};
  text-align: center;
  width: ${({ size = '16px' }) => size};
`;
