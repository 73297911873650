import { Box, styled as MuiStyled } from '@mui/material';
import loginBg from 'assets/background_1440_1660.svg';

export const StyledTermOfUseContainer = MuiStyled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const StyledTermsOfUseBody = MuiStyled(Box)`
  background: white;
  width: 100%;
  padding: 40px;
`;

export const StyledTermsOfUseCanvas = MuiStyled(Box)`
  box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.25);
  border-radius: 16px;
  background: white;
  width: 100%;
  height: auto;
  min-height: 80%;
  padding: 64px 48px 24px 48px;
  display: flex;
  flex-direction: column;
`;

export const StyledTermsOfUseHeaderWrapper = MuiStyled(Box)`
  background-image: url(${loginBg});
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  height: fit-content;
  width: 100vw;
  justify-content: space-around;
  display: flex;
`;

export const StyledTermsOfUseHeader = MuiStyled(Box)`
  display: grid;
  grid-template-rows: repeat(2, 1fr) 32px;
  gap: 40px;
  background: rgba(146, 154, 164, 0.08);
  border-radius: 16px;
  backdrop-filter: blur(8px);
  max-height: 182px;
  padding: 32px 24px;
  width: calc(100vw - 48px);
  margin: 48px;
`;

export const StyledTermsOfUseHeaderText = MuiStyled(Box)`
  font-size: 32px;
  color: white;
  line-height: 36.8px;
`;

export const StyledBullet = MuiStyled('span')`
  color: #1a1a1a;
  font-size: 20px;
  line-height: 26px;
  word-wrap: break-word;
`;

export const StyledBulletPointContent = MuiStyled('span')`
  color: #1a1a1a;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
`;

export const StyledUpdatedHeaderNote = MuiStyled('span')`
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
`;
