import { ReactNode } from 'react';
import {
  MuiCardBody,
  MuiCardBodyContent,
  MuiCardContainer,
  MuiCardFooter,
  MuiCardHeader,
  MuiCardHeaderTextContainer,
  MuiCardSubtitle,
  StyledMuiButtonContainer,
  StyledTitleLabel,
} from 'components/Card/Card.styles';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { Typography } from '@mui/material';

interface CardProps extends React.PropsWithChildren {
  className?: string;
  footer?: ReactNode;
  headerIcon?: ReactNode;
  label?: string;
  onClose?: () => void;
  subtitle?: string | ReactNode;
  title: string | ReactNode;
  button?: ReactNode;
  exiticon?: 'true' | 'false';
  maxCardHeight?: string;
  maxBodyHeight?: string;
  disableScroll?: boolean;
}

function Card({
  children,
  className,
  footer,
  headerIcon,
  label,
  onClose,
  subtitle,
  title,
  button,
  exiticon = 'false',
  maxCardHeight,
  maxBodyHeight,
  disableScroll = false,
}: CardProps) {
  return (
    <MuiCardContainer className={className} maxHeight={maxCardHeight} disableScroll={disableScroll}>
      {headerIcon}
      <MuiCardHeader withabel={!!label ? 'true' : 'false'} exiticon={exiticon}>
        <MuiCardHeaderTextContainer>
          {label && <StyledTitleLabel>{label}</StyledTitleLabel>}
          <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
            {title}
          </Typography>
          <MuiCardSubtitle>{subtitle}</MuiCardSubtitle>
        </MuiCardHeaderTextContainer>
        {onClose && (
          <StyledMuiButtonContainer onClick={onClose} size="large">
            <CloseIcon />
          </StyledMuiButtonContainer>
        )}
      </MuiCardHeader>
      {children && (
        <MuiCardBody maxHeight={maxBodyHeight} disableScroll={disableScroll}>
          <MuiCardBodyContent>{children}</MuiCardBodyContent>
        </MuiCardBody>
      )}
      {footer && <MuiCardFooter>{footer}</MuiCardFooter>}
    </MuiCardContainer>
  );
}

export default Card;
