import { createAsyncThunk } from '@reduxjs/toolkit';

// types
import { Asset } from 'utils/types';

// api
import {
  loadNewAssets as loadNewAssetsRequest,
  loadAssets as loadAssetsRequest,
} from 'utils/api/assets';

export const loadNewAssets = createAsyncThunk<Asset[], void, { rejectValue: string }>(
  'exchanges/loadNewAssets',
  async (_, { rejectWithValue }) => {
    try {
      const cryptoAssetsList = await loadNewAssetsRequest();
      return cryptoAssetsList;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message);
    }
  }
);

export const loadAssets = createAsyncThunk<Asset[], void, { rejectValue: string }>(
  'exchanges/loadAssets',
  async (_, { rejectWithValue }) => {
    try {
      const constituentAssetsList = await loadAssetsRequest();
      return constituentAssetsList;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message);
    }
  }
);
