/* eslint-disable complexity */
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { ApiKey } from 'utils/types/apiKeys';
import dateToString from 'utils/decorators/dateToString';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import Button from 'components/Button';

type Props = {
  apiKey: ApiKey;
  deleteAction: () => void;
};

export const ApiKeysTableRow = ({ apiKey, deleteAction }: Props) => {
  return (
    <MuiStyledTableRow data-qa-id={`row-${apiKey._id}`} key={apiKey._id}>
      {/* Key */}
      <MuiStyledTableCell data-qa-id={`apiKey-createdAt-${apiKey.tokenAlias}`}>
        {apiKey.tokenAlias}
      </MuiStyledTableCell>
      {/* Created  */}
      <MuiStyledTableCell data-qa-id={`apiKey-createdAt-${apiKey.createdAt}`}>
        {dateToString(apiKey.createdAt)}
      </MuiStyledTableCell>
      {/* Created by */}
      <MuiStyledTableCell data-qa-id={`apiKey-createdBy-${apiKey.createdBy.email}`}>
        {apiKey.createdBy.email}
      </MuiStyledTableCell>
      {/* Created for */}
      <MuiStyledTableCell data-qa-id={`apiKey-createdFor-${apiKey.issuer.name}`}>
        {apiKey.issuer.name}
      </MuiStyledTableCell>
      {/* Last used */}
      <MuiStyledTableCell data-qa-id={`apiKey-createdFor-${apiKey.lastUsedAt}`}>
        {dateToString(apiKey.lastUsedAt)}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell
        align="right"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        <TablePopoverMenu id={apiKey._id ?? ''} className="actionsCell">
          <Button data-qa-id="deleteWallet" fullWidth variant="ghost" onClick={deleteAction}>
            Delete API Key
          </Button>
        </TablePopoverMenu>
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
