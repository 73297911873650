import { styled as MuiStyled } from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';

// types
import { SizeType } from 'utils/types';
import fontTypes from 'utils/theme/designTokens/fontTypes';

interface StyledButtonProps {
  size?: SizeType;
  fullWidth?: boolean;
  isLoading?: boolean;
  square?: 'true' | 'false';
  justifycontent?: string;
}

export const sizeMap = {
  small: '2rem',
  medium: '2.5rem',
  large: '3rem',
};

const StyledButton = MuiStyled('button')<StyledButtonProps>`
  ${() => fontTypes['body-01']};
 
  ${({ isLoading }) =>
    isLoading && {
      justifyContent: 'center',
    }}
`;

export const StyledGhostButton = MuiStyled(StyledButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.customPalette.interactive.fill.secondary.enabled};
  &:hover {
    background-color: #F2F2F2;
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.tertiary.dark};
  }

  &:focus {
    background-color: transparent;
  }

  &&:disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.tertiary.darker};

    svg {
      fill: ${({ theme }) => theme.palette.tertiary.darker};
    }
  }
`;

export const StyledMuiButton = MuiStyled(MuiButton)<StyledButtonProps>`
  ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  span {
    color: currentColor;
  }

  svg {
    flex-shrink: 0;
    fill: currentColor;
  }

  ${({ isLoading }) =>
    isLoading && {
      justifyContent: 'center',
    }}
`;

export const StyledMuiIconButton = MuiStyled(MuiIconButton)<StyledButtonProps>`
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};

  span {
    color: currentColor;
  }

  svg {
    flex-shrink: 0;
    fill: currentColor;
  }

  ${({ isLoading }) =>
    isLoading && {
      justifyContent: 'center',
    }}
`;
