import { Column } from 'components/Grid';
import { Box, styled as MuiStyled } from '@mui/material';
import { MuiStyledTableCheckboxCell } from 'components/Table/TableCell';

interface PartnerActionsTableCellProps {
  nowrap?: boolean;
  padding?: number;
}

export const PartnerActionsTableCell = MuiStyled('td')<PartnerActionsTableCellProps>`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  height: inherit;
  vertical-align: middle;
  text-align: center;
  padding-right: 16px;
  ${({ nowrap }) => nowrap && { 'white-space': 'nowrap' }};
  min-width: 168px;
  margin: auto;
`;

export const StyledTitleColumn = MuiStyled(Column)`
  padding-bottom: 4px;
`;

export const StyledActionBarContainer = MuiStyled(Box)`
  display: flex;
  gap: 48px;
  align-items: center;
  padding-inline: 16px;
`;

export const ActionbarButtonsWrapper = MuiStyled(Box)`
  display: flex;
  gap: 32px;
`;

export const StyledDeliverableItem = MuiStyled(Box)`
  color: white;
`;

export const StyledPCFCheckboxCell = MuiStyled(MuiStyledTableCheckboxCell)(({ theme }) => ({
  '& > *': {
    margin: 'unset',
  },
}));
