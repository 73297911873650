import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import { IModalWithData } from 'shared/Modals/types';
import { registerStockSplitSchemaUrl } from 'components/Form/formSchemas';
import { CustomModal } from 'shared/Modals/Modal';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Grid, Table, Typography } from '@mui/material';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { useMutation } from 'react-query';
import { AddStockSplitDto } from 'pages/Orders/Register/types';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { addStockSplit } from 'utils/api/securities-registry';
import useAppDispatch from 'hooks/useAppDispatch';
import { useState } from 'react';
import DatepickerSingleDate from 'components/DatepickerSingleDate';
import { DateTime } from 'utils/datetime';
import { calculateRatio } from 'utils/ratio';
import { escapeNaN } from 'utils/formatting';

const CreateRegisterModalPreviewConfig = [
  { label: 'Split ratio', propName: 'splitRatio', propType: 'string', sort: false },
  { label: 'Shares before split', propName: 'sharesBeforeSplit', propType: 'string', sort: false },
  { label: 'Shares after split', propName: 'sharesAfterSplit', propType: 'string', sort: false },
];
// eslint-disable-next-line complexity
export const CreateStockSplitModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const [previewData, setPreviewData] = useState<Partial<AddStockSplitDto>>({});

  const productId = data.data as string;

  const splitRatio = calculateRatio(
    previewData.numberOfSharesBefore,
    previewData.numberOfSharesAfter
  );

  const addStockSplitMutation = useMutation({
    mutationFn: (stockSplit: AddStockSplitDto) => {
      return addStockSplit(stockSplit);
    },
    onSuccess: async () => {
      dispatch(
        createNotification(successNotification('Succesfully added stock split', 'Stock split'))
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (err: Error) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
      closeModal();
    },
  });

  const handleOnChange = (data: Partial<AddStockSplitDto>) => {
    setPreviewData(data);
  };

  const handleSubmit = (stockSplit: AddStockSplitDto) => {
    addStockSplitMutation.mutate({ ...stockSplit, splitRatio: splitRatio });
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" fullWidth variant="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        isLoading={addStockSplitMutation.isLoading}
        fullWidth
        variant="interactive"
        type="submit"
      >
        Add
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Form
        initialValues={{
          productId,
          splitRatio,
          date: new DateTime().toDateString(),
        }}
        schemaUrl={registerStockSplitSchemaUrl}
        onSubmit={handleSubmit}
        onChange={handleOnChange}
      >
        <Card footer={Footer} label="Add Stock Split" title="Add Stock Split" onClose={closeModal}>
          <DatepickerSingleDate name="date" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Input name="numberOfSharesBefore" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input name="numberOfSharesAfter" />
            </Grid>
          </Grid>

          <Typography variant="subheadingMedium">Preview</Typography>
          <Table>
            <TableHeaderWithMultiSort
              columns={CreateRegisterModalPreviewConfig}
              columnAlignment={{
                splitRatio: 'right',
                sharesBeforeSplit: 'right',
                sharesAfterSplit: 'right',
              }}
            />
            <MuiStyledTableRow>
              <MuiStyledTableCell align="right">{splitRatio}</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {escapeNaN(previewData.numberOfSharesBefore)}
              </MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {escapeNaN(previewData.numberOfSharesAfter)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          </Table>
        </Card>
      </Form>
    </CustomModal>
  );
};
