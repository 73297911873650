import type { ComponentPropsWithoutRef } from 'react';
import SideNavigation from 'layouts/DashboardContainer/components/SideNav';
import {
  BackgroundImageContainer,
  Content,
  StyledDashboardContainer,
} from './DashboardContainer.style';
import { Box } from '@mui/material';

export interface NavigationProps extends ComponentPropsWithoutRef<'div'> {}

export const DashboardContainer = ({ children, ...props }: NavigationProps) => {
  return (
    <StyledDashboardContainer {...props}>
      <Box sx={{ height: '100%', maxWidth: '100vw', display: 'flex', width: '100%' }}>
        <SideNavigation />
        <Content id="main-content">
          <BackgroundImageContainer />
          {children}
        </Content>
      </Box>
    </StyledDashboardContainer>
  );
};
