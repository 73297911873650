import { ComponentPropsWithoutRef, ReactNode } from 'react';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { LinkPalette } from 'theme/themeTokens/types/ThemeComponentTypes';

export interface LinkProps extends ComponentPropsWithoutRef<'a'> {
  text: string | ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  underline?: 'always' | 'hover' | 'none';
  href?: string;
  variant: 'primary' | 'secondary';
}

const linkStyles = (linkPallete: LinkPalette, underline?: 'always' | 'hover' | 'none') => {
  return {
    color: linkPallete.color,
    ...(underline === 'always' && {
      borderBottom: linkPallete.alwaysUnderlineBorderBottom,
    }),
    '&:hover': {
      ...(underline !== 'none' && {
        color: linkPallete.hover.underlineColor,
      }),
      ...(underline === 'hover' && {
        borderBottom: linkPallete.hover.hoverUnderlineBorderBottom,
      }),
      ...(underline === 'none' && {
        backgroundColor: linkPallete.hover.noUnderlineBackgroundColor,
      }),
    },
  };
};

const disabledLinkStyles = (theme: Theme, underline?: 'always' | 'hover' | 'none') => {
  return {
    color: theme.customPalette.static.text.default.weak,
    ...(underline !== 'none' && {
      borderBottom: `2px solid ${theme.customPalette.interactive.outline.secondary.default}`,
    }),
  };
};

const NewLink = ({
  text,
  disabled,
  variant = 'primary',
  underline = 'hover',
  ...props
}: LinkProps) => {
  const theme = useTheme();
  const link = disabled
    ? disabledLinkStyles(theme, underline)
    : linkStyles(theme.customComponents.linkPalette[variant], underline);

  return (
    <Link sx={{ ...link }} {...props}>
      {text}
    </Link>
  );
};

export default NewLink;
