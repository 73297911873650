import { PropsWithChildren, useEffect } from 'react';
import { DeepPartial, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { fullFormats } from 'ajv-formats/dist/formats';
import { pick } from 'lodash';

// types
import { FormProps } from 'components/Form/Form.types';

// hooks
import useOnyxForm from 'components/Form/hooks/useOnyxForm';
import useFormData from 'components/Form/hooks/useFormData';

// utils
import validateIsTimeBefore from 'components/Form/helpers/validateIsTimeBefore';

// components
import CircularProgress from 'components/CircularProgress';
import Notification from 'components/Notification';

// styles
import { StyledFormLoader, StyledFormWrapper, StyledLoader } from 'components/Form/Form.styles';

function Form<T extends FieldValues>(props: PropsWithChildren<FormProps<T>>) {
  const { schemaUrl, children, onSubmit, initialValues, onBlur, onChange } = props;
  const { formLoading, formProps, schema } = useFormData({
    schemaUrl,
  });

  const form = useForm<T>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    ...(schema && {
      resolver: ajvResolver(schema, {
        formats: fullFormats,
        keywords: [require('ajv-keywords/dist/definitions/transform')(), validateIsTimeBefore()],
        validateFormats: false,
        strictSchema: false,
        strictNumbers: false,
      }),
    }),
  });

  const { control, handleSubmit, register, formState, watch, getValues, resetField } = form;

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      const { dirtyFields } = formState;
      if (onChange) {
        onChange(pick(value, Object.keys({ ...dirtyFields, ...initialValues })) as DeepPartial<T>);
      }
    });
    return () => unsubscribe();
  }, [watch, onChange]);

  const handleFieldOnBlur = () => {
    const { dirtyFields } = formState;
    if (onBlur) {
      const values = getValues();
      onBlur(pick(values, Object.keys({ ...dirtyFields, ...initialValues })) as DeepPartial<T>);
    }
  };

  const processChildren = useOnyxForm({
    formProps,
    control,
    register,
    formState,
    onBlur: handleFieldOnBlur,
    resetField,
  });

  if (formLoading) {
    return (
      <StyledLoader>
        <CircularProgress />
      </StyledLoader>
    );
  }

  return Object.keys(formProps.types).length ? (
    <FormProvider {...form}>
      <StyledFormWrapper onSubmit={handleSubmit(onSubmit)}>
        {props.loading && (
          <StyledFormLoader>
            <CircularProgress />
          </StyledFormLoader>
        )}
        {processChildren(children)}
      </StyledFormWrapper>
    </FormProvider>
  ) : (
    <Notification
      variant="error"
      message={`Something went wrong while loading. Please try again.`}
      title="Error"
    />
  );
}

export default Form;
