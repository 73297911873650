import useAppDispatch from 'hooks/useAppDispatch';
import { Exchange, PaginationWithDataResponse } from 'utils/types';
import { ParamsType } from 'hooks/useUrlParams';
import { createNotification } from 'store/notifications/actions';
import { deleteExchange, loadAdminExchanges } from 'utils/api/exchanges';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { exchangeDeletedNotifications } from 'shared/Notifications/exchange.notifications';
import { useBoolState } from 'hooks/useBoolState';
import { useCallback, useEffect, useRef, useState } from 'react';

type GenericData = {
  isLoading: boolean;
};

export const useLoadExchanges = (params: ParamsType) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [{ data, ...pagination }, setExchanges] = useState<PaginationWithDataResponse<Exchange[]>>({
    data: [],
  });
  const dispatch = useAppDispatch();
  const dataLoading = useRef(false);

  const loadExchanges = useCallback(
    async (params: ParamsType) => {
      if (!dataLoading.current) {
        try {
          setLoading(true);
          dataLoading.current = true;
          const exchanges = await loadAdminExchanges(params);
          setExchanges(exchanges);
        } catch (err) {
          const error = err as Error;
          dispatch(createNotification(errorNotification(error.message), error));
        } finally {
          dataLoading.current = false;
          setLoading(false);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!dataLoading.current) loadExchanges(params);
  }, [loadExchanges, params]);

  return { data, isLoading, refetch: () => loadExchanges(params), pagination };
};

export const useDeleteExchange = (): [(exchange: Exchange) => Promise<void>, GenericData] => {
  const [isLoading, setLoadingStart, setLoadingEnd] = useBoolState(false);
  const dispatch = useAppDispatch();

  const deleteExchangeHandler = async (exchange: Exchange): Promise<void> => {
    setLoadingStart();
    try {
      await deleteExchange(exchange._id);

      dispatch(createNotification(exchangeDeletedNotifications.success(exchange.name)));
    } catch (error: any) {
      dispatch(createNotification(exchangeDeletedNotifications.error(error.message), error));
    } finally {
      setLoadingEnd();
    }
  };

  return [deleteExchangeHandler, { isLoading }];
};
