import Button from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import Input from 'components/Input';
import parseDataToAsset from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentModal/utils/parseDataToAsset';
import { Asset } from 'utils/types';
import { ProductConstituentAssetType, TokenConstituentAssetType } from 'utils/types/product';
import { FieldValues, useForm } from 'react-hook-form';
import { LoadingWrapper } from 'pages/Admin/ContactsPage/ContactsPage.styles';
import { RebalanceConstituent } from 'utils/types/rebalance';
import { StyledCardFooter } from 'components/Card/Card.styles';
import { StyledFieldsContainer } from 'shared/Modals/Custodian/CustodianModal.styles';
import { useEffect, useState } from 'react';
import { useExternalConstituentAssets } from './useExternalConstituentAssets';

interface NewRebalanceConstituentProps {
  onSubmit: (data: ProductConstituentAssetType | TokenConstituentAssetType) => void;
  selectedConstituent: RebalanceConstituent;
  toggleModal: () => void;
  isLoading: boolean;
}

export const NewRebalanceConstituent = ({
  onSubmit,
  selectedConstituent,
  toggleModal,
  isLoading,
}: NewRebalanceConstituentProps) => {
  const [newConstituent, setNewConstitent] = useState<Asset | null>(null);

  const { data: assets, isLoading: isLoadingAssets } = useExternalConstituentAssets();

  useEffect(() => {
    const newConstituentAsset = assets?.find((asset) => {
      return asset.ticker === selectedConstituent.ticker;
    });
    newConstituentAsset && setNewConstitent(newConstituentAsset);
  }, [assets, selectedConstituent.ticker]);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const handleNewConstituentSubmit = (data: FieldValues) => {
    if (assets) {
      onSubmit(
        parseDataToAsset({
          ...data,
          ...newConstituent,
        })
      );
    }
  };

  return isLoadingAssets && !newConstituent ? (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  ) : (
    <form id="new-constituent" onSubmit={handleSubmit(handleNewConstituentSubmit)}>
      <StyledFieldsContainer>
        <Input
          label="Constituent"
          name="ticker"
          placeholder="Ticker"
          value={selectedConstituent?.ticker}
          disabled
        />
        <Input
          {...register('rounding', {
            min: {
              value: 0,
              message: 'Please enter a positive non-decimal number',
            },
            required: 'Please enter a positive non-decimal number',
            valueAsNumber: true,
          })}
          errorMessage={errors.rounding?.message as string}
          isInvalid={Boolean(errors.rounding)}
          label="Rounding"
          name="rounding"
          placeholder="Rounding"
          type="number"
        />
      </StyledFieldsContainer>
      <StyledCardFooter>
        <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          data-qa-id="addButton"
          disabled={!isValid}
          fullWidth
          type="submit"
          isLoading={isLoading}
        >
          Confirm
        </Button>
      </StyledCardFooter>
    </form>
  );
};
