// components
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
// hooks
import { useState } from 'react';
import { NavDifferenceRow } from 'shared/Modals/NavReviewModals/NavReviewModal';
import { useUserPermissions } from 'store/user/selectors';
import { renderZeroValueOrDash } from 'utils/formatting';

type NavReviewDifferencesTableRowProps = {
  differenceItem: NavDifferenceRow;
  rowKey: string | number;
};

export const NavReviewDifferencesTableRow = ({
  differenceItem,
  rowKey,
}: NavReviewDifferencesTableRowProps) => {
  const user = useUserPermissions();
  const [expanded, setExpanded] = useState(false);
  const onRowClick = () => {
    setExpanded(!expanded);
  };
  const navReviewDifferencesTableRow = (
    <MuiStyledTableRow data-qa-id={`${rowKey}-row`} key={differenceItem._id} onClick={onRowClick}>
      <MuiStyledTableCell data-qa-id="name">{rowKey}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-ledger-balance">
        {renderZeroValueOrDash(differenceItem?.onyxValue)}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-fund-accountant-balance">
        {renderZeroValueOrDash(differenceItem?.accountantValue)}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-difference">
        {renderZeroValueOrDash(differenceItem?.difference)}
      </MuiStyledTableCell>
    </MuiStyledTableRow>
  );

  const navReviewDifferencesTableRowFA = (
    <MuiStyledTableRow data-qa-id={`${rowKey}-row`} key={differenceItem._id} onClick={onRowClick}>
      <MuiStyledTableCell data-qa-id="name">{rowKey}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id="onyx-fund-accountant-balance">
        {renderZeroValueOrDash(differenceItem?.accountantValue)}
      </MuiStyledTableCell>
    </MuiStyledTableRow>
  );

  return (
    <>{!user?.isFundAccount ? navReviewDifferencesTableRow : navReviewDifferencesTableRowFA}</>
  );
};
