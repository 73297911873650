import { ParamsType } from 'hooks/useUrlParams';
import { ORDERS_TAB_STATUS, tabHistorical, tabInProgress } from 'utils/constants/orders';
import { ProductType } from 'utils/types/product';

export default function createOrdersTableTitle(productType: ProductType, urlParams: ParamsType) {
  const { tab } = urlParams;
  if (tab) {
    // inprogress
    if (tab === tabInProgress) {
      return `${ORDERS_TAB_STATUS.IN_PROGRESS} ${productType} Orders`;
    }
    if (tab === tabHistorical) {
      return `Historical ${productType} Orders`;
    }
  }

  return `${productType} Orders`;
}
