import Button from 'components/Button';
import Card from 'components/Card';
import {
  StyledContentWrapper,
  StyledLoginContainer,
  StyledLoginFormWrapper,
  StyledLoginLogoWrapper,
} from 'pages/Login/Login.styles';
import { FC } from 'react';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { publicRoutesUrls } from 'router/constants';

const InvitationLinkErorrPage: FC<{ errorMessage: { title: string; content: string } }> = ({
  errorMessage,
}) => {
  const navigate = useNavigate();

  const Footer = (
    <Button variant="interactive" fullWidth onClick={() => navigate(publicRoutesUrls.login)}>
      Back to Homepage
    </Button>
  );
  return (
    <StyledLoginContainer>
      <StyledContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <StyledLoginFormWrapper>
          <Card footer={Footer} title={errorMessage.title}>
            {errorMessage.content}
          </Card>
        </StyledLoginFormWrapper>
      </StyledContentWrapper>
    </StyledLoginContainer>
  );
};

export default InvitationLinkErorrPage;
