import {
  PARTNER_STATUS_LABEL,
  PartnerTabType,
  PARTNERS_TAB_STATUS,
} from 'utils/constants/partners';

export const getPartnerStatus = (tabValue: PartnerTabType): PARTNER_STATUS_LABEL => {
  switch (tabValue) {
    case PARTNERS_TAB_STATUS.ACTIVE:
      return PARTNER_STATUS_LABEL.ACTIVE;
    case PARTNERS_TAB_STATUS.DEACTIVATED:
      return PARTNER_STATUS_LABEL.DEACTIVATED;
    default:
      return PARTNER_STATUS_LABEL.ACTIVE;
  }
};
