import CircularProgress from 'components/CircularProgress';
import EtpDetails from 'pages/Instruments/components/Form/EtpDetails/ETPDetails';
import PartnerDetails from 'pages/Instruments/components/Form/PartnerDetails';
import parseInstrumentToFormData from 'pages/Instruments/helpers/parseInstrumentToFormData';
import useAppDispatch from 'hooks/useAppDispatch';
import { Column, Row } from 'components/Grid';
import { GENERAL_DETAILS_STEP } from './EtpDetails/EtpDetails.steps';
import { INSTRUMENT_DETAILS_TAB, PARTNER_DETAILS_TAB } from 'pages/Instruments/helpers/productTabs';
import { PARTNERS_STEP } from './PartnerDetails/PartnerDetails.steps';
import { StyledContainer } from 'pages/Partners/Partner.styles';
import { instrumentsActions } from 'store/instruments/slice';
import { isEmpty } from 'lodash';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { useEffect, useMemo } from 'react';

function renderCurrentTab(activeTab: typeof INSTRUMENT_DETAILS_TAB | typeof PARTNER_DETAILS_TAB) {
  const tabs = {
    [INSTRUMENT_DETAILS_TAB]: EtpDetails,
    [PARTNER_DETAILS_TAB]: PartnerDetails,
  };
  const Tab = tabs[activeTab];
  return <Tab />;
}

function ETPForm({ activeTab }: { activeTab: 'ETP Details' | 'Partner Details' }) {
  const { instrument, loading } = useCurrentInstrument();
  const dispatch = useAppDispatch();
  const { updateActiveStep } = instrumentsActions;
  const insturmentFormsData = useMemo(() => parseInstrumentToFormData(instrument), [instrument]);
  const isLoadingFormData = loading || (!isEmpty(instrument) && isEmpty(insturmentFormsData));

  useEffect(() => {
    if (activeTab === INSTRUMENT_DETAILS_TAB) {
      dispatch(updateActiveStep(GENERAL_DETAILS_STEP));
    } else {
      dispatch(updateActiveStep(PARTNERS_STEP));
    }
  }, [activeTab, dispatch, updateActiveStep]);

  return (
    <StyledContainer>
      {isLoadingFormData ? (
        <Row>
          <Column offset={5}>
            <CircularProgress />
          </Column>
        </Row>
      ) : (
        renderCurrentTab(activeTab)
      )}
    </StyledContainer>
  );
}

export default ETPForm;
