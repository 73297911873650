export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
}

export const USER_TAB_STATUS = {
  ACTIVE: 'active',
  INVITED: 'invited',
};

export enum USERS_STATUS_LABEL {
  ACTIVE = 'Active',
  PENDING = 'Invited',
  DEACTIVATED = 'Deactivated',
}

export type UsersTabType =
  | typeof USER_TAB_STATUS.ACTIVE
  | typeof USER_TAB_STATUS.INVITED
  | undefined;
