import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import PageTitle from 'components/PageTitle';
import ProductIcon from 'components/ProductIcon';
import { Order } from 'utils/types/orders';
import { capitalize } from 'lodash';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { variantTypes } from 'components/Tag/Tag.types';
import { isDeferred } from 'utils/orders';
import { ProductType } from 'utils/types/product';
import { StyledTagWrapper } from 'pages/Ledger/PCF/components/styles';
import Tag from 'components/Tag';

export interface OrderPageHeaderProps {
  productType: ProductType;
  order?: Order | null;
  isNewOrder?: boolean;
  isDelegatedOrder?: boolean;
}

export const OrderPageHeader = ({
  productType,
  order,
  isNewOrder,
  isDelegatedOrder,
}: OrderPageHeaderProps) => {
  return (
    <StyledTableCellContentWithIcon>
      {order?.status ? (
        <>
          <ProductIcon
            className="product-icon"
            iconUrl={order?.product.fundIcon}
            ticker={order?.product.ticker}
            size="40px"
          />
          <PageTitle>{order?.product.ticker}</PageTitle>
          <PageTitle>{' Order Details'}</PageTitle>
          <StyledTagWrapper>
            <Tag
              label={capitalize(order.status)}
              variant={orderStatusTagVariants[order.status] as variantTypes}
              size="small"
            />
          </StyledTagWrapper>
          {isDeferred(order) && (
            <StyledTagWrapper>
              <Tag label="Deferred" variant="01" size="small" />
            </StyledTagWrapper>
          )}
        </>
      ) : (
        isNewOrder && (
          <PageTitle>
            {isDelegatedOrder
              ? `Create Delegated ${productType} Order`
              : `Create ${productType} Order`}
          </PageTitle>
        )
      )}
    </StyledTableCellContentWithIcon>
  );
};
