import { Slider, styled } from '@mui/material';

interface StyledSliderProps {
  slidercolor?: string;
}

export const StyledSlider = styled(Slider)(({ slidercolor = 'inherit' }: StyledSliderProps) => ({
  backgroundColor: slidercolor,
  height: '6px',
  padding: 0,
  borderRadius: 0,
  '& .MuiSlider-track': {
    backgroundColor: slidercolor,
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    width: '4px',
    height: '12px',
    borderRadius: '2px',
  },
}));
