import Link from 'components/Link';
import {
  StyledSectionHeader,
  StyledSectionBody,
  StyledTermsFooter,
} from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { Order, ProductType, User } from 'utils/types';
import { format } from 'date-fns';
import { parseDate } from 'utils/date';
import { tokensTermsOfuse } from 'utils/externalLinks';
import { Typography } from '@mui/material';

interface TermsProps {
  productType: ProductType;
  order?: Order;
  user: User | null;
}

function getUserName(user: TermsProps['user'], order: TermsProps['order']) {
  let userName;

  if (order?.createdBy?.onBehalfOf?.firstName && order?.createdBy?.onBehalfOf?.lastName) {
    userName = `${order.createdBy.onBehalfOf.firstName} ${order.createdBy.onBehalfOf.lastName}`;
  } else if (order?.createdBy?.firstName && order?.createdBy?.lastName) {
    userName = `${order.createdBy.firstName} ${order.createdBy.lastName}`;
  } else {
    userName = `${user?.firstName} ${user?.lastName}`;
  }

  return userName;
}

const Terms = ({ user, order, productType }: TermsProps) => {
  const orderDate = order?.createdAt ? new Date(order?.createdAt) : new Date();
  const userName = getUserName(user, order);
  const userEmail =
    order?.createdBy?.onBehalfOf?.email || order?.createdBy?.email || user?.contact?.email;
  const companyCreatedAt = Boolean(order?.authorizedParticipantLegalAgreementStartDate)
    ? parseDate(order?.authorizedParticipantLegalAgreementStartDate!)
    : new Date();
  const agreementDate = format(new Date(companyCreatedAt), 'ccc, dd.MM.yyyy');

  return (
    <section>
      <StyledSectionHeader>
        <Typography variant="subheadingMedium">Terms</Typography>
      </StyledSectionHeader>
      <StyledSectionBody>
        {productType === 'ETP' ? (
          <>
            <Typography variant="bodyMedium">
              All Creation or Redemption Orders are subject to the terms and conditions of the Base
              Prospectus and the applicable Final Terms of the relevant 21Shares AG Exchange Traded
              Products as currently in effect (and attached hereto) and the Authorised Participant
              Agreement between the Authorised Person and 21Shares AG. All representations and
              warranties of the Authorised Participant set forth in the Authorised Participant
              Agreement are incorporated herein by reference.
            </Typography>
            <br />
            <Typography variant="bodyMedium">
              Capitalized terms used but not defined herein shall have the meaning given to them in
              the Authorised Participant Agreement dated as of {agreementDate} entered into by the
              Authorised Participant and 21Shares AG.
            </Typography>
            <br />
            <Typography variant="bodyMedium">
              The undersigned does hereby certify as of the date set forth below that he/she is an
              Authorised Person under the Authorised Participant Agreement and that he/she is
              authorized to deliver this Creation Order to 21Shares AG on behalf of the Authorised
              Participant.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="bodyMedium">
              By submitting, you hereby certify that you have reviewed and agree to the{' '}
              <Link text="Terms of Use." href={tokensTermsOfuse} target="_blank"></Link>
            </Typography>
          </>
        )}
        <StyledTermsFooter>
          <Typography variant="bodyMedium">
            Signature: {userName}, {userEmail}
          </Typography>
          <Typography variant="bodyMedium">Date: {format(orderDate, 'ccc, dd.MM.yyyy')}</Typography>
        </StyledTermsFooter>
      </StyledSectionBody>
    </section>
  );
};

export default Terms;
