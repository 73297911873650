import CircularProgress from 'components/CircularProgress';
import { ProductStatus, Token } from 'utils/types/product';
import { ReactComponent as SaveIcon } from 'assets/save.svg';
import { StyledSavingContainer } from 'pages/Instruments/Instruments.styles';
import { useTokenContext } from 'contexts/tokens';

const PENDING_STATUS = 'PENDING';

function renderSaving() {
  return (
    <>
      <CircularProgress color="inherit" size={16} />
      Saving...
    </>
  );
}

function renderSaved(token: Token | null) {
  const savedMessage = token?.status === PENDING_STATUS ? 'Saved to drafts' : 'Saved';
  return (
    token?._id && (
      <>
        <SaveIcon />
        {savedMessage}
      </>
    )
  );
}

export const SaveProgress = ({ saving }: { saving?: Boolean }) => {
  const { currentToken } = useTokenContext();
  return (
    <StyledSavingContainer>
      {saving ? renderSaving() : renderSaved(currentToken ?? null)}
    </StyledSavingContainer>
  );
};

export const TokenStepSaveProgress = ({ saving }: { saving?: Boolean }) => {
  const { currentToken } = useTokenContext();

  return currentToken?.status === ProductStatus.ACTIVE ? null : <SaveProgress saving={saving} />;
};
