import {
  MenuStyledIcon,
  StyledIconButton,
  StyledMenuItem,
} from 'layouts/DashboardContainer/SideNavigationMenu.style';
import {
  NavItem,
  NavSubItem,
} from 'layouts/DashboardContainer/components/SideNav/SideNavigation.types';
import { ReactComponent as ChevronDown } from 'assets/chevron-down-new.svg';
import { ReactNode } from 'react';

interface SideNavigationMenuItemInterface {
  item: NavItem | NavSubItem;
  selected?: boolean;
  onClick: (item: NavItem | NavSubItem) => void;
  sideMenuRevealed: Boolean;
  iconRotated?: 'true' | 'false';
  customIcon?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children?: ReactNode;
}

export const SideNavigationMenuItem = ({
  item,
  sideMenuRevealed,
  selected,
  iconRotated,
  children,
  onClick,
  customIcon,
}: SideNavigationMenuItemInterface) => {
  return sideMenuRevealed ? (
    <StyledMenuItem
      disableRipple
      data-qa-id={item.title}
      key={item.title}
      startIcon={<MenuStyledIcon alignItems="flex-start">{item.icon}</MenuStyledIcon>}
      endIcon={
        item.subnav &&
        sideMenuRevealed && (
          <MenuStyledIcon marginLeft="auto" alignItems="flex-end" rotate180={iconRotated}>
            {customIcon ? customIcon : <ChevronDown className="chevron" />}
          </MenuStyledIcon>
        )
      }
      revealed={sideMenuRevealed ? 'true' : 'false'}
      selected={selected}
      onClick={() => onClick(item)}
    >
      {children ?? item.title}
    </StyledMenuItem>
  ) : (
    <StyledIconButton
      data-qa-id={item.title}
      onClick={() => onClick(item)}
      selected={selected}
      revealed="false"
    >
      {children ?? <MenuStyledIcon alignItems="center"> {item.icon}</MenuStyledIcon>}
    </StyledIconButton>
  );
};
