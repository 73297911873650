/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';

// hooks
import { useCurrentInstrument } from 'store/instruments/selectors';

// actions

// constants, types
import {
  CustodiansListProps,
  WalletsList,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';

// components
import ETPsWalletsTable from 'pages/Instruments/components/Form/PartnerDetails/components/WalletsInfoStep/components/ETPsWalletsTable/ETPsWalletsTable';
import Footer from 'pages/Instruments/components/Footer';

// utils
import { CUSTODIAN_WALLETS } from 'pages/Instruments/components/Form/utils';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { useInstrumentWalletsQuery } from './hooks/useInstrumentWallets';
import parseInstrumentToFormData from 'pages/Instruments/helpers/parseInstrumentToFormData';
import { useInstrumentCustodiansQuery } from 'pages/Instruments/components/Form/PartnerDetails/components/CustodianInfoStep/hooks/useInstrumentCustodians';
import { ProductCustodianWallet, WalletStatus } from 'utils/types/wallets';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';

interface WalletsInfoStepProps {
  goBack: () => void;
  onSubmit: (data: WalletsList) => void;
}

function WalletsInfoStep({ goBack, onSubmit }: WalletsInfoStepProps) {
  const { data: partners } = useCompaniesSuggestion();

  const { instrument, saving } = useCurrentInstrument();
  const instrumentFormsData = useMemo(() => parseInstrumentToFormData(instrument), [instrument]);
  const {
    data: wallets,
    refetch: refetchWallets,
    isLoading: loadingWallets,
  } = useInstrumentWalletsQuery(instrument?._id ?? '', 'ACTIVE,DELETED');
  const {
    data: custodians,
    refetch: refetchInstrumentCustodians,
    isLoading: loadingCustodians,
  } = useInstrumentCustodiansQuery(instrument?._id ?? '', 'ACTIVE');
  const [activeTab, setActiveTab] = useState(WALLETS_TABS_STATUS.ACTIVE);
  const custodianWallets = wallets ? wallets[CUSTODIAN_WALLETS] : [];
  const filteredWallets = useMemo(
    () =>
      custodianWallets?.filter((wallet) => {
        if (activeTab === WALLETS_TABS_STATUS.DEACTIVATED)
          return wallet.status === WalletStatus.DELETED;
        return wallet.status === WalletStatus.ACTIVE;
      }),
    [activeTab, wallets]
  );

  const handleSubmit = () => {
    onSubmit(wallets as WalletsList);
  };

  return (
    <>
      <ETPsWalletsTable
        loading={loadingWallets || loadingCustodians || saving}
        onUpdate={() => {
          refetchInstrumentCustodians();
          refetchWallets();
        }}
        partners={partners ?? []}
        instrumentFormsData={instrumentFormsData}
        onChangeTab={(tab) => setActiveTab(tab)}
        activeTab={activeTab}
        wallets={filteredWallets as ProductCustodianWallet[]}
        custodians={custodians as CustodiansListProps}
      />
      <Footer goBack={goBack} onSubmit={handleSubmit} />
    </>
  );
}

export default WalletsInfoStep;
