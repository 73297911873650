import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { IModalWithData } from 'shared/Modals/types';
import { PartnersUser } from 'utils/types';
import { createNotification } from 'store/notifications/actions';
import { deleteUserRequest } from 'utils/api/users';
import { useMutation } from 'react-query';
import { userDeactivatedNotifications } from 'shared/Notifications/users.notifications';
import Card from 'components/Card';
import { CustomModal } from 'shared/Modals/Modal';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const DeleteUserModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const user = data.data as PartnersUser;
  const deleteUriPath = user._actions?.delete?.uri ?? '';
  const usersName = `${user.firstName} ${user.lastName}`;

  const deleteUserMutation = useMutation({
    mutationFn: () => deleteUserRequest(deleteUriPath),
    onSuccess: () => {
      dispatch(
        createNotification(
          userDeactivatedNotifications.success(`${user.firstName} ${user.lastName}`)
        )
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(userDeactivatedNotifications.error(error.message), error));
    },
  });

  const handleDelete = () => {
    deleteUserMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={handleDelete}
        type="button"
        data-qa-id="deactivateButton"
        isLoading={deleteUserMutation.isLoading}
      >
        Deactivate
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        title={
          <>
            Are you sure you want to deactivate <b>{usersName}</b> as a user?
          </>
        }
        label={'User'}
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
