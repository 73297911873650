import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createNotification } from 'store/notifications/actions';
import { getApiKeys, deleteApiKey, createApiKey } from 'utils/api/apiKeys';
import { getApiKeysQuery } from 'utils/constants/reactQueries';

export const useGetApiKeysQuery = (companyId: string, params?: ParamsType) => {
  return useQuery(
    [getApiKeysQuery, companyId, params],
    () => getApiKeys(companyId, params ?? {}).then(({ data }) => data),
    {
      enabled: Boolean(companyId),
    }
  );
};

export const useCreateApiKeysMutation = (companyId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation((issuer: string) => createApiKey(companyId, issuer).then(({ data }) => data), {
    onSuccess: (data) => {
      // Invalidate the getApiKeysQuery cache to refetch the API keys
      queryClient.invalidateQueries([getApiKeysQuery, companyId]);
    },
    onError: (error: Error) => {
      dispatch(
        createNotification({
          title: 'Admin',
          message: error.toString(),
          type: 'error',
        })
      );
    },
  });
};
export const useDeleteApiKeysMutation = (companyId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation((deleteUri: string) => deleteApiKey(deleteUri).then(({ data }) => data), {
    onSuccess: () => {
      // Invalidate the getApiKeysQuery cache to refetch the API keys
      queryClient.invalidateQueries([getApiKeysQuery, companyId]);
    },
    onError: (error: Error) => {
      dispatch(
        createNotification({
          title: 'Admin',
          message: error.toString(),
          type: 'error',
        })
      );
    },
  });
};
