import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { Instrument, ProductStatus } from 'utils/types/product';
import { useNavigate } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { getQueryString } from 'hooks/useUrlParams';
import { INSTRUMENTS_TAB_STATUS } from 'pages/Instruments/Instruments.constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { hideModal } from 'store/modals/slice';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const CreateETPSeedOrderModal = ({
  onCloseModalAction,
  closeModal,
  data,
}: IModalWithData) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const instrument = data.data as Instrument;

  const handleClose = () => {
    navigate(
      privateRoutesUrls.dashboardRoutes.etps +
        `?${getQueryString({
          tab: INSTRUMENTS_TAB_STATUS.IN_REVIEW,
          page: DEFAULT_PAGE,
          pageSize: DEFAULT_ITEMS_PER_PAGE,
          status: ProductStatus.IN_REVIEW,
        })}`
    );
    dispatch(hideModal());
    if (onCloseModalAction) onCloseModalAction();
  };

  const handleCreateSeedOrder = () => {
    navigate(privateRoutesUrls.dashboardRoutes.ordersDelegated, {
      state: {
        seedProductId: instrument._id,
      },
    });
    dispatch(hideModal());
    if (onCloseModalAction) onCloseModalAction();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={handleClose}
        type="button"
        data-qa-id="cancelButton"
      >
        Close
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={handleCreateSeedOrder}
        type="submit"
        data-qa-id="createButton"
      >
        Create seed order
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        label="ETP"
        title={`Product ${instrument.ticker} created and ready for seed`}
        onClose={closeModal}
      >
        <span>
          This product has been created and is pending a seed order. To activate this product you
          will need to create and settle a seed order.
        </span>
      </Card>
    </CustomModal>
  );
};
