import PageTitle from 'components/PageTitle';
import { ParamsType } from 'hooks/useUrlParams';
import { StyledUserSettingPageHeader } from './UserSettingsPage.styles';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Typography, useTheme } from '@mui/material';
import { UserSettingsProfile } from './Profile/UserSettingsProfile';
import { UserSettingsSecurity } from './Security/UserSettingsSecurity';
import { dateFormat } from 'shared/Tables/table.utils';
import { format, parseISO } from 'date-fns';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useState } from 'react';

enum USER_SETTINGS_LABEL {
  PROFILE = 'Profile',
  SECURITY = 'Security',
}

function renderCurrentTab(activeTab: USER_SETTINGS_LABEL) {
  const tabs = {
    [USER_SETTINGS_LABEL.PROFILE]: UserSettingsProfile,
    [USER_SETTINGS_LABEL.SECURITY]: UserSettingsSecurity,
  };
  const Tab = tabs[activeTab];
  return <Tab />;
}

export const UserSettingsPage = () => {
  const theme = useTheme();
  const [tab, setTab] = useState<USER_SETTINGS_LABEL>(USER_SETTINGS_LABEL.PROFILE);
  const { user } = useAuthenticatedUser();

  const tabs = [
    [USER_SETTINGS_LABEL.PROFILE, USER_SETTINGS_LABEL.PROFILE],
    [USER_SETTINGS_LABEL.SECURITY, USER_SETTINGS_LABEL.SECURITY],
  ].map((tab) => ({ name: tab[0], param: tab[1] }));

  return (
    <>
      <TitleArea
        title={
          <StyledUserSettingPageHeader>
            <PageTitle>User Settings</PageTitle>
            {user?.updatedAt && (
              <Typography
                variant="subtitle1"
                color={theme.palette.common.white}
                alignSelf={'center'}
              >
                Last updated: {format(parseISO(user.updatedAt.toString()), dateFormat)}
              </Typography>
            )}
          </StyledUserSettingPageHeader>
        }
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (data: ParamsType) => {
            setTab(data.tab as USER_SETTINGS_LABEL);
          },
          active: tab as string,
        }}
      />
      {renderCurrentTab(tab)}
    </>
  );
};
