import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { Exchange } from 'utils/types';
import { IModalWithData } from 'shared/Modals/types';
import { createNotification } from 'store/notifications/actions';
import { deleteExchange } from 'utils/api/exchanges';
import { exchangeDeletedNotifications } from 'shared/Notifications/exchange.notifications';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Stack, Typography } from '@mui/material';

const DeleteExchangeModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const exchange = data?.data as Exchange;

  const deleteExchangeMutation = useMutation({
    mutationFn: (exchangeId: string) => deleteExchange(exchangeId),
    onSuccess: () => {
      dispatch(createNotification(exchangeDeletedNotifications.success(exchange.name)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(exchangeDeletedNotifications.error(error.message), error));
    },
  });

  const onDeleteHandler = (exchange: Exchange) => {
    exchange._id && deleteExchangeMutation.mutate(exchange._id);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="danger"
        fullWidth
        onClick={() => onDeleteHandler(exchange)}
        type="submit"
        data-qa-id="deleteButton"
        isLoading={deleteExchangeMutation.isLoading}
      >
        Delete
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card footer={Footer} title="Exchange Deletion" onClose={closeModal}>
        <Stack>
          <Typography>Are you sure you want to delete {exchange.name}?</Typography>
          <Typography>This action cannot be undone.</Typography>
        </Stack>
      </Card>
    </CustomModal>
  );
};

export default DeleteExchangeModal;
