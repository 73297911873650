import { FC } from 'react';
import Card from 'components/Card';
import { ReactComponent as TwoFAIcon } from 'assets/2fa-line-icon.svg';
import Button from 'components/Button';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

interface Initialise2faSetupInterface {
  handleNext: () => void;
}

export const Initialise2faSetup: FC<Initialise2faSetupInterface> = ({ handleNext }) => {
  return (
    <Card
      title="Set up 2FA"
      headerIcon={<TwoFAIcon />}
      footer={
        <MuiStyledModalFooterButtons>
          <Button style={{ width: '50%' }} onClick={handleNext} variant="interactive" fullWidth>
            <>
              <span>Continue</span>
            </>
          </Button>
        </MuiStyledModalFooterButtons>
      }
    >
      2-Factor authentication is the best way to add an additional layer of security when logging in
      to your Onyx account.
    </Card>
  );
};
