import { ProductTabType, INSTRUMENTS_TAB_STATUS } from 'pages/Instruments/Instruments.constants';
import { ProductStatus } from 'utils/types/product';

export const getProductStatus = (tabValue: ProductTabType): ProductStatus => {
  switch (tabValue) {
    case INSTRUMENTS_TAB_STATUS.ACTIVE:
      return ProductStatus.ACTIVE;
    case INSTRUMENTS_TAB_STATUS.DRAFT:
      return ProductStatus.PENDING;
    case INSTRUMENTS_TAB_STATUS.IN_REVIEW:
      return ProductStatus.IN_REVIEW;
    case INSTRUMENTS_TAB_STATUS.DELETED:
      return ProductStatus.DELETED;
    case INSTRUMENTS_TAB_STATUS.ARCHIVED:
      return ProductStatus.ARCHIVED;
    default:
      return ProductStatus.ACTIVE;
  }
};
