import { Notification } from 'utils/types';
import { createSlice } from '@reduxjs/toolkit';

type NotificationsStateType = {
  notifications: {
    [id: string]: Notification;
  };
};

const initialState: NotificationsStateType = { notifications: {} };

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    removeNotification(state, action) {
      delete state.notifications[action.payload];
    },
    addNotification(state, action) {
      state.notifications = {
        ...state.notifications,
        [action.payload.id]: { ...action.payload },
      };
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;

export default notificationsSlice;
