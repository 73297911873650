import { startCase } from 'lodash';

function formatFieldName(name: string) {
  if (name === 'type') {
    return 'Product Class';
  }

  return startCase(name);
}

export default formatFieldName;
