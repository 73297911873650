import { Button } from '@mui/material';

const butonVariants = ['primary', 'secondary', 'danger', 'inverse'];

export const NewButton = ({ ...props }) => {
  if (props.variant && !butonVariants.find((variant) => variant === props.variant)) {
    throw Error('Variant not supported');
  }

  return <Button {...props}>{props.children}</Button>;
};
