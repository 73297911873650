export enum PCF_STATE_LABEL {
  PENDING_UPLOAD = 'Pending Upload',
  PENDING_APPROVAL = 'Pending Approval',
  CONFIRMED = 'Confirmed',
}

export const PCF_TAB_STATUS = {
  CURRENT: 'current',
  PAST: 'past',
};

export enum PCF_TAB_STATUS_LABEL {
  CURRENT = 'Current',
  PAST = 'Past',
}

export type PCFTabType = typeof PCF_TAB_STATUS.CURRENT | typeof PCF_TAB_STATUS.PAST | undefined;

export const PCF_TABS = [
  [PCF_TAB_STATUS_LABEL.CURRENT, PCF_TAB_STATUS.CURRENT],
  [PCF_TAB_STATUS_LABEL.PAST, PCF_TAB_STATUS.PAST],
];
