import { Box, styled as MuiStyled } from '@mui/material';
import { TableCell } from 'components/Table';

export const StyledMessageWithLink = MuiStyled(Box)`
  a {
    color: ${({ theme }) => theme.palette.blue[600]};
    text-decoration: underline;
  }
`;

export const StyledNotificationsWrapper = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TotalTableCell = MuiStyled(TableCell)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.light};
`;
