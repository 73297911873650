import { ComponentPropsWithoutRef, ReactNode } from 'react';

//types
import { PaginationProps } from 'utils/types';

// components
import Pagination from 'components/Pagination';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';

// styles, assets
import { Grid, Table as MuiTable, useTheme } from '@mui/material';
import { StyledTableToolbar } from './Table.styles';
import TableTitleWithActions from './TableTitleWithActions';
import { capitalize } from 'lodash';

export interface TableProps extends Omit<ComponentPropsWithoutRef<'table'>, 'title'> {
  pagination?: PaginationProps;
  title?: string;
  titleComponent?: ReactNode;
  toolbarTools?: any[];
  tabsComponent?: ReactNode;
  tabsComponentUnderToolbar?: Boolean;
  noPadding?: Boolean;
}

function Table({
  children,
  pagination,
  title,
  titleComponent,
  toolbarTools,
  tabsComponent,
  tabsComponentUnderToolbar,
  noPadding,
  ...props
}: TableProps) {
  const theme = useTheme();

  return (
    <Grid container sx={{ background: theme.palette.background.default }} borderRadius={1}>
      {!tabsComponentUnderToolbar && tabsComponent}
      {title && <TableTitleWithActions title={capitalize(String(title))} />}
      {titleComponent && <TableTitleWithActions titleComponent={titleComponent} />}

      {/* TODO - Convert all tables to use TableTitleWithActions instead of StyledTableToolbar */}
      {toolbarTools && <StyledTableToolbar tools={toolbarTools} />}
      {tabsComponentUnderToolbar && tabsComponent}
      <Grid item xs={12} padding={noPadding ? 0 : 2} overflow={'auto'}>
        <MuiTable {...props}>{children}</MuiTable>
      </Grid>
      {pagination && (
        <Grid xs={12} display={'flex'} justifyContent={'flex-end'}>
          <Pagination {...pagination} />
        </Grid>
      )}
    </Grid>
  );
}

export { TableRow, TableCell };

export default Table;
