import Button from 'components/Button';
import GroupButton from 'components/GroupButton';
import { TableRow } from 'components/Table';
import { Box, styled as MuiStyled } from '@mui/material';
import { MuiStyledTableCells } from './Table/components/TableHeaderWithMultiSort.styles';

export interface TableCellProps {
  noMinWidth?: boolean;
  borderline?: boolean;
}

export const StyledEmptyTableRow = MuiStyled(TableRow)(({ theme }) => ({
  td: {
    textAlign: 'center',
    height: '84px',
    verticalAlign: 'middle',
  },
}));

export const StyledEmptyList = MuiStyled(Box)`
  text-align: center;
  color: #4d4d4d;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
`;

export const StyledActionsTableCell = MuiStyled('td')<TableCellProps>`
  border-bottom: ${({ borderline = true, theme }) =>
    borderline ? `1px solid ${theme.palette.tertiary.dark}` : 'unset'};
  vertical-align: middle;
  text-align: end;

  ${({ noMinWidth }) => !noMinWidth && { 'min-width': '168px' }}
`;

export const StyledTableTopButton = MuiStyled(Button)`
  min-width: fit-content;
`;

export const StyledDownloadButton = MuiStyled(GroupButton)`
  padding: 0 8px;
`;

export const MuiStyledActionsTableCell = MuiStyled(MuiStyledTableCells)(
  ({ theme, align = 'right' }) => ({
    svg: {
      marginRight: '8px',
      cursor: 'pointer',
    },
    'min-width': '68px',
    'vertical-align': 'middle',
    'text-align': `${align}`,
  })
);
