import {
  FULL_SPAN,
  MuiStyledTableCell,
  MuiStyledTableCheckboxCell,
  MuiStyledTableHeaderCell,
} from 'components/Table/TableCell';
import { Contact, getContactType } from 'utils/types/contacts';
import { MuiStyledTableRow } from 'components/Table/TableRow';

interface EditContactListTablePartnerRowDetailsProps {
  contacts: Contact[];
  isExpanded?: boolean;
}

export const EditContactListTablePartnerRowDetails = ({
  contacts,
  isExpanded,
}: EditContactListTablePartnerRowDetailsProps) => {
  if (!contacts.length) {
    return (
      <MuiStyledTableRow key={'empty-row'}>
        <MuiStyledTableCell style={{ alignItems: 'center' }} colSpan={FULL_SPAN}>
          No Contacts.
        </MuiStyledTableCell>
      </MuiStyledTableRow>
    );
  }
  return (
    <>
      <MuiStyledTableRow key={'edit-contacts-list-table-header'}>
        <MuiStyledTableHeaderCell align="left"></MuiStyledTableHeaderCell>
        <MuiStyledTableHeaderCell align="left" width={'25%'}>
          User name
        </MuiStyledTableHeaderCell>
        <MuiStyledTableHeaderCell align="left" width={'25%'}>
          User email
        </MuiStyledTableHeaderCell>
        <MuiStyledTableHeaderCell align="left" width={'50%'} colSpan={2}>
          User type
        </MuiStyledTableHeaderCell>
      </MuiStyledTableRow>
      {contacts.length && (
        <>
          {contacts.map((contact, idx) => (
            <MuiStyledTableRow
              isExpanded={isExpanded}
              data-qa-id={`row-${contact.name}`}
              key={`${idx}_${contact._id}`}
            >
              <MuiStyledTableCheckboxCell></MuiStyledTableCheckboxCell>
              <MuiStyledTableCell data-qa-id={`name-${contact.name}`} width={'25%'}>
                {contact.name}
              </MuiStyledTableCell>
              <MuiStyledTableCell data-qa-id={`email-${contact.name}`} width={'25%'}>
                {contact.email}
              </MuiStyledTableCell>
              <MuiStyledTableCell data-qa-id={`usertype-${contact.name}`} width={'50%'} colSpan={2}>
                {getContactType(contact.type)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        </>
      )}
    </>
  );
};
