import { alpha } from '@mui/material/styles';
import { darkThemeTokens } from './themeTokens/darkTheme/darkThemeTokens';
import { lightThemeTokens } from './themeTokens/lightTheme/lightThemeTokens';
import { blue, cyan, gold, green, grey, neutral, orange, red, yellow } from './colors/baseColors';
import {
  action,
  cellRange,
  common,
  error,
  info,
  interactive,
  notificationsColorRange,
  primary,
  secondary,
  success,
  support,
  tertiary,
  warning,
} from './utils/paletteColors';

const base = {
  grey,
  neutral,
  yellow,
  gold,
  orange,
  red,
  cyan,
  green,
  blue,
  primary,
  secondary,
  tertiary,
  info,
  success,
  warning,
  error,
  common,
  interactive,
  action,
  cellRange,
  notificationsColorRange,
};

// ----------------------------------------------------------------------

export const lightPalette = {
  ...base,
  mode: 'light',
  customBackground: {
    // mathias
    ...lightThemeTokens.static.background,
  },
  background: {
    // base theme
    paper: lightThemeTokens.static.background.default.weak,
    default: lightThemeTokens.static.background.default.weak,
    neutral: grey[200],
  },
  text: {
    ...lightThemeTokens.static.text,
    // base theme
    primary: grey[800],
    secondary: grey[600],
    disabled: grey[500],
  },
  icon: lightThemeTokens.static.icon,
  support: {
    // mathias
    ...lightThemeTokens.static.support,
    // base theme
    ...support,
  },
  fill: lightThemeTokens.interactive.fill,
  overlay: lightThemeTokens.interactive.overlay,
  outline: lightThemeTokens.interactive.outline,
  action: {
    ...base.action,
    active: grey[600],
  },
};

export const darkPalette = {
  ...base,
  mode: 'dark',
  customBackground: {
    // mathias
    ...darkThemeTokens.static.background,
  },
  background: {
    // base theme
    paper: grey[800],
    default: grey[900],
    neutral: alpha(grey[500], 0.12),
  },
  text: {
    ...darkThemeTokens.static.text,
    // base theme
    primary: darkThemeTokens.static.background.inverse.weak,
    secondary: grey[500],
    disabled: grey[600],
  },
  icon: darkThemeTokens.static.icon,
  support: {
    // mathias
    ...darkThemeTokens.static.support,
    // base theme
    ...support,
  },
  fill: darkThemeTokens.interactive.fill,
  overlay: darkThemeTokens.interactive.overlay,
  outline: darkThemeTokens.interactive.outline,
  action: {
    ...base.action,
    active: grey[500],
  },
};

export function palette(mode: 'light' | 'dark') {
  return mode === 'light' ? lightPalette : darkPalette;
}
