import Table, { TableProps } from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { ParamsType } from 'hooks/useUrlParams';
import ContactsListTableRow from './ContactsListsTableRow';
import { ContactList } from 'utils/types';
import {
  StyledToolTipRowsContainer,
  StyledRow,
  StyledTitleDiv,
  StyledContentDiv,
} from './ContactsList.styles';
import React from 'react';
import { contactListTypesToolTipText } from 'pages/Admin/ContactsPage/ContactsPage.constants';
import { ContactsListColumnsAlignments, ContactsListColumnsConfig } from './tableColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import isEmpty from 'lodash/isEmpty';

interface ContactsListsTableProps {
  contactLists: ContactList[];
  tableProperties: TableProps;
  loading: boolean;
  editAction: (contactList: ContactList) => void;
  editListInfoAction: (contactList: ContactList) => void;
  addContactsToListAction: (contactList: ContactList) => void;
  deleteListAction: (contactList: ContactList) => void;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

export const ContactsListsTable = ({
  contactLists,
  tableProperties,
  loading,
  editAction,
  editListInfoAction,
  addContactsToListAction,
  deleteListAction,
  urlParams,
  setUrlParams,
}: ContactsListsTableProps) => {
  const toolTipText = Object.entries(contactListTypesToolTipText).map(([key, value]) => ({
    id: key,
    ...value,
  }));

  const toolTipContent = (
    <StyledToolTipRowsContainer>
      {toolTipText.map((data, index) => (
        <StyledRow key={index}>
          <StyledTitleDiv>{data.title}</StyledTitleDiv>
          <StyledContentDiv sx={{ wordWrap: 'break-word' }}>{data.content}</StyledContentDiv>
        </StyledRow>
      ))}
    </StyledToolTipRowsContainer>
  );

  return (
    <Table {...tableProperties}>
      <TableHeaderWithMultiSort
        columns={ContactsListColumnsConfig}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
        toolTipContent={toolTipContent}
        toolTipArrow
        columnAlignment={ContactsListColumnsAlignments}
        showActionsCell
      />
      <TableBodyWithStates
        loadingData={Boolean(loading)}
        hasContent={!isEmpty(contactLists)}
        noContentLabel="No Contacts lists."
      >
        {contactLists?.map((contactList) => (
          <ContactsListTableRow
            key={contactList._id}
            editAction={() => editAction(contactList)}
            editListInfoAction={() => editListInfoAction(contactList)}
            addContactsToListAction={() => addContactsToListAction(contactList)}
            deleteListAction={() => deleteListAction(contactList)}
            contactList={contactList}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};
