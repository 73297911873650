import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import PageTitle from 'components/PageTitle';
import { TOKENS_STATUS_LABEL } from 'pages/Tokens/Tokens.constants';
import { Token, ProductStatus } from 'utils/types/product';
import ProductIcon from 'components/ProductIcon';
import { StyledStatusTag } from 'pages/Tokens/Tokens.styles';

export interface TokensPageHeaderProps {
  token?: Token | null;
  isNewProduct?: boolean;
}

export const TokensPageHeader = ({ token, isNewProduct }: TokensPageHeaderProps) => {
  return (
    <>
      {token ? (
        <StyledTableCellContentWithIcon>
          <ProductIcon
            className="product-icon"
            iconUrl={token?.fundIcon}
            ticker={token?.ticker ?? ''}
            size="40px"
          />
          <PageTitle>
            {token?.ticker}
            {' / '}
            {token?.name}
          </PageTitle>
          <StyledStatusTag
            label={
              TOKENS_STATUS_LABEL[token.status.toUpperCase() as ProductStatus] ??
              TOKENS_STATUS_LABEL.PENDING
            }
            variant={ProductStatus.ACTIVE === token?.status?.toUpperCase() ? '03' : '01'}
          />
        </StyledTableCellContentWithIcon>
      ) : (
        isNewProduct && (
          <StyledTableCellContentWithIcon>
            <PageTitle>Create Token</PageTitle>
          </StyledTableCellContentWithIcon>
        )
      )}
    </>
  );
};
