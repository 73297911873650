import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getNavReviews } from 'utils/api/portfolio-composition';
import { getNavReviewDataQuery } from 'utils/constants/reactQueries';

export const useNavReviewDataQuery = (queryParams: ParamsType) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getNavReviewDataQuery, queryParams],
    async () => {
      try {
        const res = await getNavReviews(queryParams);
        return res;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: true,
    }
  );
};
