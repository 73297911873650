import { createAsyncThunk } from '@reduxjs/toolkit';

// types
import { Exchange } from 'utils/types';

// api
import { loadExchanges as loadExchangesRequest } from 'utils/api/exchanges';

export const loadExchanges = createAsyncThunk<Exchange[], void, { rejectValue: string }>(
  'exchanges/loadExchanges',
  async (_, { rejectWithValue }) => {
    try {
      const exchanges = await loadExchangesRequest();
      return exchanges;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error.message);
    }
  }
);
