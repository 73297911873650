import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import spaceTokens from 'theme/utils/spacers';
import { ButtonVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';

export const buttonPalette = (themeTokens: ThemeTokens): ButtonVariantsInterface => ({
  danger: {
    highEmphasis: {
      color: themeTokens.interactive.fill.danger.onDanger,
      backgroundColor: themeTokens.interactive.fill.danger.enabled,
      hover: {
        backgroundColor: themeTokens.interactive.fill.danger.hover,
      },
      active: {
        backgroundColor: themeTokens.interactive.fill.danger.pressed,
      },
      focusVisible: {
        backgroundColor: themeTokens.interactive.fill.danger.enabled,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: themeTokens.interactive.fill.danger.onDangerDisabled,
        backgroundColor: themeTokens.interactive.fill.danger.disabled,
      },
    },
    mediumEmphasis: {
      color: themeTokens.interactive.fill.danger.enabled,
      border: `1.25px solid ${themeTokens.interactive.outline.danger.enabled}`,
      hover: {
        color: themeTokens.interactive.fill.danger.hover,
        border: `1.25px solid ${themeTokens.interactive.outline.danger.hover}`,
      },
      active: {
        color: themeTokens.interactive.fill.danger.pressed,
        border: `1.25px solid ${themeTokens.interactive.outline.danger.pressed}`,
      },
      focusVisible: {
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
        border: `1.25px solid ${themeTokens.interactive.outline.danger.enabled}`,
        backgroundColor: themeTokens.interactive.overlay.danger.enabled,
      },
      disabled: {
        color: themeTokens.interactive.fill.danger.onDangerDisabled,
        border: `1.25px solid ${themeTokens.interactive.outline.danger.disabled}`,
      },
    },
    lowEmphasis: {
      color: themeTokens.interactive.fill.danger.enabled,
      hover: {
        color: themeTokens.interactive.fill.danger.hover,
        backgroundColor: themeTokens.interactive.overlay.danger.hover,
      },
      active: {
        color: themeTokens.interactive.fill.danger.pressed,
        backgroundColor: themeTokens.interactive.overlay.danger.pressed,
      },
      focusVisible: {
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
        backgroundColor: themeTokens.interactive.overlay.danger.enabled,
      },
      disabled: {
        color: themeTokens.interactive.fill.danger.onDangerDisabled,
      },
    },
  },
  primary: {
    highEmphasis: {
      color: themeTokens.interactive.fill.primary.onPrimary,
      backgroundColor: themeTokens.interactive.fill.primary.enabled,
      borderRadius: spaceTokens['spacing-08'],
      hover: {
        backgroundColor: themeTokens.interactive.fill.primary.hover,
      },
      active: {
        backgroundColor: themeTokens.interactive.overlay.primarySelected.pressed,
      },
      focusVisible: {
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: themeTokens.interactive.fill.primary.onPrimaryDisabled,
        backgroundColor: themeTokens.interactive.fill.primary.disabled,
      },
    },
    mediumEmphasis: {
      color: themeTokens.interactive.fill.primary.enabled,
      border: `1.25px solid ${themeTokens.interactive.outline.primary.default}`,
      borderRadius: spaceTokens['spacing-08'],
      hover: {
        color: themeTokens.interactive.fill.primary.hover,
        border: `1.25px solid ${themeTokens.interactive.fill.primary.hover}`,
      },
      active: {
        color: themeTokens.interactive.fill.primary.pressed,
        border: `1.25px solid ${themeTokens.interactive.fill.primary.pressed}`,
      },
      focusVisible: {
        border: `1.25px solid ${themeTokens.interactive.fill.primary.pressed}`,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: themeTokens.interactive.fill.primary.onPrimaryDisabled,
        border: `1.25px solid ${themeTokens.interactive.outline.primary.disabled}`,
      },
    },
    lowEmphasis: {
      color: themeTokens.interactive.fill.primary.enabled,
      borderRadius: spaceTokens['spacing-08'],
      hover: {
        backgroundColor: themeTokens.interactive.overlay.primary.hover,
      },
      active: {
        color: themeTokens.interactive.fill.primary.pressed,
        backgroundColor: themeTokens.interactive.overlay.primary.pressed,
      },
      focusVisible: {
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
        backgroundColor: themeTokens.interactive.overlay.secondary.enabled,
      },
      disabled: {
        color: themeTokens.interactive.fill.primary.onPrimaryDisabled,
        backgroundColor: themeTokens.interactive.fill.primary.onPrimaryDisabled,
      },
    },
  },
  inverse: {
    highEmphasis: {
      color: themeTokens.interactive.fill.inverse.onInverse,
      backgroundColor: themeTokens.interactive.fill.inverse.enabled,
      boxShadow: '0px 3px 1px 0px rgba(0, 0, 0, 0.08)',
      hover: {
        backgroundColor: themeTokens.interactive.fill.inverse.hover,
      },
      active: {
        backgroundColor: themeTokens.interactive.fill.inverse.pressed,
      },
      focusVisible: {
        boxShadow: ' 0px 0px 0px 2px #1A1A1A, 0px 0px 0px 4px #FFF',
      },
      disabled: {
        color: themeTokens.interactive.fill.inverse.onInverseDisabled,
        backgroundColor: themeTokens.interactive.fill.inverse.disabled,
      },
    },
    mediumEmphasis: {
      color: themeTokens.static.text.inverse.standard,
      border: `1.25px solid ${themeTokens.interactive.outline.inverse.default}`,
      hover: {
        color: themeTokens.static.text.inverse.strong,
        border: `1.25px solid ${themeTokens.interactive.outline.inverse.hover}`,
      },
      active: {
        color: themeTokens.static.text.inverse.strong,
        backgroundColor: themeTokens.interactive.outline.inverse.pressed,
      },
      focusVisible: {
        border: `1.25px solid ${themeTokens.interactive.outline.inverse.default}`,
        boxShadow: ' 0px 0px 0px 2px #1A1A1A, 0px 0px 0px 4px #FFF',
        backgroundColor: themeTokens.interactive.overlay.secondary.enabled,
      },
      disabled: {
        color: themeTokens.static.text.inverse.strong,
        border: `1.25px solid ${themeTokens.interactive.outline.inverse.disabled}`,
      },
    },
    lowEmphasis: {
      color: themeTokens.static.text.inverse.standard,
      hover: {
        color: themeTokens.static.text.inverse.strong,
        backgroundColor: themeTokens.interactive.overlay.inverse.hover,
      },
      active: {
        color: themeTokens.static.text.inverse.strong,
        backgroundColor: themeTokens.interactive.overlay.inverse.pressed,
      },
      focusVisible: {
        backgroundColor: themeTokens.interactive.overlay.secondary.enabled,
        boxShadow: '0px 0px 0px 2px #1A1A1A, 0px 0px 0px 4px #FFF',
      },
      disabled: {
        color: themeTokens.interactive.fill.inverse.onInverseDisabled,
      },
    },
  },
  secondary: {
    highEmphasis: {
      color: themeTokens.interactive.fill.secondary.onSecondary,
      backgroundColor: themeTokens.interactive.fill.secondary.enabled,
      hover: {
        backgroundColor: themeTokens.interactive.fill.secondary.hover,
      },
      active: {
        backgroundColor: themeTokens.interactive.fill.secondary.pressed,
      },
      focusVisible: {
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: themeTokens.interactive.fill.tertiary.onTertiaryDisabled,
        backgroundColor: themeTokens.interactive.fill.tertiary.disabled,
      },
    },
    mediumEmphasis: {
      color: themeTokens.static.text.default.standard,
      border: `1.25px solid ${themeTokens.interactive.outline.secondary.default}`,
      hover: {
        border: `1.25px solid ${themeTokens.interactive.outline.secondary.hover}`,
      },
      active: {
        border: `1.25px solid ${themeTokens.interactive.outline.secondary.pressed}`,
      },
      focusVisible: {
        color: themeTokens.static.text.default.strong,
        border: `1.25px solid ${themeTokens.interactive.outline.secondary.default}`,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
        backgroundColor: themeTokens.interactive.overlay.secondary.enabled,
      },
      disabled: {
        color: themeTokens.static.text.default.weak,
        border: `1.25px solid ${themeTokens.interactive.outline.secondary.disabled}`,
      },
    },
    lowEmphasis: {
      color: themeTokens.static.text.default.standard,
      hover: {
        color: themeTokens.static.text.default.strong,
        backgroundColor: themeTokens.interactive.overlay.secondary.hover,
      },
      active: {
        color: themeTokens.static.text.default.strong,
        backgroundColor: themeTokens.interactive.overlay.secondary.pressed,
      },
      focusVisible: {
        backgroundColor: themeTokens.interactive.overlay.secondary.enabled,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: themeTokens.static.icon.inverse.weak,
      },
    },
  },
});
