// The store configuration follows the guides here https://redux-toolkit.js.org/usage/usage-guide
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';

// middlewares
import unauthorizedErrorMiddleware from 'store/unauthorizedErrorMiddleware';
import { assetsReducer } from 'store/assets/slice';
import { currencyReducer } from 'store/currency/slice';
import { exchangesReducer } from 'store/exchanges/slice';
import { idleTimeReducer } from './idleTime/slice';
import { instrumentsReducer } from 'store/instruments/slice';
import { modalsReducer } from 'store/modals/slice';
import { notificationsReducer } from 'store/notifications/slice';
import { userReducer, logout } from 'store/user/slice';

const combinedReducer = combineReducers({
  assets: assetsReducer,
  currency: currencyReducer,
  exchanges: exchangesReducer,
  idleTimeout: idleTimeReducer,
  instruments: instrumentsReducer,
  modals: modalsReducer,
  notifications: notificationsReducer,
  user: userReducer,
});

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const rootReducer = (state: any, action: AnyAction) => {
  if (logout.match(action)) {
    /* eslint-disable-next-line  no-undefined */
    state = undefined;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(unauthorizedErrorMiddleware),
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
