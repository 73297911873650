import { MuiStyledTableCell } from 'components/Table/TableCell';
import { TableShowMoreCell } from 'components/Table/TableShowMoreCell';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { Contact } from 'utils/types/contacts';
import { MuiStyledTableRow } from 'components/Table/TableRow';

interface ExternalContactsTableRowProps {
  contact: Contact;
  editAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
  showActionsCell?: boolean;
}

export const ExternalContactsTableRow = ({
  contact,
  editAction,
  deleteAction,
}: ExternalContactsTableRowProps) => {
  return (
    <MuiStyledTableRow
      key={contact.email}
      onClick={() => contact._actions?.update && editAction(contact)}
    >
      <MuiStyledTableCell>{contact.name}</MuiStyledTableCell>
      <MuiStyledTableCell>{contact.email}</MuiStyledTableCell>
      <TableShowMoreCell items={contact?.contactLists.map(({ name }) => name)} />
      <MuiStyledActionsTableCell
        data-qa-id="editIcons"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {contact._actions?.update && (
          <TrashIcon
            data-qa-id="trashIcon"
            onClick={() => {
              deleteAction(contact);
            }}
            className="editIcons"
          />
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
