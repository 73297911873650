import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { AddStockSplitDto, SecuritiesRegister, StockSplit } from 'pages/Orders/Register/types';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { PaginationWithDataResponse } from 'utils/types';

export const getSecuritiesRegistry = async (queryParams: ParamsType) => {
  const urlQueryParams = getQueryString(queryParams);

  try {
    return await axiosInstance.get<PaginationWithDataResponse<SecuritiesRegister[]>>(
      `/securities-registry?${urlQueryParams}`
    );
  } catch (err) {
    return errorHandler(err, 'Error while loading Securities registry');
  }
};

export const getSecuritiesRegisterProductData = async (id: string) => {
  try {
    return await axiosInstance.get<SecuritiesRegister>(`/securities-registry/productId=${id}`);
  } catch (err) {
    return errorHandler(err, 'Error while loading Securities registry');
  }
};

export const addStockSplit = async (data: AddStockSplitDto) => {
  try {
    return await axiosInstance.post<StockSplit>(`/securities-registry/stock-splits`, data);
  } catch (err) {
    return errorHandler(err, 'Error while loading Securities registry');
  }
};
