/* eslint-disable complexity */
import { CASH, ORDER_IN_KIND } from 'utils/constants';
import { Deliverable, Order } from 'utils/types';

export const isFormReadyForSubmission = (
  isDelegatedForm: boolean,
  exchangeClosed: boolean,
  companyId?: string,
  orderDetails?: Order,
  seedDeliverables?: Deliverable[],
  pricingTypeSet?: boolean
) => {
  const delegatedOrderAdditionalProps = companyId && orderDetails?.createdBy?.onBehalfOf?.userId;
  const regularProps =
    orderDetails?.product._id &&
    orderDetails.type &&
    orderDetails.numberOfUnits &&
    orderDetails.deliveryType;

  const validateSeedDeliverablesData =
    Boolean(orderDetails?.hasPortfolioComposition) ||
    (!orderDetails?.hasPortfolioComposition &&
      orderDetails?.deliveries?.expected?.length === seedDeliverables?.length &&
      Boolean(!seedDeliverables?.find((del) => !del.amount)));

  const validPricingType =
    (orderDetails?.deliveryType === CASH && pricingTypeSet) ||
    orderDetails?.deliveryType === ORDER_IN_KIND;

  const canPlaceAnyOrder = Boolean(regularProps) && validPricingType && !exchangeClosed;

  const canPlaceDelegatedOrder =
    Boolean(regularProps) &&
    validPricingType &&
    Boolean(delegatedOrderAdditionalProps) &&
    validateSeedDeliverablesData;

  const formeReadyForSubmission = isDelegatedForm ? canPlaceDelegatedOrder : canPlaceAnyOrder;

  return Boolean(formeReadyForSubmission);
};
