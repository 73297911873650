import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ModalProvider } from 'styled-react-modal';

// helpers, utils
import store from 'store';
import GlobalStyles from 'utils/theme/GlobalStyles';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider } from 'react-query';
// components
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { queryClient } from 'utils/api/queries/queryClient';
import { ModalRouter } from 'shared/Modals/ModalRouter';
import { ModalRouterContextProvider } from 'shared/Contexts/Modal/ModalContextProvider';
import ThemeProvider from './theme/ThemeProvider';
import { DatePickerProvider } from './utils/providers/datePickerProvider';
import { TokenContextProvider } from 'contexts/tokens';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <DatePickerProvider>
        <Provider store={store}>
          <ThemeProvider>
            {/* TOKENS TODO - CONVERT TO A NEW GLOBAL STATE MANAGER */}
            <TokenContextProvider>
              <ModalRouterContextProvider>
                <BrowserRouter>
                  <ModalProvider>
                    <GlobalStyles />
                    <App />
                    <ModalRouter />
                  </ModalProvider>
                </BrowserRouter>
              </ModalRouterContextProvider>
            </TokenContextProvider>
          </ThemeProvider>
        </Provider>
      </DatePickerProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
