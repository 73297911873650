import Button from 'components/Button';
import Card from 'components/Card';
import CircularProgress from 'components/CircularProgress';
import ShowMore from 'components/ShowMore';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  LoadingWrapper,
  MuiStyledContactsContainer,
  MuiStyledContactSelector,
  MuiStyledContactName,
  MuiStyledContactEmail,
  MuiStyledSearchContact,
} from 'pages/Admin/ContactsPage/ContactsPage.styles';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { addContactsToContactList } from 'utils/api/contacts';
import { Stack, Box } from '@mui/material';
import { Checkbox } from 'components/BooleanInputs';
import { ContactList } from 'utils/types/contacts';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { UNLIMITED_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { useContactsListsContactsQuery } from 'pages/Admin/ContactsPage/hooks/api/useContactsListsContactsQuery';
import { useAllExternalContactsQuery } from 'pages/Admin/ContactsPage/hooks/api/useAllExternalContacts';
import { useAllPartnerContactsQuery } from 'pages/Admin/ContactsPage/hooks/api/useAllPartnerContacts';

export const AddContactToContactListModal = ({
  closeModal,
  onCloseModalAction,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const [selectedPartners, setSelectedPartners] = useState<string[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>('');
  const contactList = data?.data as ContactList;

  const { data: externalContacts, isLoading: isLoadingExternalContacts } =
    useAllExternalContactsQuery(Boolean(data));

  const { data: partnersContacts, isLoading: isLoadingPartnerContacts } =
    useAllPartnerContactsQuery(
      {
        pageSize: UNLIMITED_ITEMS_PER_PAGE,
      },
      Boolean(data)
    );

  const { data: contactListContacts, isLoading: isLoadingContactListContacts } =
    useContactsListsContactsQuery(contactList?._id);

  const partners = partnersContacts?.partners;
  const contacts = contactListContacts ?? { external: [], partners: [] };
  const selectedPartnersNames = contacts.partners.map((partner) => partner.name);
  const partnersOptions = partners?.filter((partner) => {
    return !selectedPartnersNames.includes(partner.name) && partner.name.includes(filter);
  });
  const selectedExternalContactsEmails = contacts.external.map(
    (externalContact) => externalContact.email
  );

  const externalContactsOptions = externalContacts?.contacts?.filter((externalContact) => {
    return (
      !selectedExternalContactsEmails.includes(externalContact.email) &&
      (externalContact.email.includes(filter) || externalContact.name.includes(filter))
    );
  });

  const onSelectPartner = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedPartners([...selectedPartners, id]);
    } else {
      setSelectedPartners(selectedPartners.filter((partnerId) => partnerId !== id));
    }
  };

  const onSelectContact = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedContacts([...selectedContacts, id]);
    } else {
      setSelectedContacts(selectedContacts.filter((contactId) => contactId !== id));
    }
  };

  const addContactToContactListMutation = useMutation({
    mutationFn: (data: { contacts?: string[]; partners?: string[]; contactList: ContactList }) => {
      const { contactList, ...contactsToAdd } = data;
      return addContactsToContactList(contactList, contactsToAdd);
    },
    onSuccess: (res, { contactList, ...contactsToAdd }) => {
      const numberOfContactsString =
        (contactsToAdd.contacts?.length || 0) + (contactsToAdd.partners?.length || 0) > 1
          ? 'contacts have'
          : 'contact has';
      dispatch(
        createNotification(
          successNotification(
            `${
              (contactsToAdd.contacts?.length || 0) + (contactsToAdd.partners?.length || 0)
            } ${numberOfContactsString} been added successfully to ${res.data.name} list`,
            `${
              (contactsToAdd.contacts?.length || 0) + (contactsToAdd.partners?.length || 0)
            } ${numberOfContactsString} been added`
          )
        )
      );
      if (onCloseModalAction) {
        onCloseModalAction();
      }
      closeModal();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const handleSubmit = async () => {
    addContactToContactListMutation.mutate({
      contactList,
      partners: selectedPartners,
      contacts: selectedContacts,
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.value;
    setFilter(filter);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" fullWidth variant="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        disabled={isEmpty(selectedContacts) && isEmpty(selectedPartners)}
        fullWidth
        isLoading={addContactToContactListMutation.isLoading}
        onClick={handleSubmit}
        variant="interactive"
      >
        Add
      </Button>
    </MuiStyledModalFooterButtons>
  );

  const isLoading =
    isLoadingExternalContacts || isLoadingPartnerContacts || isLoadingContactListContacts;

  return (
    <CustomModal customwidth="800px" open onCloseModal={closeModal}>
      <Card footer={Footer} label={contactList?.name} title="Add Contact" onClose={closeModal}>
        <MuiStyledSearchContact variant="short" onChange={handleSearch} />
        {isLoading ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <MuiStyledContactsContainer container spacing={2}>
            {partnersOptions
              ?.filter((partner) => !partner.name.includes('Automation'))
              .map((partner) => (
                <MuiStyledContactSelector data-qa-id={`contact-${partner.name}`}>
                  <Checkbox
                    onChange={(isChecked: boolean) => onSelectPartner(partner._id!, isChecked)}
                  />
                  <Box>
                    <MuiStyledContactName>
                      {partner.name} ({partner.contacts.length})
                    </MuiStyledContactName>
                  </Box>
                  {Boolean(partner.contacts.length) && (
                    <ShowMore items={partner.contacts.map((contact) => contact.email)} />
                  )}
                </MuiStyledContactSelector>
              ))}
            {externalContactsOptions?.map((contact) => (
              <MuiStyledContactSelector data-qa-id={`contact-${contact.name}`}>
                <Checkbox
                  onChange={(isChecked: boolean) => onSelectContact(contact._id!, isChecked)}
                />
                <Stack>
                  <MuiStyledContactName>{contact.name}</MuiStyledContactName>
                  <MuiStyledContactEmail>{contact.email}</MuiStyledContactEmail>
                </Stack>
              </MuiStyledContactSelector>
            ))}
          </MuiStyledContactsContainer>
        )}
      </Card>
    </CustomModal>
  );
};

export default AddContactToContactListModal;
