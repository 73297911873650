import BigNumber from 'bignumber.js';
import { Deliverable, Order } from 'utils/types';

interface DeliverableValueProps {
  cashValue?: boolean;
  deliverable?: Deliverable;
  order: Order;
  expectedTotalCash?: boolean;
}

const ZERO_UNITS = 0;

const DeliverableValue = ({
  cashValue,
  deliverable,
  order,
  expectedTotalCash,
}: DeliverableValueProps) => {
  let value = 0;
  if (deliverable && !deliverable.amount) {
    return <span>-</span>;
  }

  value = cashValue
    ? new BigNumber(deliverable?.totalInCurrency || ZERO_UNITS).toNumber()
    : new BigNumber(deliverable?.amount ?? ZERO_UNITS).toNumber();

  if (expectedTotalCash && order.deliveries) {
    value = order.deliveries?.expectedTotalCash;
  }

  let displayValue;

  if (cashValue) {
    const currency = order.deliveries?.expectedTotalCurrency ?? 'USD'; // Default to USD if no currency is provided

    if (currency === 'USD') {
      displayValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value);
    } else if (currency === 'CHF') {
      displayValue = new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
      })
        .format(value)
        .replace('CHF', 'Fr'); // Replace "CHF" with "Fr"
    } else {
      // Handle other currencies
      displayValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(value);
    }
  } else {
    displayValue = value; // If cashValue is false, just return the raw value
  }

  return <>{Boolean(value || value === 0) ? displayValue : '-'}</>;
};

export default DeliverableValue;
