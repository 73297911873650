// types, constants
import { COMPANY_CONTACT_TYPE, ContactList } from 'utils/types/contacts';

import {
  MultiSelectFilter,
  StyledBottomRowRight,
  StyledToolbarsWrapper,
  StyledToollbarBottomRow,
} from 'pages/Admin/ContactsPage/ContactsPage.styles';

import { useEffect, useState } from 'react';
import Search from 'components/Search';

import { ParamsType } from 'hooks/useUrlParams';

// assets
import { OptionType } from 'components/Select/Select.types';
import Button from 'components/Button';

type Props = {
  contactList?: ContactList;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  partnersOptions: OptionType<string>[];
  onBatchDelete?: () => void;
};

const EditContactsListViewToolbar = ({
  contactList,
  setUrlParams,
  urlParams,
  partnersOptions,
  onBatchDelete,
}: Props) => {
  const [search, setSearch] = useState(urlParams.search ?? '');
  const [searchInputValue, setSearchInputValue] = useState(urlParams.search ?? '');

  //   TODO: fetch partners

  useEffect(() => {
    setUrlParams((prevState) => ({
      ...prevState,
      search: search,
    }));
  }, [search, setUrlParams]);

  return (
    <StyledToolbarsWrapper>
      <StyledToollbarBottomRow>
        <StyledBottomRowRight>
          <Search
            variant="short"
            placeholder="Search"
            value={searchInputValue}
            onChange={(event) => setSearchInputValue(event.target.value)}
            onBlur={() => setSearch(searchInputValue)}
            onKeyDown={(event) => event.key === 'Enter' && setSearch(searchInputValue)}
            onClear={() => setSearch('')}
          />
          {onBatchDelete && (
            <Button data-qa-id="deleteContacts" variant="secondary" onClick={() => onBatchDelete()}>
              Delete Contacts
            </Button>
          )}
        </StyledBottomRowRight>
        <StyledBottomRowRight>
          <div>
            <MultiSelectFilter
              data-qa-id="partnersFilter"
              data-qa-options-id="partnersFilter"
              options={[
                { label: 'Unaffiliated', value: COMPANY_CONTACT_TYPE.UNAFFILIATED },
                ...partnersOptions,
              ]}
              placeholder="All partners"
              onChange={(_, values) =>
                setUrlParams((prevState) => ({ ...prevState, partner: (values as string[]) ?? [] }))
              }
              value={urlParams.partner as string[]}
            />
          </div>
        </StyledBottomRowRight>
      </StyledToollbarBottomRow>
    </StyledToolbarsWrapper>
  );
};

export default EditContactsListViewToolbar;
