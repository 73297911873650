import { DefaultValues } from 'react-hook-form';
import { PartnerType } from 'utils/types/partner';
import { useMemo } from 'react';

// types, constants
import { MARKET_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { MarketDetailsProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { instrumentMarketDetailsSchemaUrl } from 'components/Form/formSchemas';

// components
import Form from 'components/Form/Form';
import Footer from 'pages/Instruments/components/Footer';
import MultiSelect from 'components/Select/MultiSelect';
import Select from 'components/Select';

// styles
import Input from 'components/Input';
import { OptionType } from 'components/Select/Select.types';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';

// utils
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import { useInstrumentStepFormData } from 'store/instruments/selectors';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';

interface MarketStepProps {
  goBack: () => void;
  onSubmit: () => void;
}

function MarketStep({ goBack, onSubmit }: MarketStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(MARKET_STEP) as MarketDetailsProps;
  const { data: companiesSuggestions } = useCompaniesSuggestion({
    type: PartnerType.AUTHORIZED_PARTICIPANT,
  });

  const saveValues = (data: DefaultValues<MarketDetailsProps>) => {
    saveInstrumentDraft({
      [MARKET_STEP]: data as MarketDetailsProps,
    });
  };

  const companiesSuggestionsOptions = useMemo((): OptionType<string>[] => {
    if (!companiesSuggestions) {
      return [];
    }

    return companiesSuggestions.map((el) => ({
      label: el.name,
      value: el._id,
    }));
  }, [companiesSuggestions]);

  return (
    <Form<MarketDetailsProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentMarketDetailsSchemaUrl}
    >
      <FormGridContainer>
        <FormGridItem>
          <ProductConfigHeader title="Market Details" />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <ETPStepSaveProgress />
        </FormGridItem>
        <FormGridItem>
          <Input name="sedol" />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            options={companiesSuggestionsOptions}
            data-qa-id="marketMaker"
            data-qa-options-id="marketMaker"
            name="marketMakers"
          />
        </FormGridItem>
        <FormGridItem>
          <Input name="valor" />
        </FormGridItem>
        <FormGridItem>
          <Select
            data-qa-id="collateralAgent"
            data-qa-options-id="collateralAgent"
            name="collateralAgent"
            capitalize
          />
        </FormGridItem>
        <FormGridItem>
          <Input name="wkn" />
        </FormGridItem>
        <FormGridItem>
          <Input name="reutersTicker" />
        </FormGridItem>
        <FormGridItem>
          <Input name="kurzel" />
        </FormGridItem>
        <FormGridItem>
          <Input name="bloombergTicker" />
        </FormGridItem>
        <FormGridItem>
          <Input name="inav" />
        </FormGridItem>
        <FormGridItem>
          <Input name="cusip" />
        </FormGridItem>
      </FormGridContainer>
      <Footer goBack={goBack} onSubmit={onSubmit} />
    </Form>
  );
}

export default MarketStep;
