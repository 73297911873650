import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { requestCompany } from 'utils/api/partners';
import { getCompanyQuery } from 'utils/constants/reactQueries';

export const useCompanyQuery = (companyId: string) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getCompanyQuery, companyId],
    async () => {
      try {
        const res = await requestCompany(companyId);
        return res.data;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(companyId),
    }
  );
};
