import Pusher from 'pusher-js';
import { apiUrls } from 'utils/constants/apiUrls';
import { getAuthorizationHeader } from 'utils/user/storage';

import { PusherNotificationMessage } from 'utils/types/pusher';

class PusherClient {
  private static instance: Pusher | undefined;

  private constructor() {
    // Private constructor to prevent external instantiation
  }

  public static destroy(): void {
    if (PusherClient.instance) {
      PusherClient.instance.user.unbind(process.env.REACT_APP_PUSHER_EVENT);
      PusherClient.instance.connection.disconnect();
    }
    PusherClient.instance = undefined;
  }

  public static initialize(
    onConnectionBind: () => void,
    onUserBind: (msg: PusherNotificationMessage) => void
  ): Pusher {
    if (!PusherClient.instance) {
      PusherClient.instance = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        userAuthentication: {
          endpoint: `${process.env.REACT_APP_BASE_URL}${apiUrls.authNotifications}`,
          transport: process.env.REACT_APP_PUSHER_TRANSPORT_TYPE,
          headers: {
            authorization: getAuthorizationHeader(),
          },
        },
      });

      this.checkConnections();

      PusherClient.instance.signin();
      PusherClient.instance.connection.bind('connected', onConnectionBind);
      PusherClient.instance.user.bind(process.env.REACT_APP_PUSHER_EVENT, onUserBind);

      if (
        process.env.REACT_APP_ENVIRONMENT === 'local' ||
        process.env.REACT_APP_PUSHER_LOGS_ENABLED === 'true'
      ) {
        Pusher.logToConsole = true;
      }
    }
    return PusherClient.instance;
  }

  public static isConnected(): boolean {
    return Boolean(PusherClient.instance);
  }

  /* PRIVATE METHODS */
  private static checkConnections() {
    if (PusherClient.instance) {
      const connection = PusherClient.instance.connection;

      /*
      connection.bind('connecting', () => {
        console.log('Pusher is connecting...');
      });

      connection.bind('connected', () => {
        console.log('Pusher is connected.');
      });

      connection.bind('unavailable', () => {
        console.log('Pusher connection is unavailable.');
      });

      connection.bind('disconnected', () => {
        console.log('Pusher is disconnected.');
      });
      */

      connection.bind('error', (error: any) => {
        console.error(
          `Error on connecting to notifications: (${error.error?.data.code}) ${error.error?.data.message}`
        );
      });
    }
  }
}

export default PusherClient;
