import { TableHeaderColumn } from 'shared/Tables/Table.types';

export const PartnerContactsTableColumnsConfig: TableHeaderColumn[] = [
  {
    label: 'Partner',
    propName: 'partner',
    propType: 'string',
    sort: false,
    width: '40%',
  },
  {
    label: 'Distribution Lists',
    propName: 'distributionList',
    propType: 'string',
    width: '57%',
    sort: false,
    colSpan: 4,
  },
];
