import Button from 'components/Button';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { apiUrls } from 'utils/constants/apiUrls';
import { calendarCreatedNotification } from 'shared/Notifications/calendars.notifications';
import { createNotification } from 'store/notifications/actions';
import { mapCalendarToOptions } from 'utils/calendar';
import { useCalendars } from 'pages/Admin/CalendarsPage/hooks/useCalendars';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Calendar } from 'utils/types/calendar';
import { createEntityApiCall } from 'utils/api/crudActions';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const CalendarConnectModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const [calendar, setCalendar] = useState<string>();
  const companyDataID = data.companyData?.id ?? '';
  const { calendars, loading } = useCalendars(companyDataID, true);
  const calendarOptions = useMemo(() => mapCalendarToOptions(calendars), [calendars]);
  const uriPath = `${apiUrls.companies}/id=${companyDataID}${apiUrls.calendars}`;

  const calendarMutation = useMutation({
    mutationFn: (adminCalendar: Calendar) => {
      return createEntityApiCall(adminCalendar, uriPath, 'Error while creating Calendar');
    },
    onSuccess: (res) => {
      dispatch(createNotification(calendarCreatedNotification.success(res.data.name)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarCreatedNotification.error(err.message), err));
    },
  });

  const handleUpdate = () => {
    const adminCalendar = calendars.find((cal) => cal._id === calendar);
    if (adminCalendar) {
      calendarMutation.mutate(adminCalendar);
    }
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button
        data-qa-id="addExistingCalendarButton"
        fullWidth
        onClick={handleUpdate}
        disabled={Boolean(!calendar)}
        isLoading={calendarMutation.isLoading}
      >
        Add Calendar
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        title={'Existing Holiday Calendar'}
        label={'Calendars'}
        footer={Footer}
        onClose={closeModal}
      >
        {loading ? (
          <Loader />
        ) : (
          <Select
            name="calendar"
            label="Calendars"
            options={calendarOptions}
            placeholder="Select"
            value={calendar ?? ''}
            onChange={(cal) => setCalendar(cal)}
          />
        )}
      </Card>
    </CustomModal>
  );
};
