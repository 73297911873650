import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table from 'components/Table';
import TableHeader from 'shared/Tables/Table/TableHeader';
import isEmpty from 'lodash/isEmpty';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppModal from 'hooks/useAppModal';
import { CONSTITUENT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { ProductConstituentAssetType, TokenConstituentAssetType } from 'utils/types/product';
import { ConstituentsTableRow } from './ConstituentsTableRow';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { TableHeaderColumn } from 'shared/Tables/Table.types';
import { createNotification } from 'store/notifications/actions';
import { useSortHook } from 'hooks/useSortHook';
import { getConstituentsTableColumnsConfig } from './tableColumnsConfig';
import { AssetClass } from 'utils/types/product';
import { StyledConstituentsClassMultiSelectFilter } from './ConstituentsTable.styles';
import { useMemo, useState } from 'react';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { useCurrentProduct } from 'hooks/useCurrentProduct';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import { Grid } from '@mui/material';

interface ConstituentsTableProps {
  constituentsList: ProductConstituentAssetType[] | TokenConstituentAssetType[];
  loading: boolean;
  deleteAction: (constituent: TokenConstituentAssetType | ProductConstituentAssetType) => void;
  isToken?: boolean;
}

const filterConsituents = (
  clist: ProductConstituentAssetType[] | TokenConstituentAssetType[],
  cType: AssetClass[]
) => {
  if (!cType.length) return clist;
  return clist.filter((el) => cType.includes(el.assetType));
};

export const ConstituentsTable = ({
  constituentsList,
  loading,
  deleteAction,
  isToken,
}: ConstituentsTableProps) => {
  const openModal = useAppModal();
  const dispatch = useAppDispatch();
  const { product } = useCurrentProduct(isToken ? 'Token' : 'ETP');
  const [constituentType, setConstituentType] = useState<AssetClass[]>([]);
  const filteredConstituents = useMemo(
    () => filterConsituents(constituentsList, constituentType),
    [constituentType, constituentsList]
  );

  const { sortedList, handleSortProp } = useSortHook<
    ProductConstituentAssetType | TokenConstituentAssetType
  >(filteredConstituents);

  const addNewConstituentButtonClick = () => {
    if (
      product?.type !== 'Single Asset' ||
      !product?.constituentAssets?.length ||
      product?.constituentAssets?.length < 1
    ) {
      openEditConstituentModal(MODAL_ACTIONS.CREATE);
    } else {
      dispatch(
        createNotification({
          message: `You can not add more than one Constituent asset for Single Asset ${
            isToken ? 'Token' : 'ETP'
          }.`,
          title: `Single Asset ${isToken ? 'Token' : 'ETP'}`,
          type: 'info',
        })
      );
    }
  };

  const openEditConstituentModal = (
    type: MODAL_ACTIONS,
    constituent?: ProductConstituentAssetType | TokenConstituentAssetType
  ) => {
    openModal({
      modalName: CONSTITUENT_MODAL,
      modalData: {
        data: {
          constituent,
          constituents: constituentsList,
        },
        custom: {
          isToken: Boolean(isToken),
        },
        type: type,
      },
    });
  };

  return (
    <Table
      toolbarTools={[
        <>
          <Grid container marginBottom={1} display={'flex'} alignItems={'center'}>
            <Grid item display={'flex-col'} xs={12} md={6}>
              <ProductConfigHeader title="Constituent Assets" />
            </Grid>
            <Grid
              item
              display={'flex'}
              alignItems={'center'}
              justifyContent={'end'}
              gap={2}
              xs={12}
              md={6}
            >
              <StyledConstituentsClassMultiSelectFilter
                placeholder="Filter"
                options={[
                  { label: 'Crypto', value: 'CRYPTO' },
                  { label: 'Metal', value: 'METAL' },
                  { label: 'Cash', value: 'CASH' },
                ]}
                onChange={(_, values) => setConstituentType(values as AssetClass[])}
                value={constituentType as string[]}
                fullWidth={false}
              />
              <StyledTableTopButton
                data-qa-id="addConstituentBtn"
                variant="secondary"
                onClick={addNewConstituentButtonClick}
                type="button"
                size="medium"
              >
                Add Constituent
                <PlusIcon />
              </StyledTableTopButton>
            </Grid>
          </Grid>
        </>,
      ]}
    >
      <TableHeader
        showActionsCell
        columns={getConstituentsTableColumnsConfig(
          sortedList.some((constituent) => 'status' in constituent)
        )}
        onColumnSort={(col: TableHeaderColumn) => {
          handleSortProp(col);
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loading)}
        hasContent={!isEmpty(sortedList)}
        noContentLabel="No constituents at the moment."
      >
        {sortedList.map((constituent, index) => (
          <ConstituentsTableRow
            data-qa-id={constituent}
            key={(constituent._id ?? '') + index}
            index={index}
            constituent={constituent}
            editAction={() => {
              openEditConstituentModal(MODAL_ACTIONS.EDIT, constituent);
            }}
            deleteAction={() => {
              deleteAction(constituent);
            }}
            showActionsCell
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};
