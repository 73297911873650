import Link from 'components/Link';
import { StyledReviewTermsOfUse } from 'pages/Login/Login.styles';
import { styled as MuiStyled } from '@mui/material';

export const StyledTermsLink = MuiStyled(Link)`
  font-size: 0.875rem;
  align-self: center;
`;

export const StyledTermsOfUse = MuiStyled(StyledReviewTermsOfUse)({
  marginLeft: '-10px',
});
