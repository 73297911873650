import { TAB_TYPE } from 'components/TabNavigation/TabNavigation.types';
import { Box, styled as MuiStyled } from '@mui/material';

interface MenuItemProps {
  active: 'true' | 'false';
  tabtype: TAB_TYPE;
}

export const StyledTabsContainer = MuiStyled(Box)(({ theme }) => ({
  marginTop: theme.spacers['spacing-32'],
}));

export const MuiTabContentContainer = MuiStyled(Box)<MenuItemProps>(
  ({ theme, tabtype = TAB_TYPE.DEFAULT }) => ({
    backgroundColor: tabtype === TAB_TYPE.CONTAINER ? theme.palette.common.white : 'transparent',
  })
);
