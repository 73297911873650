// types
import { PortfolioComposition } from 'utils/types/pcfs';
import PCFEtpInformation from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/PCFEtpInformation';
import PCFComposition from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/PCFComposition';
import PCFFileInfo from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/PCFFileInfo';
import PCFUnderlyingInformation from './PCFUnderlyingInformation';
import { ProductConstituentAsset } from 'utils/types';

interface PCFDetailsAPProps {
  pcf?: PortfolioComposition & { constituentAssets?: ProductConstituentAsset[] };
}

function PCFDetailsAP({ pcf }: PCFDetailsAPProps) {
  return (
    <>
      <PCFEtpInformation pcf={pcf} />
      <PCFComposition pcf={pcf} />
      <PCFUnderlyingInformation pcf={pcf} />
      <PCFFileInfo pcf={pcf} />
    </>
  );
}

export default PCFDetailsAP;
