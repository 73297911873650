import { IModalWithData } from 'shared/Modals/types';
import { InstrumentTouchedDataModal } from './InstrumentTouchedDataModal/InstrumentTouchedDataModal';
import { TokenTouchedDataModal } from './TokenTouchedDataModal/TokenTouchedDataModal';

export const ACTIVATE_ETP_MODAL_SEED = 'seed';

export const TouchedProductModal = ({ ...props }: IModalWithData) => {
  if (props.data?.custom?.isToken) {
    return <TokenTouchedDataModal {...props} />;
  }

  return <InstrumentTouchedDataModal {...props} />;
};
