import useAppDispatch from 'hooks/useAppDispatch';
import { DEFAULT_PAGE, UNLIMITED_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { loadInstruments as loadInstrumentsResponse } from 'utils/api/instruments';
import { ProductStatus } from 'utils/types/product';
import { ETPsQuery } from 'utils/constants/reactQueries';

const DEFAULT_STATUS_FILTER = [ProductStatus.ACTIVE];

export const useInstrumentsQuery = (
  companyId?: string | null,
  status: ProductStatus[] = DEFAULT_STATUS_FILTER,
  enabled: Boolean = true
) => {
  const dispatch = useAppDispatch();

  return useQuery(
    [ETPsQuery, companyId, status],
    async () => {
      try {
        const ETPsResponse = await loadInstrumentsResponse({
          status,
          authorizedParticipant: companyId ?? null,
          pageSize: UNLIMITED_ITEMS_PER_PAGE,
          page: DEFAULT_PAGE,
        });
        return ETPsResponse;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(enabled),
    }
  );
};

export function useInstrumentsOrder() {
  const { data: { data: instruments } = {}, isLoading } = useInstrumentsQuery(null, [
    ProductStatus.ACTIVE,
  ]);

  return { instruments, isLoading };
}

export function useInstrumentsDelegatedOrder() {
  const { data: { data: instruments } = {}, isLoading } = useInstrumentsQuery(null, [
    ProductStatus.ACTIVE,
    ProductStatus.IN_REVIEW,
    ProductStatus.PENDING,
  ]);

  return { instruments, isLoading };
}
