export const CustodiansTableColumnsConfig = [
  {
    label: 'Custodian',
    propName: 'custodian',
    propType: 'string',
    sort: false,
    width: '30%',
  },
  {
    label: 'Account Name',
    propName: 'accountName',
    propType: 'string',
    sort: false,
    width: '30%',
  },
  {
    label: 'Account Description',
    propName: 'accountDescription',
    propType: 'string',
    sort: false,
    width: '32%',
  },
];
