import { NotificationConfigType, NotificationWithoutId } from 'utils/types';

export const userCreatedNotifications: NotificationConfigType = {
  success: (userEmail) => ({
    message: `An invitation was successfully sent to ${userEmail}`,
    title: 'Invitation sent',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const userEditNotifications: NotificationConfigType = {
  success: (userName) => ({
    message: `User ${userName} has been updated successfully`,
    title: 'User updated',
    type: 'success',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const userDeactivatedNotifications: NotificationConfigType = {
  success: (userName) => ({
    message: `User ${userName} has been deactivated successfully`,
    title: 'User deactivated',
    type: 'info',
  }),
  error: (message) => ({
    message: message ?? '',
    title: 'Error',
    type: 'error',
  }),
};

export const userInvitedNotification = (userName = ''): NotificationWithoutId => ({
  message: `Invitation for ${userName} has been successfully sent.`,
  title: 'Invitation sent',
  type: 'success',
});

export const user2FAResetNotification = (userName = ''): NotificationWithoutId => ({
  message: `2FA reset for ${userName} has been initiated.`,
  title: 'Email sent',
  type: 'success',
});
