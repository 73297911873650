import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { Calendar, CalendarEvent } from 'utils/types/calendar';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { apiUrls } from 'utils/constants/apiUrls';
import { calendarEventDeletedNotification } from 'shared/Notifications/calendars.notifications';
import { createNotification } from 'store/notifications/actions';
import { putEntityApiCall } from 'utils/api/crudActions';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const CalendarEventDeleteModal = ({
  closeModal,
  onCloseModalAction,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();

  const calendarEvent = data.data as CalendarEvent;
  const calendar = data.calendarData as Calendar;
  const { companyData, isAdmin } = data;
  const uriPath = isAdmin
    ? `${apiUrls.admin}${apiUrls.calendars}/id=${calendar._id}`
    : `${apiUrls.companies}/id=${companyData?.id ?? ''}${apiUrls.calendars}/id=${calendar._id}`;

  const calendarEventDeleteMutation = useMutation({
    mutationFn: (uriPath: string) => {
      const requestPayload = { ...calendar };
      requestPayload.events = requestPayload.events.filter(
        (event) => event._id !== calendarEvent._id
      );
      return putEntityApiCall(requestPayload, uriPath, 'Error while deleting Calendar Event!');
    },
    onSuccess: (res) => {
      dispatch(
        createNotification(
          calendarEventDeletedNotification.success(calendarEvent.description, calendar.name)
        )
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarEventDeletedNotification.error(err.message), err));
    },
  });

  const handleDelete = () => {
    calendarEventDeleteMutation.mutate(uriPath);
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        title={` Are you sure you want to permanently delete ${calendarEvent.description} as an holiday?`}
        label={calendar.name}
        footer={
          <MuiStyledModalFooterButtons>
            <Button variant="secondary" fullWidth onClick={closeModal} type="button">
              Cancel
            </Button>
            <Button
              variant="danger"
              fullWidth
              onClick={handleDelete}
              type="submit"
              isLoading={calendarEventDeleteMutation.isLoading}
            >
              Delete
            </Button>
          </MuiStyledModalFooterButtons>
        }
        onClose={closeModal}
      />
    </CustomModal>
  );
};
