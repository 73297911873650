import { useEffect, useState } from 'react';
import WebF from 'webfontloader';

export const useIinitialRenderData = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    WebF.load({
      google: {
        families: ['Inter:300,400,600,700'],
      },
      active: () => {
        setFontsLoaded(true);
      },
    });
  }, []);

  return {
    isLoaded: fontsLoaded,
  };
};
