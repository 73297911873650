import { generatePath } from 'react-router-dom';
import { omit } from 'lodash';
import { StepIndicatorType } from 'components/ProgressIndicator/ProgressIndicator.types';
import {
  tokenDetailsStepsList,
  TokenDetailsStepType,
  GENERAL_DETAILS_STEP,
  SUMMARY_STEP,
  CONSTITUENT_STEP,
} from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import {
  TokenPartnerDetailsStepType,
  partnerStepsList,
  PARTNERS_STEP,
  CUSTODIAN_INFO_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import { StepsListType, StepsType } from 'pages/Tokens/components/Form/types';
import { TokenFormDataStepType, PartialTokenFormProps } from 'pages/Tokens/Tokens.types';
import { privateRoutesUrls } from 'router/constants';

type StepsStatusType<T extends StepsType> = Partial<
  Record<
    T,
    Pick<StepIndicatorType<T>, 'isActive' | 'isComplete' | 'isDisabled' | 'isInvalid' | 'isDirty'>
  >
>;

export function getSteps<T extends StepsType>(
  activeStep: T,
  stepList: StepsListType<T>[],
  stepsStatus: StepsStatusType<T> = {}
): StepIndicatorType<T>[] {
  return stepList.map((step) => ({
    ...step,
    isActive: stepsStatus[step.id]?.isActive || step.id === activeStep,
    isComplete: stepsStatus[step.id]?.isComplete,
    isDisabled: stepsStatus[step.id]?.isDisabled,
    isInvalid: stepsStatus[step.id]?.isInvalid,
    isDirty: stepsStatus[step.id]?.isDirty,
  }));
}

export function getStep(stepId: TokenFormDataStepType, isIndex?: boolean) {
  const steps = [...tokenDetailsStepsList, ...partnerStepsList];

  return steps.find((step) => step.id === stepId) || steps[0];
}

export function getTokenDetailsStep(stepId: TokenDetailsStepType) {
  return getStep(stepId);
}

export function getPartnerDetailsStep(stepId: TokenPartnerDetailsStepType) {
  return partnerStepsList.find((step) => step.id === stepId);
}

export const getTokenDetailsSteps = (
  activeStep: TokenDetailsStepType,
  stepsStatus: StepsStatusType<TokenDetailsStepType>
): StepIndicatorType<TokenDetailsStepType>[] => {
  const stepsList = getFormatedSteps(tokenDetailsStepsList);
  return getSteps(activeStep, stepsList, stepsStatus);
};

function isMaskedValue(value?: string) {
  return value && value.match(/^#+.{5}$/);
}

export function getTokenDetailsFormDataFormatted(formData: PartialTokenFormProps) {
  return {
    general: formData[GENERAL_DETAILS_STEP]
      ? omit(formData[GENERAL_DETAILS_STEP], ['fundIcon'])
      : undefined,
    constituents: formData[CONSTITUENT_STEP],
    partners: formData[PARTNERS_STEP],
    custodianAccounts: formData[CUSTODIAN_INFO_STEP]?.map((custodian) => ({
      ...omit(custodian, ['apiKey', 'apiPassphrase', 'signatureKey']),
      ...(!isMaskedValue(custodian.apiKey) && { apiKey: custodian.apiKey }),
      ...(!isMaskedValue(custodian.apiPassphrase) && { apiPassphrase: custodian.apiPassphrase }),
      ...(!isMaskedValue(custodian.signatureKey) && { signatureKey: custodian.signatureKey }),
    })),
    wallets: formData[WALLETS_INFO_STEP]?.unifiedWallets,
    custodianWallets: formData[WALLETS_INFO_STEP]?.custodianWallets,
  };
}

export const getPartnerDetailsSteps = (
  activeStep: TokenPartnerDetailsStepType,
  stepsStatus: StepsStatusType<TokenPartnerDetailsStepType>
): StepIndicatorType<TokenPartnerDetailsStepType>[] => {
  const stepsList = getFormatedSteps(partnerStepsList);
  return getSteps(activeStep, stepsList, stepsStatus);
};

const stepsToFormProps = {
  [GENERAL_DETAILS_STEP]: 'general',
  [CONSTITUENT_STEP]: 'constituent',
  [SUMMARY_STEP]: 'summary',
};

export function getFormPropFromStep(stepLable: TokenDetailsStepType) {
  return stepsToFormProps[stepLable];
}

function getTabUrl(createUrl: string, editUrl: string, id?: string) {
  return id
    ? generatePath(editUrl, {
        id,
      })
    : createUrl;
}

export function getTokenDetailsUrl(id?: string) {
  return getTabUrl(
    privateRoutesUrls.dashboardRoutes.newTokenDetails,
    privateRoutesUrls.dashboardRoutes.editTokenDetails,
    id
  );
}

export function getPartnerDetailsUrl(id?: string) {
  return getTabUrl(
    privateRoutesUrls.dashboardRoutes.newTokenPartnerDetails,
    privateRoutesUrls.dashboardRoutes.editTokenPartnerDetails,
    id
  );
}

const getFormatedSteps = <T>(partnerStepsList: StepsListType<T>[]) => {
  return partnerStepsList.map((step) => {
    if (step.id === SUMMARY_STEP) return { ...step, label: SUMMARY_STEP };
    return step;
  });
};

export const CUSTODIAN_WALLETS = 'custodianWallets';
export const UNIFIED_WALLETS = 'unifiedWallets';
