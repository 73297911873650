import { useCallback, useMemo, useState } from 'react';

export type CheckedItem = { _id?: string };

export const useBatchOperations = (mergedItemsWithPagination: CheckedItem[]) => {
  const [checkedItems, setCheckedItems] = useState<CheckedItem[]>([]);

  const isIndeterminate = useMemo(
    () => Boolean(checkedItems.length) && checkedItems.length < mergedItemsWithPagination?.length,
    [checkedItems.length, mergedItemsWithPagination?.length]
  );

  const isChecked = useCallback(
    (id?: string) => checkedItems.some(({ _id }) => id === _id),
    [checkedItems]
  );

  const onCheckedChange = useCallback(
    (id?: string) => {
      const itemIndex = checkedItems.findIndex(({ _id }) => id === _id);
      if (itemIndex > -1)
        setCheckedItems((prevState) => prevState.filter((_, index) => index !== itemIndex));
      else setCheckedItems((prevState) => [...prevState, { _id: id }]);
    },
    [checkedItems]
  );

  const onCheckAll = useCallback(
    (isChecked: boolean) => {
      setCheckedItems(
        isChecked
          ? mergedItemsWithPagination.map(({ _id }) => ({
              _id,
            }))
          : []
      );
    },
    [mergedItemsWithPagination]
  );

  return {
    checkedItems,
    setCheckedItems,
    isChecked,
    onCheckedChange,
    headerCheckboxProps: {
      checked: Boolean(checkedItems.length),
      dash: isIndeterminate,
      onChange: onCheckAll,
    },
  };
};
