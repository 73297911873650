import { Dashboard } from 'pages/Dashboard/Dashboard';
import { Navigate } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { useAuthenticatedUser } from 'store/user/selectors';
import { User } from 'utils/types';
import { PartnerType } from 'utils/types/partner';

const getRootPagePath = (user: User | null) => {
  switch (user?.organization.type) {
    case PartnerType.AUTHORIZED_MERCHANT:
      return privateRoutesUrls.dashboardRoutes.ordersTokens;
    case PartnerType.AUTHORIZED_PARTICIPANT:
      return privateRoutesUrls.dashboardRoutes.orders;
    case PartnerType.FUND_ACCOUNTANT:
      return privateRoutesUrls.dashboardRoutes.nav;
    case PartnerType.ISSUER:
      return privateRoutesUrls.dashboardRoutes.orders;
    case PartnerType.TECHNICAL_LISTING_AGENT:
      return privateRoutesUrls.dashboardRoutes.orders;
  }

  return null;
};

export const RootPage = () => {
  const { user } = useAuthenticatedUser();
  const customRootPagePath = getRootPagePath(user);
  return !customRootPagePath ? <Dashboard /> : <Navigate to={customRootPagePath} replace />;
};
