import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

const FormGridContainer: React.FC<GridProps> = ({ padding = 2, spacing = 2, ...props }) => {
  return (
    <Grid container padding={padding} spacing={spacing} {...props}>
      {props.children}
    </Grid>
  );
};

export default FormGridContainer;
