import { Box, Button, IconButton, styled as MuiStyled, Stack } from '@mui/material';
import { Container, Row } from 'components/Grid';
import { ReactComponent as Logo } from 'assets/Navigation/onyx-top-navigation-logo.svg';

const containerPadding = '2rem';
const height = 40;
interface MenuItemProps {
  revealed: 'true' | 'false';
  selected?: boolean;
  issubitem?: 'true' | 'false';
}

export const StyledNavigationContainer = MuiStyled(Container)(({ theme }) => ({
  padding: 0,
  margin: 0,
  height: '100vh',
  backgroundColor: theme.palette.tertiary.light,
}));

export const ContentContainer = MuiStyled(Row)`
  height: 100%;
  max-width: 100vw;
`;

export const SideNavWrapper = MuiStyled(Stack)(({ theme }) => ({
  '--padding-x': '1rem',
  backgroundColor: theme.palette.customBackground.default.standard,
  borderRight: '1.25px solid #98ABB852',
  boxShadow: `0px 2.8px 5px 0px #1B243203, 0px 0.9px 1.8px 0px #1B243204, 0px 0px 3px 0px #1B243214, 0px 7.1px 12.1px 0px #1B243205, 0px 21px 40px 0px #1B24320A`, // TODO: fix color palette
  height: '100%',
  overflow: 'hidden',
  padding: `1.5rem var(--padding-x)`,
  position: 'relative',
  rowGap: 8,
}));

export const Content = MuiStyled(Box)`
  background-color: ${({ theme }) => theme.palette.tertiary.light};
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: ${containerPadding};
`;

export const StyledMenuItem = MuiStyled(Button)<MenuItemProps>(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.customComponents.sideNavMenuItemPalette.selectedBackgroundColor,
  }),
  color: theme.customComponents.sideNavMenuItemPalette.color,
  justifyContent: 'flex-start',
  height: height,
  padding: '2px 16px',
  width: 224,
  gap: '8px',
  fontWeight: 500,
  svg: {
    stroke: theme.customComponents.sideNavMenuItemPalette.svg.color,
    fill: theme.customComponents.sideNavMenuItemPalette.svg.color,
  },
  '.MuiButton-startIcon': {
    marginLeft: 0,
  },
  '.MuiButton-endIcon': {
    marginLeft: 'auto',
    marginRight: 0,
  },
  '&&:hover': {
    backgroundColor: theme.customComponents.sideNavMenuItemPalette.hoverBackgroundColor,
  },
}));

export const StyledIconButton = MuiStyled(IconButton)<MenuItemProps>(({ theme, selected }) => ({
  ...(selected
    ? { backgroundColor: theme.customComponents.sideNavMenuItemPalette.selectedBackgroundColor }
    : {}),
}));

export const StyledIcon = MuiStyled(Box)`
  display: flex;
  height: ${height}px;
  width: ${height}px;
  svg {
    margin: auto;
  }
`;

export const MenuStyledIcon = MuiStyled(Stack)<{ rotate180?: 'true' | 'false' }>(
  ({ theme, rotate180 = 'false' }) => ({
    justifyContent: 'center',
    height: 24,
    width: 24,
    '& > svg': {
      color: theme.customComponents.sideNavMenuItemPalette.svg.color,
      stroke: theme.customComponents.sideNavMenuItemPalette.svg.color,
      ...(rotate180 === 'true' ? { transform: 'rotate(180deg)' } : {}),
    },
  })
);

export const StyledIconWithTitle = MuiStyled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StyledTagAndMenuIconWrapper = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledBottomSidenavItemWrapper = MuiStyled(Box)`
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  flex-direction: column;
`;

export const StyledProfileName = MuiStyled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledSidenavDivider = MuiStyled('hr')(({ theme }) => ({
  border: 0,
  // borderBottom: `1px solid #98ABB833`, // TODO: fix color palette
  borderBottom: `1px solid ${theme.palette.text.divider.default.standard}`,
  margin: '8px 16px',
}));

export const StyledSidenavCopyright = MuiStyled(Box)(({ theme }) => ({
  marginInline: 16,
  fontSize: '0.75rem',
  lineHeight: '1rem',
  color: theme.palette.neutral[50],
}));

export const StyledLogo = MuiStyled(Logo)`
  margin-left: 16px;
`;

export const StyledLogoAndToggleWrapper = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 16,
  paddingBlock: 16,
  // backgroundColor: getEnvTopNavBackgroundColor(true) ?? theme.palette.background.default,
  // TODO: check color
  backgroundColor: theme.palette.customBackground.default.standard,
  // getEnvTopNavBackgroundColor(true) ?? theme.palette.customBackground.default.standard,
}));

export const NewStyledLogo = MuiStyled(Logo)`
  margin-left: 16px;
`;

export const DashboardPageWrapper = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap:32px;
`;
