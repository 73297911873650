import { FC } from 'react';

import { LoaderProps } from 'components/Loader/Loader.types';
import { StyledWrapper } from 'components/Loader/Loader.styles';
import { ReactComponent as LoaderIcon } from 'assets/loading-spinner.svg';

const Loader: FC<LoaderProps> = ({ anchorToRelative = false }) => (
  <StyledWrapper anchortorelative={anchorToRelative ? 'true' : 'false'}>
    <LoaderIcon />
  </StyledWrapper>
);

export default Loader;
