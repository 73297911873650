import Button from 'components/Button';
import Card from 'components/Card';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const YesOrNoModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const onSubmitHandler = () => {
    closeModal();
    if (onCloseModalAction) onCloseModalAction(data?.data);
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        label={'Navigate'}
        title={
          <>
            <span>You have made changes that are not saved. </span>
            <span>Do you want to proceed to another page?</span>
          </>
        }
        footer={
          <MuiStyledModalFooterButtons>
            <Button
              variant="secondary"
              fullWidth
              onClick={closeModal}
              type="button"
              data-qa-id="cancelButton"
            >
              Cancel
            </Button>
            <Button
              variant="interactive"
              fullWidth
              onClick={() => onSubmitHandler()}
              type="submit"
              data-qa-id="confirmButton"
            >
              Confirm
            </Button>
          </MuiStyledModalFooterButtons>
        }
      />
    </CustomModal>
  );
};
