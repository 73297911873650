import { styled as MuiStyled } from '@mui/material';
import Toolbar from 'components/Toolbar';

export const StyledShowMore = MuiStyled('span')`
  margin-left: ${({ theme }) => `${theme.spacers['spacing-08']}`};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledShowMoreListElement = MuiStyled('li')`
  padding: ${({ theme }) => `${theme.spacers['spacing-08']} ${theme.spacers['spacing-16']}`};
`;

export const StyledTableToolbar = MuiStyled(Toolbar)`	
  width: 100%;	
`;
