import {
  LABEL_FIELD_NAME_FOR_DROPDOWN,
  VALUE_FIELD_NAME_FOR_DROPDOWN,
} from 'components/Form/Form.constants';
import { FormPropsType, FormSchemaPropType, FormSchemaResponse } from 'components/Form/Form.types';

const mapEnums = (enumValue: string, idx: number, formSchemaPropDetails: FormSchemaPropType) => ({
  [LABEL_FIELD_NAME_FOR_DROPDOWN]: formSchemaPropDetails['x-enumLabels']
    ? formSchemaPropDetails['x-enumLabels'][idx] || enumValue
    : enumValue.replaceAll('_', ' '),
  [VALUE_FIELD_NAME_FOR_DROPDOWN]: enumValue,
});

export const retrieveFormProps = (from: FormSchemaResponse, to: FormPropsType) => {
  Object.entries(from.properties).forEach(([propName, propDetails]) => {
    const formSchemaPropDetails = propDetails as FormSchemaPropType;

    switch (formSchemaPropDetails.type) {
      case 'object':
        to.defaultValues[propName] = {};
        to.labels[propName] = {};
        to.types[propName] = {};
        to.enums[propName] = {};
        to.descriptions[propName] = {};
        to.placeholders[propName] = {};
        to.formatDescriptions[propName] = {};
        to.required[propName] = formSchemaPropDetails.required;
        to.errorMessage[propName] = {};

        retrieveFormProps(
          propDetails as FormSchemaResponse,
          {
            defaultValues: to.defaultValues[propName],
            descriptions: to.descriptions[propName],
            placeholders: to.placeholders[propName],
            formatDescriptions: to.formatDescriptions[propName],
            types: to.types[propName],
            enums: to.enums[propName],
            required: to.required[propName],
            labels: to.labels[propName],
            errorMessage: to.errorMessage[propName],
          } as unknown as FormPropsType
        );
        break;
      default:
        to.types[propName] = formSchemaPropDetails.type;
        to.labels[propName] = formSchemaPropDetails.title;
        to.descriptions[propName] = formSchemaPropDetails.description;
        to.placeholders[propName] = formSchemaPropDetails['x-placeholder'];
        to.formatDescriptions[propName] = formSchemaPropDetails['x-formatDescription'];
        to.enums[propName] = (formSchemaPropDetails.enum || formSchemaPropDetails.items?.enum)?.map(
          (enumValue, idx) => mapEnums(enumValue, idx, formSchemaPropDetails)
        );
        to.defaultValues[propName] = formSchemaPropDetails.default;

        break;
    }
  });
};
