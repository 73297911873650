import { styled as MuiStyled } from '@mui/material';

import { Column, Row } from 'components/Grid';
import { TableCell } from 'components/Table';

export const StyledColumn = MuiStyled(Column)`
  align-items: center;
  display: flex;
`;

export const StyledButtonRow = MuiStyled(Row)`
  justify-content: end;
`;

export const StyledTableCell = MuiStyled(TableCell)`
  width: 50%;
`;
