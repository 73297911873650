import { Popover, Paper, IconButton } from '@mui/material';
import { styled as MuiStyled } from '@mui/material';

export const PopoverContainer = MuiStyled(Paper)`
  position: relative;
  display: inline-block;
`;

export const StyledTablePopoverMenu = MuiStyled(Popover)`
  display: inline-block;
`;

export const PopoverButton = MuiStyled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '32px',
  height: '32px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.interactive.lighter,
  },
  svg: {
    marginRight: 0,
  },
}));
