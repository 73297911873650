import { useAuthenticatedUser } from 'store/user/selectors';
import { OrdersFiltersData } from 'utils/constants/orders';
import { getOrdersCSV } from 'utils/api/orders';
import { mapOrderTabToStatus } from 'pages/Orders/Orders/utils/mapOrderTabToStatus';
import { ProductType } from 'utils/types/product';

export const useGenerateOrdersCsvFile = (
  urlParams: OrdersFiltersData,
  productType: ProductType = 'ETP'
) => {
  const { user } = useAuthenticatedUser();

  const generateCSV = async () => {
    if (!user) {
      return;
    }

    urlParams.page = undefined;
    urlParams.pageSize = undefined;
    await getOrdersCSV(mapOrderTabToStatus(urlParams), productType);
  };

  return [generateCSV];
};
