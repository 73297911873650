import { AxiosError, AxiosResponse } from 'axios';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { apiUrls } from 'utils/constants/apiUrls';
import { User } from 'utils/types';
import {
  Auth2faResponse,
  Enable2faResponse,
  Reset2faResponse,
  Request2FAResponse,
  RequestLoginResponse,
  VerifyInvitationResponse,
  SuccessRequest,
} from 'utils/types/auth';
import { FormValues } from 'utils/types/forms';

export const requestUser = async (): Promise<AxiosResponse<User, AxiosError>> => {
  try {
    return await axiosInstance.get(`${apiUrls.users}${apiUrls.me}`);
  } catch (err) {
    return errorHandler(err);
  }
};

export const authLogin = async (
  body: FormValues
): Promise<AxiosResponse<RequestLoginResponse, AxiosError>> => {
  try {
    return await axiosInstance.post(apiUrls.authLogin, body);
  } catch (err) {
    return errorHandler(err);
  }
};

export const auth2fa = async (
  uriPath: string,
  otp: string
): Promise<AxiosResponse<Auth2faResponse, AxiosError>> => {
  try {
    return await axiosInstance.post(uriPath, { otp });
  } catch (err) {
    return errorHandler(err);
  }
};

export const request2FA = async (
  uriPath: string
): Promise<AxiosResponse<Request2FAResponse, void>> => {
  try {
    return await axiosInstance.post(uriPath, {});
  } catch (err) {
    return errorHandler(err);
  }
};

export const enable2fa = async (
  uriPath: string,
  otp: number
): Promise<AxiosResponse<Enable2faResponse, AxiosError>> => {
  try {
    return await axiosInstance.post(uriPath, { otp });
  } catch (err) {
    return errorHandler(err);
  }
};

// TODO: Endopoint is not in use until we get design update for the flow
export const reset2fa = async (
  backupCode: string
): Promise<AxiosResponse<Reset2faResponse, AxiosError>> => {
  try {
    return await axiosInstance.post(`${apiUrls.auth}${apiUrls.twoFA}${apiUrls.reset}`, {
      backupCode,
    });
  } catch (err) {
    return errorHandler(err);
  }
};
export const authPasswordReset = async (
  uriPath: string,
  body: FormValues
): Promise<AxiosResponse<RequestLoginResponse, AxiosError>> => {
  try {
    return await axiosInstance.post(uriPath, body);
  } catch (err) {
    return errorHandler(err);
  }
};

export const verifyInvitation = async (
  uriPath: string,
  body: {
    [key: string]: string;
  }
): Promise<AxiosResponse<VerifyInvitationResponse, AxiosError>> => {
  try {
    return await axiosInstance.post(uriPath, body);
  } catch (err) {
    return errorHandler(err);
  }
};

export const sendEmailForResetPassword = async (
  email: string
): Promise<AxiosResponse<SuccessRequest, AxiosError>> => {
  try {
    return await axiosInstance.post(`${apiUrls.auth}${apiUrls.password}${apiUrls.resetRequest}`, {
      email,
    });
  } catch (err) {
    return errorHandler(err);
  }
};
