import { AssetList, CompanySuggestionDto } from 'utils/types';
import {
  CustodianAccount,
  ProductBase,
  ProductConstituentAsset,
  TokenConstituentAsset,
} from 'utils/types/product';
import { isNumber } from 'lodash';
import { OptionType } from 'components/Select/Select.types';

export const getProductOptions = (
  products: ProductBase[] | CustodianAccount[] | undefined,
  shouldUseTickerAsLabel = false
) =>
  Object.values(products || {}).map((product) => {
    const destinationChain = product?.destinationChain
      ? `(${product?.destinationChain.toLowerCase()})`
      : '';
    return {
      label: shouldUseTickerAsLabel ? `${product?.ticker} ${destinationChain}` : product?.name,
      value: product?._id,
    };
  });

export const getAssetsOptions = (assets: AssetList | null) =>
  Object.values(assets?.existing || {}).map((asset) => ({
    label: asset.name,
    value: asset.ticker,
  }));

export const getConstituentsOptions = (
  constituents: ProductConstituentAsset[] | TokenConstituentAsset[]
) =>
  constituents.map((constituent) => ({
    label: constituent.ticker,
    value: constituent._id,
  }));

export const getAuthorizedParticipantsOptions = (
  partners: CompanySuggestionDto[],
  authorizedParticiants: string[]
) =>
  partners?.reduce((options, partner) => {
    const index = partner._id ? authorizedParticiants?.indexOf(partner._id) : -1;
    if (partner._id && isNumber(index) && index > -1) {
      options.push({
        label: partner.name,
        value: partner._id,
      });
    }
    return options;
  }, [] as OptionType<string>[]);
