// types
import { PortfolioComposition } from 'utils/types/pcfs';
import PCFEtpInformation from './PCFEtpInformation';
import PCFComposition from './PCFComposition';
import PCFBenchmarkInformation from './PCFBenchmarkInformation';
import PCFFileInfo from './PCFFileInfo';
import { ProductConstituentAsset } from 'utils/types/product';

interface PCFDetailsIssuerProps {
  pcf?: PortfolioComposition & { constituentAssets?: ProductConstituentAsset[] };
  isLoading?: boolean;
}

function PCFDetailsIssuer({ pcf, isLoading }: PCFDetailsIssuerProps) {
  return (
    <>
      <PCFEtpInformation pcf={pcf} isLoading={isLoading} />
      <PCFComposition pcf={pcf} isLoading={isLoading} />
      <PCFBenchmarkInformation pcf={pcf} isLoading={isLoading} />
      <PCFFileInfo pcf={pcf} isLoading={isLoading} />
    </>
  );
}

export default PCFDetailsIssuer;
