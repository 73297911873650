import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getNavReview } from 'utils/api/portfolio-composition';
import { getNavReviewItemQuery } from 'utils/constants/reactQueries';

export const useNavReviewItemQuery = (portfolioId: string, navReviewItemId: string) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getNavReviewItemQuery, portfolioId, navReviewItemId],
    async () => {
      try {
        const res = await getNavReview(portfolioId, navReviewItemId);
        return res;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(portfolioId && navReviewItemId),
    }
  );
};
