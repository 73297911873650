import { FieldValues } from 'react-hook-form';

import { ProductConstituentAssetType, TokenConstituentAssetType } from 'utils/types/product';

function parseDataToAsset(
  data: FieldValues
): ProductConstituentAssetType | TokenConstituentAssetType {
  return {
    coingeckoId: data.coingeckoId,
    ticker: data.ticker.toUpperCase(),
    name: data.name,
    assetType: data.type,
    rounding: data.rounding,
  };
}

export default parseDataToAsset;
