import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Contact, PartnerContact } from 'utils/types/contacts';
import { ParamsType } from 'hooks/useUrlParams';
import { PartnerContactsTableRow } from './PartnerContactsTableRow';
import { PartnerContactsTableColumnsConfig } from './tableColumnsConfig';

interface PartnerContactsTableProps {
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  partners: PartnerContact[];
  isLoading: Boolean;
  editContactAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
}

export const PartnerContactsTable = ({
  urlParams,
  setUrlParams,
  partners,
  isLoading,
  editContactAction,
  deleteAction,
}: PartnerContactsTableProps) => {
  return (
    <>
      <TableHeaderWithMultiSort
        defaultSortedColumns={urlParams.sort ?? []}
        columns={PartnerContactsTableColumnsConfig}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
        showChevronColumn
        columnAlignment={{
          partner: 'left',
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(isLoading)}
        hasContent={!isEmpty(partners)}
        noContentLabel="No Contacts."
      >
        {partners?.map((partner, index) => (
          <PartnerContactsTableRow
            key={partner._id ?? index}
            partner={partner}
            editAction={(contact) => {
              editContactAction(contact);
            }}
            deleteAction={(contact) => {
              deleteAction(contact);
            }}
          />
        ))}
      </TableBodyWithStates>
    </>
  );
};
