import ExchangeLocalTickerList from 'pages/Instruments/components/Form/EtpDetails/components/ExchangeStep/components/ExchangeLocalTickerList';
import Tag from 'components/Tag';
import {
  ExchangeLocalTickerType,
  ExchangeType,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import { partial } from 'lodash';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { MuiStyledButtonWithIcon } from 'pages/Instruments/components/Form/EtpDetails/components/ExchangeStep/ExchangeStep.styles';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { Stack, Typography } from '@mui/material';

interface ExchangeProps {
  productExchange: ExchangeType;
  handleEditProductExchange: (crudAction: MODAL_ACTIONS, productExchange: ExchangeType) => void;
  handleRemoveProductExchange: (productExchange: ExchangeType) => void;
  handleEditProductExchangeTicker: (
    crudAction: MODAL_ACTIONS,
    productExchange: ExchangeType,
    ticker?: ExchangeLocalTickerType
  ) => void;
  handleRemoveProductExchangeTicker: (productExchange: ExchangeType) => void;
}

function ProductExchangeRow({
  productExchange,
  handleEditProductExchange,
  handleEditProductExchangeTicker,
  handleRemoveProductExchangeTicker,
  handleRemoveProductExchange,
}: ExchangeProps) {
  const removeLocalTicker = (productExchange: ExchangeType, localTickerIndex: number) => {
    const productExchangeCopy = { ...productExchange };

    if (productExchangeCopy.localTickers) {
      productExchangeCopy.localTickers = [
        ...productExchangeCopy.localTickers.slice(0, localTickerIndex),
        ...productExchangeCopy.localTickers.slice(localTickerIndex + 1),
      ];
      handleRemoveProductExchangeTicker(productExchangeCopy);
    }
  };

  return (
    <>
      <MuiStyledTableRow
        key={productExchange.id}
        onClick={(event) => {
          event.stopPropagation();
          handleEditProductExchange(MODAL_ACTIONS.EDIT, productExchange);
        }}
      >
        <MuiStyledTableCell>
          <Stack direction="row" alignItems="center">
            <Typography mr={2}>{productExchange.name}</Typography>
            {Boolean(productExchange.isPrimary) && <Tag label={'Primary Exchange'} variant="03" />}
            {Boolean(productExchange.constituentExchange) && (
              <Tag label={'Constituent Exchange'} variant="01" size="small" />
            )}
          </Stack>
        </MuiStyledTableCell>
        <MuiStyledActionsTableCell>
          <TablePopoverMenu id={productExchange.id} className="actionsCell">
            <MuiStyledButtonWithIcon
              data-qa-id="Add Ticker"
              fullWidth
              variant="ghost"
              onClick={() => handleEditProductExchangeTicker(MODAL_ACTIONS.CUSTOM, productExchange)}
            >
              <PlusIcon width={16} height={16} className="editIcons" />
              <Typography>Add Local Ticker</Typography>
            </MuiStyledButtonWithIcon>
            <MuiStyledButtonWithIcon
              data-qa-id="deleteExchange"
              fullWidth
              variant="dangerGhost"
              onClick={() => handleRemoveProductExchange(productExchange)}
            >
              <TrashIcon width={16} height={16} className="editIcons" />
              <Typography>Delete</Typography>
            </MuiStyledButtonWithIcon>
          </TablePopoverMenu>
        </MuiStyledActionsTableCell>
      </MuiStyledTableRow>
      <ExchangeLocalTickerList
        key={`${productExchange.name}-local-tickers`}
        productExchange={productExchange}
        localTickers={productExchange.localTickers}
        onEdit={(ticker) =>
          handleEditProductExchangeTicker(MODAL_ACTIONS.CUSTOM, productExchange, ticker)
        }
        onDelete={partial(removeLocalTicker, productExchange)}
      />
    </>
  );
}

export default ProductExchangeRow;
