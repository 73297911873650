import TabNavigation from 'components/TabNavigation';
import { PropsWithChildren } from 'react';
import { CUSTODIAN_INFO_TABS_STATUS } from 'utils/constants/custodians';

interface CustodianStepInfoTabProps extends PropsWithChildren {
  active: string;
  onChangeTab?: (activeTab: CUSTODIAN_INFO_TABS_STATUS) => void;
}

export const CustodianStepInfoTabs = ({
  active,
  children,
  onChangeTab,
}: CustodianStepInfoTabProps) => {
  const tabs = [
    {
      name: CUSTODIAN_INFO_TABS_STATUS.ACTIVE,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(CUSTODIAN_INFO_TABS_STATUS.ACTIVE);
        }
      },
    },
    {
      name: CUSTODIAN_INFO_TABS_STATUS.DEACTIVATED,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(CUSTODIAN_INFO_TABS_STATUS.DEACTIVATED);
        }
      },
    },
  ];

  return (
    <TabNavigation active={active} tabs={tabs}>
      {children}
    </TabNavigation>
  );
};
