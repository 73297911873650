import { useContext } from 'react';
import { ModalRouterContext } from './Modal.context';

export const useModalContext = () => {
  const modalContext = useContext(ModalRouterContext);

  if (!modalContext) {
    throw new Error('Modal Context must be used within Modal Context Provider');
  }

  return modalContext;
};
