import { Box, styled as MuiStyled } from '@mui/material';

export const StyledTitleAreaHeader = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-template-rows: repeat(2, 1fr) 32px;
  background: rgba(146, 154, 164, 0.08);
  border-radius: 16px;
  backdrop-filter: blur(8px);
  height: 156px;
  padding: 24px 24px 0;
`;

export const StyledTitleAreaTabs = MuiStyled(Box)({
  '.MuiTabs-root': {
    marginBottom: 0,
    alignItems: 'end',
  },
});
