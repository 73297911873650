import { Box, styled as MuiStyled } from '@mui/material';

export const StyledToolbar = MuiStyled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
`;

export const StyledButtonRow = MuiStyled(Box)`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  justify-content: end;
  background: white;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;
