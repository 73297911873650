import React, { createContext, useState, ReactNode, useContext, useMemo } from 'react';
import parseTokenToFormData from 'pages/Tokens/helpers/parseTokenToFormData';
import { CustodianAccount, Token } from 'utils/types/product';
import { TokenFormDataProps } from 'pages/Tokens/Tokens.types';
import { getTokenQueryString } from 'utils/constants/reactQueries';
import { useIsFetching } from 'react-query';
import {
  GENERAL_DETAILS_STEP,
  TokenDetailsStepType,
} from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import {
  PARTNERS_STEP,
  TokenPartnerDetailsStepType,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import { ModalData } from 'hooks/useAppModal';
import { TOUCHED_FIELDS_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import getStepsStatuses from 'pages/Tokens/components/Form/helpers/getStepsStatus';

interface TokenContextType {
  currentToken: Token | null;
  setCurrentToken: (data: Token | null) => void;
  tokenActiveStep: TokenDetailsStepType | TokenPartnerDetailsStepType;
  setTokenActiveStep: React.Dispatch<
    React.SetStateAction<TokenDetailsStepType | TokenPartnerDetailsStepType>
  >;
  tokenFormIsDirty: boolean;
  setTokenFormIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  tokenWallets?: Pick<Token, 'unifiedWallets' | 'custodianWallets'> | null;
  setTokenWallets: React.Dispatch<
    React.SetStateAction<Pick<Token, 'unifiedWallets' | 'custodianWallets'> | null | undefined>
  >;
  tokenCustodians?: CustodianAccount[] | null;
  setTokenCustodians: React.Dispatch<React.SetStateAction<CustodianAccount[] | null | undefined>>;
  isLoadingToken: boolean;
  tokenFormsData: TokenFormDataProps;
  setTokenFormsData: (data: TokenFormDataProps | null) => void;
  completedSteps: (TokenPartnerDetailsStepType | TokenDetailsStepType)[];
  setCompletedSteps: React.Dispatch<
    React.SetStateAction<(TokenPartnerDetailsStepType | TokenDetailsStepType)[]>
  >;
  stepsStatuses: {
    [key: string]: {
      [key: string]: string | boolean | undefined | null;
    };
  };
  openActiveTokenUpdatedModal: (
    openModal: (
      modalObject: ModalData,
      additionalData?:
        | {
            onCloseModalAction?: ((data?: any) => void) | undefined;
          }
        | undefined
    ) => {
      payload: any;
      type: 'modals/showModal';
    },
    step: TokenPartnerDetailsStepType | TokenDetailsStepType,
    closeModalCallback?: (step: TokenPartnerDetailsStepType | TokenDetailsStepType) => void
  ) => void;
  tokenToFormData: (currentToken: Token | null) => TokenFormDataProps;
}

export const TokenContext = createContext<TokenContextType | null>(null);

export const TokenContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // TODO: currenttoken is token values received from BE, and tokenFormsData is what we working with in the forms
  const [currentToken, setCurrentToken] = useState<Token | null>(null);
  const [tokenWallets, setTokenWallets] = useState<
    Pick<Token, 'unifiedWallets' | 'custodianWallets'> | null | undefined
  >(null);
  const [tokenCustodians, setTokenCustodians] = useState<CustodianAccount[] | null | undefined>(
    null
  );
  const [tokenFormsData, setTokenFormsData] = useState<TokenFormDataProps>(
    parseTokenToFormData(currentToken, tokenCustodians ?? null, tokenWallets ?? null)
  );
  const [tokenFormIsDirty, setTokenFormIsDirty] = useState(false);

  const [completedSteps, setCompletedSteps] = useState<
    (TokenPartnerDetailsStepType | TokenDetailsStepType)[]
  >([]);

  const [tokenActiveStep, setTokenActiveStep] = useState<
    TokenDetailsStepType | TokenPartnerDetailsStepType
  >(GENERAL_DETAILS_STEP);

  useMemo(
    () => setTokenFormsData(parseTokenToFormData(currentToken, tokenCustodians, tokenWallets)),
    [currentToken, tokenCustodians, tokenWallets]
  );

  const isFetching = useIsFetching(getTokenQueryString);

  const stepsStatuses = useMemo(
    () => getStepsStatuses(tokenFormsData, tokenActiveStep, completedSteps),
    [tokenFormsData, tokenActiveStep, completedSteps]
  );

  const openActiveTokenUpdatedModal = (
    openModal: (
      modalObject: ModalData,
      additionalData?:
        | {
            onCloseModalAction?: ((data?: any) => void) | undefined;
          }
        | undefined
    ) => {
      payload: any;
      type: 'modals/showModal';
    },
    step: TokenPartnerDetailsStepType | TokenDetailsStepType,
    closeModalCallback?: (step: TokenPartnerDetailsStepType | TokenDetailsStepType) => void
  ) => {
    openModal(
      {
        modalName: TOUCHED_FIELDS_MODAL,
        modalData: {
          type: MODAL_ACTIONS.EDIT,
          data: {},
          custom: {
            isToken: true,
            targetStep: step ?? PARTNERS_STEP,
          },
        },
      },
      { onCloseModalAction: closeModalCallback }
    );
  };

  const tokenToFormData = (currentToken: Token | null) => {
    return parseTokenToFormData(currentToken, tokenCustodians, tokenWallets);
  };

  return (
    <TokenContext.Provider
      value={{
        currentToken,
        setCurrentToken: (data: Token | null) => {
          let updatedData = data as Token;
          if (!data?.partners?.custodians?.length && currentToken?.partners?.custodians?.length) {
            updatedData = {
              ...(data as Token),
              custodianAccounts: currentToken?.custodianAccounts,
            };
          }
          if (!data?.constituentAssets?.length && currentToken?.constituentAssets?.length) {
            updatedData = {
              ...(updatedData as Token),
              constituentAssets: currentToken?.constituentAssets,
            };
          }
          if (!data?.unifiedWallets?.length && currentToken?.unifiedWallets?.length) {
            updatedData = {
              ...(updatedData as Token),
              unifiedWallets: currentToken?.unifiedWallets,
            };
          }
          if (!data?.custodianWallets?.length && currentToken?.custodianWallets?.length) {
            updatedData = {
              ...(updatedData as Token),
              custodianWallets: currentToken?.custodianWallets,
            };
          }
          setTokenFormIsDirty(false);
          setCurrentToken(updatedData);
        },
        tokenActiveStep,
        setTokenActiveStep,
        tokenFormIsDirty,
        setTokenFormIsDirty,
        tokenWallets,
        setTokenWallets,
        tokenCustodians,
        setTokenCustodians,
        tokenFormsData,
        setTokenFormsData: (newFormData: TokenFormDataProps | null) => {
          setTokenFormsData({ ...(tokenFormsData as TokenFormDataProps), ...newFormData });
        },
        isLoadingToken: Boolean(isFetching),
        completedSteps,
        setCompletedSteps,
        stepsStatuses,
        openActiveTokenUpdatedModal,
        tokenToFormData,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export function useTokenContext() {
  const context = useContext(TokenContext);

  if (!context) {
    throw new Error('useTokenContext must be used within a TokenContextProvider');
  }

  return context;
}
