import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { styled as MuiStyled, Box } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';

interface CardHeaderProps {
  withabel?: 'true' | 'false';
  exiticon?: 'true' | 'false';
}

interface StyledCardFooterProps {
  exiticon?: 'true' | 'false';
}

interface MuiCardContainerProps extends MuiCardBodyProps {
  disableScroll?: boolean;
}

interface MuiCardBodyProps {
  maxHeight?: string;
  disableScroll?: boolean;
}

export const StyledCardHeader = MuiStyled('header')<CardHeaderProps>`
  ${({ exiticon = 'false' }) => exiticon === 'true' && { 'align-items': 'center' }};
  justify-content: space-between;
  display: flex;
  height: ${({ withabel = 'false', theme }) =>
    withabel === 'true' ? theme.spacers['spacing-80'] : theme.spacers['spacing-64']};

  & > :first-child {
    padding-top: ${({ theme }) => theme.spacers['spacing-16']};
  }
`;

export const StyledTitleLabel = MuiStyled('span')`
  ${() => fontTypes['label-01']};
  color: ${({ theme }) => theme.palette.secondary.light};
  margin-bottom: '2px;
`;

export const StyledCardFooter = MuiStyled('footer')<StyledCardFooterProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-08']};
  padding-top: ${({ theme }) => theme.spacers['spacing-16']};
  ${({ exiticon = 'false' }) => exiticon === 'true' && { 'padding-top': '32px' }};
`;

export const MuiCardContainer = MuiStyled(Paper)<MuiCardContainerProps>(
  ({ theme, maxHeight = '100vh', disableScroll = false }) => ({
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    ...(disableScroll ? {} : { maxHeight: maxHeight }),
    overflowY: disableScroll ? 'hidden' : 'auto',
    padding: 0,
  })
);

export const MuiCardHeader = MuiStyled(Stack)<CardHeaderProps>(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  borderBottom: `1px solid ${theme.palette.text.divider.default.standard}`,
}));

export const MuiCardHeaderTextContainer = MuiStyled(Box)(({ theme }) => ({
  padding: '26.5px 24px',
  display: 'grid',
  gap: '8px',
}));

export const MuiCardSubtitle = MuiStyled(Typography)(({ theme }) => ({
  ...theme.typography.bodyMedium,
  color: theme.palette.text.secondary,
}));

export const MuiCardBody = MuiStyled(Box)<MuiCardBodyProps>(
  ({ theme, maxHeight = '700px', disableScroll = false }) => ({
    overflowY: disableScroll ? 'hidden' : 'auto',
    padding: theme.spacing(2),
    ...(disableScroll ? {} : { maxHeight: maxHeight }),
    height: '100%',
    '& > *': {
      padding: `${theme.spacers['spacing-16']}`,
    },
    '& > *:last-child': {
      paddingBottom: 0,
    },
  })
);

export const MuiCardBodyContent = MuiStyled(Stack)(({ theme }) => ({
  gap: '10px',
  padding: 0,
}));

export const MuiCardFooter = MuiStyled(Box)<StyledCardFooterProps>(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.text.divider.default.standard}`,
}));

export const StyledMuiButtonContainer = MuiStyled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  alignSelf: 'baseline',
  margin: `$12px auto`,
  svg: {
    margin: '12px',
  },
}));
