import { TableCell, TableRow } from 'components/Table';
import { RebalanceDetails } from 'utils/types/rebalance';
import { escapeNaN } from 'utils/formatting';

type TradeTableRowProps = {
  trade: RebalanceDetails;
  index: number;
};

export const TradesTableRow = ({ trade, index }: TradeTableRowProps) => (
  <TableRow data-qa-id={`${index}-row`}>
    <TableCell data-qa-id="ticker">{trade.ticker}</TableCell>
    <TableCell data-qa-id="tradeCash">{escapeNaN(trade.tradeCash)}</TableCell>
    <TableCell data-qa-id="tradeCoin">{escapeNaN(trade.tradeCoin)}</TableCell>
  </TableRow>
);
