import { Box, styled as MuiStyled } from '@mui/material';

export const StyledInfoFrame = MuiStyled(Box)`
  height: 100%;
  padding-top: 0;
  div {
    padding: 16px;
    background: rgb(247, 247, 247);
  }
`;
