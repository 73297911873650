import { Theme } from '@mui/material/styles';
import { RadioProps, radioClasses } from '@mui/material/Radio';

export function radio(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.bodyMedium,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: RadioProps }) => {
          const { color, checked, disabled } = ownerState;
          const styles: any = {};

          // DEFAULT & UNCHECKED
          if (color === 'default' && !checked) {
            styles['& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)'] = {
              color: theme.palette.text.default.strong,
            };
            styles['&:hover'] = {
              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                color: theme.customPalette.interactive.fill.primary.hover,
              },
            };
          }

          // DEFAULT & CHECKED
          if (color === 'default' && checked) {
            styles[`&.${radioClasses.root}`] = {
              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root':
                {
                  color: theme.customPalette.interactive.fill.primary.enabled,
                },
            };
            styles['&:hover'] = {
              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root':
                {
                  color: theme.customPalette.interactive.fill.primary.hover,
                },
            };
          }

          // ERROR
          if (color === 'error') {
            styles[`&.${radioClasses.root}`] = {
              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root':
                {
                  color: theme.customPalette.interactive.fill.danger.enabled,
                },
            };
            styles['&:hover'] = {
              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root':
                {
                  color: theme.customPalette.interactive.fill.danger.hover,
                },
            };
          }

          // DISABLED
          if (disabled) {
            styles[`&.${radioClasses.root}`] = {
              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root':
                {
                  color: theme.customPalette.interactive.outline.secondary.disabled,
                },
            };
            styles['&:hover'] = {
              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root':
                {
                  color: theme.customPalette.interactive.outline.secondary.hover,
                },
            };
          }

          return styles;
        },
      },
    },
  };
}
