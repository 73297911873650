/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import { pickBy, identity } from 'lodash';
import { CustodianAccount, Token, TokenType } from 'utils/types/product';
import {
  GENERAL_DETAILS_STEP,
  CONSTITUENT_STEP,
} from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import {
  PARTNERS_STEP,
  CUSTODIAN_INFO_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import { TokenFormDataProps } from 'pages/Tokens/Tokens.types';

function mapPropToList(prop?: { id: string }[]): string[] | undefined {
  return prop?.map((property) => property.id);
}

function parseTokenToFormData(
  token: Token | null,
  tokenCustodians: CustodianAccount[] | null | undefined,
  tokenWallets: Pick<Token, 'custodianWallets' | 'unifiedWallets'> | null | undefined
): TokenFormDataProps {
  return {
    [GENERAL_DETAILS_STEP]: pickBy(
      {
        name: token?.name,
        ticker: token?.ticker,
        sourceChain: token?.sourceChain,
        destinationChain: token?.destinationChain,
        tokenStandard: token?.tokenStandard,
        contractAddress: token?.contractAddress,
        mintFees: token?.fees?.mintFees && new BigNumber(token?.fees?.mintFees).toString(),
        burnFees: token?.fees?.burnFees && new BigNumber(token?.fees?.burnFees).toString(),
        type: TokenType.SINGLE_ASSET,
      },
      identity
    ),
    [CONSTITUENT_STEP]: token?.constituentAssets?.map((constituent) => ({
      _id: constituent._id,
      coingeckoId: constituent.coingeckoId,
      ticker: constituent.ticker,
      name: constituent.name,
      assetType: constituent.assetType,
      rounding: constituent.rounding,
      isStaking: constituent.isStaking,
      isMev: constituent.isMev,
      stakingFee: constituent.stakingFee,
      interestGenerating: constituent.interestGenerating,
      isLeveraged: constituent.isLeveraged,
      leverageRatio: constituent.leverageRatio,
      pairAsset: constituent.pairAsset,
    })),
    [PARTNERS_STEP]: pickBy(
      {
        issuer: token?.issuer?.id,
        authorizedParticipants: mapPropToList(token?.authorizedParticipants),
        custodians: Array.isArray(token?.partners?.custodians)
          ? token?.partners?.custodians
          : undefined,
      },
      identity
    ),
    [CUSTODIAN_INFO_STEP]:
      tokenCustodians?.map((custodian) => ({
        _id: custodian._id,
        apiKey: custodian.apiKey,
        apiPassphrase: custodian.apiPassphrase,
        provider: custodian.provider,
        name: custodian.name,
        description: custodian.description,
        signatureKey: custodian.signatureKey,
        status: custodian.status,
      })) || [],
    [WALLETS_INFO_STEP]: {
      custodianWallets:
        tokenWallets?.custodianWallets?.map((wallet) => ({
          _id: wallet._id,
          address: wallet.address,
          chain: wallet.chain,
          constituentId: wallet.constituentId,
          custodianAccount: wallet.custodianAccount,
          description: wallet.description,
          idAtCustodian: wallet.idAtCustodian,
          transactingCompany: wallet.transactingCompany,
          status: wallet.status,
          _actions: wallet._actions,
        })) || [],
      unifiedWallets:
        tokenWallets?.unifiedWallets?.map((wallet) => ({
          address: wallet.address,
          chain: wallet.chain,
          constituentId: wallet.constituentId,
          custodianAccount: wallet.custodianAccount,
          description: wallet.description,
          idAtCustodian: wallet.idAtCustodian,
          transactingCompany: wallet.transactingCompany,
          status: wallet.status,
        })) || [],
    },
  };
}

export default parseTokenToFormData;
