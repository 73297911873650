import exclamationCircleRed from 'assets/exclamation-circle-red.svg';
import fontTypes from 'utils/theme/designTokens/fontTypes';
import { Disable } from 'utils/types';
import { Box, styled as MuiStyled } from '@mui/material';

const sizeMap = {
  default: '20rem',
};

export const StyledContainer = MuiStyled(Box)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTextArea = MuiStyled('textarea')`
  ${() => fontTypes['body-01']};
  align-items: center;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  height: ${() => sizeMap.default};
  justify-content: center;
  outline: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  padding: ${({ theme }) =>
    `${theme.spacers['spacing-16']} ${theme.spacers['spacing-32']} 0 ${theme.spacers['spacing-16']}`};
  width: 100%;

  &::placeholder {
    color: #b3b3b3;
  }

  &:focus,
  &:active {
    outline: ${({ theme }) => `2px solid ${theme.palette.blue[300]}`};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.tertiary.lighter};
    outline: ${({ theme }) => `1px solid ${theme.palette.tertiary.lighter}`};
    color: ${({ theme }) => theme.palette.tertiary.darker};
    cursor: not-allowed;
  }

  &:invalid {
    outline: ${({ theme }) => `2px solid ${theme.palette.red[500]}`};
    background: ${() => `url(${exclamationCircleRed}) no-repeat`};
    background-position: ${({ theme }) =>
      `top ${theme.spacers['spacing-16']} right ${theme.spacers['spacing-16']}`};
  }
`;

export const StyledLabelContainer = MuiStyled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledCounter = MuiStyled('span')<Disable>`
  ${() => fontTypes['label-01']};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.tertiary.darker : theme.palette.secondary.light};
  margin-bottom: ${({ theme }) => `${theme.spacers['spacing-08']}`};
`;

export const StyledLabel = MuiStyled('span')<Disable>`
  ${() => fontTypes['label-01']};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.tertiary.darker : theme.palette.text.default.standard};
  display: block;
  margin-bottom: ${({ theme }) => `${theme.spacers['spacing-08']}`};
`;

export const StyledHelperText = MuiStyled('span')<Disable>`
  ${() => fontTypes['helper-text-01']};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.tertiary.darker : theme.palette.secondary.light};
  display: block;
  margin-top: ${({ theme }) => `${theme.spacers['spacing-04']}`};
`;

export const StyledErrorMessage = MuiStyled(StyledHelperText)`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.tertiary.darker : theme.palette.red[500]};
`;
