// types
import { Asset, ModelList } from 'utils/types';
import { ConstituentAssetType } from 'utils/types/product';

function mapAssetsToOptions(
  assets: ModelList<Asset> = {},
  selectedConstituents: ConstituentAssetType['ticker'][]
) {
  return Object.values(assets)
    .filter((asset) => !selectedConstituents.includes(asset.ticker))
    .map((asset) => ({
      label: asset.name,
      value: asset.ticker,
    }));
}

export default mapAssetsToOptions;
