import Table, { TableRow, TableCell } from 'components/Table';
import formatFieldName from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/utils/formatFieldName';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { useTokenContext } from 'contexts/tokens';
import { ProductConstituentAssetsProps, TokenConstituentAssetsProps } from 'utils/types/product';
import {
  PartnersStepDataProps,
  CustodiansListProps,
  WalletsList,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import { GeneralDetailsProps } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.types';
import { TableBody, TableHead } from '@mui/material';

export const TokenGeneralDetailsDifferencesSection = ({
  formDataDirtyFields,
  initialStepFormData,
  deleteUpdatedField,
}: {
  formDataDirtyFields: string[];
  initialStepFormData:
    | {}
    | ProductConstituentAssetsProps
    | TokenConstituentAssetsProps
    | GeneralDetailsProps
    | PartnersStepDataProps
    | CustodiansListProps
    | WalletsList
    | undefined;

  deleteUpdatedField: (fieldName: string) => void;
}) => {
  const { tokenFormsData, tokenActiveStep } = useTokenContext();

  return formDataDirtyFields.length ? (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell min-width="20%">Name</TableCell>
            <TableCell width="30%">Old Value</TableCell>
            <TableCell width="30%">Proposed Value</TableCell>
            <TableCell width="10%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formDataDirtyFields?.map((selectedValue, index) => (
            <TableRow key={`${selectedValue + index}`}>
              <TableCell>{formatFieldName(selectedValue)}</TableCell>
              <TableCell>
                {(initialStepFormData as { [key: string]: string })[selectedValue]}
              </TableCell>
              <TableCell>
                {(tokenFormsData[tokenActiveStep] as { [key: string]: string })[selectedValue]}
              </TableCell>
              <TableCell
                onClick={() => {
                  deleteUpdatedField(selectedValue);
                }}
              >
                <TrashIcon width={'32px'} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : null;
};
