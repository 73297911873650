import useAppDispatch from 'hooks/useAppDispatch';
import { useMutation } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';

import {
  createOrder as createOrderRequest,
  createDelegatedOrder as createDelegatedOrderRequest,
  createTokenOrder as createTokenOrderRequest,
  createDelegatedTokenOrder as createDelegatedTokenOrderRequest,
} from 'utils/api/orders';

const orderCreatedNotification = createNotification(
  successNotification('You successfully placed an order', 'Order created')
);

export const useCreateOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createOrder = useMutation({
    mutationFn: createOrderRequest,
    onSuccess: (order) => {
      dispatch(orderCreatedNotification);
      navigate(
        generatePath(privateRoutesUrls.dashboardRoutes.ordersDetails, {
          id: order._id,
        })
      );
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const createDelegatedOrder = useMutation({
    mutationFn: createDelegatedOrderRequest,
    onSuccess: (order) => {
      dispatch(orderCreatedNotification);
      navigate(
        generatePath(privateRoutesUrls.dashboardRoutes.ordersDetails, {
          id: order._id,
        })
      );
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const createTokenOrder = useMutation({
    mutationFn: createTokenOrderRequest,
    onSuccess: (order) => {
      dispatch(orderCreatedNotification);
      navigate(
        generatePath(privateRoutesUrls.dashboardRoutes.ordersTokensDetails, {
          id: order._id,
        })
      );
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const createDelegatedTokenOrder = useMutation({
    mutationFn: createDelegatedTokenOrderRequest,
    onSuccess: (order) => {
      dispatch(orderCreatedNotification);
      navigate(
        generatePath(privateRoutesUrls.dashboardRoutes.ordersTokensDetails, {
          id: order._id,
        })
      );
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  return { createOrder, createDelegatedOrder, createTokenOrder, createDelegatedTokenOrder };
};
