import Button from 'components/Button';
import Card from 'components/Card';
import Column from 'components/Grid/Column';
import { ReactComponent as Logo } from 'assets/logo.svg';
import {
  StyledLoggedOutContentWrapper,
  StyledLoginContainer,
  StyledLoginLogoWrapper,
  StyledNeedHelp,
} from 'pages/Login/Login.styles';
import { contactOnyxSupport } from 'utils/externalLinks';
import { isLoggedIn, removeUserLoggedOut } from 'utils/user/storage';
import { privateRoutesUrls, publicRoutesUrls } from 'router/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

export const LoggedOutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    removeUserLoggedOut();
    if (isLoggedIn()) {
      navigate(privateRoutesUrls.dashboardRoutes.rootPath);
    }
  }, [navigate]);

  return (
    <StyledLoginContainer>
      <StyledLoggedOutContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <Card title="Log out">
          <Typography variant="subheadingMedium">You have been logged out.</Typography>
          <Column offset={6} cols={6} md={{ cols: 4, offset: 4 }} xs={{ cols: 4, offset: 0 }}>
            <Button fullWidth type="submit" onClick={() => navigate(publicRoutesUrls.login)}>
              Sign In
            </Button>
          </Column>
        </Card>
        <StyledNeedHelp cols={12}>
          Need Help?{' '}
          <a href={contactOnyxSupport} target="_blank" rel="noreferrer">
            Contact Onyx support.
          </a>
        </StyledNeedHelp>
      </StyledLoggedOutContentWrapper>
    </StyledLoginContainer>
  );
};
