import { Theme } from '@mui/material/styles';
import { SelectProps } from '@mui/material';

export function outlinedInput(theme: Theme) {
  const rootStyles = (ownerState: SelectProps) => {
    const smallSize = ownerState.size === 'small';
    const mediumSize = ownerState.size === 'medium';
    const largeSize = ownerState.size === 'large';

    return {
      ...(smallSize && {
        minHeight: 32,
      }),
      ...(mediumSize && {
        minHeight: 40,
      }),
      ...(largeSize && {
        minHeight: 48,
      }),
    };
  };

  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: SelectProps }) => rootStyles(ownerState),
      },
    },
  };
}
