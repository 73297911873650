import { isEmpty } from 'lodash';

// types
import { Deliverable, Order, OrderFormProps } from 'utils/types/orders';

// utils
import { axiosInstance } from 'utils/axiosOnyx';
import { apiUrls } from 'utils/constants/apiUrls';
import { errorHandler } from 'utils/apiUtils';
import { PaginationWithDataResponse, ProductType } from 'utils/types';

function generateUrl(productType?: ProductType, params?: string) {
  let url = apiUrls.order;

  if (productType === 'Token') {
    url = `${url}/type=token`;
  } else {
    url = `${url}/type=etp`;
  }

  if (params) {
    url = `${url}?${params}`;
  }

  return url;
}

export async function getOrders(params?: string, productType?: ProductType) {
  const url = generateUrl(productType, params);

  try {
    const { data: orderResponse } = await axiosInstance.get<PaginationWithDataResponse<Order[]>>(
      url
    );

    return orderResponse;
  } catch (err) {
    return errorHandler(err, 'Error while fetching orders.');
  }
}

export async function getOrdersCSV(params?: string, productType: ProductType = 'ETP') {
  const apiUrl = generateUrl(productType);

  try {
    const response = await axiosInstance.get<Blob>(
      params ? `${apiUrl}/csv?${params}` : `${apiUrl}/csv`
    );

    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    const blobFile = new Blob([response.data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blobFile);
    a.href = url;
    a.download = `orders-${new Date().toISOString()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);

    return response;
  } catch (err) {
    return errorHandler(err, 'Error while fetching orders CSV.');
  }
}

export async function createOrder(order: OrderFormProps) {
  try {
    const { data: orderResponse } = await axiosInstance.post<Order>(apiUrls.order, {
      ...order,
      submit: true,
    });
    return orderResponse;
  } catch (err) {
    return errorHandler(err, 'Error while creating order.');
  }
}

export async function createDelegatedOrder(order: OrderFormProps) {
  const endpoint = isEmpty(order.deliveries) ? apiUrls.orderDelegated : apiUrls.seedOrder;

  try {
    const { data: orderResponse } = await axiosInstance.post<Order>(endpoint, {
      ...order,
      submit: true,
    });
    return orderResponse;
  } catch (err) {
    return errorHandler(err, 'Error while creating delegated order.');
  }
}

export async function loadOrder(id: Order['_id']) {
  try {
    const { data: order } = await axiosInstance.get<Order>(`${apiUrls.order}/id=${id}`);
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while loading order.');
  }
}

export async function confirmOrder(orderId: string, actualDeliverables?: Deliverable[]) {
  try {
    const { data: order } = await axiosInstance.patch<Order>(
      `${apiUrls.order}/id=${orderId}/confirm`,
      actualDeliverables
        ? {
            deliveries: actualDeliverables,
          }
        : {}
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while confirming order.');
  }
}

export async function readyOrder(orderId: string) {
  try {
    const { data: order } = await axiosInstance.patch<Order>(
      `${apiUrls.order}/id=${orderId}/ready`
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while updating order status to ready.');
  }
}

export async function settleOrder(orderId: string, actualDeliverables?: Deliverable[]) {
  try {
    const { data: order } = await axiosInstance.patch<Order>(
      `${apiUrls.order}/id=${orderId}/settle`,
      actualDeliverables
        ? {
            deliveries: actualDeliverables,
          }
        : {}
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while settling order.');
  }
}

export async function cancelOrder(orderId: string) {
  try {
    const { data: order } = await axiosInstance.delete<Order>(`${apiUrls.order}/id=${orderId}`);
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while canceling order.');
  }
}

export async function deferOrder(orderId: string) {
  try {
    const { data: order } = await axiosInstance.patch<Order>(
      `${apiUrls.order}/id=${orderId}/defer`
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while deferring order.');
  }
}

/* ----
TOKENS
---- */
export async function createTokenOrder(order: OrderFormProps, productType?: ProductType) {
  try {
    const { data: orderResponse } = await axiosInstance.post<Order>(apiUrls.orderToken, {
      ...order,
      submit: true,
    });
    return orderResponse;
  } catch (err) {
    return errorHandler(err, 'Error while creating order.');
  }
}

export async function createDelegatedTokenOrder(order: OrderFormProps) {
  try {
    const { data: orderResponse } = await axiosInstance.post<Order>(apiUrls.orderTokenDelegated, {
      ...order,
      submit: true,
    });
    return orderResponse;
  } catch (err) {
    return errorHandler(err, 'Error while creating delegated order.');
  }
}

export async function getTokenOrder(id: Order['_id']) {
  try {
    const { data: order } = await axiosInstance.get<Order>(`${apiUrls.orderToken}/id=${id}`);
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while loading order.');
  }
}

export async function confirmTokenOrder(orderId: string) {
  try {
    const { data: order } = await axiosInstance.patch<Order>(
      `${apiUrls.orderToken}/id=${orderId}/confirm`
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while confirming order.');
  }
}

export async function cancelTokenOrder(orderId: string) {
  try {
    const { data: order } = await axiosInstance.delete<Order>(
      `${apiUrls.orderToken}/id=${orderId}`
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while canceling order.');
  }
}

export async function readyTokenOrder(orderId: string) {
  try {
    const { data: order } = await axiosInstance.patch<Order>(
      `${apiUrls.orderToken}/id=${orderId}/ready`
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while updating order status to ready.');
  }
}

export async function settleTokenOrder(orderId: string, actualDeliverables?: Deliverable[]) {
  try {
    const { data: order } = await axiosInstance.patch<Order>(
      `${apiUrls.orderToken}/id=${orderId}/settle`,
      actualDeliverables
        ? {
            deliveries: actualDeliverables,
          }
        : {}
    );
    return order;
  } catch (err) {
    return errorHandler(err, 'Error while settling order.');
  }
}
