import loginBg from 'assets/login-bg.svg';
import Column from 'components/Grid/Column';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { Box, Card, styled as MuiStyled, Typography } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';

export const StyledLoginContainer = MuiStyled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  background-image: url(${loginBg});
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: fit-content;
`;

export const StyledContentWrapper = MuiStyled(Box)(({ theme }) => ({
  margin: 'auto 0',
}));

export const StyledLoggedOutContentWrapper = MuiStyled(StyledContentWrapper)(({ theme }) => ({
  width: '400px',
}));

export const StyledLoginFormWrapper = MuiStyled(Card)(({ theme }) => ({
  background: 'white',
  width: '400px',
}));

export const StyledLabel = MuiStyled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const StyledSpan = MuiStyled('span')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.blue[600],
  marginBottom: theme.spacers['spacing-08'],
  ...fontTypes['label-01'],
}));

export const StyledNeedHelp = MuiStyled(Column)(({ theme }) => ({
  width: '400px',
  color: 'white',
  paddingLeft: 0,
  a: {
    color: theme.palette.blue[400],
    textDecorationLine: 'underline',
  },
}));

export const StyledLink = MuiStyled('span')(({ theme }) => ({
  color: theme.palette.blue[400],
  textDecorationLine: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const StyledCheckboxWrapper = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  input: {
    width: '1rem',
    height: '1rem',
    borderRadius: 'unset',
    backgroundSize: '1rem 1rem',
  },
  svg: {
    verticalAlign: 'bottom',
  },
  a: {
    paddingTop: '2px',
    paddingLeft: '2px',
  },
}));

export const StyledInfoIcon = MuiStyled(InfoIcon)(({ theme }) => ({
  paddingTop: theme.spacers['spacing-02'],
  fill: theme.palette.secondary.main,
  marginLeft: theme.spacers['spacing-04'],
  marginTop: theme.spacers['spacing-02'],
  width: '1rem',
  height: '1rem',
  verticalAlign: 'sub',
}));

export const StyledLoginLogoWrapper = MuiStyled(Box)({
  textAlign: 'center',
});

export const MuiStyledSpan = MuiStyled(Typography)(({ theme }) => ({
  color: theme.palette.info.dark,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export const StyledReviewTermsOfUse = MuiStyled('span')({
  color: '#0A68F5',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0.16px',
  wordWrap: 'break-word',
  cursor: 'pointer',
  alignSelf: 'center',
  '&:hover': {
    textDecoration: 'underline',
  },
});
