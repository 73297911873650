import { TableProps } from 'components/Table';
import { Dispatch, ReactNode, SetStateAction } from 'react';

const ITEMS_PER_PAGE_10 = 10;
const ITEMS_PER_PAGE_25 = 25;
const ITEMS_PER_PAGE_50 = 50;
const ITEMS_PER_PAGE_100 = 100;

export const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_ARRAY_LENGTH = 0;

export const itemsPerPageOptionsList = [
  ITEMS_PER_PAGE_10,
  ITEMS_PER_PAGE_25,
  ITEMS_PER_PAGE_50,
  ITEMS_PER_PAGE_100,
];
export const dateFormat = 'dd/MM/yyyy, hh:mm a';
export const dailyTableDateFormat = 'dd/MM/yyyy';
export const DEFAULT_ITEMS_PER_PAGE = 50;
export const DEFAULT_PAGE = 1;

// In the future we could change it to -1. It would require backend changes or we can use scroll + autocomplete
export const UNLIMITED_ITEMS_PER_PAGE = 9999;

export type TABLE_CELL_ALIGNMENTS = 'center' | 'left' | 'right' | undefined;

export const formatTableProperties = (
  title: string,
  page = DEFAULT_PAGE_INDEX,
  setPage: Dispatch<SetStateAction<number>>,
  itemsPerPage: number,
  setItemsPerPage: Dispatch<SetStateAction<number>>,
  totalItemsLength: number = DEFAULT_ARRAY_LENGTH,
  toolbarTools?: ReactNode[]
): TableProps => ({
  title,
  toolbarTools,
  pagination: {
    itemsPerPage: itemsPerPage,
    itemsPerPageOptions: itemsPerPageOptionsList.map((num) => ({
      label: num.toString(),
      value: num,
    })),
    onChangeItemsPerPage: (value: number) => {
      setPage(DEFAULT_PAGE);
      setItemsPerPage(value);
    },
    onChangePage: function noRefCheck(pageToChange: number) {
      setPage(pageToChange);
    },
    page: page,
    totalItems: totalItemsLength,
  },
});

export const renderOrderValue = (
  showValue: Boolean,
  value: string | number | boolean | undefined
) => {
  return showValue ? value : '-';
};
