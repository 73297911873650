import {
  ComponentPropsWithoutRef,
  ChangeEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

// utils
import mergeRefs from 'utils/mergeRef';

// Styles;
import {
  StyledContainer,
  StyledLabel,
  StyledCounter,
  StyledLabelContainer,
  StyledTextArea,
  StyledErrorMessage,
  StyledHelperText,
} from 'components/TextArea/TextArea.styles';

interface TextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
  errorMessage?: string;
  helperText?: string;
  isInvalid?: boolean;
  label: string;
  withCounter?: boolean;
  value?: string | undefined;
}

const TextAreaComponent = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { disabled, errorMessage, helperText, isInvalid, label, onChange, withCounter, ...props },
    ref
  ) => {
    const [count, setCount] = useState(0);
    const innerRef = useRef<HTMLTextAreaElement>(null);
    const refs = mergeRefs<HTMLTextAreaElement>(ref, innerRef);
    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setCount(event.target.value.length);
      if (onChange) {
        onChange(event);
      }
    };

    useEffect(() => {
      if (withCounter) {
        setCount(props.value?.toString().length || 0);
      }
    }, [props.value, withCounter]);

    useEffect(() => {
      if (isInvalid) {
        innerRef?.current?.setCustomValidity('invalid');
      } else {
        innerRef?.current?.setCustomValidity('');
      }
    }, [isInvalid]);

    return (
      <StyledContainer>
        <StyledLabelContainer>
          {label && <StyledLabel disabled={disabled}>{label}</StyledLabel>}
          {withCounter && <StyledCounter disabled={disabled}>{count}/100</StyledCounter>}
        </StyledLabelContainer>
        <StyledTextArea disabled={disabled} {...props} ref={refs} onChange={handleOnChange} />
        {!errorMessage && helperText && (
          <StyledHelperText disabled={disabled}>{helperText}</StyledHelperText>
        )}
        {errorMessage && (
          <StyledErrorMessage disabled={disabled}>{errorMessage}</StyledErrorMessage>
        )}
      </StyledContainer>
    );
  }
);

export default TextAreaComponent;
