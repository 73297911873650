import { useState } from 'react';
import { Column, Row } from 'components/Grid';
import ProgressIndicator from 'components/ProgressIndicator';
import { getSteps } from 'pages/Instruments/components/Form/utils';
import {
  PartnerStepType,
  getFilteredSteps,
} from 'pages/Partners/components/PartnerRelatedEntitiesTabs/PartnerTabs.utils';
import { useUserPermissions } from 'store/user/selectors';
import { ZERO_INDEX } from 'utils/constants/numbers';
import { PartnerInformation } from './PartnerInformation/PartnerInformation';
import { useParams } from 'react-router-dom';
import { useCompanyQuery } from 'pages/Partners/hooks/useCompanyQuery';
import isEmpty from 'lodash/isEmpty';
import { CalendarsStep } from './CalendarsStep/CalendarsStep';
import { UsersStep } from './UsersStep/UsersStep';
import { WalletsStep } from './WalletsStep/WalletsStep';

export const PartnerTabs = () => {
  const { id: partnerId } = useParams();
  const { data: partnerData } = useCompanyQuery(partnerId ?? '');
  const permissions = useUserPermissions();
  const canSeeCalendars = Boolean(
    permissions?.canViewCompanyCalendar && partnerData?._links?.calendars
  );
  const canSeeUsers = Boolean(permissions?.canViewCompanyUsers);
  const canSeeWallets = Boolean(
    permissions?.canViewCompanyWallets &&
      (partnerData?._links?.walletsCustodian || partnerData?._links?.walletsOwned)
  );

  const isNewPartner = isEmpty(partnerData);

  const partnerRelatedEntitiesStepsList = getFilteredSteps(
    isNewPartner,
    canSeeCalendars,
    canSeeUsers,
    canSeeWallets
  );

  const [activeStep, setActiveStep] = useState<PartnerStepType | undefined>(
    partnerRelatedEntitiesStepsList?.[ZERO_INDEX]?.id ?? undefined
  );

  if (!partnerRelatedEntitiesStepsList.length) return null;
  const PartnerRelatedEntitiesScreens = {
    PartnerInformation: <PartnerInformation />,
    Calendars: <CalendarsStep />,
    Users: <UsersStep />,
    Wallets: <WalletsStep />,
  };

  return activeStep ? (
    <>
      <Row>
        <Column>
          <ProgressIndicator
            onClickStep={(stepLabel: PartnerStepType) => setActiveStep(stepLabel)}
            steps={getSteps(activeStep, partnerRelatedEntitiesStepsList)}
          />
        </Column>
      </Row>
      {PartnerRelatedEntitiesScreens[activeStep]}
    </>
  ) : null;
};

export default PartnerTabs;
