import {
  GroupedOptionsType,
  OptionType,
  OptionTypeWithGroup,
  OptionValueType,
} from 'components/Select/Select.types';

function transformOptions<T extends OptionValueType>(
  options?: OptionType<T>[] | GroupedOptionsType<T>
): OptionTypeWithGroup<T>[] {
  if (!options) {
    return [];
  }

  if (Array.isArray(options)) {
    return options.map((option: OptionType<T>) => ({ ...option, group: '' }));
  }

  return Object.keys(options).flatMap((group) =>
    (options[group] as OptionType<T>[]).map((option: OptionType<T>) => ({ ...option, group }))
  );
}

export default transformOptions;
