import {
  ORDERS_TAB_ALL_STATUSES,
  OrderTabType,
  OrdersFiltersData,
  tabInProgress,
} from 'utils/constants/orders';
import { omit } from 'lodash';
import { getQueryString } from 'hooks/useUrlParams';

export const mapOrderTabToStatus = (urlParams: OrdersFiltersData) => {
  const ordersListOptions = ORDERS_TAB_ALL_STATUSES[urlParams.tab as OrderTabType]
    ? ORDERS_TAB_ALL_STATUSES[urlParams.tab as OrderTabType]
    : ORDERS_TAB_ALL_STATUSES[tabInProgress];

  const statusList = ordersListOptions.map((status) => status.value);

  const modifiedFiltersData =
    urlParams.tab && !urlParams.status
      ? { ...urlParams, tab: null, status: statusList }
      : urlParams;

  return getQueryString(omit(modifiedFiltersData, 'tab'));
};
