import { TOKENS_TAB_STATUS, ProductTabType } from 'pages/Tokens/Tokens.constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { getProductStatus } from 'pages/Tokens/helpers/getProductStatus';
import { ProductStatus } from 'utils/types/product';
import { ParamsType } from 'hooks/useUrlParams';

export const formatTokenTableURLparams = (urlParams: ParamsType) => {
  return {
    ...urlParams,
    tab: urlParams.tab ?? TOKENS_TAB_STATUS.ACTIVE,
    page: urlParams.page ?? DEFAULT_PAGE,
    pageSize: urlParams.pageSize ?? DEFAULT_ITEMS_PER_PAGE,
    status:
      urlParams.status ??
      getProductStatus((urlParams.tab as ProductTabType) ?? ProductStatus.ACTIVE),
  };
};
