import PageTitle from 'components/PageTitle';
import { StyledPageHeaderWrapper } from 'router/guards/styles';
import { AppRoute } from 'router/types';

export interface PageHederInterface {
  actionComponent?: React.ReactNode;
  customComponent?: React.ReactNode;
  customComponentPosition?: 'start' | 'end';
  pageTitle?: string;
  route?: AppRoute;
}

export const PageHeader = ({
  actionComponent,
  customComponent,
  customComponentPosition,
  pageTitle,
  route,
}: PageHederInterface) => {
  const title = (() => {
    if (pageTitle) return pageTitle;
    if (route) return route.routeTitle;
    return null;
  })();

  return (
    <StyledPageHeaderWrapper>
      {customComponentPosition === 'start' && customComponent}
      {title && <PageTitle>{title}</PageTitle>}
      {actionComponent}
      {customComponentPosition === 'end' && customComponent}
    </StyledPageHeaderWrapper>
  );
};
