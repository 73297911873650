import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { PartnersUser } from 'utils/types';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { updateUserInvitationRequest } from 'utils/api/users';
import { useMutation } from 'react-query';
import { userInvitedNotification } from 'shared/Notifications/users.notifications';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const SendInvitationModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const user = data.data as PartnersUser;
  const usersName = `${user.firstName} ${user.lastName}`;

  const updateUserInvitationMutation = useMutation({
    mutationFn: () => updateUserInvitationRequest(user),
    onSuccess: (res) => {
      dispatch(createNotification(userInvitedNotification(`${user.firstName} ${user.lastName}`)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message), error));
    },
  });

  const handleActionSubmit = () => {
    updateUserInvitationMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={handleActionSubmit}
        type="button"
        data-qa-id="resendButton"
      >
        Resend
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        title={
          <>
            Are you sure you want to resend invitation to <b>{usersName}</b>?
          </>
        }
        label="User"
        footer={Footer}
        onClose={closeModal}
      />
    </CustomModal>
  );
};
