import { ComponentPropsWithoutRef } from 'react';
import { styled as MuiStyled, Link } from '@mui/material';

interface LinkProps extends ComponentPropsWithoutRef<'a'> {
  disabled?: boolean;
  underlined?: boolean;
}

export const MuiStyledMuiLink = MuiStyled(Link)<LinkProps>(({ theme, disabled }) => ({
  '&:hover': {
    cursor: disabled ? 'default' : 'pointer',
  },
  ...(disabled && {
    color: theme.palette.grey[500],
    pointerEvents: 'none',
  }),
}));
