import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

const FormGridItem: React.FC<GridProps> = ({ xs = 12, md = 6, ...props }) => {
  return (
    <Grid item xs={xs} md={md} {...props}>
      {props.children}
    </Grid>
  );
};

export default FormGridItem;
