import { ExistingConstituents } from 'shared/Modals/Constituents/CreateConstituentModal/ExistingConstituent';
import NewConstituent from 'shared/Modals/Constituents/CreateConstituentModal/NewConstituent';
import TabNavigation from 'components/TabNavigation';
import {
  StyledCard,
  StyledConstituentModalLabel,
} from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep/components/ConstituentStep.styles';
import {
  ConstituentAssetType,
  ProductConstituentAssetType,
  TokenConstituentAssetType,
} from 'utils/types/product';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { useState } from 'react';
import { postAssets } from 'utils/api/assets';
import useAppDispatch from 'hooks/useAppDispatch';
import { useMutation } from 'react-query';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { loadAssets } from 'store/assets/actions';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { useCurrentProduct } from 'hooks/useCurrentProduct';

const EXISTING_CONSTITUENTS_TAB = 'Existing Assets';
const NEW_CONSTITUENT = 'New Asset';

export const CreateConstituentModal = ({ closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const isToken = data.custom?.isToken;

  const { product, constituents, etpMutation, tokenMutation } = useCurrentProduct(
    isToken ? 'Token' : 'ETP',
    closeModal
  );

  const isLoading = etpMutation?.isLoading || tokenMutation?.isLoading;

  const [activeTab, setActiveTab] = useState(EXISTING_CONSTITUENTS_TAB);

  const createPostAssetMutation = useMutation({
    mutationFn: (constituent: ConstituentAssetType) => {
      return postAssets({
        coingeckoId: constituent.coingeckoId,
        ticker: constituent.ticker,
        name: constituent.name,
        rounding: constituent.rounding,
        type: constituent.assetType,
      });
    },
    onSuccess: async (_, constituent) => {
      await dispatch(loadAssets());
      const updatedConstituentsList = [...constituents, constituent];

      isToken
        ? tokenMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as TokenConstituentAssetType[],
          })
        : etpMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as ProductConstituentAssetType[],
          });
    },
    onError: (err: Error) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const handleSubmit = (newConstituents: ConstituentAssetType[], isNewCrypto?: Boolean) => {
    if (isNewCrypto) {
      newConstituents.length && createPostAssetMutation.mutate(newConstituents[0]);
    } else {
      const updatedConstituentsList = [...constituents, ...newConstituents];

      isToken
        ? tokenMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as TokenConstituentAssetType[],
          })
        : etpMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as ProductConstituentAssetType[],
          });
    }
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <StyledCard label="Constituent" title="Add Assets" onClose={closeModal}>
        <StyledConstituentModalLabel>
          You can add existing cryptos or create new crypto assets.
        </StyledConstituentModalLabel>
        <TabNavigation
          active={activeTab}
          tabs={[
            {
              name: EXISTING_CONSTITUENTS_TAB,
              onSelect: () => setActiveTab(EXISTING_CONSTITUENTS_TAB),
            },
            {
              name: NEW_CONSTITUENT,
              onSelect: () => setActiveTab(NEW_CONSTITUENT),
            },
          ]}
        >
          {activeTab === NEW_CONSTITUENT ? (
            <NewConstituent
              onSubmit={(data) => handleSubmit(data, true)}
              selectedConstituents={constituents?.map((constituent) => constituent.ticker) ?? []}
              toggleModal={closeModal}
              isLoading={isLoading || createPostAssetMutation.isLoading}
            />
          ) : (
            <ExistingConstituents
              isLoading={isLoading || createPostAssetMutation.isLoading}
              onSubmit={handleSubmit}
              selectedConstituents={constituents?.map((constituent) => constituent.ticker) ?? []}
              toggleModal={closeModal}
              singleAsset={product?.type === 'Single Asset'}
            />
          )}
        </TabNavigation>
      </StyledCard>
    </CustomModal>
  );
};
