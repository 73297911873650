import { ParamsType } from 'hooks/useUrlParams';
import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { loadInstrumentsBaseData } from 'utils/api/instruments';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getEtpsBaseDataQuery } from 'utils/constants/reactQueries';
import { PaginationResponse } from 'utils/types';

export const useETPsBaseDataQuery = (urlParams: ParamsType) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getEtpsBaseDataQuery, urlParams],
    async () => {
      try {
        const dataResponse = await loadInstrumentsBaseData(urlParams).then((data) => {
          const pagination: PaginationResponse = {
            page: data.page,
            pageSize: data.pageSize,
            total: data.total,
          };
          return {
            ...data,
            pagination,
          };
        });
        return dataResponse;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(urlParams),
    }
  );
};
