export const RegisterTableColumnsConfig = [
  { label: 'Product', propName: 'ticker', propType: 'string', width: '20%' },
  {
    label: 'Total Number of Shares Outstanding',
    propName: 'totalUnitsOutstanding',
    propType: 'string',
    sort: false,
    align: 'right',
    width: '32%',
  },
  {
    label: 'Download PDF',
    propName: 'downloadPDF',
    propType: 'string',
    sort: false,
    width: '20%',
  },
  {
    label: 'Download XLS',
    propName: 'downloadXLS',
    propType: 'string',
    sort: false,
    width: '20%',
  },
];
