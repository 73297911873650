import { ReactElement } from 'react';
import { FormPropsType } from 'components/Form/Form.types';
import { isUndefined, map, upperCase } from 'lodash';
import { capitalize } from 'lodash';

function allCapsLabels(array: { label: string }[]) {
  return map(array, (item) => ({ ...item, label: upperCase(item.label) }));
}
function capitalizeLabels(array: { label: string }[]) {
  return map(array, (item) => ({ ...item, label: capitalize(item.label) }));
}

export const getOptions = (
  child: ReactElement,
  formProps: FormPropsType,
  fieldName: string,
  allCaps: boolean,
  capitalizeProp?: Boolean
) => {
  const capitalize = isUndefined(capitalizeProp) ?? capitalizeProp; // default is to capitalize label unless the prop is set to false
  const formSchemaOptions = child.props.mapOptions
    ? child.props.mapOptions(formProps.enums[fieldName])
    : formProps.enums[fieldName];
  const options = child.props.options || formSchemaOptions;
  if (allCaps) {
    return allCapsLabels(options);
  }
  if (capitalize) {
    return capitalizeLabels(options);
  }

  return options;
};
