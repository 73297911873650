import Footer from 'pages/Instruments/components/Footer';
import { EXCHANGE_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { Exchange, ExchangeList } from 'utils/types';
import {
  ExchangeType,
  ExchangesProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { ProductExchangeStepTable } from './components/ProductExchangeStepTable';
import { useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { useExchangesQuery } from './hooks/useExchangesQuery';
import { useMemo } from 'react';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';

interface ExchangeStepProps {
  goBack: () => void;
  onSubmit: () => void;
}

function ExchangeStep({ goBack, onSubmit }: ExchangeStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const productExchangesFormData = useCurrentInstrumentFormData(EXCHANGE_STEP) as ExchangesProps;
  const { data: exchanges, isLoading } = useExchangesQuery();

  const saveValues = (productExchanges: ExchangeType[]) => {
    saveInstrumentDraft({
      [EXCHANGE_STEP]: productExchanges as ExchangeType[],
    });
  };

  const updateProductExchanges = (updatedExchanges: ExchangeType[]) => {
    saveValues([...updatedExchanges]);
  };

  const exchangesThatAreNotPresentInTheProduct = useMemo((): Exchange[] => {
    if (!exchanges) {
      return [];
    }
    return Object.values(exchanges).filter((exc) => {
      return !productExchangesFormData?.find((el) => {
        return el.id === String(exc._id);
      });
    });
  }, [exchanges, productExchangesFormData]);

  return (
    <>
      <ProductExchangeStepTable
        allExchanges={exchanges as ExchangeList}
        exchanges={exchangesThatAreNotPresentInTheProduct ?? {}}
        productExchangesFormData={productExchangesFormData}
        updateProductExchanges={updateProductExchanges}
        loading={isLoading}
      />
      <Footer goBack={goBack} onSubmit={onSubmit} />
    </>
  );
}

export default ExchangeStep;
