import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table, { TableProps } from 'components/Table';
import TableHeader from 'shared/Tables/Table/TableHeader';
import isEmpty from 'lodash/isEmpty';
import { ApiKeysTableRow } from './ApiKeysTableRow';
import { apiKeysTableColumnsConfig } from './tableColumnsConfig';
import { ApiKey } from 'utils/types/apiKeys';

interface ApiKeysTableProps {
  tableProperties?: TableProps;
  deleteAction?: (apiKey: ApiKey) => void;
  apiKeys: ApiKey[];
  loadingData?: boolean;
}

export const ApiKeysTable = ({
  tableProperties,
  deleteAction,
  apiKeys,
  loadingData,
}: ApiKeysTableProps) => {
  return (
    <Table {...tableProperties} noPadding>
      <TableHeader showActionsCell columns={apiKeysTableColumnsConfig} />
      <TableBodyWithStates
        loadingData={Boolean(loadingData)}
        hasContent={!isEmpty(apiKeys)}
        noContentLabel="No API Keys at the moment."
      >
        {apiKeys &&
          apiKeys?.map((apiKey: ApiKey) => (
            <ApiKeysTableRow
              key={apiKey._id}
              apiKey={apiKey}
              deleteAction={() => {
                if (deleteAction) deleteAction(apiKey);
              }}
            />
          ))}
      </TableBodyWithStates>
    </Table>
  );
};
