import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { ProductBase } from 'utils/types';
import { createNotification } from 'store/notifications/actions';
import { deleteEntityApiCall } from 'utils/api/crudActions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
// import { Typography } from '@mui/material';

export const DeactivateProductModal = ({
  onCloseModalAction,
  closeModal,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const product = data.data as ProductBase;

  const productTypeLabel = data.custom?.isToken ? 'Token' : 'ETP';

  const deleteProductMutation = useMutation({
    mutationFn: () => deleteEntityApiCall(product._actions?.delete?.uri ?? ''),
    onSuccess: () => {
      dispatch(
        createNotification(
          successNotification(
            `${product.name} ${productTypeLabel} has been deactivated`,
            productTypeLabel
          )
        )
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleDelete = () => {
    deleteProductMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="interactive"
        fullWidth
        onClick={handleDelete}
        type="submit"
        data-qa-id="deactivateButton"
        isLoading={deleteProductMutation.isLoading}
      >
        Deactivate
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        label={productTypeLabel}
        title={`Are you sure you want to deactivate ${product.name}?`}
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
