import { createNotification } from 'store/notifications/actions';
import useAppDispatch from 'hooks/useAppDispatch';
import { GetCompaniesSuggestionQueryParams } from 'utils/types';
import { getCompaniesSuggestions } from 'utils/api/partners';
import { getCompanySuggestionQuery } from 'utils/constants/reactQueries';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';

export const useCompaniesSuggestion = (
  filter?: GetCompaniesSuggestionQueryParams,
  enabled = true
) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getCompanySuggestionQuery, filter],
    async () => {
      try {
        const { data } = await getCompaniesSuggestions(filter);
        return data;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    { enabled }
  );
};
