import { createSlice, SerializedError } from '@reduxjs/toolkit';
// types
import { User } from 'utils/types';
import { fetchUser } from './action';

type UserStateType = {
  error: string | SerializedError | null;
  loading: boolean;
  user: User | null;
};

const initialState: UserStateType = {
  error: null,
  loading: false,
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.error = null;
      state.loading = true;
    });

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.user = action.payload;
    });

    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ? action.payload : action.error;
    });
  },
  reducers: {
    logout() {
      return initialState;
    },
    updateUserReducer(state, action) {
      state.error = null;
      state.loading = false;
      state.user = action.payload;
    },
  },
});

export const { logout, updateUserReducer } = userSlice.actions;

export const userReducer = userSlice.reducer;

export default userSlice;
