import useAppDispatch from 'hooks/useAppDispatch';
import { OrdersFiltersData } from 'utils/constants/orders';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { getOrders } from 'utils/api/orders';
import { getOrdersQueryKey, getTokenOrdersQueryKey } from 'utils/constants/reactQueries';
import { mapOrderTabToStatus } from 'pages/Orders/Orders/utils/mapOrderTabToStatus';
import { useQuery } from 'react-query';
import { useAuthenticatedUser } from 'store/user/selectors';
import { ProductType } from 'utils/types/product';

export const useLoadOrders = (urlParams: OrdersFiltersData, productType?: ProductType) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticatedUser();
  const queryKey = productType === 'Token' ? getTokenOrdersQueryKey : getOrdersQueryKey;

  return useQuery([queryKey, { urlParams, user }], async () => {
    try {
      const ordersList = await getOrders(mapOrderTabToStatus(urlParams), productType);
      const pagination = {
        page: ordersList.page,
        pageSize: ordersList.pageSize,
        total: ordersList.total,
      };
      return { orders: ordersList.data, pagination: pagination };
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    }
  });
};
