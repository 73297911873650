import Button from 'components/Button';
import Card from 'components/Card';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { OrdersModalData } from 'shared/Modals/Orders/OrdersModal';
import { useButtonsActions } from 'pages/Orders/Orders/hooks/useButtonsActions';

const OrderCancelConfirmationModal = ({ closeModal, data }: IModalWithData) => {
  const orderModalData = data?.data as OrdersModalData;
  const actions = useButtonsActions(orderModalData?.order, orderModalData?.refreshQueryKey);
  const confirmAction = actions.find((action) => action.status === orderModalData.targetStatus);

  const onConfirm = () => {
    confirmAction && confirmAction.callback();
    closeModal();
  };

  const Footer = (
    <>
      <Button fullWidth onClick={onConfirm}>
        Confirm
      </Button>
      <Button variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
    </>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card footer={Footer} label="Order" title="Cancel Order" onClose={closeModal}>
        Are you sure you want to cancel this order?
      </Card>
    </CustomModal>
  );
};

export default OrderCancelConfirmationModal;
