import { OrderAllowedDeliveries, OrderProductInfo } from 'utils/types/orders';
import getOpenTime from './getOpenTime';
import getCloseTime from './getCloseTime';
import { isPast } from 'date-fns';

export const getExchangeWorkingHoursInformation = (
  product?: OrderProductInfo,
  deliveryType?: OrderAllowedDeliveries,
  isDelegatedOrder?: boolean
) => {
  const openTime = getOpenTime(product);
  const closeTime = getCloseTime(
    product?.cutoffs,
    product?.primaryExchange.calendar.timezone,
    deliveryType
  );
  return {
    openTime: openTime,
    closeTime: closeTime,
    isClosed: !product || isPast(closeTime),
  };
};
