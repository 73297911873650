import { format } from 'date-fns';
import { fromZonedTime as zonedTimeToDate } from 'date-fns-tz';

// types
import { OrderFormProps, OrderProductInfo } from 'utils/types/orders';
import { Instrument } from 'utils/types';
import { ORDER_IN_KIND } from 'utils/constants';
import { DEFAULT_DATE_FORMAT } from 'utils/date';

function getCloseTime(
  cutoffs: Instrument['cutoffs'],
  timezone?: OrderProductInfo['timezone'],
  deliveryType?: OrderFormProps['deliveryType']
) {
  const cutOffTimes: { [index: string]: string | undefined } = {
    IN_KIND: cutoffs?.inKindCutOffTime,
    CASH: cutoffs?.cashCutOffTime,
  };
  const cutOff = cutOffTimes[deliveryType || ORDER_IN_KIND];

  return cutOff && timezone
    ? zonedTimeToDate(`${format(new Date(), DEFAULT_DATE_FORMAT)} ${cutOff}`, timezone)
    : new Date();
}

export default getCloseTime;
