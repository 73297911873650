import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { useMemo } from 'react';
import { useAuthenticatedUser } from 'store/user/selectors';
import { PARTNER_STATUS, PARTNER_STATUS_LABEL, PartnerTabType } from 'utils/constants/partners';
import { omit } from 'lodash';
import { getPartnerStatus } from 'pages/Partners/helpers/getPartnerStatus';
import { useQuery } from 'react-query';
import { requestCompanies } from 'utils/api/partners';
import { DEFAULT_PAGE, UNLIMITED_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { getCompaniesQuery } from 'utils/constants/reactQueries';

export const useCompanies = (urlParams: ParamsType, enabled = true) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticatedUser();

  const status = useMemo(
    () =>
      getPartnerStatus(urlParams.tab as PartnerTabType) === PARTNER_STATUS_LABEL.ACTIVE
        ? PARTNER_STATUS.ACTIVE
        : PARTNER_STATUS.DELETED,
    [urlParams.tab]
  );

  return useQuery(
    [getCompaniesQuery, urlParams, user],
    async () => {
      try {
        const params = omit({ ...urlParams, status }, 'tab');

        const paramsWithPagination =
          params?.page && params?.pageSize
            ? params
            : { ...params, page: DEFAULT_PAGE, pageSize: UNLIMITED_ITEMS_PER_PAGE };

        const { data } = await requestCompanies(paramsWithPagination);
        return data;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    { enabled }
  );
};
