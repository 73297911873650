import { format, isFuture as isFutureDateFns, parse as parseDateFns } from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export function parseDate(date: string, format: string = DEFAULT_DATE_FORMAT) {
  return parseDateFns(date, format, new Date());
}

export function isFuture(date: string, format: string = DEFAULT_DATE_FORMAT) {
  const parsedDate = parseDate(date, format);

  return isFutureDateFns(parsedDate);
}

export const getUtcDateString = (dateInput: string) => {
  const utcDateObject = getUtcDateObject(dateInput);
  return format(utcDateObject, DEFAULT_DATE_FORMAT);
};

export const getUtcDateObject = (dateInput: string | null) => {
  const date = dateInput ? new Date(dateInput) : new Date();
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth();
  const utcDate = date.getUTCDate();
  return new Date(utcYear, utcMonth, utcDate);
};
