import parseInstrumentToFormData from './helpers/parseInstrumentToFormData';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  CONSTITUENT_STEP,
  EXCHANGE_STEP,
  GENERAL_DETAILS_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import {
  useActiveStep,
  useCurrentInstrument,
  useCurrentInstrumentFormData,
  useDirtyInstrumentFormData,
} from 'store/instruments/selectors';
import { InstrumentFormDataType } from 'pages/Instruments/Instruments.types';
import { ProductStatus } from 'utils/types/product';
import { SaveProductDraftOptions } from 'utils/types';
import { instrumentsActions } from 'store/instruments/slice';
import { isEqual } from 'lodash';
import { saveInstrumentAction } from 'store/instruments/actions';
import {
  CUSTODIAN_INFO_STEP,
  WALLETS_INFO_STEP,
} from './components/Form/PartnerDetails/PartnerDetails.steps';

export function useSaveETPHook() {
  const { instrument: ETP } = useCurrentInstrument();
  const activeStep = useActiveStep();
  const currentInstrumentFormData = useCurrentInstrumentFormData(activeStep);
  const touchedActiveInstrumentFormData = useDirtyInstrumentFormData(activeStep);
  const dispatch = useAppDispatch();
  const { populateCurrentDirtyInstrumentFormData } = instrumentsActions;

  const saveInstrumentToRemoteAndStore = async (
    stepData: { [step: string]: InstrumentFormDataType },
    options?: SaveProductDraftOptions
  ) => {
    const data = parseInstrumentToFormData(ETP);
    const updatedFormData = {
      ...data,
      ...stepData,
    };
    if (
      !isEqual(updatedFormData, data) &&
      updatedFormData[GENERAL_DETAILS_STEP]?.name &&
      updatedFormData[GENERAL_DETAILS_STEP]?.productType &&
      updatedFormData[GENERAL_DETAILS_STEP]?.ticker
    ) {
      return await dispatch(saveInstrumentAction(stepData, { ...options, id: ETP?._id }));
    }
  };

  const saveInstrumentToStore = async (stepData: { [step: string]: InstrumentFormDataType }) => {
    return dispatch(
      populateCurrentDirtyInstrumentFormData({
        ...currentInstrumentFormData,
        ...touchedActiveInstrumentFormData,
        ...stepData,
      })
    );
  };

  const saveInstrumentDraft = async (
    stepData: { [step: string]: InstrumentFormDataType },
    options?: SaveProductDraftOptions
  ) => {
    // TODO: We are doing this filtering since Steps with Elements are showing the modal for update
    // and we are doing Active Instrument Update on spot with updated action button
    if (
      ETP?.status === ProductStatus.ACTIVE &&
      ![EXCHANGE_STEP, CONSTITUENT_STEP, CUSTODIAN_INFO_STEP, WALLETS_INFO_STEP].includes(
        activeStep ?? ''
      )
    ) {
      return saveInstrumentToStore(stepData);
    } else {
      return saveInstrumentToRemoteAndStore(stepData, options);
    }
  };

  const deleteUpdatedField = (fieldName: string) => {
    const updatedValues = {
      [activeStep as string]: {
        ...touchedActiveInstrumentFormData,
        [fieldName]: (currentInstrumentFormData as { [key: string]: string })[fieldName],
      },
    };
    if (!(currentInstrumentFormData as { [key: string]: string })[fieldName]) {
      delete updatedValues[fieldName];
    }
    saveInstrumentToStore(updatedValues);
  };

  return {
    saveInstrumentDraft,
    saveInstrumentToStore,
    saveInstrumentToRemoteAndStore,
    deleteUpdatedField,
  };
}
