import Login from 'pages/Login';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from 'utils/user/storage';
import { privateRoutesUrls } from 'router/constants';

export function LoginRouteGuard() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(privateRoutesUrls.dashboardRoutes.rootPath);
    }
  }, [navigate]);

  return <Login />;
}

export default LoginRouteGuard;
