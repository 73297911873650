import { GetAllPCFResponse } from 'utils/types/pcfs';
import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { PortfolioComposition } from 'utils/types/pcfs';
import { axiosInstance } from 'utils/axiosOnyx';
import { errorHandler } from 'utils/apiUtils';

export const fetchAllPCFs = async (params?: ParamsType) => {
  try {
    return await axiosInstance.get<GetAllPCFResponse>(
      params ? `/portfolio-composition?${getQueryString(params)}` : `/portfolio-composition`
    );
  } catch (err) {
    return errorHandler(err, 'Error while fetching PCFs');
  }
};

export const getPCF = async (id: string, valuationDate: string) => {
  try {
    return await axiosInstance.get<PortfolioComposition>(
      `/portfolio-composition/productId=${id}/valuationDate=${valuationDate}`
    );
  } catch (err) {
    return errorHandler(err, 'Error while loading PCF');
  }
};

export const uploadPCF = async (productId: string, pcf: File) => {
  const data = new FormData();
  data.append('file', pcf);

  try {
    return await axiosInstance.post<PortfolioComposition>(
      `/portfolio-composition/upload/id=${productId}`,
      data,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  } catch (err) {
    return errorHandler(err, 'Error while uploading PCF');
  }
};
