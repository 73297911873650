import { GeneralDetailsProps } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.types';
import { tokenGeneralDetailsSchemaUrl } from 'components/Form/formSchemas';
import Form from 'components/Form/Form';
import Select from 'components/Select';
import Input from 'components/Input';
import { useTokenContext } from 'contexts/tokens';
import { useSaveToken } from 'hooks/useTokens';
import { TokenStepSaveProgress } from 'pages/Tokens/components/SaveProgress';
import { TokenType } from 'utils/types/product';
import { GENERAL_DETAILS_STEP } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import Footer from 'pages/Tokens/components/Footer';
import { tokenStandardMapping } from 'pages/Tokens/Tokens.constants';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';

interface GeneralDetailsStepProps {
  onSubmit: () => void;
}

function GeneralDetailsStep({ onSubmit }: GeneralDetailsStepProps) {
  const { isLoadingToken, tokenFormsData } = useTokenContext();
  const { saveToken, isSaving } = useSaveToken();
  const generalDetailsFormData = tokenFormsData[GENERAL_DETAILS_STEP];

  const saveValues = async (data: GeneralDetailsProps) => {
    if (data.destinationChain && data.name && data.ticker) {
      try {
        saveToken({ [GENERAL_DETAILS_STEP]: data });
      } catch (error) {
        console.error('Error saving token:', error);
      }
    }
  };

  const tokenStandardOptions = Object.entries(tokenStandardMapping).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  return (
    <Form<GeneralDetailsProps>
      initialValues={{ ...generalDetailsFormData, type: TokenType.SINGLE_ASSET }}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={tokenGeneralDetailsSchemaUrl}
      loading={isLoadingToken}
    >
      <FormGridContainer>
        <FormGridItem>
          <ProductConfigHeader title="General Details" />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <TokenStepSaveProgress saving={isSaving} />
        </FormGridItem>
        <FormGridItem>
          <Input name="name" />
        </FormGridItem>
        <FormGridItem>
          <Input name="ticker" />
        </FormGridItem>
        <FormGridItem>
          <Select
            name="destinationChain"
            data-qa-id="destinationChain"
            data-qa-options-id="destinationChain"
          />
        </FormGridItem>
        <FormGridItem>
          <Select name="sourceChain" data-qa-id="sourceChain" data-qa-options-id="sourceChain" />
        </FormGridItem>
        <FormGridItem>
          <Select
            name="tokenStandard"
            data-qa-id="tokenStandard"
            data-qa-options-id="tokenStandard"
            options={tokenStandardOptions}
            capitalize={false}
          />
        </FormGridItem>
        <FormGridItem>
          <Input name="contractAddress" />
        </FormGridItem>
        <FormGridItem marginTop={2} md={12}>
          <ProductConfigHeader title="Admin Fees" />
        </FormGridItem>
        <FormGridItem>
          <Input name="mintFees" />
        </FormGridItem>
        <FormGridItem>
          <Input name="burnFees" />
        </FormGridItem>
      </FormGridContainer>
      <Footer isFirstStep />
    </Form>
  );
}

export default GeneralDetailsStep;
