import { Box, styled as MuiStyled, Typography } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';

export const CellContainer = MuiStyled('td')`
  padding-inline: ${({ theme }) => `${theme.spacers['spacing-16']}`};
  padding-top: ${({ theme }) => `${theme.spacers['spacing-16']}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
`;

export const RowContent = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacers['spacing-04']}`};
  padding: ${({ theme }) => `${theme.spacers['spacing-16']} 0`};
  align-items: flex-start;
  cursor: pointer;
`;

export const RowFooter = MuiStyled(Box)`
  display: flex;
  gap: ${({ theme }) => `${theme.spacers['spacing-16']}`};
  padding: ${({ theme }) => `${theme.spacers['spacing-12']} 0`};
  justify-content: space-between;
  align-items: center;
`;

export const ContactsCount = MuiStyled(Box)`
  ${() => fontTypes['body-02']};
  color: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledTitle = MuiStyled(Box)`
  ${() => fontTypes['heading-03']};
  color: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledRow = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const StyledToolTipRowsContainer = MuiStyled(Box)`
  width: fit-content;
  max-width: inherit;
  margin: 8px;
  background:  ${({ theme }) => theme.customPalette.interactive.fill.secondary.enabled}
  > div {
    padding: 12px 16px;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.light};
    }
  }
`;

export const StyledTitleDiv = MuiStyled(Typography)`
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 8px;
`;

export const StyledContentDiv = MuiStyled(Typography)`
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  word-break: break-word;
  display: block;
`;
