import TabNavigation from 'components/TabNavigation';
import { BackButton } from 'shared/BackButton';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ParamsType } from 'hooks/useUrlParams';
import { ReactNode, useMemo } from 'react';
import { StyledTitleAreaHeader, StyledTitleAreaTabs } from './TitleArea.styles';

interface TitleAreaTabConfig {
  tabs?: { name: string; param: string }[];
  rootTab?: { name: string; param: string };
  setUrlParamsWithDefaults?: (params: ParamsType) => void;
  active?: string;
}

export const TitleArea = ({
  title,
  showBackButton = false,
  actionButton = null,
  tabConfig,
}: {
  title: string | ReactNode;
  showBackButton?: Boolean;
  actionButton?: ReactNode | null;
  tabConfig?: TitleAreaTabConfig;
}) => {
  const theme = useTheme();
  const tabs = tabConfig?.tabs;
  const rootTab = tabConfig?.rootTab;
  const setUrlParamsWithDefaults = tabConfig?.setUrlParamsWithDefaults;
  const activeTab = tabConfig?.active;
  const mappedTabs = useMemo(
    () =>
      tabs?.map((tab) => ({
        name: tab.name,
        param: tab.param,
        onSelect: () => {
          rootTab
            ? setUrlParamsWithDefaults &&
              setUrlParamsWithDefaults({ tab: rootTab.name, subtab: rootTab.param })
            : setUrlParamsWithDefaults && setUrlParamsWithDefaults({ tab: tab.param });
        },
      })),
    [rootTab, setUrlParamsWithDefaults, tabs]
  );
  return (
    <Box>
      <StyledTitleAreaHeader>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
            {showBackButton && (
              <BackButton
                sx={{
                  alignSelf: 'center',
                  border: `1px solid ${theme.customPalette.static.icon.inverse.weak}`,
                  '& svg': {
                    fill: theme.customPalette.static.icon.inverse.strong,
                  },
                }}
              />
            )}
            <Typography
              variant="headingSmall"
              color={'#F9FBFD'}
              fontWeight={500}
              lineHeight={'32px'}
              alignSelf={'center'}
              marginLeft={showBackButton ? 2 : 0}
              sx={{ paddingTop: '7px', paddingBottom: '7px' }}
            >
              {title}
            </Typography>
          </Grid>
          {actionButton}
        </Box>
        <StyledTitleAreaTabs className="titleAreaTabs">
          {mappedTabs && (
            <TabNavigation activeParam={activeTab ?? ''} tabs={mappedTabs} tabSize={'small'} />
          )}
        </StyledTitleAreaTabs>
      </StyledTitleAreaHeader>
    </Box>
  );
};
