import useAppDispatch from 'hooks/useAppDispatch';
import { useCallback, useEffect, useRef, useState } from 'react';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { loadInstrumentAPs } from 'utils/api/instruments';
import { InstrumentAPs } from 'utils/types';

export const useAuthorizedParticipants = (productId?: string) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<InstrumentAPs[]>([]);

  const [loading, setLoading] = useState(false);
  const dataLoading = useRef(false);

  const fetchData = useCallback(async () => {
    try {
      if (productId) {
        dataLoading.current = true;
        setLoading(true);
        const res = await loadInstrumentAPs(productId);
        setData(res);
      }
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    } finally {
      dataLoading.current = false;
      setLoading(false);
    }
  }, [productId, dispatch]);

  useEffect(() => {
    if (!dataLoading.current) {
      fetchData();
    }
  }, [productId, fetchData]);

  return {
    instrumentAPs: data.map((instrument) => ({ label: instrument.name, value: instrument.id })),
    loading,
    refetch: fetchData,
  };
};
