import { AxiosError, AxiosResponse } from 'axios';
import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { PaginationWithDataResponse } from 'utils/types';

import {
  Contact,
  ContactList,
  ContactListContacts,
  ContactListRequestDto,
  ContactResponse,
  PartnerContact,
} from 'utils/types/contacts';

export const createContact = async (
  contact: Contact
): Promise<AxiosResponse<Contact, AxiosError>> => {
  try {
    return await axiosInstance.post(`/admin/contacts`, contact);
  } catch (err) {
    return errorHandler(err, 'Error while creating a wallet');
  }
};

export const fetchAllContacts = async (params?: ParamsType) => {
  try {
    return await axiosInstance.get<ContactResponse>(
      params ? `/admin/contacts?${getQueryString(params)}` : `/admin/contacts`
    );
  } catch (err) {
    return errorHandler(err, 'Error while fetching contacts');
  }
};

export const getContactList = async (id: ContactList['_id']) => {
  try {
    return await axiosInstance.get<ContactList>(`/admin/contact-lists/id=${id}`);
  } catch (err) {
    return errorHandler(err, 'Error while loading contact list');
  }
};

export const getContactListContacts = async (id: ContactList['_id']) => {
  try {
    return await axiosInstance.get<ContactListContacts>(`/admin/contact-lists/id=${id}/contacts`);
  } catch (err) {
    return errorHandler(err, 'Error while loading contact list contacts');
  }
};

export const fetchContactLists = async (params?: ParamsType) => {
  try {
    return await axiosInstance.get<PaginationWithDataResponse<ContactList[]>>(
      params ? `/admin/contact-lists?${getQueryString(params)}` : `/admin/contact-lists`
    );
  } catch (err) {
    return errorHandler(err, 'Error while fetching contacts');
  }
};

export const fetchAllExternalContacts = async (params?: ParamsType) => {
  try {
    return await axiosInstance.get<ContactResponse>(
      params
        ? `/admin/contacts/type=external?${getQueryString(params)}`
        : `/admin/contacts/type=external`
    );
  } catch (err) {
    return errorHandler(err, 'Error while fetching contacts');
  }
};

export const fetchAllPartnerContacts = async (params?: ParamsType) => {
  try {
    return await axiosInstance.get<PaginationWithDataResponse<PartnerContact[]>>(
      params
        ? `/admin/contacts/type=partner?${getQueryString(params)}`
        : `/admin/contacts/type=partner`
    );
  } catch (err) {
    return errorHandler(err, 'Error while fetching partner contacts');
  }
};

export const createContactList = async (
  contactList: ContactListRequestDto
): Promise<AxiosResponse<ContactList, AxiosError>> => {
  try {
    return await axiosInstance.post(`/admin/contact-lists`, contactList);
  } catch (err) {
    return errorHandler(err, 'Error while creating contact list');
  }
};

export const updateContactList = async (
  contactList: ContactListRequestDto
): Promise<AxiosResponse<ContactList, AxiosError>> => {
  try {
    return await axiosInstance.patch(`/admin/contact-lists/id=${contactList._id}`, contactList);
  } catch (err) {
    return errorHandler(err, 'Error while updating contact list');
  }
};

export const addContactsToContactList = async (
  contactList: ContactList,
  contacts: {
    contacts?: string[];
    partners?: string[];
  }
): Promise<AxiosResponse<ContactList, AxiosError>> => {
  try {
    return await axiosInstance.patch(`/admin/contact-lists/id=${contactList._id}/assign`, contacts);
  } catch (err) {
    return errorHandler(err, `Error while adding contacts to ${contactList.name}`);
  }
};

export const removeContactsFromContactList = async (
  contactList: ContactList,
  contacts: {
    contacts: string[];
    partners: string[];
  }
): Promise<AxiosResponse<ContactList, AxiosError>> => {
  try {
    return await axiosInstance.patch(
      `/admin/contact-lists/id=${contactList._id}/unassign`,
      contacts
    );
  } catch (err) {
    return errorHandler(err, `Error while removing contacts from ${contactList.name}`);
  }
};
