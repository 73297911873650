import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Contact } from 'utils/types/contacts';
import { PaginationProps } from 'utils/types';
import { ParamsType } from 'hooks/useUrlParams';
import { YourContactsTableColumnsconfig } from './tableColumnsConfig';
import { YourContactsTableRow } from './YourContactsTableRow';
import { fieldFilter, getPaginationSliceValues, searchFilter } from 'utils/filters';

interface YourContactsTableProps {
  contacts: Contact[];
  isLoading: Boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  editAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
  pagination: PaginationProps;
}

export const YourContactsTable = ({
  contacts,
  isLoading,
  setUrlParams,
  urlParams,
  editAction,
  deleteAction,
  pagination,
}: YourContactsTableProps) => {
  const yourContacts = contacts ?? [];

  const { page, itemsPerPage } = pagination;
  const filteredContacts = yourContacts
    .filter((item) => fieldFilter(item.type, urlParams?.type))
    .filter(
      (item) =>
        item.contactLists.some((list) => fieldFilter(list.id, urlParams?.contactLists)) ||
        !urlParams?.contactLists ||
        (Array.isArray(urlParams?.contactLists) && !urlParams?.contactLists.length)
    )
    .filter((item) => searchFilter(item, ['name', 'email'], urlParams?.search));
  const paginationValues = getPaginationSliceValues(page, itemsPerPage, filteredContacts?.length);

  return (
    <>
      <TableHeaderWithMultiSort
        showActionsCell
        defaultSortedColumns={urlParams.sort ?? []}
        columns={YourContactsTableColumnsconfig}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(isLoading)}
        hasContent={!isEmpty(filteredContacts)}
        noContentLabel="No Contacts."
      >
        {filteredContacts?.slice(...paginationValues).map((contact, index) => (
          <YourContactsTableRow
            key={contact.email}
            contact={contact}
            editAction={(contact) => {
              editAction(contact);
            }}
            deleteAction={deleteAction}
          />
        ))}
      </TableBodyWithStates>
    </>
  );
};
