import { ActiveModalsType } from 'shared/Modals/types';
import useAppDispatch from './useAppDispatch';
import { showModal } from 'store/modals/slice';
import { ModalDataType } from 'store/modals/types';
import { useModalContext } from 'shared/Contexts/Modal/useModalContext.hook';

export interface ModalData {
  modalName: ActiveModalsType;
  modalData: ModalDataType;
}

const useAppModal = () => {
  const dispatch = useAppDispatch();
  const { updateOnCloseModalAction } = useModalContext();
  return (
    modalObject: ModalData,
    additionalData?: { onCloseModalAction?: (data?: any) => void }
  ) => {
    updateOnCloseModalAction(additionalData?.onCloseModalAction);
    return dispatch(showModal(modalObject));
  };
};
export default useAppModal;
