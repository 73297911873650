import Notification from 'components/Notification';
import isFeatureEnabled, { FeatureFlags } from 'utils/featureFlag';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, PCF_UPLOAD_MODAL } from 'shared/Modals/constants';
import { PortfolioComposition, PortfolioCompositionStatus } from 'utils/types/pcfs';
import { User } from 'utils/types';
import { privateRoutesUrls } from 'router/constants';
import { useNavigate } from 'react-router-dom';
import { useUserPermissions } from 'store/user/selectors';

interface PCFDetailsStatusBarProps {
  pcf?: PortfolioComposition;
}

function renderErrorsCount(
  openUploadPCFModal: (pcf?: PortfolioComposition) => void,
  permissions?: User['permissions'],
  pcf?: PortfolioComposition
) {
  const canOverridePcf =
    isFeatureEnabled(FeatureFlags.PCF_UPLOAD) &&
    pcf?._actions.upload &&
    permissions?.canOverridePcf;
  const pcfErrorsCount =
    (pcf?.errors?.constituent?.errors?.length || 0) +
    (pcf?.errors?.totalNav?.errors?.length || 0) +
    (pcf?.errors?.totalProjectedNavCreationUnit?.errors?.length || 0) +
    (pcf?.errors?.totalProjectedNavPerUnit?.errors?.length || 0) +
    (pcf?.errors?.totalUnitsOutstanding?.length || 0);

  if (pcfErrorsCount > 0) {
    return (
      <Notification
        action={() => canOverridePcf && openUploadPCFModal(pcf)}
        actionText={canOverridePcf ? 'Upload file' : undefined}
        messageMaxWidth={true}
        message="Errors found in the PCF files. Please re-upload to fix discrepancies"
        multiline={'false'}
        title={`${pcfErrorsCount} errors found`}
        variant="error"
      />
    );
  }
  if (pcf?.status === PortfolioCompositionStatus.PENDING) {
    return (
      <Notification
        messageMaxWidth={true}
        message="Zero errors found. You can approve the PCF and APs will receive it”"
        multiline={'false'}
        title="Zero errors found"
        variant="info"
      />
    );
  }
  if (pcf?.status === PortfolioCompositionStatus.APPROVED) {
    return (
      <Notification
        messageMaxWidth={true}
        message="Zero errors found. PCF is approved and accessible by APs"
        multiline={'false'}
        title="Zero errors found"
        variant="success"
      />
    );
  }
  return null;
}

function PCFDetailsStatusBar({ pcf }: PCFDetailsStatusBarProps) {
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const navigate = useNavigate();

  if (!permissions?.canOverridePcf) {
    return null;
  }

  const openUploadPCFModal = (pcf?: PortfolioComposition) => {
    openModal({
      modalName: PCF_UPLOAD_MODAL,
      modalData: {
        data: pcf,
        type: MODAL_ACTIONS.CREATE,
      },
    });
  };

  const rebalanceNotApproved = pcf?.isRebalanceApproved === false && pcf?.isRebalanceDay === true;

  return (
    <>
      {pcf?.isPendingUpload ? (
        <Notification
          action={
            isFeatureEnabled(FeatureFlags.PCF_UPLOAD) && pcf._actions.upload
              ? () => openUploadPCFModal(pcf)
              : undefined
          }
          actionText="Upload file"
          messageMaxWidth={true}
          message="Please upload the PCF"
          multiline={'false'}
          title="PCF not uploaded."
          variant="error"
        />
      ) : (
        renderErrorsCount(openUploadPCFModal, permissions, pcf)
      )}
      {rebalanceNotApproved && (
        <Notification
          action={() => navigate(privateRoutesUrls.dashboardRoutes.rebalance)}
          actionText="Rebalance"
          messageMaxWidth={true}
          message="PCF cannot be approved on rebalance day for a fund until the rebalance is approved"
          multiline={'false'}
          title="Rebalance needed"
          variant="error"
        />
      )}
    </>
  );
}

export default PCFDetailsStatusBar;
